import makeStyles from "@mui/styles/makeStyles";
import { Bold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  total_text: {
    fontSize: remCalc(16),
    fontFamily: Bold,
    color: "#071741",
  },
}));