import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold, remCalc, vehicleTypeBGSVG } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.main,
        padding: "0px",
    },
    security_card_div: {
        display: "flex",
    },
    security_card_root: {
        minWidth: (props) => props?.dimension?.width >= 600 ? 552 : props?.dimension?.width,
        padding: "16px",
    },
    security_card: {
        borderRadius: "4px",
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.16)"
    },
    gate_info_div: {
        padding: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    gate_info: {
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 8px",
        borderRadius: "4px",
        backgroundColor: "white"
    },
    gate_no_label: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "#98A0AC"
    },
    gate_no_value: {
        fontSize: "0.75rem",
        fontFamily: ExtraBold,
        color: "#5078E1",
        marginInlineStart: "4px"
    },
    guard_info_div: {
        backgroundColor: "white",
        padding: "12px 12px 10px 12px",
        borderRadius: "0px 0px 4px 4px"
    },
    guard_info_div_1: { justifyContent: "space-between", alignItems: "center" },
    guard_info_div_2: { justifyContent: "start", alignItems: "center" },
    guard_name: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: "#091B29"
    },
    guard_no: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
        direction: "ltr"
    },
    call_button: {
        cursor: "pointer",
        borderRadius: "4px",
        border: "1px solid #E4E8EE",
        height: "40px",
        width: "40px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    tab_div: {
        backgroundColor: "white",
        borderRadius: "4px 4px 0px 0px",
        padding: "16px 16px 0px 16px"
    },
    search_filter_div: {
        display: "flex"
    },
    parking_units_div: {
        height: (props) => props?.dimension?.height - 233,
        overflow: "overlay",
        padding: "16px 0px"
    },
    parking_unit_heading: {
        fontFamily: Bold,
        color: "#98A0AC",
        fontSize: "0.75rem",
        marginBottom: "8px"
    },
    parking_card_vacant: {
        minHeight: "159px",
        cursor: "pointer",
        borderRadius: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        backgroundColor: "#F2F6FF"
    },
    parking_card_occupied: {
        minHeight: "159px",
        cursor: "pointer",
        borderRadius: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        backgroundColor: "#F9F0FF"
    },
    common_parking_card: {
        cursor: "pointer",
        justifyContent: "start",
        alignItems: "center"
    },
    common_parking_avatar: {
        borderRadius: "4px",
        backgroundColor: "#F5F7FA",
        height: "42px",
        width: "42px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    common_parking_name: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: "#4E5A6B"
    },
    common_parking_type: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "#4E5A6B"
    },
    horizontal_divider: {
        height: "1px",
        display: "flex",
        background: "#E4E8EE"
    },
    drawer: {
        "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
            "background-color": "transparent",
            "box-shadow": "none",
        },
    },
    dialog: {
        "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
            "background-color": "transparent",
            "box-shadow": "none",
        },
    },
    dialog_style: {
        background: "white",
        borderRadius: "4px",
        border: "1px solid #F9FAFE",
        justifyContent: "center",
    },
    drawer_header_style: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 16px 16px 16px",
    },
    drawer_header_text_style: {
        fontSize: "1rem",
        fontFamily: ExtraBold,
        color: "#091B29",
    },
    drawer_body_style: {
        alignItems: "center",
        padding: "16px",
        display: "flex"
    },
    bottom_sheet_style: {
        background: "white",
        borderRadius: "4px 4px 0px 0px",
        border: "1px solid #F9FAFE",
        justifyContent: "center",
    },
    view_cards_div: {
        backgroundColor: "rgba(80, 120, 225, 0.06)",
        borderRadius: "4px",
        padding: "12px"
    },
    icon_div: {
        display: "flex",
        alignItems: "center",
        height: "30px",
        width: "30px"
    },
    label: {
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "#98A0AC"
    },
    value: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "#091B29"
    },
    parking_slot_card: {
        padding: "16px",
        borderRadius: "4px",
        height: "110px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        backgroundImage: (props) => `url("${vehicleTypeBGSVG[props?.vehicle_type] ?? `/images/parkingSlotCarBGSVG.svg`}")`,
        backgroundColor: "rgba(80, 120, 225, 0.06)",
    },
    parking_slot_value: {
        fontFamily: ExtraBold,
        fontSize: "1.25rem",
        color: "#091B29"
    },
    slot_name_occupied: {
        color: "#98A0AC",
        fontFamily: Bold,
        fontSize: "0.75rem"
    },
    slot_name_vacant: {
        textAlign: "center",
        color: "#008C33",
        fontFamily: Bold,
        fontSize: "0.75rem"
    },
    booking_id: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: "#5078E1",
        fontFamily: SemiBold,
        fontSize:"0.75rem"
    },
    vehicle_type: {
        color: "#008C33",
        fontFamily: SemiBold,
        fontSize:"0.75rem"
    },
    available: {
        color: "#5AC782",
        fontSize:"0.75rem",
        fontFamily: Regular
    },
    vehicle_no_div: {
        padding: "4px 8px",
        backgroundColor: "white",
        borderRadius: "4px",
        border: "1px solid #E6ECFF"
    },
    vehicle_no: {
        color: "#4E5A6B",
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    drawer_1: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog_1: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    no_data_found_div: {
        padding: "20px 0px 0px 0px",
        justifyContent: "center",
        alignItems: "center"
    },
    no_data_found_text: {
        textAlign: "center",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        fontSize: remCalc(14)
    }
}))