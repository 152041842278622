export const PassIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_55929" data-name="Rectangle 55929" width="24" height="24" transform="translate(82 183)" fill="#f15a29"/>
        </clipPath>
      </defs>
      <g id="Group_108832" data-name="Group 108832" transform="translate(-82 -183)" clipPath="url(#clip-path)">
        <path id="icons8-ticket" d="M22.333,14.062A.714.714,0,0,0,23,13.348V11.667A1.668,1.668,0,0,0,21.333,10H17.286v.952a.714.714,0,0,1-1.429,0V10H4.667A1.668,1.668,0,0,0,3,11.667v1.681a.714.714,0,0,0,.667.714,2.611,2.611,0,0,1,0,5.21A.714.714,0,0,0,3,19.986v1.681a1.668,1.668,0,0,0,1.667,1.667h11.19v-.952a.714.714,0,0,1,1.429,0v.952h4.048A1.668,1.668,0,0,0,23,21.667V19.986a.714.714,0,0,0-.667-.714,2.611,2.611,0,0,1,0-5.21Zm-5.048,5.462a.714.714,0,0,1-1.429,0V18.1a.714.714,0,0,1,1.429,0Zm0-4.286a.714.714,0,0,1-1.429,0V13.81a.714.714,0,0,1,1.429,0Z" transform="translate(81 178.333)" fill="#78b1fe"/>
      </g>
    </svg>
    );
    