import { Box, Stack, Typography } from '@mui/material';
import React from 'react'
import { BackupAndRestoreErrorStyle } from './style';
import BackupRestoreErrorSVG from '../../assets/backupRestoreError';
import { withNamespaces } from 'react-i18next';

const BackupAndRestoreError = ({
    t = () => false
}) => {
    const classes = BackupAndRestoreErrorStyle()
    return (
        <Box className={classes.box}>
            <Stack className={classes.contentBox} spacing={3}>
                <BackupRestoreErrorSVG />
                <Typography className={classes.contentText}>
                    {t("Request cannot be processed. Backup Restoring is In Progress")}
                </Typography>
            </Stack>
        </Box>
    )
}
export default withNamespaces("backupAndRestoreError")(BackupAndRestoreError)