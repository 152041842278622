import * as React from "react"
const CIOLocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 117574" transform="translate(20150 1079)">
      <rect
        width={40}
        height={40}
        fill="#fef4f4"
        data-name="Rectangle 55701"
        rx={6}
        transform="translate(-20150 -1079)"
      />
      <path
        fill="#ff4b4b"
        d="M-20130-1070a9 9 0 0 0-9 9c0 5.919 5.852 8.752 6.585 9.412a7.6 7.6 0 0 1 1.615 3.038.82.82 0 0 0 1.6 0 7.606 7.606 0 0 1 1.617-3.039c.731-.659 6.583-3.492 6.583-9.411a9 9 0 0 0-9-9Zm0 11.455a2.455 2.455 0 1 1 2.455-2.455 2.454 2.454 0 0 1-2.455 2.455Z"
        data-name="icons8-location (7)"
      />
    </g>
  </svg>
)
export default CIOLocationIcon
