
import { Box, Dialog, DialogContent, DialogTitle, Drawer, Hidden } from "@mui/material";
import * as React from "react";
import { useStyles } from "./styles";
export const DialogDrawer = (props) => {
    const classes = useStyles(props);
    return (
        <div>
            <Hidden smDown>
                <Dialog
                    open={props?.open}
                    onClose={props?.onClose}
                    aria-labelledby="alert-dialog-title"
                    className={classes.dialog}
                    maxWidth={props?.width ?? "xs"}
                    fullWidth
                    aria-describedby="alert-dialog-description"
                    sx={{padding:props?.is_filter?"0px":"auto"}}
                >
                    {
                        !props?.isnotTitle &&
                        <DialogTitle className={classes.header}>
                            <span>{props?.header}</span>{" "}
                            <img
                                src="/images/close.svg"
                                alt="close"
                                onClick={props?.onClose}
                                style={{ cursor: 'pointer' }}
                            />
                        </DialogTitle>
                    }

                    <DialogContent style={{ padding: "0 !important" }}>
                        <Box height={props?.height ?? "auto"} overflow="auto" p={2} className={classes.component}>
                            {props?.component}
                        </Box>
                    </DialogContent>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor={props?.anchor??"bottom"}
                    className={classes.drawer}
                    open={props?.open}
                    onClose={props?.onClose}
                >
                    {
                        !props?.isnotTitle &&
                        <DialogTitle className={classes.header}>
                            <span>{props?.header}</span>{" "}
                            <img
                                src="/images/close.svg"
                                alt="close"
                                onClick={props?.onClose}
                                style={{ cursor: 'pointer' }}
                            />
                        </DialogTitle>
                    }

                    <Box height={props?.height ?? "auto"} overflow="auto" p={props?.padding??2} className={classes.component}>
                        {props?.component}
                    </Box>
                </Drawer>
            </Hidden>
        </div>
    );
}
