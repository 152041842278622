import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  label: {
    color: (props) => props?.labelColor ?? theme.typography.color.tertiary,
    fontFamily: (props) => (props?.fontFamily === "bold" ? Bold : SemiBold),
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "8px",
    textAlign: (props) => props?.textAlign ?? "-webkit-match-parent",

  },
  required: {
    color: "red",
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
}));
