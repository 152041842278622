import * as React from "react"
export const Cameraff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 117698">
      <g fill="#ffecec" stroke="#ff4b4b" data-name="Ellipse 131002">
        <circle cx={24} cy={24} r={24} stroke="none" />
        <circle cx={24} cy={24} r={23.5} fill="none" />
      </g>
      <path
        fill="#ff4b4b"
        d="M33.228 14a.75.75 0 0 0-.515.226L14.22 32.72a.75.75 0 1 0 1.06 1.06l2.279-2.28h7.187a3.249 3.249 0 0 0 3.249-3.249v-7.186l5.778-5.778A.75.75 0 0 0 33.228 14Zm-15.979 2.506A3.249 3.249 0 0 0 14 19.755v8.5a3.23 3.23 0 0 0 .649 1.943l12.558-12.561a3.241 3.241 0 0 0-2.461-1.131Zm15.984 2a.749.749 0 0 0-.376.107l-3.863 2.318v6.146l3.863 2.318a.75.75 0 0 0 1.135-.642v-9.5a.751.751 0 0 0-.759-.75Z"
      />
    </g>
  </svg>
)
