import * as React from "react"
export const CameraOn = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 117699">
      <g fill="#f2f4f7" stroke="#e4e8ee" data-name="Ellipse 131002">
        <circle cx={24} cy={24} r={24} stroke="none" />
        <circle cx={24} cy={24} r={23.5} fill="none" />
      </g>
      <path
        fill="#091b29"
        d="M28 28.75A3.25 3.25 0 0 1 24.75 32h-7.5A3.25 3.25 0 0 1 14 28.75v-8.5A3.25 3.25 0 0 1 17.25 17h7.5A3.25 3.25 0 0 1 28 20.25Zm5.62-9.65a.751.751 0 0 0-.756.009L29 21.426v6.149l3.865 2.318A.75.75 0 0 0 34 29.25v-9.5a.75.75 0 0 0-.38-.65Z"
        data-name="icons8-video-call (4)"
      />
    </g>
  </svg>
)
