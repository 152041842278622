import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
import { dashboardStyles } from "./style";
import { AuthContext } from "../../contexts";
import { DocumentViewerPDF } from "../documentViewer";

export const Slider = (props) => {
  const { heightSx,customStyle} = props
  const classes = dashboardStyles(props);
  const [selected, setSelected] = React.useState(0)

  const auth = React.useContext(AuthContext)

  const next = () => {
    if ((props?.assets?.length - 1) > selected) {
      setSelected(selected + 1)
    }
  }

  const previous = () => {
    if (selected !== 0) {
      setSelected(selected - 1)
    }
  }

  React.useEffect(() => {
    setSelected(0)
  }, [props?.assets])

  return (
    <div className={classes.caroselRoot}>
      {/* carosel */}
      <Grid container alignItems="center">
        {props?.uploadImage ?
          <Grid item xs={12}>
            {props?.assets &&
              < Box >
                {props?.assets?.asset_type === 4 ?
                  <DocumentViewerPDF url={props?.assets?.url} />
                  :
                  < img
                    src={props?.assets?.url ? props?.assets?.url : null}
                    alt=""
                    height="500px"
                    width="100%"
                    style={{ borderRadius: "4px !important", objectFit: "contain", }}
                  />
                }
              </Box>
            }
          </Grid>
          :
          <>
            <Grid item xs={props?.leftDivider ?? 1} justifySelf="flex-end" className={customStyle}>

              <IconButton sx={{ float: "right" }} disabled={selected === 0} onClick={previous} size="small" className={classes.arrowBtn}>

                <ArrowBackIosIcon style={{ fontSize:"0.75rem", transform: auth?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }} />
              </IconButton >


            </Grid >
            <Grid item xs={props?.divider ?? 10}> 
              {props?.assets[selected] &&
                < Box >
                  {props?.assets[selected]?.asset_type === 4 ?
                    <Box sx={{ height: "500px", width: "100%", margin: "0 auto" }}>
                      <DocumentViewerPDF url={props?.assets[selected]?.url} /> 

                    </Box >
                    :
                    <img
                      src={props?.imageOnly ? props?.assets[selected] : props?.assets[selected]?.url ? props?.assets[selected]?.url : props?.assets[selected]?.src}
                      alt=""
                      height={heightSx ? heightSx : "500px"}
                      width="100%"
                      style={{ borderRadius: "4px !important", objectFit: "contain" }}
                    />}
                </Box >
              }
            </Grid >
            <Grid item xs={props?.rightDivider ?? 1}  className={customStyle}>

              <IconButton disabled={(props?.assets?.length - 1) > selected ? false : true} onClick={next} size="small" className={classes.arrowBtn}>

                <ArrowForwardIosIcon style={{ fontSize:"0.75rem", transform: auth?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }} />
              </IconButton >


            </Grid >
          </>
        }


      </Grid >
    </div >
  );
};
