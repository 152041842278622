import { Box, Stack, IconButton, Avatar, Typography } from "@mui/material"
import { CameraOn, Cameraff, Micoff, Micon, SwitchOn, Switchoff } from "../../assets";
import { TitleBar } from "../../components"
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import { GuestDetailCard } from "./component";


const VideoCall = ({ goBack = () => false, controls = {}, accessControl = () => false, details = {}, partnerControl = {}, t = () => false }) => {

    const size = useWindowDimensions()
    const classes = useStyles(size);
    return (
        <Box class={classes.root}>
            <TitleBar goBack={goBack} text="Video Call" />


            <Box height={size?.height - 374} style={{ width: "100%" }}>

                {
                    !partnerControl?.video &&
                    <center style={{ paddingTop: "20vh" }}>
                        <Avatar src={details?.notes?.[0]?.resident_image} style={{ height: 200, width: 200 }} />
                    </center>
                }

                <video id="partner-video" autoPlay playsInline style={{ height: partnerControl?.video ? "100%" : "0px" }} />

            </Box>

            <Box className={classes.videoBottomRoot} padding="12px">
                <Stack direction="row" justifyContent="center" >
                    <IconButton
                        onClick={() =>
                            accessControl({ audio: !controls?.audio, video: controls?.video ? { facingMode: controls?.facingMode } : false })
                        }
                    >
                        {controls?.audio ? <Micon /> : <Micoff />}
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            accessControl({ audio: controls?.audio, video: !controls?.video })
                        }
                    >
                        {controls?.video ? <CameraOn /> : <Cameraff />}
                    </IconButton>
                    {controls?.video &&
                        <IconButton
                            onClick={() =>
                                accessControl({ audio: controls?.audio, video: { facingMode: controls?.facingMode === "user" ? "environment" : "user" } })
                            }
                        >
                            {controls?.facingMode === "user" ? <SwitchOn /> : <Switchoff />}
                        </IconButton>
                    }
                </Stack>

                <Box mt={2} mb={1}>
                    <GuestDetailCard
                        shadow={true}
                        details={{
                            person_name: details?.notes?.[0]?.resident_name,
                            detail: `${details?.property_name ?? "-"}${details?.block_name ? `, ${details?.block_name}` : ""}${details?.floor_name ? `, ${details?.floor_name}` : ""}`,
                            vendor_image: details?.notes?.[0]?.resident_image,

                        }}
                        t={t}
                    />
                </Box>
                <Typography className={classes.subtitle2} sx={{ color: "#4E5A6B" }}>{t("Visitor Detail")}</Typography>
                <Box mt={1} mb={2}>
                    <GuestDetailCard
                        shadow={true}
                        details={{
                            person_name: details?.person_name,
                            reason: details?.reason,
                            vendor_image: details?.vendor_image

                        }}
                        t={t}
                    />
                </Box>
            </Box>

        </Box>
    )
}
export default VideoCall