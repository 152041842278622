export const ExploreIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.3}
        height={21.7}
        viewBox="18.3 21.7 18.3 21.7"
        {...props}
    >
        <defs>
            <linearGradient
                id="b"
                x1={0.151}
                x2={0.858}
                y1={0.155}
                y2={0.864}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#c668ff" />
                <stop offset={0.472} stopColor="#dea4ff" />
                <stop offset={1} stopColor="#c76aff" />
            </linearGradient>
            <filter
                id="a"
                width={36.346}
                height={39.703}
                x={0}
                y={0}
                filterUnits="userSpaceOnUse"
            >
                <feOffset />
                <feGaussianBlur result="blur" stdDeviation={3} />
                <feFlood floodOpacity={0.059} />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g
            data-name="Group 119211"
            style={{
                transformOrigin: "27px 313px",
            }}
        >
            <g filter="url(#a)" transform="translate(9.3 12.7)">
                <path
                    fill="url(#b)"
                    d="m22970.463 5504.405-3.062-3.062h-2.4a3 3 0 0 1-3-3V5486a3 3 0 0 1 3-3h12.348a3 3 0 0 1 3 3v12.342a3 3 0 0 1-3 3h-2.4l-3.062 3.062a1 1 0 0 1-1.418 0Z"
                    data-name="Union 36"
                    transform="translate(-22953 -5474)"
                />
            </g>
            <path
                fill="#fff"
                d="M25.423 32.86a.669.669 0 0 1-.635-.457l-.675-2.03-2.025-.675a.67.67 0 0 1 0-1.27l2.025-.675.676-2.026a.669.669 0 0 1 .634-.454.669.669 0 0 1 .635.457l.672 2.026 2.026.675a.67.67 0 0 1 0 1.27l-2.026.672-.675 2.026a.669.669 0 0 1-.632.461ZM30.811 35.963a.443.443 0 0 1-.42-.3l-.447-1.34-1.342-.449a.443.443 0 0 1 0-.84l1.339-.447.447-1.34a.443.443 0 0 1 .42-.3.443.443 0 0 1 .42.3l.447 1.34 1.34.447a.443.443 0 0 1 0 .84l-1.34.447-.447 1.34a.443.443 0 0 1-.417.302Z"
                data-name="icons8-shine (5)"
            />
        </g>
    </svg>
)