import * as React from "react"
const CIOFuncLocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 117571">
      <path
        fill="#98a0ac"
        d="M10.154 4A6.124 6.124 0 0 0 4 10.079a6.042 6.042 0 0 0 1.489 3.96c.1.115 2.519 2.821 3.335 3.588a1.943 1.943 0 0 0 2.659 0c.948-.891 3.241-3.481 3.344-3.6a6.032 6.032 0 0 0 1.481-3.951A6.124 6.124 0 0 0 10.154 4Zm0 7.693a1.539 1.539 0 1 1 1.539-1.539 1.538 1.538 0 0 1-1.539 1.539Zm11.693 2.154a6.124 6.124 0 0 0-6.154 6.079 6.042 6.042 0 0 0 1.489 3.96c.1.115 2.519 2.821 3.335 3.588a1.943 1.943 0 0 0 2.659 0c.948-.891 3.241-3.481 3.344-3.6A6.032 6.032 0 0 0 28 19.925a6.124 6.124 0 0 0-6.153-6.079Zm0 7.693A1.539 1.539 0 1 1 23.385 20a1.538 1.538 0 0 1-1.538 1.539Z"
        data-name="icons8-location (9)"
      />
    </g>
  </svg>
)
export default CIOFuncLocationIcon
