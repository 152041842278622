import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { CheckInOutStyle } from './style'

export const TimeCard = ({
    count = "",
    label = ""
}) => {
    const classes = CheckInOutStyle()

    return (
        <Stack rowGap={1} alignItems={"center"}>
            <Box className={classes.timeCard} id="flipcard">
                <Typography className={classes.countText}>{count}</Typography>
            </Box>
            <Typography className={classes.countStamp}>{label}</Typography>
        </Stack>
    )
}