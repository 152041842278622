export const Routes = {
  welcome: "/",
  login: "/login",
  welcomePage: "/welcome",
  setupAccount: "/setupaccount",
  otpPage: "/otppage",
  setpassword: "/setpassword",
  dashboard: "/dashboard",
  gateEntry: "/gateentry",
  getPassVerification: "/getpassverification",
  gokul: "/gokul",
  gatePass: "/gate_pass",
  passDetails: "/gate_pass_details",
  deliveryDetails: "/delivery_details",
  parkingSlotDetails: "/parking_slot_details",
  swiftInOutDetails: "/swiftinout_details",
  swiftInOut: "/swiftinout",
  create: "/create",
  details: "/details",
  vendor: "/vendor",
  profileView: "/profileView",
  profileEdit: "/profileedit",
  addAddress: "/addaddress",
  companyselect: '/companyselect',
  info: "/info",
  notification: "/notification",
  announcement_details:"/announcement_details",
  backupRestoreError:"/backupRestoreError",
  audioVideoCall: "/audioVideoCall",
  "#": "/#",
  myOrganizations: "/myOrganizations",
  myOrganizationsView: "/myOrganizationsView",
  checkInOut: "/checkInOut",
  checkInOutDetail: "/checkInOutDetail",
  createWalkIn:"/create_walk_in",
  walkInDetails:"/walk_in_details",
  videoCall:"/video_call",
  entries:"/entries",
  escort:"/escort",
  surveyList: "/survey_list",
  surveyForm: "/survey_form",
};
export const BackendRoutes = {
  login_domain_initial_render: "/login_domain/initial_render",
  client_terms_and_conditions: "/client/terms_and_conditions",
  auth_forgotpassword: "/auth/forgotpassword",
  login_domain_domain_list: "/login_domain/domain_list",
  weather_get_current_weather: "/weather/get_current_weather",
  survey_list: "/survey/list",
  survey_get: "/survey/get",
  survey_upsert_values: "/survey/upsert_values",
};