import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SwiftInOutCreate from "../screens/swiftIn&Out/create";
import SwiftInOutListVendor from "../screens/swiftIn&Out/vendorParent";
import {
  // Dashboard,
  DeliveryDetails,
  GateEntry,
  GatePassDetails,
  GetPassVerification,
  Login,
  NotFound,
  OtpPage,
  ParkingSlotDetails,
  ServiceVendorDetails,
  SetPassword,
  SetupAccount,
  SwiftInOut,
  SwiftInOutDetails,
  WelcomePage,
  ProfileView,
  CompanySelect,
  Info,
  Notification,
  BackupRestoreError,
  AudioVideoCall,
  MyOrganizations,
  MyOrganizationsView,
  CreateWalkInRequest,
  WalkInDetails,
  SurveyList,
  SurveyForm
} from "./../screens";
import Dashboard from "./../screens/dashboard";
import PrivateRouter from "./privateRouter";
import { Routes } from "./routes";
import ProfileEdit from "../screens/profileEdit/profileEdit";
import AddAddress from "../screens/addAddress/addAddress";
import AnnouncementDetails from "../screens/announsment/announcementDetails";
import ExternalSite from "../components/externalSite";
import CheckInOut from "../screens/dashboard/checkInOut";
import CheckInOutDetails from "../screens/dashboard/checkInOutDetails";
import VideoCall from "../screens/videoCall";
import GatePass from "../screens/gatePass/requset";
import Entries from "../screens/gatePass/listing";
import CreateEscort from "../screens/walkInDetails/createEscort";
import Welcome from "../screens/welcome";



const RouterApp = (props) => {
  return (
    <Router>
      <Switch>

        {/* Check in out detail */}
        <PrivateRouter exact path={Routes.createWalkIn}>
          <CreateWalkInRequest />
        </PrivateRouter>
        {/* escort  */}
        <PrivateRouter exact path={Routes.escort}>
          <CreateEscort />
        </PrivateRouter>

        {/* Entires Listing*/}
        <PrivateRouter exact path={Routes.entries}>
          <Entries />
        </PrivateRouter>


        {/* Video Call */}
        <PrivateRouter exact path={Routes.videoCall}>
          <VideoCall />
        </PrivateRouter>

        {/* Check in out detail */}
        <PrivateRouter exact path={Routes.walkInDetails}>
          <WalkInDetails />
        </PrivateRouter>

        {/* Login Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>
        {/* Login Route */}
        <Route exact path={Routes.welcome}>
          <Welcome />
        </Route>
        {/* WelcomePage Route */}
        <Route exact path={Routes.welcomePage}>
          <WelcomePage />
        </Route>
        {/* setupAccount Route */}
        <Route exact path={Routes.setupAccount}>
          <SetupAccount />
        </Route>
        {/* otp Route */}
        <Route exact path={Routes.otpPage}>
          <OtpPage />
        </Route>
        {/* setpassword Route */}
        <Route exact path={Routes.setpassword}>
          <SetPassword />
        </Route>
        {/* Dashboard */}
        <PrivateRouter exact path={Routes.dashboard}>
          <Dashboard />
        </PrivateRouter>
        {/* Gate entry */}
        <PrivateRouter exact path={Routes.gateEntry}>
          <GateEntry />
        </PrivateRouter>
        {/* Gate entry */}
        <PrivateRouter exact path={Routes.getPassVerification}>
          <GetPassVerification />
        </PrivateRouter>

        {/* Gokul Route */}
        <PrivateRouter exact path={Routes.gatePass}>
          <GatePass />
        </PrivateRouter>
        {/* Gokul Route */}
        <PrivateRouter exact path={Routes.passDetails}>
          <GatePassDetails />
        </PrivateRouter>
        {/* delivery details */}
        <PrivateRouter exact path={Routes.deliveryDetails}>
          <DeliveryDetails />
        </PrivateRouter>
        {/* parking slot details */}
        <PrivateRouter exact path={Routes.parkingSlotDetails}>
          <ParkingSlotDetails />
        </PrivateRouter>
        {/* swift in out */}
        <PrivateRouter exact path={Routes.swiftInOut}>
          <SwiftInOut />
        </PrivateRouter>
        {/* swift in out */}
        <PrivateRouter exact path={Routes.swiftInOutDetails}>
          <SwiftInOutDetails />
        </PrivateRouter>
        {/* swift in out create */}
        <PrivateRouter exact path={Routes.create}>
          <SwiftInOutCreate />
        </PrivateRouter>
        {/* details*/}
        <PrivateRouter exact path={Routes.details}>
          <ServiceVendorDetails />
        </PrivateRouter>
        {/* Vendor*/}
        <PrivateRouter exact path={Routes.vendor}>
          <SwiftInOutListVendor />
        </PrivateRouter>
        {/* Profile View Route */}
        <PrivateRouter exact path={Routes.profileView}>
          <ProfileView />
        </PrivateRouter>
        {/* Profile Edit Route */}
        <PrivateRouter exact path={Routes.profileEdit}>
          <ProfileEdit />
        </PrivateRouter>
        {/* Add Address Route */}
        <PrivateRouter exact path={Routes.addAddress}>
          <AddAddress />
        </PrivateRouter>
        {/* Info */}
        <PrivateRouter exact path={Routes.info}>
          <Info />
        </PrivateRouter>
        {/* company select page Route */}
        <Route exact path={Routes.companyselect}>
          <CompanySelect />
        </Route>
        {/* notification */}
        <PrivateRouter exact path={Routes.notification}>
          <Notification />
        </PrivateRouter>
        {/* Announcement Detials*/}
        <PrivateRouter exact path={Routes.announcement_details}>
          <AnnouncementDetails />
        </PrivateRouter>
        {/* Backup and Restore Error */}
        <PrivateRouter exact path={Routes.backupRestoreError}>
          <BackupRestoreError />
        </PrivateRouter>
        {/* Audio Video Call */}
        <PrivateRouter exact path={Routes.audioVideoCall}>
          <AudioVideoCall />
        </PrivateRouter>
        <PrivateRouter exact path={Routes["#"]}>
          <ExternalSite />
        </PrivateRouter>
        {/* myOrganizations */}
        <PrivateRouter exact path={Routes.myOrganizations}>
          <MyOrganizations />
        </PrivateRouter>
        {/* myOrganizationsView */}
        <PrivateRouter exact path={Routes.myOrganizationsView}>
          <MyOrganizationsView />
        </PrivateRouter>
        {/* Check in out */}
        <PrivateRouter exact path={Routes.checkInOut}>
          <CheckInOut />
        </PrivateRouter>
        {/* Check in out detail */}
        <PrivateRouter exact path={Routes.checkInOutDetail}>
          <CheckInOutDetails />
        </PrivateRouter>
        {/* Survey List */}
        <PrivateRouter exact path={Routes.surveyList}>
          <SurveyList />
        </PrivateRouter>
        {/* Survey Form */}
        <PrivateRouter exact path={Routes.surveyForm}>
          <SurveyForm />
        </PrivateRouter>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />

      </Switch>
    </Router>
  );
};

export default RouterApp;
