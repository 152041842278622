import React from "react";
import { withBottombar } from "../../HOCs";
import BackupAndRestoreError from "./backupAndrestoreError";

class BackupAndRestoreErrorParent extends React.Component {
    render() {
        return <BackupAndRestoreError />;
    }
}

export default withBottombar(BackupAndRestoreErrorParent, { titleBar: true, text: "Error", hideBottom: true });