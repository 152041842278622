import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import TermsAndConditionSvg from '../../../assets/termsAndCondition'
import { makeStyles } from '@mui/styles'
import { Bold } from '../../../utils'
import { Slider } from '../../../components/slider'
import { Document } from '../../../assets/document'
import { DialogDrawer } from '../../../components/dialogDrawer'

const useStyles = makeStyles((theme) => ({
    tcText: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    imgContainer: {
        width: "100%",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "0px 0px 4px #00000029",
        height: "125px",
        margin: "4px"
    },
    img: {
        objectFit: "cover",
        height: "125px",
        width: "100%",
        borderRadius: 12,
    },

    detailsBox: {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        backgroundColor: theme?.palette?.background?.background
    },
    pdfText: {
        padding: 4,
        color: "#fff",
        fontFamily: Bold,
        fontSize: "0.75rem",
        background: theme.palette.primary.main,
        borderRadius: 8
    },
    pdfName: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        wordBreak: "break-all",
        whiteSpace: "nowrap",
        width: '95%',
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
}))

export const TermsAndCondition = ({ termsCondition = {}, t = () => false }) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("")

    const handleClick = (val) => {
        setOpen(!open)
        setSelected(val)
    }
    return (
        <Box>
            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                <TermsAndConditionSvg />
                <Typography className={classes.tcText}>{t("Terms & Conditions")}</Typography>
            </Stack>
            <div dangerouslySetInnerHTML={{
                __html: termsCondition?.terms_conditions,
            }} ></div>
            <Box mt={2}>
                <Grid container>
                    {termsCondition?.docs?.map((val) => {
                        return (

                            <Grid item xs={3.7} className={classes.imgContainer}>
                                {
                                    val?.asset_type === 4 || val?.file_meta?.type === "pdf" ?
                                        <div style={{ height: "125px", overflow: "auto" }} onClick={() => handleClick(val)}>
                                            {/* <DocumentViewerPDF url={val?.url} /> */}
                                            <Stack>
                                                <Stack rowGap={1} onClick={() => handleClick(val)} className={classes.detailsBox}>
                                                    <Document />
                                                    <Typography className={classes.pdfText}>PDF</Typography>
                                                </Stack>
                                                <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "6px" }}>
                                                    <Typography className={classes.pdfName}>{val?.file_meta?.name ?? "-"}</Typography>
                                                </Box>
                                            </Stack>
                                        </div>
                                        :
                                        <img
                                            onClick={() => handleClick(val)}
                                            alt="not fount"
                                            width={"100%"}
                                            src={val?.url}
                                            className={classes.img}
                                        />
                                }

                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <DialogDrawer
                height={"535px"}
                open={open}
                header={t("View Document")}
                maxWidth="sm"
                component={
                    <Box>
                        <Slider assets={selected} uploadImage />
                    </Box>
                }
                onClose={() => setOpen(!open)} />


            {/* <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={open}
                    onClose={() => setOpen(!open)}
                >
                    <Grid container>
                        <Grid item xs={12} p={2}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"cneter"}>
                                <Typography className={classes.tcText}>{t("View Document")}</Typography>
                                <Close sx={{ color: "#4E5A6B" }} onClick={() => setOpen(!open)} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height="450px" alignItems="center">
                                <Slider assets={selected} uploadImage />
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={open}
                    onClose={() => setOpen(!open)}
                >
                    <Grid container>
                        <Grid item xs={12} p={2}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"cneter"}>
                                <Typography className={classes.tcText}>{t("View Document")}</Typography>
                                <Close sx={{ color: "#4E5A6B" }} onClick={() => setOpen(!open)} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height="450px" alignItems="center">
                                <Slider assets={selected} uploadImage />
                            </Box>
                        </Grid>
                    </Grid>
                </Drawer>
            </Hidden> */}

            {/* <AlertDialog
                medium={selected?.asset_type === 4 ? false : true}
                header="View Document"
                open={open}
                onClose={() => setOpen(!open)}
                component={
                    <Box height="450px" alignItems="center">
                        <Slider assets={selected} uploadImage />
                    </Box>
                }
            /> */}
        </Box>
    )
}