import makeStyles from "@mui/styles/makeStyles";
import { Bold, Italic, SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    notificationroot: {
        padding: "0px 8px 8px 12px",
    },
    boxSection: {
        width: "100%",
        padding: "0px 16px",
        height: "calc(100vh - 57px)",
        overflow: "auto"
    },
    avatarImg: {
        width: "80px",
        height: "80px",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    avatarImgBox: {
        marginTop: "20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    },
    name: {
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: Bold,
        textAlign: "center",
        marginTop: "12px"
    },
    title: {
        fontSize: "0.75rem",
        color: "#fff",
        fontFamily: Italic,
        backgroundColor: theme.palette.background.glaucous,
        padding: "4px 8px",
        borderRadius: theme.palette.borderRadius

    },
    department: {
        fontSize: "0.75rem",
        color: "#fff",
        fontFamily: Italic,
        backgroundColor: theme.palette.background.brown,
        padding: "4px 8px",
        borderRadius: theme.palette.borderRadius

    },
    subtitles: {
        gap: "8px",
        marginTop: "12px"
    },
    reportingname: {
        color: theme.typography.color.secondary,
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        textAlign: "center",
        marginTop: "12px"
    },
    topNavfix: {
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        zIndex: 999
    },
    managerCardBox: {
        marginTop: "35px",
        backgroundColor: "#fff",
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",

    },
    reportingBox: {
        position: "relative",
        background: "#fff",
        marginTop: "24px"
    },
    accordianBox: {
        marginTop: "24px",



    },
    accordianBottom: {
        marginBottom: "18px",
        backgroundColor: "#fff",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        position: "relative",

    },
    drawer: {
        "& .MuiDrawer-root": {
            borderRadius: "12px"
        }
    },
    viewAll: {
        color: "#B20606",
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        textAlign: "center",
        marginTop: "16px",
        cursor: "pointer"
    },
    dividerSide: {
        position: "absolute",
        color: "red",
        height: "100%",
        borderLeft: "4px solid",
        padding: "4px 0px",
        display: "flex",
        justifyContent: "center",
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px"
    },
    noDataBox: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    noDataText: {
        fontSize: remCalc(14),
        fontFamily: Bold
    }
}));


export const myOrgViewStyle = makeStyles((theme) => ({
    notificationroot: {
        padding: "0px 8px 8px 12px",
    },
    root: {
        height: "100%",
        overflow: "auto"
    },
    boxSection: {
        width: "100%",
        padding: "0px 16px",
        height: "calc(100vh - 57px)",
        overflow: "auto"
    },


    managerCardBox: {
        marginTop: "16px",
        backgroundColor: "#fff",
        padding: "16px 0px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
    },

    managerCard: {
        marginBottom: "16px",
        padding: "0px 16px"
    },
    textBox: {
        marginTop: "16px"
    },
    noDataFoundBox: {
        height: "calc(100vh - 150px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    noDataText: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary
    }

}));