import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { Bold, ExtraBold, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rootCard: {
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: "0px 3px 16px #00000014",
    border: "1px solid #E4E8EE",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.Tertiary,
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: ExtraBold,
  },
  date: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: SemiBold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },

  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  round: {
    color: "#00000014",
    fontSize: "4.0rem",
  },
  border: {
    borderBottom: "2px dashed #E4E8EE",
    // marginTop: "-4px",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
  box: {
    backgroundColor: (props) =>
      (props?.data?.visitors?.[0]?.check_in_time !== null && props?.data?.visitors?.[0]?.check_out_time !== null && props?.data?.entry_type === "single") ?
        theme.palette.error.main : moment(props?.data?.request_to) >= moment()
          ? theme.palette.success.main
          : theme.palette.error.main,
    textAlign: "center",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    padding: "24px",
  },
  box2: {
    backgroundColor: (props) => props?.data?.domestic_helper_units && theme.palette.success.main,

    textAlign: "center",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    padding: "24px",
  },
}));

export const Card = (props) => {
  const classes = useStyles(props);
  const isRequestValid = moment(props?.data?.request_to) >= moment();
  const passStatus = isRequestValid ? "Approved" : "Expired";
  const { t = () => false } = props
  return (
    <div className={classes.rootCard}>
      {props?.data?.domestic_helper_units && props?.data?.domestic_helper_units ?
        <>
          <Box className={classes.box2}>
            <img
              src={
                props?.data?.domestic_helper_units &&
                "/images/2.svg"
              }
              alt=""
            />
            <Typography className={classes.approved}>
              {props?.data?.domestic_helper_units && "Approved"}
            </Typography>
          </Box>
        </> :
        <>
          <Box className={classes.box}>
            <img
              src={
                passStatus === "Approved"
                  ? "/images/2.svg"
                  : "/images/22.svg"
              }
              alt=""
            />
            <Typography className={classes.approved}>
              {(props?.data?.visitors?.[0]?.check_in_time !== null && props?.data?.visitors?.[0]?.check_out_time !== null && props?.data?.entry_type === "single") ? "Expired" : passStatus}
            </Typography>
            <Typography className={classes.date}>{t("visit till")} {moment(props?.data?.request_to).format("DD MMM ")}</Typography>
          </Box>
        </>
      }
      <Box
        display="flex"
        alignItems="center"
        p={"12px"}
        className={classes.border}
      >
        <Box className={classes.img}>
          <img
            src="/images/pass.svg"
            alt=""
            style={{ objectFit: "contain", marginTop: "4px" }}
          />
        </Box>
        <Box marginLeft={"12px"}>
          <Typography variant="subtitle2" className={classes.title}>
            {props?.data?.domestic_helper_units && t("Domestic Helper")}
            {props?.data?.request_type === "VGR" && t("Visitor Gate Pass")}
            {props?.data?.request_type === "PSR" && t("Visitor Parking Pass")}
            {props?.data?.request_type === "WGR" && t("Worker Gate Pass")}
          </Typography>

          {props?.data?.request_on && (
            <Typography variant="subtitle2" className={classes.sub}>
              {t("Requested on")} {moment(props?.data?.request_on).format("DD MMM ")}
            </Typography>
          )}
        </Box>
      </Box>

      <Box p={"12px"} >
        <Grid container>
          {props?.data?.domestic_helper_units ?
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("Helper Name")}
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.domestic_helper_units[0]?.helper?.name ?? props?.data?.swift_in_out_log?.[0]?.helper?.name ?? ""} {`(${props?.data?.domestic_helper_units?.[0]?.helper?.profession?.name ?? props?.data?.swift_in_out_log?.[0]?.helper?.profession?.name ?? ""})`}
              </Typography>
            </Grid>
            :
            <>
              {props?.data?.request_type === "PSR" ? (
                <>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("Parking Slot")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.slot_name}
                    </Typography>

                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("GATE ENTRY")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.gate}
                    </Typography>

                    {/* <Typography variant="subtitle2" className={classes.heading}>
                      PARKING START & END DATE
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {moment(props?.data?.request_from).tz(moment.tz.guess())
                        .format("DD MMM   hh:mm:a")}
                      &nbsp;-&nbsp;{moment(props?.data?.request_to).tz(moment.tz.guess())
                        .format("DD MMM   hh:mm:a")}
                    </Typography> */}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("UNIT")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.unit?.[0]?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("PARKING START & END DATE")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {moment(props?.data?.request_from).tz(moment.tz.guess())
                        .format("DD MMM   hh:mm:a")}
                      &nbsp;-&nbsp;{moment(props?.data?.request_to).tz(moment.tz.guess())
                        .format("DD MMM   hh:mm:a")}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("UNIT")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.unit?.[0]?.name}
                    </Typography>

                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("GATE ENTRY")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.gate}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("VISIT START & END DATE")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {moment(props?.data?.request_from).tz(moment.tz.guess())
                        .format("DD MMM   hh:mm:a")}
                      &nbsp;-&nbsp; {moment(props?.data?.request_to).tz(moment.tz.guess())
                        .format("DD MMM   hh:mm:a")}
                    </Typography>

                    <Typography variant="subtitle2" className={classes.heading}>
                      {props?.data?.request_type === "WGR" && t("NO OF WORKERS")}
                      {props?.data?.request_type === "VGR" && t("NO OF VISITORS")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.visitors && props?.data?.visitors?.length}
                    </Typography>
                  </Grid>
                </>
              )}
            </>}
        </Grid>
      </Box>
    </div>
  );
};
