import {
  Badge,
  Box,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LoaderSimmer } from "../../components";
import Filter from "../../components/filter";

import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_FILTER_UNITS, GET_PROPERTY_BY_ROLE } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, Bold, customEnumModifier, enumSelect, enumTypes, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import InfiniteScroll from "react-infinite-scroll-component";
import { EntriesCard } from "./components/entriesCard";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  loader: {
    textAlign: "center",
    marginTop: "50%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const GatePass = (props) => {
  const { state } = useLocation();
  const { t = () => false } = props;

  const classes = useStyles();
  const size = useWindowDimensions();
  const [list, setList] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [property, setProperty] = React.useState();
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [filter, setFilter] = React.useState(false);
  const [unitList, setUnitList] = React.useState([]);
  // const backdrop = React.useContext(BackdropContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(true);
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],
    objectKey: {}

  })
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const getPassList = (val, type, status, offset = 0, filter = true, dateFilterr = false, dateValue = false) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: type,
      status: status,
      is_new_entries: true,
      entries: true,
      property_id: dateFilterr ? undefined : val,
      unit_id: dateFilterr ? val : undefined,
      entry_check_in_start_date: dateFilter?.startDate?.toString()?.length > 0 ? new Date(dateFilter?.startDate).toISOString() : undefined,
      entry_check_in_end_date: dateFilter?.endDate?.toString()?.length > 0 ? new Date(dateFilter?.endDate).toISOString() : undefined,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setFilter(false)

        if (filter) {
          setList(response?.data?.data);

        } else {
          setList(list?.concat(response?.data?.data));

        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  // const getPassList2 = (val, type, status, offset, limit) => {
  //   const data = {
  //     tenantId: `${config.tenantid}`,
  //     request_type: type,
  //     status: status,
  //     entries: true,
  //     unit_id: val,
  //     offset: 0,
  //     limit: 1000,
  //   };
  //   NetworkCall(
  //     `http://localhost:4000/api/v1/security/visitors`,
  //     NetWorkCallMethods.post,
  //     data,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       setList(response?.data?.data);
  //      
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: t("Something Went Wrong"),
  //       });
  //     });
  // };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_PROPERTY_BY_ROLE,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {

        getEnum(response?.data?.data?.access_control?.map((val) => val?.property?.value))
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  //filter json
  const type = [
    {
      label: t("Worker Gate Pass"),
      value: "WGR",
    },
    {
      label: t("Visitor Gate Pass"),
      value: "VGR",
    },
  ]
  const filterData = [
    {
      id: "1",
      title: t("Type"),
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: type,
    },
    {
      id: "2",
      title: t("Status"),
      key: "status",

      filterType: "CHECKBOX",
      values: enumValue?.status ?? []

    },
    {
      id: "3",
      title: t("Unit"),
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    {
      id: "4",
      title: t("Date"),
      key: "date",
      filterType: "new_date",
      values: [],
    },
  ];

  //apply filter
  const applyFilter = (data) => {
    // setOffset(0);
    if (data?.type?.length > 0) {
      if (data?.unit?.length > 0) {
        setSelectedFilter(data);
        getPassList(
          data.unit,
          data.type,
          data.status.length > 0 ? data?.status : ["Approved"],
          0,
          true,
          true,
          dateFilter
        );
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Select Unit"),
        });
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Select Type"),
      });
    }
  };
  const getListFromdashboard = (offset = 0, filter = true) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {

        let dataList = response?.data?.data?.map((val) => {
          return {
            check_in_time: val?.check_in_time,
            check_out_time: val?.check_out_time,
            mobile_no: val?.mobile_no,
            mobile_country_code: val?.mobile_country_code,
            name: val?.visitor_name,
            visitor_image: val?.visitor_image,
            request_id: val?.request_id,
            id: val?.id
          };
        });

        let finalData = response?.data?.data?.map((val) => {
          return {
            ...val,
            visitors: dataList?.filter((i) => i.id === val?.id)
          };
        });
        if (filter) {
          setList(finalData);

        } else {
          setList(list?.concat(finalData));

        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  const filterUnits = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_FILTER_UNITS,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setUnitList(response?.data?.data?.access_control[0]?.property?.unit);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  //enum
  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.security_status_enum_type, enumTypes?.security_request_type])
    let requestSourceMap = result?.security_request_type?.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key])?.reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let requestSourceMapStatus = result?.security_status_enum_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus)?.map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let options = {
      filterKey: "Received At Gate",
      replaceKey: "In Progress",
      actualKey: "In Progress",
      data: result?.security_status_enum_type?.filter(_ => !["Received At Gate", "Cancelled"].includes(_?.value)),
    }
    let status = customEnumModifier(options)
    setEnumValue({ ...enumValue, status: status, type: requestType, objectKey: requestTypeStatus })
    if (data?.length > 0) {
      setProperty(data)
      getPassList(
        data,
        ["VGR", "WGR"],
        [
          "Rejected",
          "Pending",
          "Approved"
        ]
      );
    }
  }
  const fetchMoreData = () => {
    setOffset(offset + 10);
    if (
      state?.type === "visitors" ||
      state?.type === "workers" ||
      state?.type === "overStayVisitors" ||
      state?.type === "overStayWorkers"
    ) {
      getListFromdashboard(
        offset + 10,
        false
      );
    } else {
      getPassList(
        property,
        ["VGR", "WGR"],
        selectedFilter.status.length > 0
          ? selectedFilter?.status
          : [
            "Rejected",
            "Pending",
            "Approved"
          ],
        offset + 10,
        false
      );
    }
  };

  React.useEffect(() => {
    if (clientId) {
      // getEnum()
      if (
        state?.type === "visitors" ||
        state?.type === "workers" ||
        state?.type === "overStayVisitors" ||
        state?.type === "overStayWorkers"
      ) {
        getListFromdashboard();
      } else {
        getPropertyId();
      }
      filterUnits();
    }
    // eslint-disable-next-line
  }, []);


  return (
    <>
      {loading ? (
        <LoaderSimmer count={10} />

      ) : (
        <div className={classes.root}>
          <Box display="flex" alignItems="center" p={"8px 16px 8px 16px"}>
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                {t("Gate_Pass")} ({list?.length})
              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div
          // style={{
          //   height: size.height - 210,
          //   overflow: "scroll",
          //   marginRight: "-3px",
          // }}
          >
            <InfiniteScroll
              dataLength={list?.length}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 110}
            >
              <Box padding={"16px"}>
                {list?.map((val) => {
                  return (
                    <>
                      <Box marginBottom={"10px"}>
                        {/* <PassCard t={t} data={val} statusKey={enumValue?.type} /> */}
                        <EntriesCard
                          id={val?.id}
                          imgUrl={generateImageUrl(val?.visitors?.[0]?.visitor_image, img_size.small_square)}
                          title={val?.visitors[0]?.name}
                          mobCountryCode={val?.visitors?.[0]?.mobile_country_code}
                          mobNo={val?.visitors?.[0]?.mobile_no}
                          count={`${val?.visitors_count - 1 > 0 ? `${t("Family")} +${val?.visitors_count - 1}` : ""}`}
                          propertyName={val?.property_name}
                          unitNo={val?.unit?.[0]?.unit_no ?? val?.unit_no ?? "-"}
                          startDateString={`${t("In")} -`}
                          startDate={val?.request_from}
                          endDateString={`${t("Out")} -`}
                          endDate={val?.request_to}
                          type="pass"
                          status={val?.entries_status}
                          selectedTab="gatepass" />
                      </Box>
                    </>
                  )

                })}
              </Box>
            </InfiniteScroll>

          </div>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={filter}
              onClose={() => {
                setFilter(false)
                setdateFilter({
                  startDate: "",
                  endDate: ""

                })
              }}
            >
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  type: selectedFilter.type ?? type?.map(i => i.value),
                  status: selectedFilter.status ?? enumValue?.status?.map(i => i.label),
                  unit: selectedFilter.unit ?? unitList?.map(i => i.value),
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                onApply={applyFilter}
                onClose={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                updateState={updateState}
                data={dateFilter}
              />
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer open={filter} onClose={() => {
              setFilter(false)
              setdateFilter({
                startDate: "",
                endDate: ""

              })
            }}>
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  type: selectedFilter.type ?? type?.map(i => i.value),
                  status: selectedFilter.status ?? enumValue?.status?.map(i => i.label),
                  unit: selectedFilter.unit ?? unitList?.map(i => i.value),
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                onApply={applyFilter}
                onClose={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                updateState={updateState}
                data={dateFilter}
              />
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("gatePass")(GatePass);

