import { Box, Button } from "@mui/material";
import { ProfileUpload } from "../../../components/profileUpload"
import { MobileNumberInputComponent, TextBox } from "../../../components";
import { remCalc } from "../../../utils";
import { useStyles } from "./styles"
import useWindowDimensions from "../../../utils/useWindowDimensions";

export const CreateForm = ({ t = () => false, onSubmit = () => false, data = {}, updateState = () => false ,setData=()=>false}) => {
    const classes = useStyles();


    const isFormValid = () => {

        let isValid = true;
        let error = data.error;

        if (data.name.length === 0) {
          isValid = false;
          error.name = t("Visitor Name is Required") 
         }
    
         if (data.reason.length === 0) {
            isValid = false;
            error.reason = t("Reason for visit is required") 
           }
      
           setData({ ...data, error });

        return isValid;
      };

    const size = useWindowDimensions()


      const onConfirm=()=>{
        if(isFormValid()){
            onSubmit()
        }
      }

    return (
        <Box p={2}>
            <Box height={size?.height - 156} overflow="auto">
                <Box paddingTop="20px">
                    <center>
                        <ProfileUpload
                            logo_title={t("Take Visitor Photo")}
                            handleChange={(url) => updateState("logo", url)}
                            data={data?.logo}
                        />
                    </center>
                </Box>

                <Box marginTop="12px">
                    <TextBox
                        label={t("Visitor Name")}
                        placeholder={t("Enter Visitor Name")}
                        textBoxBgColor="white"
                        fontSize={remCalc(14)}
                        onChange={(e) => {if(e.target.value?.length<30){updateState("name", e.target.value)}}}
                        value={data?.name}
                        isError={data?.error?.name?.length > 0}
                        errorMessage={data?.error?.name}
                        isRequired
                    />
                </Box>
                <Box marginTop="20px">
                    <MobileNumberInputComponent
                    textBoxBgColor="white"
                    placeholder={t("Enter Visitor Mobile Number")}
                    label={t("Visitor Mobile Number")}
                    fontSize={remCalc(14)}
                    handleChange={(val) => updateState("mobile", val)}
                    value={data?.mobile}
                    isRequired
                    />

                </Box>
                <Box marginTop="20px">
                    <TextBox
                        label={t("Reason For Visit")}
                        placeholder={t("Enter Reason For Visit")}
                        textBoxBgColor="white"
                        multiline
                        fontSize={remCalc(14)}
                        onChange={(e) => updateState("reason", e.target.value)}
                        value={data?.reason}
                        isError={data?.error?.reason?.length > 0}
                        errorMessage={data?.error?.reason}
                        isRequired

                    />
                </Box>
            </Box>
            <Button onClick={onConfirm}
                variant="contained"
                fullWidth
                className={classes.unitCardBtn}>
                {t("Create Entry")}
            </Button>
        </Box>
    )
}