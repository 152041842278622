import * as React from "react"
const TotalItemIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 112335"
    {...props}
  >
    <rect
      width={40}
      height={40}
      fill="#edf7f8"
      data-name="Rectangle 57272"
      rx={6}
    />
    <g data-name="Group 112334">
      <path
        fill="#6dafb3"
        d="M16.37 11.13a8.87 8.87 0 1 0 1.565 17.592 8.423 8.423 0 0 0 3.13 0 8.87 8.87 0 1 0 0-17.446 8.421 8.421 0 0 0-3.13 0 8.859 8.859 0 0 0-1.565-.146Zm8.73 2a7.3 7.3 0 0 1 0 13.738 8.852 8.852 0 0 0 0-13.738Zm-3.127 0a7.3 7.3 0 0 1 0 13.736 8.855 8.855 0 0 0 0-13.736Zm-6.386 1.652a.783.783 0 0 1 .783.783v3.913h2.87a.783.783 0 1 1 0 1.565h-3.653a.783.783 0 0 1-.787-.783v-4.7a.783.783 0 0 1 .787-.778Z"
        data-name="icons8-tenses (3)"
      />
    </g>
  </svg>
)
export default TotalItemIcon
