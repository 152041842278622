import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MobileNumberInputComponent,
  SlideButton,
  TextBox,
  TitleBar
} from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, Bold, enumSelect, enumTypes, ExtraBold, generateImageUrl, img_size, keyMapSource, LocalStorageKeys, NetWorkCallMethods, Regular, remCalc, SemiBold } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import VisiterCard from "./visiterCard";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    margin: "12px 10px 8px 14px",
    borderRadius: "12px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: Bold,
    paddingLeft: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 8px 8px 14px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  userimg: {
    borderRadius: "50%",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  stack: {
    backgroundColor: "#F5F7FA",
    margin: "12px",
    borderRadius: "12px"
  },
  stack1: {
    borderRight: "1px solid #E4E8EE",
  },
  preview: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.Tertiary,
    textAlign: "center",
    marginBottom: "8px",
  },
  input: {
    marginTop: "10px",
  },
  border: {
    borderBottom: "2px solid #E4E8EE",
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  subCardtitle:{
    fontSize: remCalc(14),
    fontFamily: Bold,
    color: theme.typography.color.primary,
  }
}));
const InitialState = {
  name: "",
  mobile: "",
  mail: "",
  idType: "",
  idno: "",
};
const GatePassDetails = (props) => {
  const { t = () => false } = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const [viewDetail, setVieDetail] = React.useState({ ...InitialState });
  const [drawer, setDrawer] = React.useState(false);
  const [detail, setDetail] = React.useState([]);
  const searchURL = useLocation().search;
  const alert = React.useContext(AlertContext);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");
  const [requestType, setRequestType] = React.useState();


  //openImageViewer
  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  //closeImageViewer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetail(response?.data?.data?.[0]);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  React.useEffect(() => {
    getPassDetails();
    getEnum()


    // eslint-disable-next-line
  }, []);

  const viewDetails = (data) => {
    setDrawer(true);
    const InitialState = {
      name: data?.name,
      mobile: {
        mobile: data?.mobile_no,
        mobile_code: data?.mobile_country_code,
      },
      mail: data?.email_id,
      idType: data?.id_type,
      idno: data?.identification_no,
      previewImage: data?.visitor_image,
      verifiedImage: data?.guard_image,
      ...data
    };

    setVieDetail(InitialState);
  };
  //get enum
  const getEnum = async () => {
    const result = await enumSelect([enumTypes?.security_request_type])
    let mapResult = keyMapSource({ enumType: result?.security_request_type })
    setRequestType(mapResult)
  }

  const UpdateDetailsWhileCheckOut = () => {
    let currentTime = new Date().toISOString();
    let updatedBy = userProfileId;
    const payload = {
      update_params: {
        updated_at: currentTime,
        updated_by: updatedBy,
        check_out_time: currentTime,
      },
      visitor_id: viewDetail?.id,
    }
    NetworkCall(
      `${config.api_url}/security_request_visitors/check_in_out`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: t("checked Out")
        });
        getPassDetails()
        setDrawer(false)
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };



  //render form
  const renderForm = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("View Visitor Details")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
        <div
          style={{
            // padding: "12px",
            height: size.height - 200,
            overflow: "scroll",
          }}
        >
          <Box
            p={1}
            className={classes.stack}
            display="flex"
            justifyContent="space-around"
          >
            <Box p={1}>
              <Typography className={classes.preview}>{t("PREVIEW")}</Typography>
              <Avatar sx={{ width: 90, height: 90 }}>

                <Avatar src={generateImageUrl((viewDetail?.previewImage || viewDetail?.domestic_helpers?.visitor_image), img_size.small_square) ?? null} className={classes.userimg} />

              </Avatar>
            </Box>
            <Box className={classes.stack1} />
            <Box p={1} className={classes.stack2}>
              <Typography className={classes.preview}>{t("VERIFIED")}</Typography>
              <Avatar sx={{ width: 90, height: 90 }}>

                <Avatar src={generateImageUrl(viewDetail?.verifiedImage, img_size.small_square) ?? null} className={classes.userimg} />

              </Avatar>
            </Box>
          </Box>
          <Box p={2}>
            <Typography className={classes.title} sx={{textTransform: "uppercase"}}>{t("VISITOR DETAIL")}</Typography>
            <div className={classes.input}>
              <TextBox
                label={t("Visitor Name")}
                placeholder={t("Visitor Name")}
                value={(viewDetail?.name || viewDetail?.domestic_helpers?.name)}
                disabled
                textBoxBgColor={"#E4E8EE"}
              />
            </div>
            <div className={classes.input}>
              <MobileNumberInputComponent
                label={t("Phone Number")}
                placeholder={t("Phone Number")}
                value={(viewDetail?.mobile || {
                  mobile: viewDetail?.domestic_helpers?.mobile_no,
                  mobile_code: viewDetail?.domestic_helpers?.mobile_country_code,
                })}
                disabled
                textBoxBgColor={"#E4E8EE"}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                label={t("Mail ID")}
                placeholder={t("Mail ID")}
                value={viewDetail?.mail}
                disabled
                textBoxBgColor={"#E4E8EE"}
              />
            </div>
          </Box>
          <Box className={classes.border} />
          <Box p={2}>
            <Typography className={classes.title} sx={{textTransform: "uppercase"}}>{t("VISITOR PROOF")}</Typography>
            <div className={classes.input}>
              <TextBox
                label={t("ID Proof")}
                placeholder={t("ID Proof")}
                value={(viewDetail?.idType || viewDetail?.domestic_helpers?.id_type)}
                disabled
                textBoxBgColor={"#E4E8EE"}
              />
            </div>

            <div className={classes.input}>
              <TextBox
                label={t("ID Number")}
                placeholder={t("ID Number")}
                value={(viewDetail?.idno || viewDetail?.domestic_helpers?.identification_no)}
                disabled
                textBoxBgColor={"#E4E8EE"}
              />
            </div>
          </Box>
        </div>
        <div style={{ padding: "12px", borderTop: "2px solid #E4E8EE" }}>

          {(viewDetail?.check_out_time !== null || viewDetail?.check_in_time === null) ?
            <Button
              className={classes.submitbtn}
              variant="contained"
              fullWidth
              onClick={() => setDrawer(false)}
            >
              {t("Close")}
            </Button>
            :
            <SlideButton
              mainText={(viewDetail?.check_in_time === null || viewDetail?.check_out_time !== null) ? t("Swipe to Check In") : t("Swipe to Check Out")}
              overlayText={(viewDetail?.check_in_time === null || viewDetail?.check_out_time !== null) ? t("Checked In") : t("Checked Out")}
              classList={(viewDetail?.check_in_time === null || viewDetail?.check_out_time !== null) ? "my-class1" : "my-class2"}
              caretClassList={(viewDetail?.check_in_time === null || viewDetail?.check_out_time !== null) ? "my-caret-class1" : "my-caret-class2"}
              overlayClassList={(viewDetail?.check_in_time === null || viewDetail?.check_out_time !== null) ? "my-overlay-class1" : "my-overlay-class2"}
              onSlideDone={UpdateDetailsWhileCheckOut}
              disabled={disabled}
            />
          }
        </div>
      </>
    );
  };


  const timeStringStart = detail?.work_hour_start; // example time string in HH:MM:SS format
  const timeStringEnd = detail?.work_hour_end; // example time string in HH:MM:SS format

  const timePartsStart = timeStringStart?.split(":"); // split the string into an array of parts
  const timePartsEnd = timeStringEnd?.split(":"); // split the string into an array of parts

  const workStartHours = parseInt(timePartsStart?.[0], 10); // get the hours as an integer
  const workStartMinutes = parseInt(timePartsStart?.[1], 10); // get the minutes as an integer
  // const workStartSeconds = parseInt(timePartsStart[2], 10);

  const workEndHours = parseInt(timePartsEnd?.[0], 10); // get the hours as an integer
  const workEndMinutes = parseInt(timePartsEnd?.[1], 10); // get the minutes as an integer
  // const workEndSeconds = parseInt(timePartsEnd[2], 10);

  let now = new Date();

  // Create a new Date object for the given time
  let givenStartTime = new Date();
  givenStartTime.setHours(workStartHours); // Set the hours to 10 (for example)
  givenStartTime.setMinutes(workStartMinutes); // Set the minute
  // Create a new Date object for the given time
  let givenEndTime = new Date();
  givenEndTime.setHours(workEndHours); // Set the hours to 10 (for example)
  givenEndTime.setMinutes(workEndMinutes); // Set the minute
  const workerDisableButton = ((now.getTime() < givenStartTime.getTime()) || now.getTime() > givenEndTime.getTime()) ? true : false

  const disableButton = ((new Date() <= new Date(detail?.request_from)) || (new Date() >= new Date(detail?.request_to))) ? true : false
  const disabled = detail?.type === "WGR" ? (disableButton ? disableButton : ((viewDetail?.check_in_time !== null && viewDetail?.check_out_time !== null) || viewDetail?.check_in_time === null) && workerDisableButton) : (disableButton || (viewDetail?.check_in_time !== null && viewDetail?.check_out_time !== null && detail?.entry_type === "single"))


  return (
    <Container maxWidth="sm" sx={{ padding: "0px", overflow: "hidden" }}>
      <TitleBar
        text={t("Visitor Gate Pass")}
        // color="#5078E1"
        goBack={() => history.goBack()}
      />
      <div
        className={classes.root}
        style={{
          height: size.height - 60,
          overflow: "scroll",
        }}
      >
        <div className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <Box className={classes.img}>
              <img
                src="/images/pass.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginInlineStart="14px" >
              <Typography className={classes.title}>
                {detail?.request_type === requestType?.typeC && t("Visitor Gate Pass")}
                {detail?.request_type === requestType?.typeB && t("Worker Gate Pass")}
              </Typography>
              <Typography className={classes.sub}>
                {detail?.request_no}
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("UNIT")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subCardtitle}>
                {detail?.unit?.[0]?.name}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("GATE ENTRY")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subCardtitle}>
                {detail?.gate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("VISIT START & END DATE")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subCardtitle} noWrap>
                &#x202a;{moment(detail?.request_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}&#x202c;
                -{/* .format("DD MMM YY hh:mm:a")} */}
                {/* <Box height="2px" /> */}
                &#x202a;{moment(detail?.request_to)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY ")}&#x202c;
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("NO OF VISITOR")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subCardtitle}>
                {detail?.visitors?.length ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {detail?.visitors?.length > 0 && (
          <div style={{ margin: "14px 8px 8px 14px" }}>
            <Typography className={classes.subtitle} style={{textTransform: "uppercase"}}>
              {t("VISITOR DETAILS")}
            </Typography>
            <Box height="10px" />
            {detail?.visitors?.map((val) => (
              <>
                <VisiterCard
                  disabled={disabled}
                  data={val}
                  onClick={viewDetails}
                  closeImageViewer={closeImageViewer}
                  openImageViewer={openImageViewer}
                  isViewerOpen={isViewerOpen}
                  currentImage={currentImage}
                  parentData={detail}
                />
                <Box height="10px" />
              </>
            ))}
          </div>
        )}
      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("gatePassDetails")(GatePassDetails)