import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
  },
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      height: 50,
      borderRadius: "8px",
      border: "1.5px solid  #CED3DD",
      boxShadow: "none",
    },
  },
}));

const DateTime = (props) => {
  const classes = useStyles(props);
  const getTitle = (props) => {
    return (
      <Typography variant="body1" className={classes.text} gutterBottom>
        {props.title}{" "}
      </Typography>
    );
  };

  return (
    <>
      {getTitle(props)}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          <DatePicker
            disablePast={props.disablePast}
            className={classes.datepicker}
            disableFuture={props.disableFuture}
            label=""
            views={["day", "month"]}
            value={props.value ?? null}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" className={classes.textbox} />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </>
  );
};
export default DateTime;
