import * as React from "react"
const CallCardIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 96548">
      <g data-name="Group 94905">
        <path
          fill="#f0f4ff"
          d="M24 0A24 24 0 1 1 0 24 24 24 0 0 1 24 0Z"
          data-name="Path 6956"
        />
        <path
          fill="none"
          d="M28.974 17.999a.464.464 0 0 0-.184.893 5.537 5.537 0 0 1 .956.515.464.464 0 1 0 .524-.766 6.447 6.447 0 0 0-1.116-.6.464.464 0 0 0-.18-.04Zm-4.794.869c-2.44 0-4.5.526-5.507 1.406a1.91 1.91 0 0 0-.647 1.784l.1.353a1.48 1.48 0 0 0 1.6 1.087l.785-.1a1.446 1.446 0 0 0 1.282-1.061l.216-.959a.257.257 0 0 1 .119-.162 8.5 8.5 0 0 1 2.052-.184 8.495 8.495 0 0 1 2.052.184.282.282 0 0 1 .127.185l.2.909a1.453 1.453 0 0 0 1.282 1.088l.8.1c.05 0 .1.007.15.007a1.467 1.467 0 0 0 1.436-1.083l.1-.334.007-.03a1.911 1.911 0 0 0-.647-1.785c-1.008-.878-3.066-1.404-5.506-1.404Zm-1.089 3.4a.464.464 0 0 0-.456.47v.8l-1.1.551a4.69 4.69 0 0 0-2.607 4.218 1.7 1.7 0 0 0 1.7 1.7h7.11a1.7 1.7 0 0 0 1.7-1.7 4.69 4.69 0 0 0-2.607-4.218l-1.1-.551v-.8a.464.464 0 1 0-.927 0v.464h-1.242v-.468a.464.464 0 0 0-.471-.47Zm-5.123 1.765a.464.464 0 0 0-.244.842 3.327 3.327 0 0 0 1.258.566.464.464 0 1 0 .2-.906 2.4 2.4 0 0 1-.906-.409.464.464 0 0 0-.258-.093h-.05Zm4.513 1.638a.464.464 0 1 1-.464.464.464.464 0 0 1 .463-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .464-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .464-.464Zm-3.401 1.545a.464.464 0 1 1-.464.464.464.464 0 0 1 .464-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .465-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .465-.464Z"
          data-name="icons8-ringing-phone (1)"
        />
      </g>
      <g data-name="Group 112334">
        <path
          fill="#5078e1"
          stroke="#5078e1"
          strokeWidth={0.5}
          d="M24.692 15a.691.691 0 0 0-.424.145l-6.08 4.707a3.485 3.485 0 0 0-1.342 2.738v1.536l1.385-1.1v-.441a2.09 2.09 0 0 1 .8-1.642l5.657-4.379 5.657 4.379a2.091 2.091 0 0 1 .8 1.642v9.026h-5.53A2.022 2.022 0 0 1 25.154 33h6.231a1.155 1.155 0 0 0 1.154-1.154v-9.257a3.485 3.485 0 0 0-1.339-2.737l-6.08-4.706a.691.691 0 0 0-.428-.146Zm-5.1 8.518a.692.692 0 0 0-.406.149l-3.4 2.675A2.079 2.079 0 0 0 15 27.973v3.874A1.164 1.164 0 0 0 16.154 33h6.923a1.164 1.164 0 0 0 1.154-1.154v-3.873a2.077 2.077 0 0 0-.791-1.632l-3.4-2.675a.692.692 0 0 0-.451-.149Zm.023 1.574 2.968 2.337a.691.691 0 0 1 .263.544v3.643h-6.461v-3.643a.69.69 0 0 1 .264-.544Z"
          data-name="icons8-real-estate (4)"
        />
      </g>
    </g>
  </svg>
)
export default CallCardIcon
