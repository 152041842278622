import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Bold, ExtraBold, Regular, SemiBold, remCalc } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },

  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    margin: "12px 10px 8px 14px",

    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  detailsection: {
    borderBottom: "1px dashed #E4E8EE",
    padding: "12px 4px",
  },
  detailsection2: {
    borderTop: "1px dashed #E4E8EE",
  },
  alertsection: {
    backgroundColor: (props) =>
      props?.detail?.delivery_status === props?.statusKey?.type2
        ? "#78B1FE"
        : props?.detail?.delivery_status === props?.statusKey?.type1
          ? "#FF9340"
          : props?.detail?.delivery_status === props?.statusKey?.type4
            ? "#CED3DD"
            : "#5AC782",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
  msg: {
    fontSize: "0.75rem",
    color: "white",
    fontFamily: ExtraBold,
    textAlign: "center",
  },
  subTitle: {
    fontSize: remCalc(14),
    fontFamily: Bold,
    color: theme.typography.color.primary,
  }
}));

const DetailCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  console.log("===>", props?.detail?.delivery_status, "----", props?.statusKey)
  return (
    <>
      <div className={classes.cardContent}>
        <Box
          display="flex"
          alignItems="center"
          className={classes.alertsection}
          justifyContent="center"
          p={1.8}
        >
          <Box>
            <center>
              {props?.detail?.delivery_status === "Received At Gate" ? (
                <CheckCircleIcon
                  style={{ color: "white", textAlign: "center" }}
                />
              ) : (
                <ErrorIcon style={{ color: "white", textAlign: "center" }} />
              )}

              <Typography className={classes.msg}>
                {props?.detail?.delivery_status}
                {/* {props?.detail?.delivery_status === props?.statusKey?.type2 &&
                  props?.statusKey?.type2}
                {props?.detail?.delivery_status === props?.statusKey?.type1 &&
                  props?.statusKey?.type1}
                {props?.detail?.delivery_status === props?.statusKey?.type4 && props?.statusKey?.type4} */}
              </Typography>
            </center>
          </Box>
        </Box>
        <Box p={1.6} marginTop="-10px">
          <Box
            display="flex"
            alignItems="center"
            className={classes.detailsection}
          >
            <Box className={classes.img}>
              <img
                src="/images/deliverycard.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginInlineStart="14px">
              <Typography className={classes.title}>
                {props?.detail?.name}
              </Typography>
              <Typography className={classes.sub}>
                {t("Requested on")}
                &#x202a;{moment(props?.detail?.request_on)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}&#x202c;
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("REFERENCE ID")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subTitle}>
                {props?.detail?.request_no}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("GATE ENTRY")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subTitle}>
                {props?.detail?.gate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("UNIT")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subTitle}>
                {props?.detail?.unit?.[0]?.name}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("TENTATIVE DELIVERY")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subTitle}>
                &#x202a;{moment(props?.detail?.request_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY hh:mm:a")}&#x202c;
                <Box height="2px" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {props?.detail?.delivery_status === "Received At Gate" ||
          props?.detail?.delivery_status === "Collected" ? (
          <Box p={1.6} className={classes.detailsection2}>
            <Grid container sx={{ marginTop: "-10px" }}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  {t("RECEIVED BY")}
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle}>
                  {props?.detail?.delivery_collected_by}
                </Typography>
                <Typography variant="subtitle2" className={classes.heading}>
                  {t("RECEIVED TIME")}
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle}>
                  &#x202a;{moment(props?.detail?.received_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY hh:mm:a")}&#x202c;
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  {t("RECEIVED GATE")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.subTitle}
                  noWrap
                >
                  {props?.detail?.collected_access_gate}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}
        {props?.detail?.delivery_status === "Collected" ? (
          <Box p={1.6} className={classes.detailsection2}>
            <Grid container sx={{ marginTop: "-10px" }}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  {t("COLLECTED GATE")}
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle}>
                  {props?.detail?.collected_access_gate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  {t("COLLECTED TIME")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.subTitle}
                  noWrap
                >
                  &#x202a;{moment(props?.detail?.delivery_collected_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY hh:mm:a")}&#x202c;
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default withNamespaces("deliveryDetails")(DetailCard)