import * as React from "react"
const VideoCallIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} {...props}>
    <path
      fill="#5078e1"
      d="M11.2 9.4A2.6 2.6 0 0 1 8.6 12h-6A2.6 2.6 0 0 1 0 9.4V2.6A2.6 2.6 0 0 1 2.6 0h6a2.6 2.6 0 0 1 2.6 2.6Zm4.5-7.722a.6.6 0 0 0-.6.008L12 3.54v4.92l3.092 1.855A.6.6 0 0 0 16 9.8V2.2a.6.6 0 0 0-.3-.522Z"
    />
  </svg>
)
export default VideoCallIcon
