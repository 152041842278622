import { Avatar, Box, IconButton, Stack, Typography, Container } from "@mui/material"
import { withNamespaces } from "react-i18next"
import { useStyles } from "./styles";
import { TitleBar } from "../../components"
import useWindowDimensions from "../../utils/useWindowDimensions";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { GuestDetailCard } from "./component";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";

const RingingScreen = ({ t = () => false, onAccept = () => false, details = {}, goBack = () => false, cancelCall = () => false, live_user = false, controls = {}, accessControl = () => false }) => {

    const size = useWindowDimensions()
    const classes = useStyles(size);


    const handleAccept = () => {

        const payload = {
            is_swift_in: true,
            is_walk_in: true,
            swift_in_param: {
                id: details?.id,
                status: "Approved",
                updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                updated_at: new Date()
            },
            walk_in_param: {
                status: "Approved",
                request_id: details?.id,
                created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                agreement_unit_user: details?.notes?.[0]?.agreement_unit_user,
                security_guard_id: details?.created_by,
                message: "Approved"

            }
        }

        NetworkCall(
            `${config.api_url}/walkInout/upsert_walk_in_log`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            onAccept()

        }).catch((err) => {
            console.log(err)
        })




    }

    const handleCancel = () => {

        const payload = {
            is_walk_in: true,
            walk_in_param: {
                status: "Rejected",
                request_id: details?.id,
                created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                agreement_unit_user: details?.notes?.[0]?.agreement_unit_user,
                security_guard_id: details?.created_by,
                message: "Video Call Rejected By Security"
            }
        }

        NetworkCall(
            `${config.api_url}/walkInout/upsert_walk_in_log`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            cancelCall()

        }).catch((err) => {
            console.log(err)
        })


    }



    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
            <Box class={classes.root} >
                <TitleBar goBack={goBack} transparent color="transparent" text={t("Video call request")} />

                <Box p={2}>
                    <Box  display="flex" justifyContent={"center"} alignItems={"center"} height={size?.height - 230}>
                        <Box>
                            <Stack alignItems="center" justifyContent="center" direction="row" spacing={1}>
                                <img alt="" className={classes.logo} src="/images/icons/36x36.png" />
                                <Typography className={classes.subtitle1}>{t("Requesting Video call")}</Typography>

                            </Stack>
                            <center>
                                <Avatar
                                    className={classes.avatar}
                                    src={details?.notes?.[0]?.guard_logo ?? null}
                                />
                                <Typography className={classes.personName}>{details?.notes?.[0]?.resident_name}</Typography>
                                <Typography className={classes.address}>{`${details?.property_name ?? "-"}${details?.block_name ? `, ${details?.block_name}` : ""}${details?.floor_name ? `, ${details?.floor_name}` : ""}`}</Typography>

                                <br />
                                <Typography className={classes.subtitle2} sx={{ marginBottom: "10px",color:"#98A0AC" }}>{t("Visitor Detail")}</Typography>
                            </center>
                            <Box padding="0px 20px">
                                <GuestDetailCard
                                    details={{
                                        person_name: details?.person_name,
                                        reason: details?.reason,
                                        vendor_image: details?.vendor_image

                                    }}
                                    t={t}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {live_user ?
                        <center>
                            <Typography className={classes.alreadyText}>User Already In Call</Typography>
                        </center>
                        :

                        <Box className={classes.btnRoot}>
                            <Box p={0.2}>
                                <IconButton onClick={handleCancel} size="large" className={classes.clearIcon}>
                                    <ClearIcon style={{ color: "white", fontSize: 34 }} />
                                </IconButton>
                            </Box>

                            <Box p={0.2}>
                                <IconButton size="large" onClick={handleAccept} className={classes.doneIcon}>
                                    <DoneIcon style={{ color: "white", fontSize: 34 }} />
                                </IconButton>
                            </Box>

                        </Box>
                    }
                </Box>


            </Box>
        </Container>

    )
}
export default withNamespaces("walkInRequest")(RingingScreen)