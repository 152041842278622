import React from "react";
import { withBottombar } from "../../HOCs";
import Dashboard from "./dashboard";

class DashboardParent extends React.Component {
  render() {
    return <Dashboard />;
  }
}

export default withBottombar(DashboardParent, { note: true });
