import React from "react";
import CompanySelect from "./companyselectpage";

class CompanySelectParent extends React.Component {
    render() {
        return <CompanySelect />;
    }
}

export default CompanySelectParent;
