import * as React from "react"
export const Switchoff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 117142">
      <g fill="#f5f7fa" stroke="#e4e8ee" data-name="Ellipse 131001">
        <circle cx={24} cy={24} r={24} stroke="none" />
        <circle cx={24} cy={24} r={23.5} fill="none" />
      </g>
      <path
        fill="#ced3dd"
        d="M30.75 18h-1.944l-1.1-1.884A2.257 2.257 0 0 0 25.764 15h-3.528a2.257 2.257 0 0 0-1.944 1.116L19.194 18H17.25A3.254 3.254 0 0 0 14 21.25v9.5A3.254 3.254 0 0 0 17.25 34h13.5A3.254 3.254 0 0 0 34 30.75v-9.5A3.254 3.254 0 0 0 30.75 18Zm-2.25 6.25a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h.98a3 3 0 1 0 .417 3.4.75.75 0 0 1 1.326.7A4.5 4.5 0 1 1 27 22.151v-.4a.75.75 0 0 1 1.5 0Z"
      />
    </g>
  </svg>
)
