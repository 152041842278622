import { Box, Divider, Grid, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import React from 'react'
import { EntriesCardStyle } from './style'
import DateIcon from '../../../assets/dateIcon'
import moment from 'moment'
import { PassIcon } from '../utils/passIcon'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../router/routes'
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from "@mui/styles/useTheme";
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    }
}));

export const EntriesCard = ({
    id = "",
    imgUrl = "",
    title = "",
    mobCountryCode = "",
    mobNo = "",
    count = "",
    propertyName = "",
    unitNo = "",
    startDate = "",
    endDate = "",
    type = "",
    status = "Yet To Checkout",
    startDateString = "",
    endDateString = "",
    vehicleType = "",
    description = "",
    selectedTab = ""
}) => {
    const classes = EntriesCardStyle()
    const theme = useTheme();
    const below320 = useMediaQuery(theme.breakpoints.down('321'));
    const imageIcon = {
        "pass": <PassIcon />,
        "parking": "/images/parkingslot.svg"
    }
    const statusClasses = {
        "Yet To Checkout": classes.rejected,
        "Yet To CheckIn": classes.pending,
        "Pending": classes.pending,
        "Approved": classes.approved,
        "Received At Gate": classes.approved,
        "Rejected": classes.rejected,
        "Cancelled": classes.cancelled,
        "Checkout": classes.approved,
        "Yet To Receive": classes.pending,
        "Collected": classes.approved
    }

    const history = useHistory()

    const handleClick = (selectedTab) => {
        switch (selectedTab) {
            case "swiftCard":
                history.push(Routes.swiftInOutDetails + "?passId=" + id)
                break;
            case "parking":
                history.push(Routes.parkingSlotDetails + "?passId=" + id)
                break
            case "delivery":
                history.push(Routes.deliveryDetails + "?passId=" + id)
                break;
            case "gatepass":
                history.push(Routes.passDetails + "?passId=" + id)
                break
            case "walkIn":
                history.push(Routes.walkInDetails + "?passId=" + id)
                break
            default:
                break;
        }
    }

    return (
        <>
            <Stack className={classes.card} onClick={() => handleClick(selectedTab)}>
                <Stack direction="row" columnGap={1} p={2}>
                    <Stack className={classes.avatarBox}>
                        {imgUrl ? <img className={classes.avatar} src={imgUrl} alt='' /> :
                            type === "parking" ?
                                <img className={classes.parkingavatar} src={imageIcon[type]} alt="" /> :
                                < Box className={classes.avatarIcon}>{imageIcon[type]}</Box>}
                    </Stack>
                    <Stack rowGap={0.5} sx={{ width: below320 ? "80%" : "85%" }}>
                        <Grid container>
                            <Grid item xs={below320 ? 5 : 7}>
                                <Box width={"100%"}>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography className={classes.tooltipText}>{title}</Typography>
                                            </React.Fragment>
                                        }
                                        arrow
                                    >
                                        <Typography className={classes.title1}>{title}</Typography>
                                    </HtmlTooltip>
                                </Box>
                            </Grid>
                            {status &&
                            <Grid item xs={below320 ? 7 : 5} sx={{ display: "flex", justifyContent: "end" }}>
                                <Typography className={statusClasses[status]}>{status}</Typography>
                            </Grid>
                            }
                        </Grid>
                        <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ height: "15px", marginTop: "2px" }} />} columnGap={1}>
                            {vehicleType &&
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography className={classes.tooltipText}>{vehicleType}</Typography>
                                        </React.Fragment>
                                    }
                                    arrow
                                ><Typography className={classes.title2}>{vehicleType}</Typography>
                                </HtmlTooltip>}
                            {mobCountryCode &&
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography className={classes.tooltipText}>{`${mobCountryCode} ${mobNo}`}</Typography>
                                        </React.Fragment>
                                    }
                                    arrow
                                ><Typography className={classes.title2}>{`${mobCountryCode} ${mobNo}`}</Typography>
                                </HtmlTooltip>}
                            {count &&
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography className={classes.tooltipText}>{count}</Typography>
                                        </React.Fragment>
                                    }
                                    arrow
                                ><Typography className={classes.title2}>{count}</Typography>
                                </HtmlTooltip>}
                        </Stack>
                        <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ height: "15px", marginTop: "2px" }} />} columnGap={1}>
                            {propertyName &&
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography className={classes.tooltipText}>{propertyName}</Typography>
                                        </React.Fragment>
                                    }
                                    arrow
                                ><Typography className={classes.title2}>{propertyName}</Typography>
                                </HtmlTooltip>}
                            {unitNo &&
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography className={classes.tooltipText}>{unitNo}</Typography>
                                        </React.Fragment>
                                    }
                                    arrow
                                ><Typography className={classes.title2}>{unitNo}</Typography>
                                </HtmlTooltip>}
                        </Stack>
                        {description &&
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography className={classes.tooltipText}>{description}</Typography>
                                    </React.Fragment>
                                }
                                arrow
                            ><Typography className={classes.title2}>{description}</Typography>
                            </HtmlTooltip>
                        }
                    </Stack>
                </Stack>
                <Divider></Divider>
                <Stack
                    direction={"row"}
                    justifyContent={"space-evenly"}
                    divider={<Divider orientation="vertical" flexItem />}
                    columnGap={1}
                    p={"10px 14px"}
                    alignItems={"center"}
                >
                    <Stack direction="row" columnGap={1} alignItems={"center"}>
                        <DateIcon />
                        <Typography className={classes.dateText}>{startDateString}</Typography>
                        <Typography className={classes.dateText}>
                            &#x202a;{startDate ? moment(startDate)
                                .tz(moment.tz.guess())
                                .format("DD MMM YY") : ""}&#x202c;
                        </Typography>
                    </Stack>
                    {endDate && <Stack direction="row" columnGap={1} alignItems={"center"}>
                        <DateIcon />
                        <Typography className={classes.dateText}>{endDateString}</Typography>
                        <Typography className={classes.dateText}>
                            &#x202a;{moment(endDate)
                                .tz(moment.tz.guess())
                                .format("DD MMM YY ")}&#x202c;
                        </Typography>
                    </Stack>}
                </Stack>
            </Stack >
        </>
    )
}