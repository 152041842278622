import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { Bold, ExtraBold, SemiBold } from "../../utils";
import { withNamespaces } from "react-i18next";
import {SwiftInoutCard} from "./swiftInoutCard";
import {DialogDrawer} from "../dialogDrawer"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  containers: {
    padding: "10px 0px",
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    height: "75px",
    backgroundColor: "#ffffff",
    boxShadow: "0px -1px 6px #00000021",
    [theme.breakpoints.down('sm')]: {
      height: "auto",
      padding: "12px 0px 12px 0px",
      alignItems: "start",
    },
  },

  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    fontFamily: SemiBold
  },
  unselectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold
  },
  menuText: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  menuItem: {
    position: "absolute",
    padding: 0,
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      right: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      left: "58%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  box: {
    border: "1px solid #E4E8EE",
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  text: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.palette.secondary.main,
  },
}));
const BottomNavbar = (props) => {
  const { t = () => false } = props

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const [drawer, setDrawer] = React.useState(false);

  const renderForm = () => {
    return (
      <>
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                {t("Swift In & Out")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setDrawer(false)}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(`${Routes.getPassVerification}${"?title="}${"Domestic"}`)}
              >
                <img src="/images/vendor.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Registered Worker pass")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.vendor)}
              >
                <img src="/images/domestic.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>Vendor Entry</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.swiftInOut)}
              >
                <img src="/images/service.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Service Provider")}
                </Typography>
              </Box>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };
  return (
    <>
      <Container maxWidth="sm" style={{ padding: "0px" }}>
        <Grid
          container
          justifyContent={"space-around"}
          alignItems={"center"}
          className={classes.containers}
        >
          <Grid
            item
            xs={2}
            className={classes.tabStyle}
            onClick={props.onTab1Clicked}
          >
            <center>
              <img
                src={
                  window.location.pathname === Routes?.dashboard
                    ? "/images/dashboardselected.svg"
                    : "/images/dashboard_unselected.svg"
                }
                alt="dashboard"
                height={"24px"}
                width={"24px"}
              />
              <Typography
                className={
                  window.location.pathname === Routes?.dashboard
                    ? classes.selectedLabelTextStyle
                    : classes.unselectedLabelTextStyle
                }
              >
                {t("Dashboard")}
              </Typography>
            </center>
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.tabStyle}
            onClick={props.onTab2Clicked}
          >
            <center>
              <img
                src={
                  window.location.pathname === "/gate_pass"
                    ? "/images/entiresselect.svg"
                    : "/images/Group 7042.svg"
                }
                alt="gate_pass"
              />
              <Typography
                className={
                  window.location.pathname === "/gate_pass"
                    ? classes.selectedLabelTextStyle
                    : classes.unselectedLabelTextStyle
                }
              >
                {t("Entries")}
              </Typography>
            </center>
          </Grid>

          <Grid
            item
            xs={2}
            className={classes.tabStyle}
            onClick={props.onTab3Clicked}
          >
            <center>
              <img
                src={
                  window.location.pathname === "/info"
                    ? "/images/info_selected.svg"
                    : "/images/info.svg"
                }
                alt="request"
              />
              <Typography
                className={
                  window.location.pathname === "/info"
                    ? classes.selectedLabelTextStyle
                    : classes.unselectedLabelTextStyle
                }
              >
                {t("Parking")}
              </Typography>
            </center>
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.tabStyle}
            onClick={props.onTab4Clicked}
          >
            <center>
              <Avatar
                variant="circular"
                style={{
                  height: "46px",
                  width: "46px",
                  border: "2px solid #CCD3E4",
                  backgroundColor: "#5078E1",
                }}
                onClick={handleToggle}
              >
                <img
                  src={open ? "/images/search_selected.svg" : "/images/qr.svg"}
                  alt="search"
                />
              </Avatar>
              {/* <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
              >
                <div className={classes.menuItem}>
                  <MenuItem
                    onClick={() => history.push(Routes.getPassVerification)}
                  >
                    <Typography className={classes.menuText}>
                      &nbsp;{t("Gate Pass Verification")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setDrawer(true)}>
                    <Typography className={classes.menuText}>
                      &nbsp; {t("Swift In & Out")}
                    </Typography>
                  </MenuItem>
                </div>
              </Backdrop> */}
            </center>
          </Grid>
         
        </Grid>

        <DialogDrawer
        onClose={handleClose}
        header={t("Quick Access")}
        component={
          <SwiftInoutCard
          t={t}
          />
        }
        open={open}
        />
        {/* hidden */}
        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullWidth
            open={drawer}
            onClose={() => setDrawer(false)}
          >
            {renderForm()}
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            anchor="bottom"
            open={drawer}
            onClose={() => setDrawer(false)}
          >
            {renderForm()}
          </Drawer>
        </Hidden>
      </Container>
    </>
  );
};
export default withNamespaces("bottomNavbar")(BottomNavbar)