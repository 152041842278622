import { differenceInMinutes, parseISO } from "date-fns";
import { config } from "../../../config";

export const getHourmin = (from, to) => {

    const providedDate = parseISO(from);
    const currentDate = parseISO(to)

    const minutesDifference = differenceInMinutes(currentDate, providedDate);

    const hours = Math.floor(minutesDifference / 60);
    const minutes = minutesDifference % 60;

    return `${hours} hrs ${minutes} mins`
}

export const getCurrentLocationDetails = async (lat, long) => {
    const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${config.googleMapApiKey}`)
        .then(data => data.json())
        .then(({ results }) => {

            const resObj = results?.map((e) => {
                return {
                    ...e?.address_components
                }
            })
            const flated_address = resObj?.flatMap(val => {
                return Object.values(val)
            });

            let street = flated_address?.find(comp => comp?.types?.includes("route"))
            let city = flated_address?.find(comp => comp?.types?.includes("locality"));
            let location = flated_address?.find(comp => comp?.types?.includes("administrative_area_level_1"));
            let _obj = {};
            _obj["street"] = street?.long_name;
            _obj["address"] = `${city?.long_name}, ${location?.long_name}`;
            _obj["latitude"] = lat;
            _obj["longitude"] = long;
            _obj["city"] = city?.long_name;
            _obj["location"] = location?.long_name;
            return _obj
        }).catch((err) => {
            console.log(err);
            return null
        });
        console.log("result", result)
    return result
}