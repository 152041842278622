import { Grid, Typography } from '@mui/material'
import React from 'react'
import { CustomSwitchStyle } from './style'

export const CustomSwitch = ({
    list = [],
    selected = {},
    onChange = () => false
}) => {
    const classes = CustomSwitchStyle()

    return (
        <Grid container className={classes.container}>
            {
                list?.map((data) => {
                    return (
                        <Grid item xs={6}
                            className={selected?.value === data?.value ? classes.selected : classes.notSelected}
                            onClick={() => onChange(data)}>
                            <Typography>{data?.label}</Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}