import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, ValidateEmail } from "../../utils";

export const silentOTP = (e, login, alert, history, isIamValideToLogin, setDisable, welcomePageAuthToken, deviceToken) => {
    e.preventDefault();
    let data = {
        password: login.password,
    };
    if (isIamValideToLogin()) {
        //eslint-disable-next-line
        var isphone = /^\d+$/.test(login.email)
        //eslint-disable-next-line
        var email = ValidateEmail(
            login.email
        );

        if (isphone) {
            data["mobile_no"] = login.email;
        } else if (email) {
            data["email_id"] = login?.email?.toLowerCase();
        } else {
            data["ucid"] = login.email;
        }
        NetworkCall(
            `${config.auth_api_url}/auth/login`,
            NetWorkCallMethods.post, data, null, false, false
        ).then((response) => {
            if (response.status === 200) {
                localStorage.setItem(LocalStorageKeys.authToken, response.data.token)
                const payload = {
                    otp: "0000",
                };
                NetworkCall(
                    `${config.auth_api_url}/auth/verifyOTP/?token=${response.data.token}`,
                    NetWorkCallMethods.post, payload, null, false, false
                ).then((response) => {
                    if (response?.status === 200) {
                        localStorage.setItem(LocalStorageKeys.authToken, response.data.token);

                        if (deviceToken) {
                            localStorage.setItem(LocalStorageKeys.deviceToken, deviceToken);
                        }
                        history.push({
                            pathname: Routes.companyselect,
                            state: response.data.user.clients
                        });
                    } else {
                        setDisable(false);
                        localStorage.setItem(LocalStorageKeys.authToken, null);
                        history.push(Routes.login);
                    }
                }).catch((err) => {
                    if (err?.response?.data?.status === 403) {                        
                        alert.setSnack({
                            ...alert, open: true, msg: "Sorry you do not have access",
                            severity: AlertProps.severity.error,
                        });
                        setDisable(false);
                    }
                    else {
                        localStorage.setItem(LocalStorageKeys.authToken, null);
                        alert.setSnack({
                            ...alert, open: true, msg: "Invalid OTP",
                            severity: AlertProps.severity.error,
                        });
                        setDisable(false);
                    }
                });
            }
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: "Invalid Credientials",
                severity: AlertProps.severity.error,
            });
            setDisable(false);
        });
    } else {
        setDisable(false);
        return false;
    }
};