import { Button, Container, Grid, Typography, Box, Stack, InputAdornment, IconButton, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, Bold, ExtraBold, Message, NetWorkCallMethods, Regular, RetainLocalStorage, SemiBold, remCalc } from "../../utils";
import PasswordInfo from "../../components/passwordInfo";
import { withNamespaces } from "react-i18next";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useState } from "react";
import { initital_screen_state } from "../../utils/login/utils";
import { TitleBarNew } from "../../components/titlebarNew";
import { Label } from "../../components/label";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { InfoIcon } from "./assets/info_icon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { getCurrentBuildNumber } from "../../utils/version";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props}
    classes={{
      popper: className,
      arrow: {
        backgroundColor: "#525252",
        cursor: "pointer",
      },
    }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "6px",
    backgroundColor: "#525252",
    color: theme?.typography?.color?.white,
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    cursor: "pointer",
  },
}));

const InitialState = {
  email: "",
  enterPassword: "",
  confirmPassword: "",
  error: {
    email: "",
    enterPassword: "",
    confirmPassword: "",
  },
};
export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "white",
  },
  grid: {
    height: ({ size }) => size?.height,
    alignItems: "center",
  },
  left_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  right_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
  },
  web_right_section_card: {
    display: "contents",
    height: ({ size }) => size?.height,
    padding: "0px",
  },
  web_back_button: {
    cursor: "pointer",
    height: "32px",
    width: "32px",
  },
  web_content: {
    height: "100vh",
    overflow: "overlay",
    display: "flex",
    position: "relative",
    padding: "40px",
    backgroundColor: "white",
  },
  web_logo: {
    height: "32px",
    objectFit: "contain",
  },
  web_title: {
    fontSize: remCalc(16),
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  web_description: {
    fontSize: remCalc(16),
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  web_forget_password: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: theme.typography.color.primary_2,
    textAlign: "end",
    cursor: "pointer",
  },
  web_login_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: theme.palette.background.button_background_1,
    border: "1px solid #5078E1",
  },
  web_login_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.white,
  },
  web_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
    boxShadow: "0px 6px 10px #00000014",
    border: "1px solid #5078E1",
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  web_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.primary_2,
  },
  mob_right_section_card: {
    padding: "0px",
  },
  mob_content: {
    // height: "calc(100vh - 54px)",
    // overflow: "overlay",
  },
  mob_body_items: {
    padding: "16px",
  },
  mob_background: {
    height: "235px",
    borderRadius: theme.palette.borderRadius,
  },
  mob_background_image: {
    height: "235px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  },
  mob_bottom_items: {
    padding: "16px",
  },
  mob_logo: {
    height: "32px",
    objectFit: "contain",
  },
  mob_description: {
    fontSize: remCalc(20),
    fontFamily: SemiBold,
    color: "#FEF4F4",
    textShadow: "0px 6px 10px #00000033",
  },
  mob_set_up_new_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  mob_set_up_new_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "white",
  },
  mob_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#ffffff75",
    "&:hover": {
      backgroundColor: "#ffffff75",
    },
  },
  mob_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "white",
  },
  mob_terms_of_use_description: {
    width: "100%",
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: "white",
  },
  mob_terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: Bold,
    color: "white",
    textDecoration: "underline",
    display: "inline",
    cursor: "pointer",
  },
  version_no: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.tertiary,
  },
  terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.tertiary,
    cursor: "pointer",
  },
  powered_by_logo: {
    height: "10px",
    objectFit: "contain",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      minWidth: ({ size }) => size?.width > 599 && "500px",
      maxWidth: ({ size }) => size?.width > 599 && "500px",
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  drawer_header_grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
    padding: "16px",
  },
  drawer_header_text: {
    marginLeft: "12px",
    fontSize: remCalc(16),
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  drawer_content_box: {
    height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
    overflow: "overlay",
    padding: "16px",
  },
  drawer_button_box: {
    padding: "16px",
    borderTop: `1px solid ${theme.palette.border.secondary}`
  },
  region_flag: {
    borderRadius: "50%",
    objectFit: "contain",
    height: "18px",
    width: "18px",
  },
  flag_dropdown: {
    cursor: "pointer",
    height: "48px",
    padding: "14px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
  },
  region_text: {
    fontSize: "0.875rem",
    fontFamily: SemiBold
  },
}));

const SetupAccount = ({
  t = () => false
}) => {
  const [app_version, set_app_version] = React.useState(null);
  const size = useWindowDimensions();
  const classes = useStyles({ size });
  const history = useHistory();
  const [account, setAccount] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);

  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  // const decoded =jwt_decode(welcomePageAuthToken);
  const [decoded, setDecoded] = React.useState(null);
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const [disable, setDisable] = React.useState(false);
  const [show, setShow] = React.useState({
    show1: false,
    show2: false
  })
  const updateShow = (key, value) => {
    setShow({ ...show, [key]: value })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    getCurrentBuild();
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
      initialLoad();
    }
    // eslint-disable-next-line
  }, []);

  const getCurrentBuild = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
        set_app_version(temp_app_version);
      } catch (error) {
        reject(error);
      }
    });
  }
  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }
  const updateState = (key, value) => {
    let error = account.error;
    error[key] = "";
    setAccount({ ...account, [key]: value, error });
  };
  const isIamValideToCreateAccount = () => {
    let isValid = true;
    let error = account.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(account.enterPassword)

    //Checking Email
    // if (account.email.length === 0) {
    //     isValid = false;
    //     error.email = Message.English.requiredMessage('Email')
    // }

    //Checking enterPassword
    if (account.enterPassword.length === 0) {
      isValid = false;
      error.enterPassword = Message.English.requiredMessage("Password");
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.enterPassword = t("Password is not stong");
    }

    //Checking confirmPassword
    if (account.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword =
        Message.English.requiredMessage("confirm Password");
    }
    if (account.confirmPassword.length > 0) {
      if (
        account.enterPassword.length > 0 &&
        account.enterPassword !== account.confirmPassword
      ) {
        isValid = false;
        error.confirmPassword = t("Password does not Match");
      }
    }
    if (
      account.email &&
      account.enterPassword !== "" &&
      account.confirmPassword !== ""
    ) {
      if (account.enterPassword === account.confirmPassword) {
        isValid = true;
      }
    }

    setAccount({ ...account, error });
    return isValid;
  };

  const onSendAccountBtnClicked = () => {
    setDisable(true);
    if (isIamValideToCreateAccount()) {
      const payload = {
        password: account.confirmPassword,
      };
      NetworkCall(
        `${config.auth_api_url}/auth/updatepassword/?token=${welcomePageAuthToken}`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 201) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Password created successfully"),
            });
            RetainLocalStorage();
            history.push(
              Routes.login + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          }
        })
        .catch((err) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing went wrong"),
          });
        });
    } else {
      setDisable(false);
      return false;
    }
  };

  const handleGoBack = () => {
    history.goBack(-1);
  }

  const handleImageOnLoad = () => {
    setIsBackgroundImageLoading(false);
  }

  const handleImageOnError = () => {
    set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
  }

  return (
    is_loading
      ? <></>
      : <>
        <Container className={classes.mob_right_section_card} maxWidth="sm">
          <TitleBarNew is_back_button title={t("Set Up New Account")} goBack={handleGoBack} />
          <Grid container className={classes.mob_content}>
            <Grid item xs={12} className={classes.mob_body_items}
              sx={{ height: size?.height - 160, overflow: "overlay" }}>
              <Box className={classes.mob_background}>
                {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes.mob_background_image} />}
                <img className={classes.mob_background_image}
                  style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                  src={screen_state?.mob_background} alt=""
                  onLoad={handleImageOnLoad}
                  onError={handleImageOnError} />
              </Box>
              <Box height={"16px"} />
              <Typography className={classes.web_title}>
                {t("Set up your account details below")}
              </Typography>
              <Box height={"16px"} />
              {!Boolean(screen_state?.is_custom_domain) &&
                <>
                  <Label label={t("Region")} isRequired />
                  <Stack className={classes.flag_dropdown}
                    direction={"row"} columnGap={"8px"}
                    justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} columnGap={"8px"}
                      justifyContent={"space-between"} alignItems={"center"}>
                      <img className={classes.region_flag} src={screen_state?.flag} alt="" />
                      <Typography className={classes.region_text}>
                        {screen_state?.region}
                      </Typography>
                    </Stack>
                    <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                  </Stack>
                  <Box height={"16px"} />
                </>
              }
              <TextBox
                label={Message.English.createAccount.email.label}
                placeholder={Message.English.createAccount.email.placeholder}
                isError={account.error.email.length > 0}
                errorMessage={account.error.email}
                value={decoded && decoded.email_id ? decoded.email_id : ""}
                isReadonly
                disabled
                isRequired
              />
              <Box height={"16px"} />
              <TextBox
                value={account.enterPassword}
                onChange={(e) => updateState("enterPassword", e.target.value)}
                label={Message.English.createAccount.enterPassword.label}
                placeholder={
                  Message.English.createAccount.enterPassword.placeholder
                }
                isError={account.error.enterPassword.length > 0}
                errorMessage={account.error.enterPassword}
                isRequired
                type={show?.show1 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => updateShow("show1", !show?.show1)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {show?.show1 ? (
                        <RemoveRedEyeOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box height={"16px"} />
              <TextBox
                value={account.confirmPassword}
                onChange={(e) => updateState("confirmPassword", e.target.value)}
                label={Message.English.createAccount.confirmPassword.label}
                placeholder={
                  Message.English.createAccount.confirmPassword.placeholder
                }
                isError={account.error.confirmPassword.length > 0}
                errorMessage={account.error.confirmPassword}
                isRequired
                type={show?.show2 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => updateShow("show2", !show?.show2)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {show?.show2 ? (
                        <RemoveRedEyeOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box height={"10px"} />
              <Box display={"flex"} justifyContent={"end"}>
                <LightTooltip title={<PasswordInfo t={t} />} placement="left" open={isInfoOpen} arrow>
                  <Box
                    onClick={() => setIsInfoOpen(!isInfoOpen)}
                    onMouseEnter={() => setIsInfoOpen(true)}
                    onMouseLeave={() => setIsInfoOpen(false)}>
                    <InfoIcon />
                  </Box>
                </LightTooltip>
              </Box>
            </Grid>
            <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
              <Stack direction={"column"} alignItems={"start"}>
                <Button className={classes.mob_set_up_new_button} disabled={disable}
                  onClick={onSendAccountBtnClicked}>
                  <Typography className={classes.mob_set_up_new_button_text}>
                    {t("Set Password")}
                  </Typography>
                </Button>
                <Box height={"16px"} />
                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                  justifyContent={"space-between"} alignItems={"center"}>
                  {/* <Typography className={classes.terms_of_use_word}
                    onClick={() => setTCStatus(true)}>
                    {t("Terms of Use")}
                  </Typography> */}
                  <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                  {app_version &&
                    <Typography className={classes.version_no}>
                      {`V ${app_version}`}
                    </Typography>}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </>
  );
};
export default withNamespaces("setupAccount")(SetupAccount);