import { Stack, Typography } from '@mui/material'
import React from 'react'
import { OtherFeatureCardStyle } from './style'

export const OtherFeatureCard = ({
    logo = "",
    title = "",
    handleClick = () => false
}) => {
    const classes = OtherFeatureCardStyle()
    return (
        <Stack direction={"row"} columnGap={1.5} className={classes.card} alignItems={"center"}
            onClick={handleClick} sx={{width: "100%"}}>
            {logo}
            <Typography className={classes.text}>{title}</Typography>
        </Stack>
    )
}