import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { Regular, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize:(props) => (props.fontSize ?? "0.75rem !important"),
    fontFamily: SemiBold
  },
  text: {},

  textbox: {
    "& input::placeholder": {
      fontSize:(props) => (props.fontSize ?? "0.75rem !important"),
      fontFamily:SemiBold,
      color:"#a2a2a2 !important",
      opacity:"1 !important",
    },
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #E4E8EE",
      fontFamily:SemiBold,
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "0px" : "11px 14px"),
      fontFamily:SemiBold,
      font:"revert"
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      borderRadius: "4px !important"
    },
  },
}));

export const TextBox = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" className={classes.Label} gutterBottom>
        {props.label}{" "}{props?.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </Typography>
    );
  };
  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <div className={classes.text}>
          <TextField
            className={classes.textbox}
            type={props.type}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            style={{
              backgroundColor: props?.textBoxBgColor ? props?.textBoxBgColor : props?.color ? "white" : "auto",
              borderRadius: "10px",
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autoCapitalize: "none",
            }}
            InputProps={{
              endAdornment: props?.endAdornment,
              startAdornment: props?.startAdornment,
            }}
            disabled={props?.disabled ?? false}
            size="small"
            multiline={props.multiline}
            rows={5}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"} style={{fontFamily:Regular}}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool
};
TextBox.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
  isRequired: false,
};
