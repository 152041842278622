export const BiCycle = (props) => (
    <svg
        width={31}
        height={65}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <linearGradient
                id="a"
                x1={343.818}
                y1={-428.616}
                x2={347.232}
                y2={-428.616}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#302c28" />
                <stop offset={0.09} stopColor="#3a3632" />
                <stop offset={0.35} stopColor="#514d4b" />
                <stop offset={0.5} stopColor="#5a5654" />
                <stop offset={0.65} stopColor="#514d4b" />
                <stop offset={0.91} stopColor="#3a3632" />
                <stop offset={1} stopColor="#302c28" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={578.854}
                y1={413.499}
                x2={580.812}
                y2={413.499}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.05} stopColor="#b9b9b9" />
                <stop offset={0.21} stopColor="#999a9a" />
                <stop offset={0.26} stopColor="#a1a1a1" />
                <stop offset={0.34} stopColor="#b6b6b6" />
                <stop offset={0.42} stopColor="#d1d0d0" />
                <stop offset={0.53} stopColor="#f8fafb" />
                <stop offset={0.69} stopColor="#d1d0d0" />
                <stop offset={0.74} stopColor="#b3b4b3" />
                <stop offset={0.78} stopColor="#a6a7a6" />
                <stop offset={0.83} stopColor="#aaa" />
                <stop offset={0.87} stopColor="#b3b3b3" />
                <stop offset={0.97} stopColor="#bdbdbd" />
            </linearGradient>
            <linearGradient
                id="n"
                x1={339.866}
                y1={139.786}
                x2={343.269}
                y2={139.786}
                xlinkHref="#a"
            />
            <linearGradient
                id="o"
                x1={458.781}
                y1={79.565}
                x2={458.781}
                y2={79.717}
                xlinkHref="#b"
            />
            <linearGradient
                id="p"
                x1={-1778.754}
                y1={424.261}
                x2={-1775.878}
                y2={424.261}
                xlinkHref="#a"
            />
            <linearGradient
                id="q"
                x1={356.615}
                y1={160.825}
                x2={359.434}
                y2={160.825}
                xlinkHref="#a"
            />
            <linearGradient
                id="c"
                x1={80.768}
                y1={433.223}
                x2={80.408}
                y2={438.049}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#302c28" />
                <stop offset={0.24} stopColor="#211e1b" />
                <stop offset={0.71} stopColor="#080807" />
                <stop offset={1} />
            </linearGradient>
            <linearGradient
                id="r"
                x1={84.401}
                y1={418.798}
                x2={84.032}
                y2={423.36}
                xlinkHref="#c"
            />
            <linearGradient
                id="s"
                x1={596.542}
                y1={395.393}
                x2={598.521}
                y2={395.393}
                xlinkHref="#b"
            />
            <linearGradient
                id="t"
                x1={64.367}
                y1={124.013}
                x2={65.078}
                y2={125.279}
                xlinkHref="#a"
            />
            <linearGradient
                id="d"
                x1={64.367}
                y1={124.598}
                x2={65.038}
                y2={124.598}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#040000" />
                <stop offset={0.04} stopColor="#0c0909" />
                <stop offset={0.25} stopColor="#333232" />
                <stop offset={0.41} stopColor="#4c4b4b" />
                <stop offset={0.51} stopColor="#555" />
                <stop offset={0.61} stopColor="#505050" />
                <stop offset={0.7} stopColor="#454444" />
                <stop offset={0.8} stopColor="#312f2f" />
                <stop offset={0.9} stopColor="#151212" />
                <stop offset={0.92} stopColor="#110e0e" />
            </linearGradient>
            <linearGradient
                id="u"
                x1={87.453}
                y1={558.84}
                x2={87.659}
                y2={558.84}
                xlinkHref="#d"
            />
            <linearGradient
                id="v"
                x1={87.846}
                y1={548.729}
                x2={88.051}
                y2={548.729}
                xlinkHref="#d"
            />
            <linearGradient
                id="e"
                x1={84.69}
                y1={443.613}
                x2={84.292}
                y2={448.744}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} />
                <stop offset={0.33} stopColor="#eff3f6" />
                <stop offset={1} />
            </linearGradient>
            <linearGradient
                id="w"
                x1={-498.429}
                y1={-1536.193}
                x2={-498.825}
                y2={-1531.05}
                xlinkHref="#e"
            />
            <linearGradient
                id="f"
                x1={68.786}
                y1={418.012}
                x2={68.786}
                y2={422.686}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#eff3f6" />
                <stop offset={0.22} />
                <stop offset={0.83} />
                <stop offset={1} stopColor="#eff3f6" />
            </linearGradient>
            <linearGradient
                id="x"
                x1={68.846}
                y1={422.395}
                x2={68.846}
                y2={427.111}
                xlinkHref="#f"
            />
            <linearGradient
                id="y"
                x1={-2617.031}
                y1={-227.862}
                x2={-2617.031}
                y2={-227.735}
                xlinkHref="#b"
            />
            <linearGradient
                id="z"
                x1={255.198}
                y1={-1146.283}
                x2={258.087}
                y2={-1146.283}
                xlinkHref="#a"
            />
            <linearGradient
                id="A"
                x1={-2010.54}
                y1={-544.595}
                x2={-2007.686}
                y2={-544.595}
                xlinkHref="#a"
            />
            <linearGradient
                id="B"
                x1={-456.131}
                y1={-1491.673}
                x2={-456.492}
                y2={-1486.847}
                xlinkHref="#c"
            />
            <linearGradient
                id="C"
                x1={-478.747}
                y1={-1439.293}
                x2={-479.119}
                y2={-1434.731}
                xlinkHref="#c"
            />
            <linearGradient
                id="D"
                x1={-3503.47}
                y1={-1362.316}
                x2={-3501.47}
                y2={-1362.316}
                xlinkHref="#b"
            />
            <linearGradient
                id="E"
                x1={-366.218}
                y1={-424.916}
                x2={-365.504}
                y2={-423.65}
                xlinkHref="#a"
            />
            <linearGradient
                id="F"
                x1={93.725}
                y1={551.582}
                x2={93.931}
                y2={551.582}
                xlinkHref="#d"
            />
            <linearGradient
                id="G"
                x1={93.332}
                y1={553.494}
                x2={93.538}
                y2={553.494}
                xlinkHref="#d"
            />
            <linearGradient
                id="H"
                x1={68.644}
                y1={125.6}
                x2={69.313}
                y2={125.6}
                xlinkHref="#d"
            />
            <linearGradient
                id="I"
                x1={-481.652}
                y1={-1525.526}
                x2={-482.05}
                y2={-1520.396}
                xlinkHref="#e"
            />
            <linearGradient
                id="J"
                x1={72.379}
                y1={412.149}
                x2={71.983}
                y2={417.28}
                xlinkHref="#e"
            />
            <linearGradient
                id="K"
                x1={-389.294}
                y1={-1455.072}
                x2={-389.294}
                y2={-1450.356}
                xlinkHref="#f"
            />
            <linearGradient
                id="L"
                x1={-389.219}
                y1={-1454.787}
                x2={-389.219}
                y2={-1450.072}
                xlinkHref="#f"
            />
            <linearGradient
                id="M"
                x1={110.205}
                y1={15.042}
                x2={110.205}
                y2={14.923}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.05} stopColor="#b16f43" />
                <stop offset={0.15} stopColor="#75543f" />
                <stop offset={0.26} stopColor="#b16f43" />
                <stop offset={0.4} stopColor="#e6a65e" />
                <stop offset={0.74} stopColor="#f7b767" />
                <stop offset={0.87} stopColor="#d29053" />
                <stop offset={1} stopColor="#b16f43" />
            </linearGradient>
            <linearGradient
                id="N"
                x1={974.546}
                y1={14.968}
                x2={974.546}
                y2={14.984}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.12} stopColor="#b16f43" />
                <stop offset={0.23} stopColor="#c27e48" />
                <stop offset={0.46} stopColor="#eea755" />
                <stop offset={0.54} stopColor="#ffb75a" />
                <stop offset={0.9} stopColor="#ca8f5d" />
                <stop offset={1} stopColor="#bc845e" />
            </linearGradient>
            <linearGradient
                id="O"
                x1={105.663}
                y1={15.091}
                x2={114.01}
                y2={15.091}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#b16f43" />
                <stop offset={0.02} stopColor="#b7784d" />
                <stop offset={0.11} stopColor="#ce9b72" />
                <stop offset={0.2} stopColor="#dfb78f" />
                <stop offset={0.29} stopColor="#eccba4" />
                <stop offset={0.38} stopColor="#f4d7b0" />
                <stop offset={0.46} stopColor="#f7dbb5" />
                <stop offset={0.55} stopColor="#f3d6af" />
                <stop offset={0.66} stopColor="#eac7a0" />
                <stop offset={0.77} stopColor="#dbb087" />
                <stop offset={0.9} stopColor="#c58f65" />
                <stop offset={1} stopColor="#b16f43" />
            </linearGradient>
            <linearGradient
                id="i"
                x1={232.052}
                y1={16.73}
                x2={248.515}
                y2={16.73}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#b16f43" />
                <stop offset={0.03} stopColor="#b7784d" />
                <stop offset={0.12} stopColor="#ce9b72" />
                <stop offset={0.2} stopColor="#dfb78f" />
                <stop offset={0.29} stopColor="#eccba4" />
                <stop offset={0.38} stopColor="#f4d7b0" />
                <stop offset={0.46} stopColor="#f7dbb5" />
                <stop offset={0.55} stopColor="#f3d6af" />
                <stop offset={0.66} stopColor="#eac7a0" />
                <stop offset={0.77} stopColor="#dbb087" />
                <stop offset={0.9} stopColor="#c58f65" />
                <stop offset={1} stopColor="#b16f43" />
            </linearGradient>
            <linearGradient
                id="g"
                x1={410.151}
                y1={368.722}
                x2={410.389}
                y2={368.508}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.12} stopColor="#b16f43" />
                <stop offset={0.21} stopColor="#b87c54" />
                <stop offset={0.38} stopColor="#ca9e80" />
                <stop offset={0.63} stopColor="#e7d4c7" />
                <stop offset={0.81} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="P"
                x1={411.605}
                y1={367.603}
                x2={411.83}
                y2={367.389}
                xlinkHref="#g"
            />
            <linearGradient
                id="Q"
                x1={413.06}
                y1={366.498}
                x2={413.285}
                y2={366.285}
                xlinkHref="#g"
            />
            <linearGradient
                id="R"
                x1={414.502}
                y1={365.375}
                x2={414.74}
                y2={365.161}
                xlinkHref="#g"
            />
            <linearGradient
                id="S"
                x1={415.956}
                y1={364.271}
                x2={416.181}
                y2={364.057}
                xlinkHref="#g"
            />
            <linearGradient
                id="T"
                x1={417.398}
                y1={363.151}
                x2={417.636}
                y2={362.938}
                xlinkHref="#g"
            />
            <linearGradient
                id="U"
                x1={418.853}
                y1={361.323}
                x2={419.078}
                y2={361.11}
                xlinkHref="#g"
            />
            <linearGradient
                id="V"
                x1={420.294}
                y1={360.924}
                x2={420.532}
                y2={360.71}
                xlinkHref="#g"
            />
            <linearGradient
                id="W"
                x1={421.749}
                y1={359.8}
                x2={421.974}
                y2={359.586}
                xlinkHref="#g"
            />
            <linearGradient
                id="X"
                x1={423.204}
                y1={357.997}
                x2={423.429}
                y2={357.784}
                xlinkHref="#g"
            />
            <linearGradient
                id="Y"
                x1={424.645}
                y1={357.572}
                x2={424.87}
                y2={357.358}
                xlinkHref="#g"
            />
            <linearGradient
                id="Z"
                x1={426.1}
                y1={356.468}
                x2={426.325}
                y2={356.254}
                xlinkHref="#g"
            />
            <linearGradient
                id="aa"
                x1={427.542}
                y1={355.349}
                x2={427.78}
                y2={355.135}
                xlinkHref="#g"
            />
            <linearGradient
                id="ab"
                x1={428.996}
                y1={354.23}
                x2={429.221}
                y2={354.016}
                xlinkHref="#g"
            />
            <linearGradient
                id="ac"
                x1={430.438}
                y1={353.121}
                x2={430.676}
                y2={352.907}
                xlinkHref="#g"
            />
            <linearGradient
                id="ad"
                x1={431.893}
                y1={352.002}
                x2={432.118}
                y2={351.788}
                xlinkHref="#g"
            />
            <linearGradient
                id="ae"
                x1={433.347}
                y1={350.21}
                x2={433.572}
                y2={349.997}
                xlinkHref="#g"
            />
            <linearGradient
                id="af"
                x1={434.789}
                y1={349.769}
                x2={435.014}
                y2={349.555}
                xlinkHref="#g"
            />
            <linearGradient
                id="ag"
                x1={436.244}
                y1={348.665}
                x2={436.469}
                y2={348.451}
                xlinkHref="#g"
            />
            <linearGradient
                id="ah"
                x1={437.699}
                y1={347.536}
                x2={437.923}
                y2={347.322}
                xlinkHref="#g"
            />
            <linearGradient
                id="ai"
                x1={439.153}
                y1={345.673}
                x2={439.378}
                y2={345.46}
                xlinkHref="#g"
            />
            <linearGradient
                id="aj"
                x1={411.6}
                y1={-1219.738}
                x2={411.825}
                y2={-1219.941}
                xlinkHref="#g"
            />
            <linearGradient
                id="ak"
                x1={413.042}
                y1={-1220.857}
                x2={413.28}
                y2={-1221.07}
                xlinkHref="#g"
            />
            <linearGradient
                id="al"
                x1={414.497}
                y1={-1221.976}
                x2={414.721}
                y2={-1222.189}
                xlinkHref="#g"
            />
            <linearGradient
                id="am"
                x1={415.938}
                y1={-1220.695}
                x2={416.176}
                y2={-1220.908}
                xlinkHref="#g"
            />
            <linearGradient
                id="an"
                x1={417.393}
                y1={-1224.204}
                x2={417.618}
                y2={-1224.407}
                xlinkHref="#g"
            />
            <linearGradient
                id="ao"
                x1={418.848}
                y1={-1225.313}
                x2={419.072}
                y2={-1225.526}
                xlinkHref="#g"
            />
            <linearGradient
                id="ap"
                x1={420.289}
                y1={-1226.392}
                x2={420.514}
                y2={-1226.616}
                xlinkHref="#g"
            />
            <linearGradient
                id="aq"
                x1={421.744}
                y1={-1227.511}
                x2={421.969}
                y2={-1227.725}
                xlinkHref="#g"
            />
            <linearGradient
                id="ar"
                x1={423.185}
                y1={-1226.22}
                x2={423.41}
                y2={-1226.433}
                xlinkHref="#g"
            />
            <linearGradient
                id="as"
                x1={424.64}
                y1={-1229.739}
                x2={424.865}
                y2={-1229.943}
                xlinkHref="#g"
            />
            <linearGradient
                id="at"
                x1={426.082}
                y1={-1230.888}
                x2={426.32}
                y2={-1231.101}
                xlinkHref="#g"
            />
            <linearGradient
                id="au"
                x1={427.537}
                y1={-1231.977}
                x2={427.761}
                y2={-1232.181}
                xlinkHref="#g"
            />
            <linearGradient
                id="av"
                x1={428.991}
                y1={-1233.086}
                x2={429.216}
                y2={-1233.3}
                xlinkHref="#g"
            />
            <linearGradient
                id="aw"
                x1={430.433}
                y1={-1234.205}
                x2={430.658}
                y2={-1234.409}
                xlinkHref="#g"
            />
            <linearGradient
                id="ax"
                x1={431.888}
                y1={-1235.314}
                x2={432.112}
                y2={-1235.518}
                xlinkHref="#g"
            />
            <linearGradient
                id="ay"
                x1={433.329}
                y1={-1236.433}
                x2={433.554}
                y2={-1236.637}
                xlinkHref="#g"
            />
            <linearGradient
                id="az"
                x1={434.784}
                y1={-1237.552}
                x2={435.009}
                y2={-1237.756}
                xlinkHref="#g"
            />
            <linearGradient
                id="aA"
                x1={436.225}
                y1={-1238.68}
                x2={436.463}
                y2={-1238.894}
                xlinkHref="#g"
            />
            <linearGradient
                id="aB"
                x1={437.68}
                y1={-1237.358}
                x2={437.905}
                y2={-1237.561}
                xlinkHref="#g"
            />
            <linearGradient
                id="aC"
                x1={439.135}
                y1={-1240.899}
                x2={439.373}
                y2={-1241.103}
                xlinkHref="#g"
            />
            <linearGradient
                id="aD"
                x1={440.59}
                y1={-1242.018}
                x2={440.815}
                y2={-1242.222}
                xlinkHref="#g"
            />
            <linearGradient
                id="aH"
                x1={595.634}
                y1={15.886}
                x2={640.23}
                y2={15.886}
                xlinkHref="#i"
            />
            <linearGradient
                id="aI"
                x1={1407.186}
                y1={15.886}
                x2={1512.596}
                y2={15.886}
                xlinkHref="#i"
            />
            <linearGradient
                id="aJ"
                x1={1406.868}
                y1={15.882}
                x2={1512.277}
                y2={15.882}
                xlinkHref="#i"
            />
            <linearGradient
                id="aK"
                x1={201.716}
                y1={18.602}
                x2={218.187}
                y2={18.602}
                xlinkHref="#i"
            />
            <linearGradient
                id="aL"
                x1={357.703}
                y1={409.022}
                x2={357.941}
                y2={408.808}
                xlinkHref="#g"
            />
            <linearGradient
                id="aM"
                x1={359.157}
                y1={407.899}
                x2={359.382}
                y2={407.685}
                xlinkHref="#g"
            />
            <linearGradient
                id="aN"
                x1={360.612}
                y1={406.794}
                x2={360.836}
                y2={406.58}
                xlinkHref="#g"
            />
            <linearGradient
                id="aO"
                x1={362.052}
                y1={404.881}
                x2={362.277}
                y2={404.668}
                xlinkHref="#g"
            />
            <linearGradient
                id="aP"
                x1={363.507}
                y1={407.914}
                x2={363.731}
                y2={407.698}
                xlinkHref="#g"
            />
            <linearGradient
                id="aQ"
                x1={364.948}
                y1={403.443}
                x2={365.172}
                y2={403.229}
                xlinkHref="#g"
            />
            <linearGradient
                id="aR"
                x1={366.402}
                y1={402.334}
                x2={366.627}
                y2={402.121}
                xlinkHref="#g"
            />
            <linearGradient
                id="aS"
                x1={367.843}
                y1={401.219}
                x2={368.081}
                y2={401.006}
                xlinkHref="#g"
            />
            <linearGradient
                id="aT"
                x1={369.297}
                y1={400.096}
                x2={369.522}
                y2={399.883}
                xlinkHref="#g"
            />
            <linearGradient
                id="aU"
                x1={370.751}
                y1={398.991}
                x2={370.976}
                y2={398.778}
                xlinkHref="#g"
            />
            <linearGradient
                id="aV"
                x1={372.192}
                y1={397.872}
                x2={372.417}
                y2={397.659}
                xlinkHref="#g"
            />
            <linearGradient
                id="aW"
                x1={373.646}
                y1={396.759}
                x2={373.871}
                y2={396.546}
                xlinkHref="#g"
            />
            <linearGradient
                id="aX"
                x1={375.087}
                y1={395.644}
                x2={375.312}
                y2={395.431}
                xlinkHref="#g"
            />
            <linearGradient
                id="aY"
                x1={376.541}
                y1={393.767}
                x2={376.766}
                y2={393.554}
                xlinkHref="#g"
            />
            <linearGradient
                id="aZ"
                x1={377.982}
                y1={393.412}
                x2={378.22}
                y2={393.199}
                xlinkHref="#g"
            />
            <linearGradient
                id="ba"
                x1={379.437}
                y1={392.293}
                x2={379.661}
                y2={392.08}
                xlinkHref="#g"
            />
            <linearGradient
                id="bb"
                x1={380.891}
                y1={391.184}
                x2={381.116}
                y2={390.971}
                xlinkHref="#g"
            />
            <linearGradient
                id="bc"
                x1={382.332}
                y1={390.065}
                x2={382.556}
                y2={389.852}
                xlinkHref="#g"
            />
            <linearGradient
                id="bd"
                x1={383.799}
                y1={388.189}
                x2={384.024}
                y2={387.976}
                xlinkHref="#g"
            />
            <linearGradient
                id="be"
                x1={355.699}
                y1={-1179.464}
                x2={355.921}
                y2={-1179.677}
                xlinkHref="#g"
            />
            <linearGradient
                id="bf"
                x1={357.126}
                y1={-1180.611}
                x2={357.348}
                y2={-1180.825}
                xlinkHref="#g"
            />
            <linearGradient
                id="bg"
                x1={358.566}
                y1={-1181.702}
                x2={358.788}
                y2={-1181.905}
                xlinkHref="#g"
            />
            <linearGradient
                id="bh"
                x1={359.993}
                y1={-1182.821}
                x2={360.215}
                y2={-1183.024}
                xlinkHref="#g"
            />
            <linearGradient
                id="bi"
                x1={361.433}
                y1={-1183.948}
                x2={361.656}
                y2={-1184.162}
                xlinkHref="#g"
            />
            <linearGradient
                id="bj"
                x1={362.86}
                y1={-1182.724}
                x2={363.083}
                y2={-1182.937}
                xlinkHref="#g"
            />
            <linearGradient
                id="bk"
                x1={364.3}
                y1={-1186.021}
                x2={364.523}
                y2={-1186.234}
                xlinkHref="#g"
            />
            <linearGradient
                id="bl"
                x1={365.74}
                y1={-1187.295}
                x2={365.963}
                y2={-1187.509}
                xlinkHref="#g"
            />
            <linearGradient
                id="bm"
                x1={367.167}
                y1={-1188.414}
                x2={367.39}
                y2={-1188.628}
                xlinkHref="#g"
            />
            <linearGradient
                id="bn"
                x1={368.608}
                y1={-1189.523}
                x2={368.83}
                y2={-1189.737}
                xlinkHref="#g"
            />
            <linearGradient
                id="bo"
                x1={370.035}
                y1={-1190.614}
                x2={370.257}
                y2={-1190.827}
                xlinkHref="#g"
            />
            <linearGradient
                id="bp"
                x1={371.475}
                y1={-1191.751}
                x2={371.697}
                y2={-1191.965}
                xlinkHref="#g"
            />
            <linearGradient
                id="bq"
                x1={372.902}
                y1={-1190.521}
                x2={373.125}
                y2={-1190.724}
                xlinkHref="#g"
            />
            <linearGradient
                id="br"
                x1={374.342}
                y1={-1193.971}
                x2={374.565}
                y2={-1194.174}
                xlinkHref="#g"
            />
            <linearGradient
                id="bs"
                x1={375.769}
                y1={-1195.069}
                x2={376.005}
                y2={-1195.283}
                xlinkHref="#g"
            />
            <linearGradient
                id="bt"
                x1={377.209}
                y1={-1196.217}
                x2={377.432}
                y2={-1196.431}
                xlinkHref="#g"
            />
            <linearGradient
                id="bu"
                x1={378.65}
                y1={-1197.297}
                x2={378.872}
                y2={-1197.511}
                xlinkHref="#g"
            />
            <linearGradient
                id="bv"
                x1={380.077}
                y1={-1198.445}
                x2={380.299}
                y2={-1198.659}
                xlinkHref="#g"
            />
            <linearGradient
                id="bw"
                x1={381.53}
                y1={-1199.535}
                x2={381.752}
                y2={-1199.749}
                xlinkHref="#g"
            />
            <linearGradient
                id="bx"
                x1={382.957}
                y1={-1200.683}
                x2={383.179}
                y2={-1200.897}
                xlinkHref="#g"
            />
            <linearGradient
                id="by"
                x1={122.759}
                y1={55.44}
                x2={125.585}
                y2={55.44}
                xlinkHref="#a"
            />
            <linearGradient
                id="bz"
                x1={-1550.633}
                y1={92.768}
                x2={-1549.633}
                y2={92.768}
                xlinkHref="#a"
            />
            <linearGradient
                id="j"
                x1={286.257}
                y1={87.042}
                x2={290.009}
                y2={87.042}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#3b3734" />
                <stop offset={0.14} stopColor="#494543" />
                <stop offset={0.32} stopColor="#55514f" />
                <stop offset={0.5} stopColor="#595654" />
                <stop offset={0.68} stopColor="#55514f" />
                <stop offset={0.86} stopColor="#494543" />
                <stop offset={1} stopColor="#3b3734" />
            </linearGradient>
            <linearGradient
                id="bA"
                x1={-3344.408}
                y1={143.447}
                x2={-3343.478}
                y2={143.447}
                xlinkHref="#a"
            />
            <linearGradient
                id="bB"
                x1={290.917}
                y1={88.286}
                x2={294.67}
                y2={88.286}
                xlinkHref="#j"
            />
            <linearGradient
                id="bC"
                x1={-3370.587}
                y1={143.447}
                x2={-3369.65}
                y2={143.447}
                xlinkHref="#a"
            />
            <linearGradient
                id="bD"
                x1={-1550.633}
                y1={21.212}
                x2={-1549.633}
                y2={21.212}
                xlinkHref="#a"
            />
            <linearGradient
                id="bE"
                x1={260.706}
                y1={21.367}
                x2={277.422}
                y2={21.367}
                xlinkHref="#j"
            />
            <linearGradient
                id="bF"
                x1={208.687}
                y1={305.276}
                x2={208.687}
                y2={304.264}
                xlinkHref="#b"
            />
            <linearGradient
                id="m"
                x1={208.293}
                y1={304.75}
                x2={208.761}
                y2={304.75}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.3} stopColor="#989697" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="bG"
                x1={105.024}
                y1={51.81}
                x2={107.855}
                y2={51.81}
                xlinkHref="#a"
            />
            <linearGradient
                id="k"
                x1={159.44}
                y1={67.284}
                x2={162.359}
                y2={67.582}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} />
                <stop offset={0.07} stopColor="#111010" />
                <stop offset={0.26} stopColor="#383634" />
                <stop offset={0.41} stopColor="#514d4b" />
                <stop offset={0.5} stopColor="#5a5654" />
                <stop offset={0.59} stopColor="#514d4b" />
                <stop offset={0.74} stopColor="#383634" />
                <stop offset={0.93} stopColor="#111010" />
                <stop offset={1} />
            </linearGradient>
            <linearGradient
                id="bH"
                x1={105.028}
                y1={51.81}
                x2={107.858}
                y2={51.81}
                xlinkHref="#j"
            />
            <linearGradient
                id="bI"
                x1={592.583}
                y1={-1279.592}
                x2={594.541}
                y2={-1279.592}
                xlinkHref="#b"
            />
            <linearGradient
                id="bJ"
                x1={106.433}
                y1={-159.216}
                x2={109.268}
                y2={-159.216}
                xlinkHref="#a"
            />
            <linearGradient
                id="bK"
                x1={162.298}
                y1={-208.404}
                x2={165.224}
                y2={-208.106}
                xlinkHref="#k"
            />
            <linearGradient
                id="bL"
                x1={106.429}
                y1={-159.216}
                x2={109.264}
                y2={-159.216}
                xlinkHref="#j"
            />
            <linearGradient
                id="bM"
                x1={82.382}
                y1={79.958}
                x2={84.213}
                y2={79.228}
                xlinkHref="#d"
            />
            <linearGradient
                id="bN"
                x1={82.182}
                y1={79.906}
                x2={84.009}
                y2={79.177}
                xlinkHref="#d"
            />
            <linearGradient
                id="bO"
                x1={-240.425}
                y1={1522.778}
                x2={-240.142}
                y2={1522.778}
                xlinkHref="#d"
            />
            <linearGradient
                id="bQ"
                x1={88.448}
                y1={-273.96}
                x2={90.279}
                y2={-274.689}
                xlinkHref="#d"
            />
            <linearGradient
                id="bR"
                x1={88.232}
                y1={-273.69}
                x2={90.058}
                y2={-274.419}
                xlinkHref="#d"
            />
            <linearGradient
                id="bS"
                x1={436.64}
                y1={-793.719}
                x2={436.923}
                y2={-793.719}
                xlinkHref="#d"
            />
            <linearGradient
                id="bT"
                x1={139.263}
                y1={-1199.108}
                x2={139.263}
                y2={-1199.738}
                xlinkHref="#m"
            />
            <radialGradient
                id="h"
                cx={0.375}
                cy={0.5}
                r={0.353}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#cfa68b" />
                <stop offset={0.11} stopColor="#ca9d80" />
                <stop offset={0.62} stopColor="#b87b54" />
                <stop offset={0.88} stopColor="#b16f43" />
            </radialGradient>
            <radialGradient
                id="aE"
                cx={3573.696}
                cy={15.091}
                r={0.353}
                xlinkHref="#h"
            />
            <radialGradient
                id="aF"
                cx={3571.029}
                cy={15.079}
                r={0.353}
                xlinkHref="#h"
            />
            <radialGradient
                id="aG"
                cx={0.493}
                cy={0.498}
                r={0.356}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#fff" />
                <stop offset={0.12} stopColor="#fcfaf9" />
                <stop offset={0.27} stopColor="#f6efea" />
                <stop offset={0.43} stopColor="#ecdcd1" />
                <stop offset={0.6} stopColor="#ddc1ae" />
                <stop offset={0.79} stopColor="#cb9f81" />
                <stop offset={0.97} stopColor="#b4754b" />
                <stop offset={1} stopColor="#b16f43" />
            </radialGradient>
            <radialGradient
                id="l"
                cx={0.091}
                cy={5.581}
                r={5.875}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.87} stopColor="#989697" />
                <stop offset={0.94} stopColor="#eff3f6" />
                <stop offset={1} stopColor="#989697" />
            </radialGradient>
            <radialGradient
                id="bP"
                cx={33.457}
                cy={113.572}
                r={5.879}
                xlinkHref="#l"
            />
        </defs>
        <g data-name="Group 107520" origin="0 0">
            <path
                data-name="Path 100596"
                d="M10.35 159.438a.179.179 0 0 0 .187-.177v-2.4a.185.185 0 0 0-.187-.177h-.433a.179.179 0 0 0-.187.177v2.4a.185.185 0 0 0 .187.177Z"
                transform="translate(3.395 -145.149)"
                fill="url(#a)"
            />
            <g data-name="Group 107280">
                <path
                    data-name="Path 100597"
                    d="M14.82 158.646h.38a.369.369 0 0 0 .089-.217v-.492c0-.118-.039-.217-.089-.217h-.38v.916Z"
                    transform="translate(4.357 -145.165)"
                    fill="url(#b)"
                />
                <path
                    data-name="Path 100598"
                    d="M19.457 13.304v-.569a.175.175 0 0 1 .118-.167h-.4v.916h.4a.171.171 0 0 1-.128-.167Z"
                    opacity={0.3}
                />
            </g>
            <path
                data-name="Path 100599"
                d="M13.157 159.438a.179.179 0 0 1-.187-.177v-2.4a.185.185 0 0 1 .187-.177h.433a.179.179 0 0 1 .187.177v2.4a.179.179 0 0 1-.187.177Z"
                transform="translate(4.037 -145.149)"
                fill="url(#n)"
            />
            <g data-name="Group 107284">
                <g data-name="Group 107281">
                    <path
                        data-name="Path 100600"
                        d="M14.11 174.989V170.7a.38.38 0 0 1 .414-.325h.2l.01 4.334a.384.384 0 0 1-.424.325l-.207-.039Z"
                        transform="translate(4.1 -145.355)"
                        fill="url(#o)"
                    />
                </g>
                <path
                    data-name="Path 100601"
                    d="M13.32 175.391h.65c.079 0 .148-.128.148-.286v-.65c0-.158-.069-.286-.148-.286h-.65v1.211Z"
                    transform="translate(4.112 -145.412)"
                    fill="url(#p)"
                />
                <path
                    data-name="Path 100602"
                    d="M12.847 175.965c-.108 0-.2-.069-.2-.148v-2c0-.079.089-.148.2-.148h.433a.17.17 0 0 1 .187.148v2a.176.176 0 0 1-.187.148Z"
                    transform="translate(4.122 -145.405)"
                    fill="url(#q)"
                />
                <g data-name="Group 107283">
                    <path
                        data-name="Path 100603"
                        d="M17.713 171.867a.84.84 0 0 0 .483-.3c.187-.236-.177-.128-.512-.158s-1.674.01-1.812.01-.305 0-.2.1-.246-.118-.355-.217.709-.2.709-.2l2.837-.059-.394.778a1.564 1.564 0 0 1-.758.03Z"
                        transform="translate(4.081 -145.365)"
                        fill="url(#c)"
                    />
                    <path
                        data-name="Path 100604"
                        d="M18.04 170.519a1.075 1.075 0 0 0 .256-.039c.059-.02-.069-.158-.187-.227s-.325-.059-.916-.079-1.172.01-1.251.02a.3.3 0 0 0-.276.128 1.658 1.658 0 0 1-.207.236l.217-.689 2.61-.1.561.877-.808-.138Z"
                        transform="translate(4.079 -145.346)"
                        fill="url(#r)"
                    />
                    <path
                        data-name="Path 100605"
                        d="M19.08 171.4h.384a.369.369 0 0 0 .089-.217v-.492c0-.118-.039-.217-.089-.217h-.384v.916Z"
                        transform="translate(4.024 -145.357)"
                        fill="url(#s)"
                    />
                    <path
                        data-name="Path 100606"
                        d="M23.381 25.872v-.571a.175.175 0 0 1 .118-.167h-.4v.916h.4a.171.171 0 0 1-.128-.167Z"
                        opacity={0.3}
                    />
                    <path
                        data-name="Path 100607"
                        d="M18.778 172.04c.207-.286.394-.611.394-.611v-1.044s-.187-.325-.394-.611a.738.738 0 0 0-.66-.345h-2.462c-.315 0-.394.138-.6.453a4.4 4.4 0 0 0-.315.581v.9a6.526 6.526 0 0 0 .315.581c.207.315.286.453.6.453h2.462a.76.76 0 0 0 .66-.345Zm-3.211-1.527a4.189 4.189 0 0 1 .246-.394c.069-.079.138-.1.424-.1h1.694a.484.484 0 0 1 .414.227c.1.138.177.256.177.256h-2.955Zm.67 1.28c-.286 0-.364-.02-.424-.1a4.191 4.191 0 0 1-.246-.394h2.955l-.177.266a.484.484 0 0 1-.414.227Z"
                        transform="translate(4.09 -145.341)"
                        fill="url(#t)"
                    />
                    <g data-name="Group 107282">
                        <path
                            data-name="Path 100608"
                            d="M18.778 172.04c.207-.286.394-.611.394-.611v-1.044s-.187-.325-.394-.611a.738.738 0 0 0-.66-.345h-2.462c-.315 0-.394.138-.6.453a4.4 4.4 0 0 0-.315.581v.9a6.526 6.526 0 0 0 .315.581c.207.315.286.453.6.453h2.462a.76.76 0 0 0 .66-.345Zm-3.122.256c-.266 0-.315-.089-.512-.394l-.02-.03a5.915 5.915 0 0 1-.305-.552v-.857a4.151 4.151 0 0 1 .305-.552l.02-.03c.187-.3.246-.384.512-.384h2.462a.638.638 0 0 1 .581.315 5.968 5.968 0 0 1 .374.581v1a6.8 6.8 0 0 1-.374.581.66.66 0 0 1-.581.305h-2.462Z"
                            transform="translate(4.09 -145.341)"
                            opacity={0.3}
                            fill="url(#d)"
                        />
                        <path
                            data-name="Path 100609"
                            d="M18.434 170.206a.564.564 0 0 0-.492-.266h-1.694c-.286 0-.4.02-.492.128a2.97 2.97 0 0 0-.246.4l-.069.128H18.7l-.089-.138s-.079-.128-.177-.266Zm-2.856.315a4.188 4.188 0 0 1 .246-.394c.069-.079.138-.1.424-.1h1.694a.484.484 0 0 1 .414.227c.1.138.177.256.177.256h-2.955Z"
                            transform="translate(4.079 -145.349)"
                            opacity={0.3}
                            fill="url(#u)"
                        />
                        <path
                            data-name="Path 100610"
                            d="M18.434 171.634c.1-.138.177-.266.177-.266l.089-.138h-3.26l.069.128s.177.325.246.414c.089.108.207.128.492.128h1.694a.588.588 0 0 0 .493-.266Zm-2.186.177c-.286 0-.364-.02-.424-.1a4.189 4.189 0 0 1-.246-.394h2.955l-.177.266a.484.484 0 0 1-.414.227Z"
                            transform="translate(4.079 -145.368)"
                            opacity={0.3}
                            fill="url(#v)"
                        />
                    </g>
                    <path
                        data-name="Path 100611"
                        d="M15.65 169.62s2.029-.02 2.383-.02a.629.629 0 0 1 .66.315c.158.266.325.512.325.512a4.614 4.614 0 0 0-.483-.463c-.148-.108-.187-.246-1.034-.246s-1.852-.108-1.852-.108Z"
                        transform="translate(4.076 -145.344)"
                        opacity={0.5}
                        fill="url(#e)"
                    />
                    <path
                        data-name="Path 100612"
                        d="M18.268 172.228s-2.029.02-2.383.02a.629.629 0 0 1-.66-.315c-.167-.266-.325-.512-.325-.512a4.616 4.616 0 0 0 .483.463c.158.108.187.236 1.044.236s1.852.108 1.852.108Z"
                        transform="translate(4.088 -145.371)"
                        opacity={0.5}
                        fill="url(#w)"
                    />
                    <path
                        data-name="Path 100613"
                        d="M19 170.4v.847s.01-.325-.177-.355-3.644-.059-3.743-.059-.217.207-.246.286v-.749s.079.256.2.276 3.565.039 3.723.01.246-.266.246-.266Z"
                        transform="translate(4.089 -145.355)"
                        opacity={0.5}
                        fill="url(#f)"
                    />
                    <path
                        data-name="Path 100614"
                        d="M19.036 170.69v.837s0-.325-.177-.355-3.644-.049-3.743-.049-.217.207-.246.286v-.749s.079.256.2.276 3.565.039 3.723.01.246-.266.246-.266Z"
                        transform="translate(4.088 -145.36)"
                        opacity={0.5}
                        fill="url(#x)"
                    />
                </g>
            </g>
            <g data-name="Group 107288">
                <g data-name="Group 107285">
                    <path
                        data-name="Path 100615"
                        d="M9.36 174.689v5.122a.38.38 0 0 1-.414.325h-.2l-.01-5.171a.38.38 0 0 1 .414-.325l.207.039Z"
                        transform="translate(3.609 -145.419)"
                        fill="url(#y)"
                    />
                </g>
                <path
                    data-name="Path 100616"
                    d="M10.158 174.35h-.65c-.079 0-.148.128-.148.286v.65c0 .158.069.286.148.286h.65v-1.212Z"
                    transform="translate(3.6 -145.415)"
                    fill="url(#z)"
                />
                <path
                    data-name="Path 100617"
                    d="M10.62 173.75a.17.17 0 0 1 .187.148v2a.176.176 0 0 1-.187.148h-.433A.17.17 0 0 1 10 175.9v-2a.176.176 0 0 1 .187-.148Z"
                    transform="translate(3.59 -145.406)"
                    fill="url(#A)"
                />
                <g data-name="Group 107287">
                    <path
                        data-name="Path 100618"
                        d="M5.712 178.71a.84.84 0 0 0-.483.3c-.187.236.177.128.512.158s1.674-.01 1.812-.01.305 0 .2-.1.256.118.355.217-.709.2-.709.2l-2.837.059.394-.778a1.564 1.564 0 0 1 .758-.03Z"
                        transform="translate(3.672 -145.481)"
                        fill="url(#B)"
                    />
                    <path
                        data-name="Path 100619"
                        d="M5.387 180.068a2.074 2.074 0 0 0-.256.039c-.059.02.069.158.187.227a2.676 2.676 0 0 0 .906.079c.581.02 1.172-.01 1.251-.02a.3.3 0 0 0 .276-.128 2.2 2.2 0 0 1 .207-.246l-.217.689-2.61.1-.561-.877.808.138Z"
                        transform="translate(3.672 -145.5)"
                        fill="url(#C)"
                    />
                    <path
                        data-name="Path 100620"
                        d="M4.393 179.18h-.384a.391.391 0 0 0-.079.217v.492c0 .118.039.217.079.217h.384v-.916Z"
                        transform="translate(3.682 -145.488)"
                        fill="url(#D)"
                    />
                    <path
                        data-name="Path 100621"
                        d="M7.799 33.869v.571a.163.163 0 0 1-.118.172h.4v-.916h-.4a.171.171 0 0 1 .128.167Z"
                        opacity={0.3}
                    />
                    <path
                        data-name="Path 100622"
                        d="M4.634 178.515c-.207.286-.394.611-.394.611v1.044s.187.325.394.611a.749.749 0 0 0 .66.345h2.462c.325 0 .394-.138.6-.453a4.4 4.4 0 0 0 .315-.581v-.9a4.4 4.4 0 0 0-.315-.581c-.207-.325-.276-.453-.6-.453H5.294a.76.76 0 0 0-.66.345Zm3.211 1.527a4.191 4.191 0 0 1-.246.394c-.069.079-.138.1-.424.1H5.481a.484.484 0 0 1-.414-.227c-.1-.138-.177-.266-.177-.266Zm-.67-1.28c.286 0 .364.02.424.1a4.191 4.191 0 0 1 .246.394H4.89l.177-.266a.463.463 0 0 1 .414-.227Z"
                        transform="translate(3.677 -145.473)"
                        fill="url(#E)"
                    />
                    <g data-name="Group 107286">
                        <path
                            data-name="Path 100623"
                            d="M7.675 180.522a2.97 2.97 0 0 0 .246-.4l.069-.128H4.73l.089.138.177.266a.576.576 0 0 0 .492.266h1.694c.286 0 .4-.02.492-.128Zm-2.2.039a.484.484 0 0 1-.414-.227c-.1-.138-.177-.266-.177-.266h2.958a4.189 4.189 0 0 1-.246.394c-.069.079-.138.1-.424.1Z"
                            transform="translate(3.67 -145.5)"
                            opacity={0.3}
                            fill="url(#F)"
                        />
                        <path
                            data-name="Path 100624"
                            d="M7.675 178.818c-.1-.108-.217-.128-.492-.128H5.488a.588.588 0 0 0-.492.266c-.1.138-.177.266-.177.266l-.089.138h3.26l-.069-.128s-.177-.325-.246-.414Zm-2.787.453.177-.266a.463.463 0 0 1 .414-.227h1.694c.286 0 .364.02.424.1a4.19 4.19 0 0 1 .246.394Z"
                            transform="translate(3.67 -145.481)"
                            opacity={0.3}
                            fill="url(#G)"
                        />
                        <path
                            data-name="Path 100625"
                            d="M8.357 180.682a4.4 4.4 0 0 0 .315-.581v-.9a4.4 4.4 0 0 0-.315-.581c-.207-.325-.276-.453-.6-.453H5.294a.76.76 0 0 0-.66.345c-.207.286-.394.611-.394.611v1.044s.187.325.394.611a.749.749 0 0 0 .66.345h2.462c.325 0 .394-.138.6-.453Zm-3.063.364a.648.648 0 0 1-.581-.315 5.963 5.963 0 0 1-.374-.581v-1a6.8 6.8 0 0 1 .374-.581.669.669 0 0 1 .581-.315h2.462c.266 0 .325.089.512.394l.02.03a5.916 5.916 0 0 1 .305.552v.857a5.915 5.915 0 0 1-.305.552l-.02.03c-.187.3-.246.394-.512.394H5.294Z"
                            transform="translate(3.677 -145.473)"
                            opacity={0.3}
                            fill="url(#H)"
                        />
                    </g>
                    <path
                        data-name="Path 100626"
                        d="M7.778 180.968s-2.029.02-2.383.02a.629.629 0 0 1-.66-.315c-.167-.266-.325-.512-.325-.512a4.616 4.616 0 0 0 .483.463c.158.108.187.246 1.034.246s1.852.108 1.852.108Z"
                        transform="translate(3.675 -145.503)"
                        opacity={0.5}
                        fill="url(#I)"
                    />
                    <path
                        data-name="Path 100627"
                        d="M5.17 178.36s2.029-.02 2.383-.02a.629.629 0 0 1 .66.315c.167.266.325.512.325.512a4.614 4.614 0 0 0-.483-.463c-.158-.108-.187-.246-1.044-.246s-1.852-.108-1.852-.108Z"
                        transform="translate(3.663 -145.476)"
                        opacity={0.5}
                        fill="url(#J)"
                    />
                    <path
                        data-name="Path 100628"
                        d="M4.42 180.177v-.847s-.01.325.177.355 3.644.049 3.743.049.217-.207.246-.286v.749s-.079-.246-.2-.276-3.565-.039-3.723 0-.246.266-.246.266Z"
                        transform="translate(3.675 -145.491)"
                        opacity={0.5}
                        fill="url(#K)"
                    />
                    <path
                        data-name="Path 100629"
                        d="M4.39 179.9v-.847s-.01.325.177.345 3.644.059 3.743.059.217-.207.246-.286v.749s-.079-.256-.2-.276-3.565-.039-3.723 0-.236.266-.236.266Z"
                        transform="translate(3.675 -145.486)"
                        opacity={0.5}
                        fill="url(#L)"
                    />
                </g>
            </g>
            <g data-name="Group 107337">
                <path
                    data-name="Path 100630"
                    d="M11.965 209.965h.108a1.169 1.169 0 0 0 1.172-1.172v-21.372a1.169 1.169 0 0 0-1.172-1.172h-.108a.209.209 0 0 0-.158-.089.215.215 0 0 0-.167.089h-.1a1.177 1.177 0 0 0-1.182 1.172v21.373a1.177 1.177 0 0 0 1.182 1.172h.1a.169.169 0 0 0 .158.089.194.194 0 0 0 .167-.089Z"
                    transform="translate(3.745 -145.593)"
                    fill="url(#M)"
                />
                <path
                    data-name="Path 100631"
                    d="M11.66 209.965v-23.726a.215.215 0 0 1 .167-.089.187.187 0 0 1 .158.1v23.717a.194.194 0 0 1-.167.089.169.169 0 0 1-.158-.089Z"
                    transform="translate(3.725 -145.593)"
                    opacity={0.7}
                    fill="url(#N)"
                />
                <path
                    data-name="Path 100632"
                    d="M13.246 208.785v-21.373a1.169 1.169 0 0 0-1.172-1.172h-.542a1.177 1.177 0 0 0-1.182 1.172v21.373a1.177 1.177 0 0 0 1.182 1.172h.542a1.169 1.169 0 0 0 1.172-1.172Zm-1.605-.345c-.394 0-.709-.453-.709-1.014v-18.379c0-.561.315-1.014.709-1.014h.325a1.112 1.112 0 0 1 .985 1.014v18.379a1.121 1.121 0 0 1-.985 1.014Z"
                    transform="translate(3.745 -145.594)"
                    opacity={0.3}
                    fill="url(#O)"
                />
                <g data-name="Group 107289">
                    <path
                        data-name="Path 100633"
                        d="M11.86 208.7c-.364 0-.66-4.777-.66-10.657s.3-10.667.66-10.667.66 4.777.66 10.667-.296 10.657-.66 10.657Z"
                        transform="translate(3.732 -145.612)"
                        opacity={0.4}
                        fill="url(#i)"
                    />
                </g>
                <g data-name="Group 107311" opacity={0.6}>
                    <g data-name="Group 107290">
                        <path
                            data-name="Path 100634"
                            d="M12 187.735a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.602)"
                            fill="url(#g)"
                        />
                        <path
                            data-name="Path 100635"
                            d="M15.72 42.133v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.039-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107291">
                        <path
                            data-name="Path 100636"
                            d="M12 188.835a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.619)"
                            fill="url(#P)"
                        />
                        <path
                            data-name="Path 100637"
                            d="M15.72 43.216v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.43 2.43 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107292">
                        <path
                            data-name="Path 100638"
                            d="M12 189.925a2.573 2.573 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325Z"
                            transform="translate(3.72 -145.635)"
                            fill="url(#Q)"
                        />
                        <path
                            data-name="Path 100639"
                            d="M15.72 44.29v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.109 3.109 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107293">
                        <path
                            data-name="Path 100640"
                            d="M12 191.025a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.652)"
                            fill="url(#R)"
                        />
                        <path
                            data-name="Path 100641"
                            d="M15.72 45.373v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107294">
                        <path
                            data-name="Path 100642"
                            d="M12 192.115a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.668)"
                            fill="url(#S)"
                        />
                        <path
                            data-name="Path 100643"
                            d="M15.72 46.447v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.591.325-.591.039.03-.118.286-.345.561a3.111 3.111 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107295">
                        <path
                            data-name="Path 100644"
                            d="M12 193.215a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.685)"
                            fill="url(#T)"
                        />
                        <path
                            data-name="Path 100645"
                            d="M15.72 47.53v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.039-.118.286-.345.561a3.109 3.109 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107296">
                        <path
                            data-name="Path 100646"
                            d="M12 194.315a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.701)"
                            fill="url(#U)"
                        />
                        <path
                            data-name="Path 100647"
                            d="M15.72 48.614v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107297">
                        <path
                            data-name="Path 100648"
                            d="M12 195.405a2.572 2.572 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.718)"
                            fill="url(#V)"
                        />
                        <path
                            data-name="Path 100649"
                            d="M15.72 49.687v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.11 3.11 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107298">
                        <path
                            data-name="Path 100650"
                            d="M12 196.505a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.227-.246.325v.2Z"
                            transform="translate(3.72 -145.734)"
                            fill="url(#W)"
                        />
                        <path
                            data-name="Path 100651"
                            d="M15.72 50.771v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.395Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107299">
                        <path
                            data-name="Path 100652"
                            d="M12 197.6a2.572 2.572 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.227-.246.325v.2Z"
                            transform="translate(3.72 -145.751)"
                            fill="url(#X)"
                        />
                        <path
                            data-name="Path 100653"
                            d="M15.72 51.849v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.108 3.108 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107300">
                        <path
                            data-name="Path 100654"
                            d="M12 198.7a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.767)"
                            fill="url(#Y)"
                        />
                        <path
                            data-name="Path 100655"
                            d="M15.72 52.933v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.427 2.427 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107301">
                        <path
                            data-name="Path 100656"
                            d="M12 199.785a2.57 2.57 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.784)"
                            fill="url(#Z)"
                        />
                        <path
                            data-name="Path 100657"
                            d="M15.72 54.001v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.591.325-.591.039.039-.118.286-.345.561a3.108 3.108 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107302">
                        <path
                            data-name="Path 100658"
                            d="M12 200.885a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.227-.246.325v.2Z"
                            transform="translate(3.72 -145.8)"
                            fill="url(#aa)"
                        />
                        <path
                            data-name="Path 100659"
                            d="M15.72 55.085v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.11 3.11 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107303">
                        <path
                            data-name="Path 100660"
                            d="M12 201.985a2.57 2.57 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.817)"
                            fill="url(#ab)"
                        />
                        <path
                            data-name="Path 100661"
                            d="M15.72 56.168v.015a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.111 3.111 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107304">
                        <path
                            data-name="Path 100662"
                            d="M12 203.075a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.833)"
                            fill="url(#ac)"
                        />
                        <path
                            data-name="Path 100663"
                            d="M15.72 57.242v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.591.325-.591.039.03-.118.286-.345.561a3.111 3.111 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107305">
                        <path
                            data-name="Path 100664"
                            d="M12 204.175a2.57 2.57 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.227-.246.325Z"
                            transform="translate(3.72 -145.85)"
                            fill="url(#ad)"
                        />
                        <path
                            data-name="Path 100665"
                            d="M15.72 58.325v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.109 3.109 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107306">
                        <path
                            data-name="Path 100666"
                            d="M12 205.265a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.866)"
                            fill="url(#ae)"
                        />
                        <path
                            data-name="Path 100667"
                            d="M15.72 59.399v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.108 3.108 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107307">
                        <path
                            data-name="Path 100668"
                            d="M12 206.365a2.9 2.9 0 0 0 .394-.394c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.883)"
                            fill="url(#af)"
                        />
                        <path
                            data-name="Path 100669"
                            d="M15.72 60.482v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107308">
                        <path
                            data-name="Path 100670"
                            d="M12 207.455a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.899)"
                            fill="url(#ag)"
                        />
                        <path
                            data-name="Path 100671"
                            d="M15.72 61.556v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.111 3.111 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107309">
                        <path
                            data-name="Path 100672"
                            d="M12 208.565a2.572 2.572 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.167-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.916)"
                            fill="url(#ah)"
                        />
                        <path
                            data-name="Path 100673"
                            d="M15.72 62.649v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.108 3.108 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107310">
                        <path
                            data-name="Path 100674"
                            d="M12 209.665a2.571 2.571 0 0 0 .394-.4c.227-.276.384-.532.345-.561s-.256.158-.492.443c-.1.118-.177.236-.246.325v.2Z"
                            transform="translate(3.72 -145.933)"
                            fill="url(#ai)"
                        />
                        <path
                            data-name="Path 100675"
                            d="M15.72 63.732v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.109 3.109 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Group 107333" opacity={0.6}>
                    <g data-name="Group 107312">
                        <path
                            data-name="Path 100676"
                            d="M11.669 187.745a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.603)"
                            fill="url(#aj)"
                        />
                        <path
                            data-name="Path 100677"
                            d="M15.404 42.142v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.345.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107313">
                        <path
                            data-name="Path 100678"
                            d="M11.669 188.845a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a2.83 2.83 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.62)"
                            fill="url(#ak)"
                        />
                        <path
                            data-name="Path 100679"
                            d="M15.404 43.225v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107314">
                        <path
                            data-name="Path 100680"
                            d="M11.669 189.945a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.063 4.063 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.636)"
                            fill="url(#al)"
                        />
                        <path
                            data-name="Path 100681"
                            d="M15.404 44.309v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107315">
                        <path
                            data-name="Path 100682"
                            d="M11.669 191.035a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.06 4.06 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.653)"
                            fill="url(#am)"
                        />
                        <path
                            data-name="Path 100683"
                            d="M15.404 45.382v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107316">
                        <path
                            data-name="Path 100684"
                            d="M11.669 192.135a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.669)"
                            fill="url(#an)"
                        />
                        <path
                            data-name="Path 100685"
                            d="M15.404 46.466v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107317">
                        <path
                            data-name="Path 100686"
                            d="M11.669 193.225a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a2.83 2.83 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.686)"
                            fill="url(#ao)"
                        />
                        <path
                            data-name="Path 100687"
                            d="M15.404 47.539v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107318">
                        <path
                            data-name="Path 100688"
                            d="M11.669 194.325a2.571 2.571 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.055 4.055 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.702)"
                            fill="url(#ap)"
                        />
                        <path
                            data-name="Path 100689"
                            d="M15.404 48.623v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.345.561a3.108 3.108 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107319">
                        <path
                            data-name="Path 100690"
                            d="M11.669 195.415a2.572 2.572 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.719)"
                            fill="url(#aq)"
                        />
                        <path
                            data-name="Path 100691"
                            d="M15.404 49.696v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.591-.325-.591-.039.039.118.286.345.561a3.109 3.109 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107320">
                        <path
                            data-name="Path 100692"
                            d="M11.669 196.515a2.571 2.571 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.063 4.063 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.735)"
                            fill="url(#ar)"
                        />
                        <path
                            data-name="Path 100693"
                            d="M15.404 50.78v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.111 3.111 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107321">
                        <path
                            data-name="Path 100694"
                            d="M11.669 197.605a2.569 2.569 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.752)"
                            fill="url(#as)"
                        />
                        <path
                            data-name="Path 100695"
                            d="M15.404 51.853v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107322">
                        <path
                            data-name="Path 100696"
                            d="M11.669 198.705a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.768)"
                            fill="url(#at)"
                        />
                        <path
                            data-name="Path 100697"
                            d="M15.404 52.937v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.345.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107323">
                        <path
                            data-name="Path 100698"
                            d="M11.669 199.805a2.572 2.572 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.055 4.055 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.785)"
                            fill="url(#au)"
                        />
                        <path
                            data-name="Path 100699"
                            d="M15.404 54.02v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107324">
                        <path
                            data-name="Path 100700"
                            d="M11.669 200.9a2.572 2.572 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.801)"
                            fill="url(#av)"
                        />
                        <path
                            data-name="Path 100701"
                            d="M15.404 55.099v.02a2.273 2.273 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.345.561a3.112 3.112 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107325">
                        <path
                            data-name="Path 100702"
                            d="M11.669 202a2.571 2.571 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.818)"
                            fill="url(#aw)"
                        />
                        <path
                            data-name="Path 100703"
                            d="M15.404 56.182v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.108 3.108 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107326">
                        <path
                            data-name="Path 100704"
                            d="M11.669 203.085a2.57 2.57 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.06 4.06 0 0 1 .246.325Z"
                            transform="translate(3.736 -145.834)"
                            fill="url(#ax)"
                        />
                        <path
                            data-name="Path 100705"
                            d="M15.404 57.251v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.108 3.108 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107327">
                        <path
                            data-name="Path 100706"
                            d="M11.669 204.185a2.57 2.57 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.851)"
                            fill="url(#ay)"
                        />
                        <path
                            data-name="Path 100707"
                            d="M15.404 58.334v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107328">
                        <path
                            data-name="Path 100708"
                            d="M11.669 205.285a2.571 2.571 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.055 4.055 0 0 1 .246.325Z"
                            transform="translate(3.736 -145.867)"
                            fill="url(#az)"
                        />
                        <path
                            data-name="Path 100709"
                            d="M15.404 59.418v.02a2.271 2.271 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107329">
                        <path
                            data-name="Path 100710"
                            d="M11.669 206.375a2.9 2.9 0 0 1-.394-.394c-.227-.276-.384-.532-.345-.571s.266.167.492.443a4.058 4.058 0 0 1 .246.325Z"
                            transform="translate(3.736 -145.884)"
                            fill="url(#aA)"
                        />
                        <path
                            data-name="Path 100711"
                            d="M15.404 60.491v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.345.571a2.429 2.429 0 0 0 .394.39Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107330">
                        <path
                            data-name="Path 100712"
                            d="M11.669 207.475a2.571 2.571 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a4.06 4.06 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.9)"
                            fill="url(#aB)"
                        />
                        <path
                            data-name="Path 100713"
                            d="M15.404 61.575v.02a2.273 2.273 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.107 3.107 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107331">
                        <path
                            data-name="Path 100714"
                            d="M11.669 208.575a2.569 2.569 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325Z"
                            transform="translate(3.736 -145.917)"
                            fill="url(#aC)"
                        />
                        <path
                            data-name="Path 100715"
                            d="M15.404 62.658v.02a2.271 2.271 0 0 1-.414-.4c-.433-.483-.325-.581-.325-.581-.039.03.118.286.345.561a3.108 3.108 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107332">
                        <path
                            data-name="Path 100716"
                            d="M11.669 209.675a2.571 2.571 0 0 1-.394-.4c-.227-.276-.384-.532-.345-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.736 -145.934)"
                            fill="url(#aD)"
                        />
                        <path
                            data-name="Path 100717"
                            d="M15.404 63.741v.02a2.272 2.272 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.345.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <path
                    data-name="Path 100718"
                    d="M11.729 186.25s0 .374.02 1.024c0 .325.01.719.02 1.172v19.344c0 .453-.01.847-.02 1.172 0 .65-.02 1.014-.02 1.014s0-.374-.01-1.014c0-.325-.01-.719-.02-1.172 0-.227 0-.463-.01-.719v-17.906c0-.256 0-.492.01-.719 0-.453.01-.847.02-1.172.001-.65.01-1.024.01-1.024Z"
                    transform="translate(3.725 -145.595)"
                    opacity={0.5}
                    fill="url(#h)"
                />
                <path
                    data-name="Path 100719"
                    d="M11.679 186.25s0 .374.01 1.024c0 .325.01.719.02 1.172 0 .227 0 .463.01.719v17.906c0 .256 0 .492-.01.719 0 .453-.01.847-.02 1.172 0 .65-.01 1.014-.01 1.014s0-.374-.02-1.014c0-.325 0-.719-.02-1.172 0-.227 0-.463-.01-.719v-17.906c0-.256 0-.492.01-.719 0-.453.01-.847.02-1.172.001-.65.02-1.024.02-1.024Z"
                    transform="translate(3.726 -145.595)"
                    opacity={0.5}
                    fill="url(#aE)"
                />
                <path
                    data-name="Path 100720"
                    d="M11.929 186.27s0 .374.01 1.024c0 .325 0 .719.02 1.172 0 .227 0 .463.01.719v17.9c0 .256 0 .492-.01.719 0 .453-.01.847-.02 1.172 0 .65-.01 1.024-.01 1.024s0-.374-.02-1.024c0-.325 0-.719-.02-1.172 0-.227 0-.463-.01-.719v-17.9c0-.256 0-.492.01-.719 0-.453.01-.847.02-1.172.01-.65.02-1.024.02-1.024Z"
                    transform="translate(3.722 -145.595)"
                    opacity={0.3}
                    fill="url(#aF)"
                />
                <path
                    data-name="Path 100721"
                    d="M15.71 40.655s0 .374.02 1.024c0 .325 0 .719.02 1.172 0 .227 0 .463.01.719v17.906c0 .256 0 .492-.01.719 0 .453-.01.847-.02 1.172 0 .65-.02 1.014-.02 1.014s0-.374-.01-1.014c0-.325-.01-.719-.02-1.172 0-.227 0-.463-.01-.719V42.851c0-.453.01-.847.02-1.172 0-.65.01-1.024.01-1.024Z"
                    fill="#fff"
                    opacity={0.5}
                />
                <path
                    data-name="Path 100722"
                    d="M11.966 209.966h.108a1.169 1.169 0 0 0 1.172-1.172v-21.373a1.169 1.169 0 0 0-1.172-1.172h-.108a.073.073 0 0 0-.03-.03l-.02-.01a.031.031 0 0 1-.02-.01h-.059a.215.215 0 0 0-.167.089h-.1a1.177 1.177 0 0 0-1.182 1.172v21.373a1.177 1.177 0 0 0 1.182 1.172h.1a.169.169 0 0 0 .158.089.194.194 0 0 0 .167-.089Z"
                    transform="translate(3.744 -145.594)"
                    opacity={0.6}
                    fill="url(#aG)"
                />
                <g data-name="Group 107334">
                    <path
                        data-name="Path 100723"
                        d="M12.586 209.717c-.138 0-.256-5.023-.256-11.208s.118-11.208.256-11.208.256 5.023.256 11.208-.118 11.208-.256 11.208Z"
                        transform="translate(3.715 -145.61)"
                        opacity={0.5}
                        fill="url(#aH)"
                    />
                </g>
                <g data-name="Group 107335">
                    <path
                        data-name="Path 100724"
                        d="M12.588 209.717c-.059 0-.108-5.023-.108-11.208s.049-11.208.108-11.208.108 5.023.108 11.208-.049 11.208-.108 11.208Z"
                        transform="translate(3.713 -145.61)"
                        fill="url(#aI)"
                    />
                </g>
                <g data-name="Group 107336">
                    <path
                        data-name="Path 100725"
                        d="M12.678 209.727c-.059 0-.108-5.013-.108-11.208s.049-11.208.108-11.208.108 5.013.108 11.208-.049 11.208-.108 11.208Z"
                        transform="translate(3.711 -145.611)"
                        fill="url(#aJ)"
                    />
                </g>
            </g>
            <path
                data-name="Path 100726"
                d="M15.715 64.372h.108a1.169 1.169 0 0 0 1.172-1.172V41.827a1.169 1.169 0 0 0-1.172-1.172h-.108a.073.073 0 0 0-.03-.03l-.02-.01a.031.031 0 0 1-.02-.01h-.059a.215.215 0 0 0-.167.089h-.1a1.177 1.177 0 0 0-1.182 1.172v21.373a1.177 1.177 0 0 0 1.182 1.172h.1a.169.169 0 0 0 .158.089.194.194 0 0 0 .167-.089Z"
                fill="#283242"
            />
            <g data-name="Group 107380">
                <path
                    data-name="Path 100727"
                    d="M15.674 24.305h.108a1.175 1.175 0 0 0 1.172-1.172V1.761A1.175 1.175 0 0 0 15.782.589h-.108A.209.209 0 0 0 15.516.5a.215.215 0 0 0-.167.089h-.11a1.175 1.175 0 0 0-1.172 1.172v21.373a1.169 1.169 0 0 0 1.172 1.172h.108a.169.169 0 0 0 .158.089.194.194 0 0 0 .169-.09Z"
                    fill="#283242"
                />
                <g data-name="Group 107338">
                    <path
                        data-name="Path 100728"
                        d="M11.83 168.023c-.364 0-.66-4.777-.66-10.667s.3-10.667.66-10.667.66 4.777.66 10.667-.296 10.667-.66 10.667Z"
                        transform="translate(3.726 -144.998)"
                        opacity={0.4}
                        fill="url(#aK)"
                    />
                </g>
                <g data-name="Group 107358" opacity={0.6}>
                    <g data-name="Group 107339">
                        <path
                            data-name="Path 100729"
                            d="M11.97 148.155a2.571 2.571 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a2.827 2.827 0 0 0-.246.325Z"
                            transform="translate(3.714 -145.006)"
                            fill="url(#aL)"
                        />
                        <path
                            data-name="Path 100730"
                            d="M15.684 3.149v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.11 3.11 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107340">
                        <path
                            data-name="Path 100731"
                            d="M11.97 149.255a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.054 4.054 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.023)"
                            fill="url(#aM)"
                        />
                        <path
                            data-name="Path 100732"
                            d="M15.684 4.232v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107341">
                        <path
                            data-name="Path 100733"
                            d="M11.97 150.345a2.571 2.571 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.057 4.057 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.039)"
                            fill="url(#aN)"
                        />
                        <path
                            data-name="Path 100734"
                            d="M15.684 5.306v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.039-.118.286-.345.561a3.11 3.11 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107342">
                        <path
                            data-name="Path 100735"
                            d="M11.97 151.445a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a2.828 2.828 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.056)"
                            fill="url(#aO)"
                        />
                        <path
                            data-name="Path 100736"
                            d="M15.684 6.389v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107343">
                        <path
                            data-name="Path 100737"
                            d="M11.97 152.535a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.055 4.055 0 0 0-.246.325Z"
                            transform="translate(3.714 -145.072)"
                            fill="url(#aP)"
                        />
                        <path
                            data-name="Path 100738"
                            d="M15.684 7.463v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107344">
                        <path
                            data-name="Path 100739"
                            d="M11.97 153.635a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.063 4.063 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.089)"
                            fill="url(#aQ)"
                        />
                        <path
                            data-name="Path 100740"
                            d="M15.684 8.546v.02a2.27 2.27 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.43 2.43 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107345">
                        <path
                            data-name="Path 100741"
                            d="M11.97 154.725a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.054 4.054 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.105)"
                            fill="url(#aR)"
                        />
                        <path
                            data-name="Path 100742"
                            d="M15.684 9.62v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107346">
                        <path
                            data-name="Path 100743"
                            d="M11.97 155.825a2.571 2.571 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.06 4.06 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.122)"
                            fill="url(#aS)"
                        />
                        <path
                            data-name="Path 100744"
                            d="M15.684 10.703v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.039-.118.286-.345.561a3.11 3.11 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107347">
                        <path
                            data-name="Path 100745"
                            d="M11.97 156.925a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.057 4.057 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.138)"
                            fill="url(#aT)"
                        />
                        <path
                            data-name="Path 100746"
                            d="M15.684 11.787v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.591.325-.591.039.039-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107348">
                        <path
                            data-name="Path 100747"
                            d="M11.97 158.015a2.572 2.572 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.06 4.06 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.155)"
                            fill="url(#aU)"
                        />
                        <path
                            data-name="Path 100748"
                            d="M15.684 12.86v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.039-.118.286-.345.561a3.111 3.111 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107349">
                        <path
                            data-name="Path 100749"
                            d="M11.97 159.115a2.572 2.572 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a2.827 2.827 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.171)"
                            fill="url(#aV)"
                        />
                        <path
                            data-name="Path 100750"
                            d="M15.684 13.944v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.11 3.11 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107350">
                        <path
                            data-name="Path 100751"
                            d="M11.97 160.205a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.06 4.06 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.188)"
                            fill="url(#aW)"
                        />
                        <path
                            data-name="Path 100752"
                            d="M15.684 15.017v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.429 2.429 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107351">
                        <path
                            data-name="Path 100753"
                            d="M11.97 161.305a2.572 2.572 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.06 4.06 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.204)"
                            fill="url(#aX)"
                        />
                        <path
                            data-name="Path 100754"
                            d="M15.684 16.101v.02a2.273 2.273 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.109 3.109 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107352">
                        <path
                            data-name="Path 100755"
                            d="M11.97 162.4a2.571 2.571 0 0 0 .394-.4c.236-.276.384-.532.345-.561s-.266.167-.492.443a2.828 2.828 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.221)"
                            fill="url(#aY)"
                        />
                        <path
                            data-name="Path 100756"
                            d="M15.684 17.179v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a3.109 3.109 0 0 1-.394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107353">
                        <path
                            data-name="Path 100757"
                            d="M11.97 163.5a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.057 4.057 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.237)"
                            fill="url(#aZ)"
                        />
                        <path
                            data-name="Path 100758"
                            d="M15.684 18.263v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107354">
                        <path
                            data-name="Path 100759"
                            d="M11.97 164.6a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.055 4.055 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.254)"
                            fill="url(#ba)"
                        />
                        <path
                            data-name="Path 100760"
                            d="M15.684 19.346v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107355">
                        <path
                            data-name="Path 100761"
                            d="M11.97 165.685a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.057 4.057 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.27)"
                            fill="url(#bb)"
                        />
                        <path
                            data-name="Path 100762"
                            d="M15.684 20.415v.02a2.272 2.272 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.43 2.43 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107356">
                        <path
                            data-name="Path 100763"
                            d="M11.97 166.785a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.06 4.06 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.287)"
                            fill="url(#bc)"
                        />
                        <path
                            data-name="Path 100764"
                            d="M15.684 21.498v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107357">
                        <path
                            data-name="Path 100765"
                            d="M11.97 167.885a2.9 2.9 0 0 0 .394-.394c.236-.276.384-.532.345-.561s-.266.167-.492.443a4.057 4.057 0 0 0-.246.325v.2Z"
                            transform="translate(3.714 -145.304)"
                            fill="url(#bd)"
                        />
                        <path
                            data-name="Path 100766"
                            d="M15.684 22.581v.02a2.271 2.271 0 0 0 .414-.4c.433-.492.325-.581.325-.581.039.03-.118.286-.345.561a2.428 2.428 0 0 1-.394.394Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Group 107379" opacity={0.6}>
                    <g data-name="Group 107359">
                        <path
                            data-name="Path 100767"
                            d="M11.638 148.165a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.006)"
                            fill="url(#be)"
                        />
                        <path
                            data-name="Path 100768"
                            d="M15.369 3.159v.02a2.664 2.664 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107360">
                        <path
                            data-name="Path 100769"
                            d="M11.638 149.265a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.061 4.061 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.023)"
                            fill="url(#bf)"
                        />
                        <path
                            data-name="Path 100770"
                            d="M15.369 4.242v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107361">
                        <path
                            data-name="Path 100771"
                            d="M11.638 150.355a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a2.83 2.83 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.039)"
                            fill="url(#bg)"
                        />
                        <path
                            data-name="Path 100772"
                            d="M15.369 5.316v.02a2.666 2.666 0 0 1-.414-.4c-.433-.483-.325-.581-.325-.581-.039.03.118.286.355.561a3.108 3.108 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107362">
                        <path
                            data-name="Path 100773"
                            d="M11.638 151.455a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.056)"
                            fill="url(#bh)"
                        />
                        <path
                            data-name="Path 100774"
                            d="M15.369 6.399v.02a2.664 2.664 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.111 3.111 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107363">
                        <path
                            data-name="Path 100775"
                            d="M11.638 152.545a2.43 2.43 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.061 4.061 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.072)"
                            fill="url(#bi)"
                        />
                        <path
                            data-name="Path 100776"
                            d="M15.369 7.473v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.355.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107364">
                        <path
                            data-name="Path 100777"
                            d="M11.638 153.645a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.061 4.061 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.089)"
                            fill="url(#bj)"
                        />
                        <path
                            data-name="Path 100778"
                            d="M15.369 8.556v.02a2.666 2.666 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107365">
                        <path
                            data-name="Path 100779"
                            d="M11.638 154.745a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.158.492.443a4.055 4.055 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.105)"
                            fill="url(#bk)"
                        />
                        <path
                            data-name="Path 100780"
                            d="M15.369 9.64v.02a2.667 2.667 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.428 2.428 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107366">
                        <path
                            data-name="Path 100781"
                            d="M11.638 155.835a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.122)"
                            fill="url(#bl)"
                        />
                        <path
                            data-name="Path 100782"
                            d="M15.369 10.713v.02a2.667 2.667 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.427 2.427 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107367">
                        <path
                            data-name="Path 100783"
                            d="M11.638 156.935a2.43 2.43 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.138)"
                            fill="url(#bm)"
                        />
                        <path
                            data-name="Path 100784"
                            d="M15.369 11.797v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107368">
                        <path
                            data-name="Path 100785"
                            d="M11.638 158.025a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.063 4.063 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.155)"
                            fill="url(#bn)"
                        />
                        <path
                            data-name="Path 100786"
                            d="M15.369 12.87v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.355.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107369">
                        <path
                            data-name="Path 100787"
                            d="M11.638 159.125a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.066 4.066 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.171)"
                            fill="url(#bo)"
                        />
                        <path
                            data-name="Path 100788"
                            d="M15.369 13.954v.02a2.666 2.666 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.355.561a3.109 3.109 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107370">
                        <path
                            data-name="Path 100789"
                            d="M11.638 160.215a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.188)"
                            fill="url(#bp)"
                        />
                        <path
                            data-name="Path 100790"
                            d="M15.369 15.027v.02a2.667 2.667 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107371">
                        <path
                            data-name="Path 100791"
                            d="M11.638 161.315a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.061 4.061 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.204)"
                            fill="url(#bq)"
                        />
                        <path
                            data-name="Path 100792"
                            d="M15.369 16.111v.02a2.666 2.666 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107372">
                        <path
                            data-name="Path 100793"
                            d="M11.638 162.415a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a2.827 2.827 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.221)"
                            fill="url(#br)"
                        />
                        <path
                            data-name="Path 100794"
                            d="M15.369 17.194v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.111 3.111 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107373">
                        <path
                            data-name="Path 100795"
                            d="M11.638 163.505a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.237)"
                            fill="url(#bs)"
                        />
                        <path
                            data-name="Path 100796"
                            d="M15.369 18.268v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107374">
                        <path
                            data-name="Path 100797"
                            d="M11.638 164.605a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.061 4.061 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.254)"
                            fill="url(#bt)"
                        />
                        <path
                            data-name="Path 100798"
                            d="M15.369 19.351v.02a2.666 2.666 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107375">
                        <path
                            data-name="Path 100799"
                            d="M11.638 165.7a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.058 4.058 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.27)"
                            fill="url(#bu)"
                        />
                        <path
                            data-name="Path 100800"
                            d="M15.369 20.43v.02a2.666 2.666 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107376">
                        <path
                            data-name="Path 100801"
                            d="M11.638 166.8a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.287)"
                            fill="url(#bv)"
                        />
                        <path
                            data-name="Path 100802"
                            d="M15.369 21.513v.02a2.665 2.665 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.03.118.286.355.561a2.427 2.427 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107377">
                        <path
                            data-name="Path 100803"
                            d="M11.638 167.9a2.2 2.2 0 0 1-.394-.4c-.236-.276-.384-.532-.355-.561s.266.167.492.443a2.829 2.829 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.304)"
                            fill="url(#bw)"
                        />
                        <path
                            data-name="Path 100804"
                            d="M15.369 22.596v.02a2.664 2.664 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.355.561a3.11 3.11 0 0 0 .394.4Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 107378">
                        <path
                            data-name="Path 100805"
                            d="M11.638 169a2.429 2.429 0 0 1-.394-.394c-.236-.276-.384-.532-.355-.561s.266.167.492.443a4.055 4.055 0 0 1 .246.325v.2Z"
                            transform="translate(3.731 -145.32)"
                            fill="url(#bx)"
                        />
                        <path
                            data-name="Path 100806"
                            d="M15.369 23.68v.02a2.664 2.664 0 0 1-.414-.4c-.433-.492-.325-.581-.325-.581-.039.039.118.286.355.561a2.429 2.429 0 0 0 .394.394Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
            <path
                data-name="Path 100807"
                d="M15.215 23.222a1.266 1.266 0 0 1-1.4-1.064V2.687c0-.591.62-1.064 1.4-1.064h.62a1.266 1.266 0 0 1 1.4 1.064v19.472a1.266 1.266 0 0 1-1.4 1.064Z"
                fill="#656364"
            />
            <path
                data-name="Path 100808"
                d="M11.2 178.227a.5.5 0 0 1-.552-.433v-5.85a.5.5 0 0 1 .552-.433h1.271a.5.5 0 0 1 .552.433v5.85a.5.5 0 0 1-.552.433Z"
                transform="translate(3.748 -145.373)"
                fill="url(#by)"
            />
            <g data-name="Group 107381">
                <path
                    data-name="Path 100809"
                    d="M15.014 194.89c0 .059-.236.118-.532.118-.3 0-.542-.049-.542-.118v-3.8c0-.059.246-.108.542-.108.3 0 .532.049.532.108Z"
                    transform="translate(4.265 -145.666)"
                    fill="url(#bz)"
                />
                <path
                    data-name="Path 100810"
                    d="M14.472 190.98c-.3 0-.542.049-.542.108v3.8c0 .059.246.118.542.118s.532-.049.532-.118v-3.8c-.004-.059-.237-.108-.532-.108Zm0 3.536c-.522 0-.424-.581-.424-1.31s-.108-1.625.424-1.625.424.9.424 1.625.098 1.31-.424 1.31Z"
                    transform="translate(4.265 -145.666)"
                    opacity={0.7}
                    fill="url(#j)"
                />
                <path
                    data-name="Path 100811"
                    d="M14.723 193.3c0 .63 0 1.142-.236 1.142s-.246-.512-.246-1.142-.069-1.458.246-1.458.236.825.236 1.458Z"
                    transform="translate(4.26 -145.679)"
                    opacity={0.9}
                    fill="url(#bA)"
                />
            </g>
            <g data-name="Group 107382">
                <path
                    data-name="Path 100812"
                    d="M9.924 194.89c0 .059-.236.118-.542.118-.3 0-.532-.049-.532-.118v-3.8c0-.059.236-.108.532-.108.3 0 .542.049.542.108Z"
                    transform="translate(3.243 -145.666)"
                    fill="url(#bz)"
                />
                <path
                    data-name="Path 100813"
                    d="M9.382 190.98c-.3 0-.532.049-.532.108v3.8c0 .059.236.118.532.118s.542-.049.542-.118v-3.8c0-.059-.237-.108-.542-.108Zm0 3.536c-.522 0-.424-.581-.424-1.31s-.108-1.625.424-1.625.424.9.424 1.625.094 1.31-.424 1.31Z"
                    transform="translate(3.243 -145.666)"
                    opacity={0.7}
                    fill="url(#bB)"
                />
                <path
                    data-name="Path 100814"
                    d="M9.632 193.3c0 .63 0 1.142-.246 1.142s-.236-.512-.236-1.142-.069-1.458.236-1.458.246.825.246 1.458Z"
                    transform="translate(3.239 -145.679)"
                    opacity={0.9}
                    fill="url(#bC)"
                />
            </g>
            <g data-name="Group 107383">
                <path
                    data-name="Path 100815"
                    d="M12.294 177.813a.53.53 0 0 1-.532.532.538.538 0 0 1-.542-.532v-16.871a.547.547 0 0 1 .542-.542.538.538 0 0 1 .532.542Z"
                    transform="translate(3.718 -145.205)"
                    fill="url(#bD)"
                />
                <path
                    data-name="Path 100816"
                    d="M11.752 160.4a.547.547 0 0 0-.542.542v16.872a.538.538 0 0 0 .542.532.53.53 0 0 0 .532-.532v-16.872a.538.538 0 0 0-.532-.542Zm0 16.891c-.522 0-.424-2.8-.424-6.244s-.108-7.751.424-7.751.424 4.3.424 7.751.098 6.244-.424 6.244Z"
                    transform="translate(3.718 -145.205)"
                    opacity={0.7}
                    fill="url(#bE)"
                />
            </g>
            <g data-name="Group 107384">
                <path
                    data-name="Path 100817"
                    d="M15.503 45.619h.768c.305 0 .65 0 1.044-.02 2.541-.039 3.024-1.054 2.974-2.147a4.733 4.733 0 0 0-1.4-3.2c-1.054-1.182-1.664-4.816-1.93-5.87s-.483-1.881-1.32-1.881h-.305c-.837 0-1.054.827-1.31 1.881s-.877 4.688-1.93 5.87a4.653 4.653 0 0 0-1.4 3.2c-.039 1.093.433 2.1 2.974 2.147.394 0 .749.01 1.044.02h.775Z"
                    fill="#484a4f"
                />
                <path
                    data-name="Path 100818"
                    d="M16.272 45.609c.305 0 .65 0 1.044-.02 2.541-.039 3.024-1.054 2.974-2.147a4.277 4.277 0 0 0-.6-2.127 2.142 2.142 0 0 1 .079 1.645 2.34 2.34 0 0 1-2.364 1.4c-1.221 0-4.245-.128-4.816-.217a2.224 2.224 0 0 1-1.261-2.837 4.253 4.253 0 0 0-.611 2.137c-.039 1.093.433 2.1 2.974 2.147.394 0 .749.01 1.044.02h1.536Z"
                    fill="#484a4f"
                />
                <path
                    data-name="Path 100819"
                    d="M16.272 45.609c.305 0 .65 0 1.044-.02a4.039 4.039 0 0 0 2.315-.561 4.932 4.932 0 0 1-1.921.325c-.394 0-.749.01-1.054.01h-1.537c-.305 0-.65 0-1.044-.01-2.541-.039-3.024-1.014-2.974-2.078a4.469 4.469 0 0 1 1.4-3.093c1.054-1.143 1.664-4.669 1.93-5.683s.483-1.822 1.32-1.822h.305a.576.576 0 0 1 .187.02.933.933 0 0 0-.581-.187h-.305c-.837 0-1.054.827-1.31 1.881s-.877 4.688-1.93 5.87a4.653 4.653 0 0 0-1.4 3.2c-.039 1.093.433 2.1 2.974 2.147.394 0 .749.01 1.044.02h1.536Z"
                    fill="#fff"
                />
            </g>
            <path
                data-name="Path 100820"
                d="M12.439 161.009c0 .63.108 1.182-.709 1.182s-.591-1.211-.62-1.251 1.339.069 1.339.069Z"
                transform="translate(3.725 -145.213)"
                fill="url(#bF)"
            />
            <path
                data-name="Path 100821"
                d="M12.439 161.009c0 .63.108 1.182-.709 1.182s-.591-1.211-.62-1.251 1.339.069 1.339.069Z"
                transform="translate(3.725 -145.213)"
                fill="url(#m)"
            />
            <g data-name="Group 107385">
                <path
                    data-name="Path 100822"
                    d="M13.748 160.882a1.743 1.743 0 0 1-1.113 1.842c-.236.069-.325-.128-.325-.483v2.275a3.528 3.528 0 0 0 2.078-1.576c.7-1.251.542-3.349.5-4.639-.039-1.527-.788-1.162-1.2-1.2 0 0 .069 2.955.069 3.782Z"
                    transform="translate(4.137 -145.155)"
                    fill="url(#bG)"
                />
                <path
                    data-name="Path 100823"
                    d="M14.724 158.512s.03 1.724.01 2.324a3.74 3.74 0 0 1-.542 2.1 2.22 2.22 0 0 1-1.064.729c-.217.059-.128-.089.128-.325a3.646 3.646 0 0 0 1.172-2.039c.059-.906.079-2 .1-2.452s.089-1.477.187-.335Z"
                    transform="translate(4.126 -145.169)"
                    opacity={0.9}
                    fill="url(#k)"
                />
                <path
                    data-name="Path 100824"
                    d="M12.3 162.241v2.275a3.528 3.528 0 0 0 2.078-1.576c.7-1.251.542-3.349.5-4.639-.039-1.527-.788-1.162-1.2-1.2 0 0 .069 2.955.069 3.782a1.743 1.743 0 0 1-1.113 1.842c-.236.069-.325-.128-.325-.483Zm1.251.118a2.38 2.38 0 0 0 .552-1.743v-2.472a.335.335 0 0 1 .67 0c0 1.32.305 3.674-.552 4.9a1.889 1.889 0 0 1-1.093.7c-.305.1-.542-.286-.2-.67.256-.286.463-.512.63-.719Z"
                    transform="translate(4.137 -145.155)"
                    opacity={0.7}
                    fill="url(#bH)"
                />
            </g>
            <g data-name="Group 107386">
                <path
                    data-name="Path 100825"
                    d="M8.7 158.646h-.381a.369.369 0 0 1-.089-.217v-.492c0-.118.039-.217.089-.217H8.7v.916Z"
                    transform="translate(3.072 -145.165)"
                    fill="url(#bI)"
                />
                <path
                    data-name="Path 100826"
                    d="M11.499 13.304v-.569a.175.175 0 0 0-.118-.167h.4v.916h-.4a.171.171 0 0 0 .128-.167Z"
                    opacity={0.3}
                />
            </g>
            <g data-name="Group 107387">
                <path
                    data-name="Path 100827"
                    d="M9.742 160.882a1.743 1.743 0 0 0 1.113 1.842c.246.069.325-.128.325-.483v2.275a3.528 3.528 0 0 1-2.08-1.576c-.7-1.251-.542-3.349-.5-4.639.039-1.527.788-1.162 1.2-1.2 0 0-.069 2.955-.069 3.782Z"
                    transform="translate(3.325 -145.155)"
                    fill="url(#bJ)"
                />
                <path
                    data-name="Path 100828"
                    d="M8.78 158.512s-.03 1.724-.02 2.324a3.821 3.821 0 0 0 .542 2.1 2.22 2.22 0 0 0 1.064.729c.217.059.128-.089-.128-.325a3.646 3.646 0 0 1-1.173-2.04c-.059-.906-.069-2-.1-2.452s-.087-1.478-.185-.336Z"
                    transform="translate(3.322 -145.169)"
                    opacity={0.9}
                    fill="url(#bK)"
                />
                <path
                    data-name="Path 100829"
                    d="M10.855 162.724a1.735 1.735 0 0 1-1.113-1.842c0-.837.069-3.782.069-3.782-.414.039-1.162-.325-1.2 1.2-.039 1.29-.2 3.4.5 4.639a3.528 3.528 0 0 0 2.078 1.576v-2.275c0 .355-.089.552-.325.483Zm-.286.364c.355.394.118.778-.2.67a1.889 1.889 0 0 1-1.093-.7c-.857-1.231-.552-3.575-.552-4.9a.335.335 0 0 1 .67 0c0 .827-.01 1.645 0 2.472a2.368 2.368 0 0 0 .552 1.743c.167.207.374.433.63.719Z"
                    transform="translate(3.325 -145.155)"
                    opacity={0.7}
                    fill="url(#bL)"
                />
            </g>
            <g data-name="Group 107395">
                <g data-name="Group 107390">
                    <path
                        data-name="Path 100830"
                        d="M11.74 159.75c.532-.01 5.2-.089 6.944.611a5.855 5.855 0 0 1 2.236 2.334l-.916.5a4.89 4.89 0 0 0-1.7-1.871c-1.093-.443-4.156-.581-6.53-.532l-.02-1.054ZM20 163.2Z"
                        transform="translate(6.143 -145.196)"
                        fill="url(#l)"
                    />
                    <g data-name="Group 107388" opacity={0.2}>
                        <path
                            data-name="Path 100831"
                            d="M24.827 15.156c-1.743-.7-6.422-.621-6.944-.611l.02 1.054c2.374-.049 5.437.089 6.53.532a4.89 4.89 0 0 1 1.7 1.871l.916-.5a5.782 5.782 0 0 0-2.236-2.334Zm-.315.67a20.54 20.54 0 0 0-4.186-.512c-.946-.01-1.123-.158-1.093-.335s.266-.276 1.133-.246a18.144 18.144 0 0 1 4.274.512c1.123.483 1.31.995 1.2 1.143s-.6-.276-1.33-.552Z"
                        />
                    </g>
                    <g data-name="Group 107389" opacity={0.4}>
                        <path
                            data-name="Path 100832"
                            d="M27.062 17.49a5.782 5.782 0 0 0-2.236-2.334c-1.743-.7-6.422-.621-6.944-.611l.02 1.054c2.374-.049 5.437.089 6.53.532a4.89 4.89 0 0 1 1.7 1.871l.916-.5Zm-2.659-1.566a23.162 23.162 0 0 0-4.422-.424c-.946-.01-.8-.345-.8-.522-.02-.453.335-.364 1.211-.335a17.85 17.85 0 0 1 4.245.512c1.123.483 1.605 1.064 1.2 1.31-.65.394-.7-.266-1.428-.542Zm1.556 1.143c.118-.1.227-.187.355-.276a.109.109 0 0 1 .079 0 .29.29 0 0 1 .1.118.182.182 0 0 1-.069.138c-.118.059-.256.1-.384.158-.069.03-.128-.079-.069-.128Z"
                            opacity={0.4}
                        />
                    </g>
                    <path
                        data-name="Path 100833"
                        d="M27.022 17.155s1.251 2.974 1.418 3.506.177.5-.02.66-.808.522-1 .2-1.615-3.7-1.615-3.7Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 100834"
                        d="M25.654 17.893a.187.187 0 0 1-.236-.118.185.185 0 0 1 .089-.246l1.487-.591a.187.187 0 0 1 .236.118.185.185 0 0 1-.089.246Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 100835"
                        d="M20.309 161.732a.207.207 0 0 0 .335.039c.207-.177.492-.305.906.463s.276 1.664.808 2.571.492.67.63.995.6-.187.512-.453-.079.02-.236-.059a1.777 1.777 0 0 1-.67-.965c-.207-.571-.276-2.1-.965-2.709s-1.192-.374-1.389-.177c-.059.059.079.3.079.3Z"
                        transform="translate(6.015 -145.218)"
                        fill="url(#bM)"
                    />
                    <path
                        data-name="Path 100836"
                        d="M23.5 165.445a.557.557 0 0 1-.266.276.073.073 0 0 1-.1-.1v-.03c-.433-.207-.66-.847-.827-1.251-.266-.66-.276-1.359-.512-2.009a1.4 1.4 0 0 0-.325-.561c-.207-.207-.364-.177-.62-.207-.108-.02-.217-.049-.217-.177 0-.108.138-.138.3-.148a.754.754 0 0 0-.689.207c-.059.059.079.3.079.3a.207.207 0 0 0 .335.039c.207-.177.492-.305.906.463s.276 1.665.808 2.571.492.67.63.995c.118.286.5-.089.522-.355Z"
                        transform="translate(6.015 -145.218)"
                        fill="url(#bN)"
                    />
                    <path
                        data-name="Path 100837"
                        d="M19.476 162.366a.158.158 0 0 1-.2-.089.16.16 0 0 1 .02-.217l.975-.571c.059-.039.148 0 .2.1a.16.16 0 0 1-.02.217l-.975.571Z"
                        transform="translate(6.03 -145.222)"
                        fill="url(#bO)"
                    />
                </g>
                <g data-name="Group 107393">
                    <path
                        data-name="Path 100838"
                        d="M11.659 160.794c-2.383-.049-5.437.089-6.54.532a4.89 4.89 0 0 0-1.7 1.871l-.926-.5a5.782 5.782 0 0 1 2.236-2.334c1.743-.7 6.422-.621 6.944-.611l-.02 1.054Zm-8.244 2.4Z"
                        transform="translate(1.426 -145.195)"
                        fill="url(#bP)"
                    />
                    <g data-name="Group 107391" opacity={0.2}>
                        <path
                            data-name="Path 100839"
                            d="m3.916 17.491.926.5a4.89 4.89 0 0 1 1.7-1.871c1.093-.443 4.156-.581 6.54-.532l.02-1.054c-.522-.01-5.2-.089-6.944.611a5.782 5.782 0 0 0-2.232 2.336Zm1.221-1.113c-.118-.148.069-.66 1.2-1.143a18.689 18.689 0 0 1 4.274-.512c.877-.03 1.113.069 1.142.246s-.148.325-1.093.335a20.541 20.541 0 0 0-4.186.512c-.729.276-1.211.7-1.33.552Z"
                        />
                    </g>
                    <g data-name="Group 107392" opacity={0.4}>
                        <path
                            data-name="Path 100840"
                            d="M4.842 18.006a4.89 4.89 0 0 1 1.7-1.871c1.093-.443 4.156-.581 6.54-.532l.02-1.054c-.522-.01-5.2-.089-6.944.611a5.782 5.782 0 0 0-2.232 2.331l.926.5Zm.315-1.527c-.414-.246.069-.827 1.192-1.31a18.387 18.387 0 0 1 4.245-.512c.877-.03 1.221-.118 1.211.335 0 .177.148.512-.808.522a22.394 22.394 0 0 0-4.422.424c-.729.276-.788.936-1.428.542Zm-.2.729a6.907 6.907 0 0 1-.384-.158c-.01 0-.02-.02-.03-.03a.073.073 0 0 1-.039-.108.571.571 0 0 1 .1-.118.109.109 0 0 1 .079 0c.118.089.236.177.355.276.059.049 0 .158-.069.128Z"
                            opacity={0.4}
                        />
                    </g>
                    <path
                        data-name="Path 100841"
                        d="M3.966 17.155s-1.251 2.974-1.428 3.506-.177.5.02.66.808.522 1 .2 1.625-3.7 1.625-3.7l-1.221-.66Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 100842"
                        d="M5.335 17.894a.187.187 0 0 0 .236-.118.185.185 0 0 0-.089-.246l-1.487-.591a.187.187 0 0 0-.236.118.185.185 0 0 0 .089.246l1.487.591Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 100843"
                        d="M3.191 161.732a.207.207 0 0 1-.335.039c-.207-.177-.492-.305-.906.463s-.276 1.664-.808 2.571-.492.67-.63.995-.6-.187-.512-.453.079.02.236-.059a1.777 1.777 0 0 0 .67-.965c.207-.571.276-2.1.965-2.709s1.192-.374 1.389-.177c.059.059-.079.3-.079.3Z"
                        transform="translate(1.464 -145.217)"
                        fill="url(#bQ)"
                    />
                    <path
                        data-name="Path 100844"
                        d="M0 165.445a.538.538 0 0 0 .276.276.073.073 0 0 0 .1-.1v-.03c.424-.207.66-.847.827-1.251.266-.66.276-1.359.512-2.009a1.4 1.4 0 0 1 .325-.561c.207-.207.374-.177.62-.207.108-.02.207-.049.217-.177 0-.108-.138-.138-.3-.148a.764.764 0 0 1 .689.207c.059.059-.079.3-.079.3a.207.207 0 0 1-.335.039c-.207-.177-.492-.305-.906.463s-.276 1.665-.808 2.571-.492.67-.63.995c-.118.286-.5-.089-.522-.355Z"
                        transform="translate(1.464 -145.217)"
                        fill="url(#bR)"
                    />
                    <path
                        data-name="Path 100845"
                        d="M4.064 162.366a.158.158 0 0 0 .2-.089.16.16 0 0 0-.02-.217l-.975-.571c-.059-.039-.148 0-.2.1a.16.16 0 0 0 .02.217l.975.571Z"
                        transform="translate(1.418 -145.221)"
                        fill="url(#bS)"
                    />
                    <path
                        data-name="Path 100846"
                        d="M9.76 160.8v-.384c.02-.227.049-.443.049-.66.995-.02 1.773 0 1.97 0l-.02 1.054c-.63-.01-1.32-.01-2 0Z"
                        transform="translate(1.317 -145.195)"
                        fill="url(#bT)"
                    />
                </g>
                <g data-name="Group 107394" transform="translate(14.351 14.151)">
                    <rect
                        data-name="Rectangle 55719"
                        width={2.275}
                        height={1.753}
                        rx={0.877}
                        fill="#302c28"
                    />
                </g>
            </g>
        </g>
    </svg>
)