import { Container, Typography, Stack, Box, Grid, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TitleBar, DialogDrawer } from "../../components";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";
import { GET_ANNOUNCEMENT_BY_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, Bold, generateImageUrl, img_size, NetWorkCallMethods, SemiBold } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "@mui/styles";
import { withNamespaces } from "react-i18next";
import FileViewer from "react-file-viewer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  posted: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
  },
  title: {
    marginBottom: "8px",
  },
  dis: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
    cursor: "pointer",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  content: {
    padding: "16px",
    backgroundColor: "white",
    borderRadius: "16px",
    marginTop: "10px",
    boxShadow: "0px 0px 4px #00000029",
  },
  health: {
    fontSize:"0.75rem",
    color: "#98A0AC",
    fontFamily: Bold,
    background: "#E4E8EE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  reactQuil: {
    "& .ql-editor": {
      padding: "0px 0px 10px 0px",
    },
  },
  heading: {
    "& .ql-editor": {
      fontFamily: Bold,
      fontSize: "1.125rem",
    },
  },
  imageCard: {
    height: "75px",
    width: "100%",
    display: 'flex'
  },
}));

const AnnouncementDetails = (props) => {
  const { t = () => false } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const match = useMediaQuery('(min-width:602px)')
  const backdrop = React.useContext(BackdropContext);
  const size = useWindowDimensions();
  const searchURL = useLocation().search;
  const Id = new URLSearchParams(searchURL).get("Id");
  const [datas, setDatas] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [pdfViewer, setPdfViewer] = React.useState({
    bool: false,
    data: "",
  });

  const openImageViewer = (url) => {
    setPdfViewer({
      bool: true,
      data: url,
    });
  };

  const closeImageViewer = () => {
    setPdfViewer({
      bool: false,
      data: "",
    });
  };

  const getNotification = () => {
    const params = {
      query: GET_ANNOUNCEMENT_BY_ID,
      variables: {
        id: Id,
        type: "Announcement",
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setDatas(response.data.data.notifications[0]);

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  React.useEffect(() => {
    getNotification();
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    // eslint-disable-next-line
  }, []);
  const modules = {
    toolbar: false,
  };
  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ height: size?.height }}
    >
      <TitleBar text={t("Announcement")} goBack={() => history.goBack()} />
      <div
        style={{
          padding: "16px",
        }}
      >
        <div
          className={classes.content}
          style={{ height: size.height - 120, overflow: "auto" }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            marginBottom={"10px"}
          >
            <Typography>
              <span className={classes.health}>{datas?.category}</span>
            </Typography>
            <Typography className={classes.posted}>
              {t("Postedat")} {moment(new Date(datas?.created_at)).fromNow()}
            </Typography>
          </Stack>
          {datas?.title && (
                  <Typography className={classes.title}>
                    {datas?.title}
                  </Typography>
                )}

          {datas?.description?.map((x, index) => {
            return (
              <>
                {x?.images !==""?(
                  <img
                    src={generateImageUrl(x?.images, img_size.small_rectangle) ?? ""}
                    alt=""
                    style={{
                      width: "100%",
                      height: 185,
                      borderRadius: theme.palette.borderRadius,
                      marginBottom: "10px",
                    }}
                  />
                ):<img src={"/images/announcementPlaceholder.png"} alt="placeholder" width="100%" height={match ? "285px" : "auto"} />}
              
                {x?.Description && (
                  // <Typography className={classes.dis}>
                  <ReactQuill
                    readOnly
                    theme="bubble"
                    value={x?.Description ?? ""}
                    modules={modules}
                    className={classes.reactQuil}
                  />
                  // </Typography>
                )}
                {x?.attachment && (
                  <Box>
                    <Box height={'16px'} />
                    <Grid container spacing={1}>
                      {x?.attachment?.map((val) => {
                        return (
                          <Grid item xs={4} sm={4} md={3} lg={2}>
                            <Box
                              className={classes.imageCard}
                              onClick={() => openImageViewer(val)}
                            >
                              <FileViewer fileType={"pdf"} filePath={val?.url} />
                            </Box>
                            <DialogDrawer
                              height={"400px"}
                              open={pdfViewer?.bool ?? false}
                              header={pdfViewer?.data?.name ?? ""}
                              component={
                                pdfViewer?.data?.url?.length > 0 ? (
                                  <FileViewer
                                    fileType={"pdf"}
                                    filePath={pdfViewer?.data?.url ?? ""}
                                  />
                                ) : (
                                  t("No Date Found")
                                )
                              }
                              onClose={() => closeImageViewer()}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )}
              </>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default withNamespaces("announcement")(AnnouncementDetails);
