import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  MobileNumberInputComponent,
  SelectBox,
  SlideButton,
  TextBox,
} from "../../components";
import { config } from "../../config";
import {
  // CHECK_IN_OUT,
  // EDIT_AND_UPDATE_DETAILS,
  EDIT_AND_UPDATE_VEHICLE_DETAILS,
  PARKING_SLOT_IS_ACTIVE,
  VECHICLE_CHECK_IN_OUT,
} from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { AlertProps, allowed_file_size, Bold, enumSelect, enumTypes, ExtraBold, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods, SemiBold, singleFileUpload } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  screen: {
    borderRadius: "16px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profilePicturePadding: {
    justifyContent: "center",
    padding: "24px 16px 24px 16px",
  },
  iconButtonStyle: {
    height: "44px",

    padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    color: "#5078E1",
    background: "#5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "0px 2px 6px #00000021",
  },
  saveNUpdateButtonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  saveNUpdateButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  addressBoxStyle: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
}));


const ViewDetails = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const size = useWindowDimensions();
  // const [profileImage, setProfileImage] = React.useState("");
  const [url, setUrl] = React.useState(props?.data?.guard_image ?? null);
  const alert = React.useContext(AlertContext);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const [enumValue, setEnum] = React.useState({
    options: [],
  })
  React.useEffect(() => {
    getEnum()
    //eslint-disable-next-line
  }, [])

  const [initialData, setInitialData] = React.useState({
    Name: (props?.data?.name || props?.data?.domestic_helpers?.name),
    profileImageID: "",
    profileImage: "",
    MobileNo: {
      mobile: (props?.data?.mobile_no || props?.data?.domestic_helpers?.mobile_no),
      mobile_code: (props?.data?.mobile_country_code || props?.data?.domestic_helpers?.mobile_country_code),
    },
    EmailId: (props?.data?.email_id || props?.data?.domestic_helpers?.email_id),
    IdNo: (props?.data?.identification_no || props?.data?.domestic_helpers?.identification_no),
    IdProof: [{ label: (props?.data?.id_type || props?.data?.domestic_helpers?.id_type) }],
    vechicle_number: (props?.data?.vechicle_number),
    vechicle_type: [{ label: props?.data?.vechicle_type }],
    error: {
      Name: "",
      MobileNo: "",
      IdNo: "",
      IdProof: "",
      vechicle_number: "",
      vechicle_type: "",
    },
  });



  const updateState = (key, value) => {
    let error = initialData.error;
    error[key] = "";
    setInitialData({ ...initialData, [key]: value, error });
  };

  // const isIamValid = () => {
  //   let isValid = true;
  //   let error = initialData.error;
  //   //Checking Name
  //   if (initialData.Name.length === 0) {
  //     isValid = false;
  //     error.Name = "Name is Required";
  //   }

  //   //Checking MobileNo
  //   if (initialData.MobileNo.length === 0) {
  //     isValid = false;
  //     error.MobileNo = "MobileNo is Required";
  //   }
  //   //Checking EmailId
  //   if (initialData.EmailId.length === 0) {
  //     isValid = false;
  //     error.EmailId = "EmailId is Required";
  //   }
  //   if (initialData.IdNo.length === 0) {
  //     isValid = false;
  //     error.IdNo = "IdNo is Required";
  //   }
  //   if (initialData.IdProof.length === 0) {
  //     isValid = false;
  //     error.IdProof = "IdProof is Required";
  //   }
  //   setInitialData({ ...initialData, error });
  //   return isValid;
  // };

  const uploadS3 = async (data) => {
    let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}` }, alert, allowed_file_size)
    if (uploaded_file?.[0]?.url) {
      setUrl(uploaded_file?.[0]?.url);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Something Went Wrong"),
      });
    }
  };
  // // checkin out
  // const CheckIn = () => {
  //   let checkintime = new Date().toISOString();
  //   const payload = {
  //     query: CHECK_IN_OUT,
  //     variables: {
  //       data: {
  //         guard_image: url,
  //         check_in_time: checkintime,
  //         check_in_by: userProfileId,
  //       },
  //       visitor_id: props?.data?.id,
  //     },
  //   };
  //   NetworkCall(
  //     `${config.graphql_url}`,
  //     NetWorkCallMethods.post,
  //     payload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       props?.Reload();
  //       props?.onClose();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: "Something Went Wrong",
  //       });
  //     });
  // };
  // //checkout
  // const CheckOut = () => {
  //   const payload = {
  //     query: CHECK_IN_OUT,
  //     variables: {
  //       data: {
  //         guard_image: url,
  //         check_out_time: new Date().toISOString(),
  //         check_out_by: userProfileId,
  //       },
  //       visitor_id: props?.data?.id,
  //     },
  //   };
  //   NetworkCall(
  //     `${config.graphql_url}`,
  //     NetWorkCallMethods.post,
  //     payload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       props?.Reload();
  //       props?.onClose();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: "Something Went Wrong",
  //       });
  //     });
  // };
  //vechicle checkin out
  const VechicleCheckIn = () => {
    const payload = {
      query: VECHICLE_CHECK_IN_OUT,
      variables: {
        data: {
          parking_check_in_by: userProfileId,
          actual_in: new Date().toISOString(),
        },
        request_id: props?.data?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("checked In"),
        });
        props?.Reload();
        props?.onClose();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const VechicleCheckOut = () => {
    const payload = {
      query: VECHICLE_CHECK_IN_OUT,
      variables: {
        data: {
          parking_check_out_by: userProfileId,
          actual_out: new Date().toISOString(),
        },
        request_id: props?.data?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let parkingId =
          response?.data?.data?.security_request?.allocated_parking_slot;
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: t("checked Out"),
        });
        ParkingSlot(parkingId);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  //Parking slot is active

  const ParkingSlot = (id) => {
    const payload = {
      query: PARKING_SLOT_IS_ACTIVE,
      variables: {
        id: id,
        data: {
          is_active: false,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        props?.Reload();
        props?.onClose();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  // worker and visitor checkin function
  const UpdateDetailsWhileCheckIn = () => {
    let currentTime = new Date().toISOString();
    let updatedBy = userProfileId;
    const payload = {
      update_params: {
        name: initialData?.Name,
        gender: props?.data?.gender,
        id_type: initialData?.IdProof?.value,
        identification_no: initialData?.IdNo,
        mobile_no: initialData?.MobileNo?.mobile,
        mobile_country_code: initialData?.MobileNo?.mobile_code,
        email_id: initialData?.EmailId,
        visitor_image: props?.data?.visitor_image
          ? props?.data?.visitor_image
          : null,
        guard_image: props?.data?.guard_image
          ? props?.data?.guard_image
          : url,
        updated_at: currentTime,
        updated_by: updatedBy,
        check_in_time: currentTime,
      },
      visitor_id: props?.data?.id,
      id: props?.requestId,
      approved_access_gate: Number(props?.gateId)
    }
    NetworkCall(
      `${config.api_url}/security_request_visitors/check_in_out`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("checked In"),
        });
        props?.Reload();
        props?.onClose();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  // worker and visitor checkout function

  const UpdateDetailsWhileCheckOut = () => {
    let currentTime = new Date().toISOString();
    let updatedBy = userProfileId;
    const payload = {
      update_params: {
        name: initialData?.Name,
        gender: props?.data?.gender,
        id_type: initialData?.IdProof?.value,
        identification_no: initialData?.IdNo,
        mobile_no: initialData?.MobileNo?.mobile,
        mobile_country_code: initialData?.MobileNo?.mobile_code,
        email_id: initialData?.EmailId,
        visitor_image: props?.data?.visitor_image
          ? props?.data?.visitor_image
          : null,
        guard_image: props?.data?.guard_image
          ? props?.data?.guard_image
          : url,
        updated_at: currentTime,
        updated_by: updatedBy,
        check_out_time: currentTime,
      },
      visitor_id: props?.data?.id,
      id: props?.requestId,
      approved_access_gate: Number(props?.gateId)
    }
    NetworkCall(
      `${config.api_url}/security_request_visitors/check_in_out`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: t("checked Out"),
        });
        props?.Reload();
        props?.onClose();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  //vehicle parking checkin function

  const UpdateVehicleDetailsWhileCheckIn = () => {
    let currentTime = new Date().toISOString();
    let updatedBy = userProfileId;
    const payload = {
      query: EDIT_AND_UPDATE_VEHICLE_DETAILS,
      variables: {
        data: [
          {
            vechicle_number: initialData?.vechicle_number,
            vechicle_type: initialData?.vechicle_type?.label,
            vehicle_image: props?.data?.vehicle_image
              ? props?.data?.vehicle_image
              : url,
            updated_at: currentTime,
            updated_by: updatedBy,
            approved_access_gate: props?.gateId?.length > 0 ? Number(props?.gateId) : undefined
          },
        ],
        id: props?.data?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        VechicleCheckIn();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  //vehicle parking checkout function

  const UpdateVehicleDetailsWhileCheckOut = () => {
    let currentTime = new Date().toISOString();
    let updatedBy = userProfileId;
    const payload = {
      query: EDIT_AND_UPDATE_VEHICLE_DETAILS,
      variables: {
        data: [
          {
            vechicle_number: initialData?.vechicle_number,
            vechicle_type: initialData?.vechicle_type?.label,
            vehicle_image: props?.data?.vehicle_image
              ? props?.data?.vehicle_image
              : url,
            updated_at: currentTime,
            updated_by: updatedBy,
            approved_access_gate: props?.gateId?.length > 0 ? Number(props?.gateId) : undefined
          },
        ],
        id: props?.data?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        VechicleCheckOut();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getEnum = async () => {
    const result = await enumSelect([enumTypes?.vechicle_type])
    setEnum({
      options: result?.vechicle_type,
    })
  }





  return (
    <div className={classes.root}>
      <Container maxWidth="sm" style={{ padding: "0px" }}>
        <Grid
          className={classes.screen}
          // style={{ height: size?.height }}
          item
          xs={12}
        >
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                {props?.type === "WGR" && t(" View Worker Detail")}
                {props?.type === "VGR" && t(" View Visitor Detail")}
                {props?.type === "PSR" && t(" View Vechicle Detail")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={props?.onClose}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
          {props?.type === "WGR" && (
            <Grid
              className={classes.screenPadding}
              style={{ height: size?.height - 196, overflow: "scroll" }}
            >
              <Grid container className={classes.profilePicturePadding}>
                <Avatar
                  variant="circular"
                  style={{
                    height: "90px",
                    width: "90px",
                    border: "2px solid #ffffff",
                  }}
                >
                  <img
                    height={90}
                    width={90}
                    src={
                      (props?.data?.visitor_image || props?.data?.domestic_helpers?.visitor_image)
                        ? generateImageUrl((props?.data?.visitor_image || props?.data?.domestic_helpers?.visitor_image), img_size.small_square)
                        : "/images/profile_image.png"
                    }
                    alt="profile_image"
                  />
                </Avatar>

                <Grid item xs={2} />
                <Avatar
                  variant="circular"
                  style={{
                    height: "90px",
                    width: "90px",
                    border: "2px solid #ffffff",
                  }}
                >
                  <img
                    height={90}
                    width={90}
                    src={generateImageUrl(url, img_size.small_square) ?? "/images/profile_image.png"}
                    alt="profile_image"
                  />
                </Avatar>
                <label htmlFor="uplodebtn">
                  <Avatar
                    variant="circular"
                    style={{
                      backgroundColor: "#E4E8EE",
                      border: "3px solid #ffffff",
                      marginTop: "50px",
                      marginLeft: "-25px",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      style={{
                        height: "12px",
                      }}
                      src="/images/icons8-camera.svg"
                      alt=""
                    />
                  </Avatar>
                </label>
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="uplodebtn"
                  onChange={(value) => {
                    uploadS3(value.target.files[0]);
                    // setProfileImage(value.target.files[0]);
                  }}
                />
              </Grid>
              <Divider />
              <Typography style={{ fontFamily: Bold, marginTop: "8px" }}>
                {props?.type === "WGR" && t("WORKER DETAILS")}
                {props?.type === "VGR" && t("VISITOR DETAILS")}
              </Typography>
              <Box height="16px" />
              <TextBox
                label={t("Name")}
                onChange={(e) => updateState("Name", e.target.value)}
                value={initialData?.Name}
              />
              <Box height="16px" />
              <MobileNumberInputComponent
                label={t("Mobile Number")}
                placeholder={t("enter mobile number")}
                value={initialData?.MobileNo}
                handleChange={(value) => updateState("MobileNo", value)}
              // handleChange={(value) => {
              //     initialData.primaryMobileNo = value.mobile;
              //     setInitialData({ ...initialData })
              // }}
              />
              <Box height="16px" />
              <TextBox
                label={t("Email Address")}
                value={initialData?.EmailId}
                onChange={(e) => updateState("EmailId", e.target.value)}
              />
              <Box height="16px" />
              <Grid
                container
                direction={"row"}
                style={{ justifyContent: "space-between" }}
              >
                <Typography style={{ fontFamily: Bold }}>
                  {props?.type === "WGR" && t("WORKER PROOF")}
                  {props?.type === "VGR" && t("VISITOR PROOF")}
                </Typography>
                <Typography
                  style={{
                    fontFamily: SemiBold,
                    fontSize: "0.875rem",
                    color: "#5078E1",
                    cursor: "pointer",
                  }}
                >
                  {/* <div onClick={() => history.push(Routes.addAddress)}>
                  Add Address
                </div> */}
                </Typography>
              </Grid>
              <Box height="16px" />
              <TextBox
                label={t("ID Number")}
                onChange={(e) => updateState("IdNo", e.target.value)}
                value={initialData?.IdNo}
              />
              <Box height="16px" />
              <SelectBox
                label={t("ID Proof")}
                options={props?.proofData}
                value={initialData?.IdProof}
                onChange={(value) => updateState("IdProof", value)}
                menuPlacement="top"
              />
              <Box height="16px" />
            </Grid>
          )}
          {props?.type === "VGR" && (
            <Grid
              className={classes.screenPadding}
              style={{ height: size?.height - 196, overflow: "scroll" }}
            >
              <Grid container className={classes.profilePicturePadding}>
                <Avatar
                  variant="circular"
                  style={{
                    height: "90px",
                    width: "90px",
                    border: "2px solid #ffffff",
                  }}
                >
                  <img
                    height={90}
                    width={90}
                    src={
                      props?.data?.visitor_image
                        ? generateImageUrl(props?.data?.visitor_image, img_size.small_square)
                        : "/images/profile_image.png"
                    }
                    alt="profile_image"
                  />
                </Avatar>

                <Grid item xs={2} />
                <Avatar
                  variant="circular"
                  style={{
                    height: "90px",
                    width: "90px",
                    border: "2px solid #ffffff",
                  }}
                >
                  <img
                    height={90}
                    width={90}
                    src={generateImageUrl(url, img_size.small_square) ?? "/images/profile_image.png"}
                    alt="profile_image"
                  />
                </Avatar>
                <label htmlFor="uplodebtn">
                  <Avatar
                    variant="circular"
                    style={{
                      backgroundColor: "#E4E8EE",
                      border: "3px solid #ffffff",
                      marginTop: "50px",
                      marginLeft: "-25px",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      style={{
                        height: "12px",
                      }}
                      src="/images/icons8-camera.svg"
                      alt=""
                    />
                  </Avatar>
                </label>
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="uplodebtn"
                  onChange={(value) => {
                    uploadS3(value.target.files[0]);
                    // setProfileImage(value.target.files[0]);
                  }}
                />
              </Grid>
              <Divider />
              <Typography style={{ fontFamily: Bold, marginTop: "8px" }}>
                {props?.type === "WGR" && t("WORKER DETAILS")}
                {props?.type === "VGR" && t("VISITOR DETAILS")}
              </Typography>
              <Box height="16px" />
              <TextBox
                label={t("Name")}
                onChange={(e) => updateState("Name", e.target.value)}
                value={initialData?.Name}
              />
              <Box height="16px" />
              <MobileNumberInputComponent
                label={t("Primary Contact")}
                placeholder={t("enter mobile number")}
                value={initialData?.MobileNo}
                handleChange={(value) => updateState("MobileNo", value)}
              />
              <Box height="16px" />
              <TextBox
                label={t("Email Address")}
                value={initialData?.EmailId}
                onChange={(e) => updateState("EmailId", e.target.value)}
              />
              <Box height="16px" />
              <Grid
                container
                direction={"row"}
                style={{ justifyContent: "space-between" }}
              >
                <Typography style={{ fontFamily: Bold }}>
                  {props?.type === "WGR" && t("WORKER PROOF")}
                  {props?.type === "VGR" && t("VISITOR PROOF")}
                </Typography>
                <Typography
                  style={{
                    fontFamily: SemiBold,
                    fontSize: "0.875rem",
                    color: "#5078E1",
                    cursor: "pointer",
                  }}
                >
                  {/* <div onClick={() => history.push(Routes.addAddress)}>
                  Add Address
                </div> */}
                </Typography>
              </Grid>
              <Box height="16px" />
              <TextBox
                label={t("ID Number")}
                onChange={(e) => updateState("IdNo", e.target.value)}
                value={initialData?.IdNo}
              />
              <Box height="16px" />
              <SelectBox
                label={t("ID Proof")}
                options={props?.proofData}
                value={initialData?.IdProof}
                onChange={(value) => updateState("IdProof", value)}
                menuPlacement="top"

              />
              <Box height="16px" />
            </Grid>
          )}
          {props?.type === "PSR" && (
            <Grid
              className={classes.screenPadding}
              style={{ height: size?.height - 196, overflow: "scroll" }}
            >
              <Grid container className={classes.profilePicturePadding}>
                <Avatar
                  variant="circular"
                  style={{
                    height: "128px",
                    width: "128px",
                    border: "2px solid #ffffff",
                  }}
                >
                  <img
                    height={128}
                    width={128}
                    src={
                      props?.data?.vehicle_image
                        ? generateImageUrl(props?.data?.vehicle_image, img_size.small_square)
                        : url !== null
                          ? generateImageUrl(url, img_size.small_square)
                          : "/images/profile_image.png"
                    }
                    alt="profile_image"
                  />
                </Avatar>
                <label htmlFor="uplodebtn">
                  <Avatar
                    variant="circular"
                    style={{
                      backgroundColor: "#E4E8EE",
                      border: "3px solid #ffffff",
                      marginTop: "90px",
                      marginLeft: "-25px",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      style={{
                        height: "12px",
                      }}
                      src="/images/icons8-camera.svg"
                      alt=""
                    />
                  </Avatar>
                </label>
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="uplodebtn"
                  onChange={(value) => {
                    uploadS3(value.target.files[0]);
                    // setProfileImage(value.target.files[0]);
                  }}
                />
              </Grid>
              <Divider />
              <SelectBox
                label={t("Vechicle Type")}
                value={initialData?.vechicle_type}
                options={enumValue.options}
                onChange={(value) => updateState("vechicle_type", value)}
              />

              <Box height="16px" />
              <TextBox
                label={t("Vechicle No")}
                onChange={(e) => updateState("vechicle_number", e.target.value)}
                value={initialData?.vechicle_number}
              />
              <Box height="16px" />
            </Grid>
          )}
          {/* buttons */}
          {props?.type === "WGR" && (
            <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>

              {(props?.data?.check_in_time === null ||
                props?.data?.check_out_time !== null) ? (
                <SlideButton
                  mainText={`${t("Swipe to  Check In")}`}
                  overlayText={t("Checked In")}
                  classList={"my-class1"}
                  caretClassList={"my-caret-class1"}
                  overlayClassList={"my-overlay-class1"}
                  onSlideDone={UpdateDetailsWhileCheckIn}
                  disabled={props?.disabled}
                />
                // <Button
                //   variant="contained"
                //   fullWidth
                //   className={classes.iconButtonStyle}
                //   onClick={UpdateDetailsWhileCheckIn}
                //   disabled={props?.disabled}

                // >
                //   <Typography className={classes.saveNUpdateButtonTextStyle}>
                //     Check In
                //   </Typography>
                // </Button>
              ) : (
                <SlideButton
                  mainText={`${t("Swipe to  Check Out")}`}
                  overlayText={t("Checked Out")}
                  classList={"my-class2"}
                  caretClassList={"my-caret-class2"}
                  overlayClassList={"my-overlay-class2"}
                  onSlideDone={UpdateDetailsWhileCheckOut}
                  disabled={props?.disabled}
                />
                // <Button
                //   variant="contained"
                //   fullWidth
                //   className={classes.iconButtonStyle}
                //   onClick={UpdateDetailsWhileCheckOut}
                //   disabled={props?.disabled}

                // >
                //   <Typography className={classes.saveNUpdateButtonTextStyle}>
                //     Check Out
                //   </Typography>
                // </Button>
              )}
            </Grid>
          )}
          {props?.type === "VGR" && (
            <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
              {(props?.data?.check_in_time === null ||
                props?.data?.check_out_time !== null) ? (
                <SlideButton
                  mainText={`${t("Swipe to  Check In")}`}
                  overlayText={t("Checked In")}
                  classList={"my-class1"}
                  caretClassList={"my-caret-class1"}
                  overlayClassList={"my-overlay-class1"}
                  onSlideDone={UpdateDetailsWhileCheckIn}
                  disabled={props?.disabled}
                />
                // <Button
                //   variant="contained"
                //   fullWidth
                //   className={classes.iconButtonStyle}
                //   onClick={UpdateDetailsWhileCheckIn}
                //   disabled={props?.disabled}

                // >
                //   <Typography className={classes.saveNUpdateButtonTextStyle}>
                //     Check In
                //   </Typography>
                // </Button>
              ) : (
                <SlideButton
                  mainText={`${t("Swipe to  Check Out")}`}
                  overlayText={t("Checked Out")}
                  classList={"my-class2"}
                  caretClassList={"my-caret-class2"}
                  overlayClassList={"my-overlay-class2"}
                  onSlideDone={UpdateDetailsWhileCheckOut}
                  disabled={props?.disabled}
                />
                // <Button
                //   variant="contained"
                //   fullWidth
                //   className={classes.iconButtonStyle}
                //   onClick={UpdateDetailsWhileCheckOut}
                //   disabled={props?.disabled}
                // >
                //   <Typography className={classes.saveNUpdateButtonTextStyle}>
                //     Check Out
                //   </Typography>
                // </Button>
              )}
            </Grid>
          )}
          {props?.type === "PSR" && (
            <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
              {props?.data?.actual_in ? (
                <SlideButton
                  mainText={`${t("Swipe to  Check Out")}`}
                  overlayText={t("Checked Out")}
                  classList={"my-class2"}
                  caretClassList={"my-caret-class2"}
                  overlayClassList={"my-overlay-class2"}
                  onSlideDone={UpdateVehicleDetailsWhileCheckOut}
                  disabled={props?.disabled || props?.data?.actual_out}
                />
                // <Button
                //   variant="contained"
                //   fullWidth
                //   className={classes.iconButtonStyle}
                //   onClick={UpdateVehicleDetailsWhileCheckOut}
                //   disabled={props?.data?.actual_out}
                // >
                //   <Typography className={classes.saveNUpdateButtonTextStyle}>
                //     Check Out
                //   </Typography>
                // </Button>
              ) : (
                <SlideButton
                  mainText={`${t("Swipe to  Check In")}`}
                  overlayText={t("Checked In")}
                  classList={"my-class1"}
                  caretClassList={"my-caret-class1"}
                  overlayClassList={"my-overlay-class1"}
                  onSlideDone={UpdateVehicleDetailsWhileCheckIn}
                  disabled={props?.disabled}
                />
                // <Button
                //   variant="contained"
                //   fullWidth
                //   className={classes.iconButtonStyle}
                //   onClick={UpdateVehicleDetailsWhileCheckIn}
                // >
                //   <Typography className={classes.saveNUpdateButtonTextStyle}>
                //     Check In
                //   </Typography>
                // </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};
export default withNamespaces("viewDetails")(ViewDetails)