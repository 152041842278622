import React from "react";
import AudioVideoCall from "./audioVideoCall";

class AudioVideoCallParent extends React.Component {
  render() {
    return <AudioVideoCall />;
  }
}

export default AudioVideoCallParent;
