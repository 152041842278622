import { Button, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { TextBox } from '../../components';
import { NetworkCall } from '../../networkcall';
import { AlertProps, Message, NetWorkCallMethods } from '../../utils';
import { ValidateEmail } from '../../utils';
import { AlertContext } from '../../contexts';
import { config } from '../../config';
import { withNamespaces } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        // textAlign: "center",
        // marginTop: "10%"
    },
    btn: {
        padding: "7px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "opx 6px 10px #00000014",
        marginTop: 30,
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#5078E1",
        },
    },
}))

const InitialState = {
    email: "",
    error: {
        email: "",

    }
}
const ResetPassword = (props) => {
    const { t = () => false } = props;


    const classes = useStyles();
    const [forgetMail, setForgetMail] = React.useState({ ...InitialState })
    const alert = React.useContext(AlertContext);
    const updateState = (key, value) => {
        if (key === "email") {
            ValidateEmail(value)
        }
        let error = forgetMail.error;
        error[key] = "";
        setForgetMail({ ...forgetMail, [key]: value, error })
    }
    const isIamValideToforgetMail = () => {

        let isValid = true;
        let error = forgetMail.error;
        // Checking Email
        if (forgetMail.email.length === 0) {
            isValid = false;
            error.email = Message.English.requiredMessage('Email')
        }
        setForgetMail({ ...forgetMail, error })
        return isValid;
    }
    const onSendforgetMailBtnClicked = () => {

        if (isIamValideToforgetMail()) {
            const data = {
                email_id: forgetMail?.email?.toLowerCase(),
            }

            NetworkCall(
                `${config.auth_api_url}/auth/forgotpassword`,
                NetWorkCallMethods.post,
                data,
                null,
                false,
                false)
                .then((response) => {
                    if (response.status === 200) {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: "Reset password link send to respective email address"
                        })
                        props.onDrawerClose()
                    }

                })
                .catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Invalid Email ID"
                    })
                    console.log(err)
                })

        } else {




            return false;
        }

    }



    return <div className={classes.root}>
        <Container maxWidth="sm">
            <Grid container>
                <Grid item xs={12}>
                    <TextBox
                        label={t("Registered Email ID")}
                        placeholder={t("Enter Registered Email ID")}
                        value={forgetMail.email}
                        isError={forgetMail.error.email.length > 0}
                        errorMessage={forgetMail.error.email}
                        onChange={(e) => updateState('email', e.target.value)}

                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                    <br />
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.btn}
                        onClick={onSendforgetMailBtnClicked}
                    >
                        {t("Submit")}
                    </Button>
                    <br />
                    <br />
                </Grid>

            </Grid>

        </Container>

    </div>
}
export default withNamespaces("security_guard_keys")(ResetPassword)