import { Typography ,Box} from "@mui/material"
import { FourWheel } from "../../../assets/fourWheel"
import { Bicycle3, TwoWheel } from "../../../assets"
import { SemiBold } from "../../../utils"
import {useStyles} from "./styles";
export const VechileType = ({onChange=()=>false,value,t=()=>false}) => {
    const classes=useStyles()

    const VehicleTypeList = [
        {
            type: t("Four Wheeler"),
            value: "FOUR-WHEELER",
            icon: (<FourWheel />),
            selected_icon: (<FourWheel color="#5078e1" />)
        },
        {
            type: t("Two Wheeler"),
            value: "TWO-WHEELER",
            icon: (<TwoWheel />),
            selected_icon: (<TwoWheel color="#5078e1" />)
        },
        {
            type: t("Bi Cycle"),
            value: "BI-CYCLE",
            icon: (<Bicycle3 />),
            selected_icon: (<Bicycle3 color="#5078e1" />)
        },
    ]

    return (
        <Box style={{ marginTop:"12px",marginBottom:"12px"}}>
            <Typography
            style={{
                fontFamily:SemiBold,
                fontSize:"0.75rem",
                color:"#a2a2a2",
                marginBottom:"12px"
            }}
            >{t("Vehicle Type")}</Typography>
            <div style={{ display: "flex", columnGap: "10px" }}>

            {VehicleTypeList.map((item) => {
                return (<Box
                    className={classes.vehicleCardRoot}
                    style={{border: value === item.value ? "1px solid #5078E1" : undefined}}

                    onClick={() => onChange(value === item.value ? null:item.value)}>
                        <center>
                    {value === item.value ? item?.selected_icon : item?.icon}
                    <Box height={"12px"} />
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "0.75rem",
                            color: value === item.value ? "#091B29" : "#98A0AC",
                            fontFamily: SemiBold
                        }}>
                        {item.type}
                    </Typography>
                    </center>
                </Box>)
            })}
            </div>

        </Box>
    )
}