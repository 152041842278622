import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton, Stack, Typography
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from "@mui/styles/makeStyles";
import useTheme from "@mui/styles/useTheme";
import moment from "moment-timezone";
import React from "react";
import { DialogDrawer, SlideButton, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { COLLECTED_AMOUNT } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { AlertProps, Bold, currencyFormater, enumSelect, enumTypes, ExtraBold, generateImageUrl, img_size, NetWorkCallMethods, Regular, remCalc, SemiBold } from "../../utils";
// import ImageViewer from "react-simple-image-viewer";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },

  sub: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  checksub: {
    fontSize: "0.8125rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  checkheading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  bottom: {
    padding: "12px",
    marginTop: "-20px",
  },
  span: {
    backgroundColor: "#5078E11E",
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: Bold,
    marginInlineStart: "6px",
    padding: "4px",
    borderRadius: "4px",
    display: "inline-block"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  iconButtonStyle: {
    height: "44px",
    padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    color: "#5078E1",
    background: "#5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "0px 2px 6px #00000021",
  },
  saveNUpdateButtonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  saveNUpdateButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },

  heading2: {
    fontSize: "0.875rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
  },
  heading3: {
    fontSize: "0.875rem",
    color: theme.palette.success.main,
    fontFamily: SemiBold,
  },

  title: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: "#4E5A6B",
  },

  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
}));
const VehicleCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const [drawerAmount, setDrawerAmount] = React.useState(false);
  const [showBill, setShowBill] = React.useState(props?.data?.is_unit_bill);
  const [amount, setAmount] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [collectedAmount, setCollectedAmount] = React.useState("");
  const theme = useTheme();
  const below360 = useMediaQuery(theme.breakpoints.down('360'));
  const [enumValue, setEnumValue] = React.useState({
    type: [],
  })
  // const openDrawer = (val) => {
  //   setDrawer(true)
  //   // setDetails(val)
  // }
  const openDrawerAmount = () => {
    setDrawerAmount(true);
  };
  const ChargesApi = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: props?.data?.id,
    };
    NetworkCall(
      `${config.api_url}/security/charges`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          setAmount(response?.data?.data[0]);
          props?.Reload();
        }
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  React.useEffect(() => {
    if (props?.data?.actual_out) {
      ChargesApi();
    }
    // eslint-disable-next-line
  }, [props?.data?.actual_out]);

  const CollectedAmountApi = () => {
    if (collectedAmount !== "") {
      const payload = {
        query: COLLECTED_AMOUNT,
        variables: {
          data: {
            actual_payment: parseFloat(collectedAmount),
            balance: parseFloat(
              amount?.payment_charges - collectedAmount
            ).toFixed(1),
            payment_status: enumValue?.type?.type2,
          },
          request_id: props?.data?.id,
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Amount collected successfully"),
          });
          setDrawerAmount(false);
          props?.Reload();
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
        });
    }
  };
  const BillToAmountApi = () => {
    const payload = {
      query: COLLECTED_AMOUNT,
      variables: {
        data: {
          payment_status: enumValue?.type?.type1,
          is_unit_bill: true,
        },
        request_id: props?.data?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setShowBill(true);
        props?.Reload();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getEnum = async () => {

    const result = await enumSelect([enumTypes?.parking_payment_type])
    let requestSourceMap = result?.parking_payment_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})


    setEnumValue({ ...enumValue, type: requestType })
  }
  const disableButton = ((new Date() <= new Date(props?.data?.request_from)) || (new Date() >= new Date(props?.data?.request_to))) ? true : false

  React.useEffect(() => {
    getEnum()
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <div className={classes.card}>
        <Box alignItems="center" display="flex" p={2}>
          <Box>
            {props?.data?.request_type === "PSR" &&
              <>
                {props?.data?.vehicle_image ?
                  <Avatar src={props?.data?.vehicle_image ? generateImageUrl(props?.data?.vehicle_image, img_size.small_square) : ""}
                    onClick={() => props?.openImageViewer([props?.data?.vehicle_image])}
                  ></Avatar> :
                  <Avatar></Avatar>
                }
              </>
            }
            {props?.data?.request_type === "WGR" &&
              <>
                {props?.data?.visitor_image ?
                  <Avatar src={props?.data?.visitor_image ? generateImageUrl(props?.data?.visitor_image, img_size.small_square) : ""}
                    onClick={() => props?.openImageViewer([props?.data?.visitor_image])}
                  >
                  </Avatar> : <Avatar></Avatar>
                }
              </>
            }
            {props?.data?.request_type === "VGR" &&
              <>
                {props?.data?.visitor_image ?
                  <Avatar src={props?.data?.visitor_image ? generateImageUrl(props?.data?.visitor_image, img_size.small_square) : ""}
                    onClick={() => props?.openImageViewer([props?.data?.visitor_image])}
                  >
                  </Avatar> : <Avatar></Avatar>
                }
              </>
            }
            {/* {props?.isViewerOpen && (
              <ImageViewer
                src={props?.currentImage}
                currentIndex={0}
                onClose={props?.closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )} */}
            {
              <DialogDrawer
                open={props?.isViewerOpen}
                onClose={props?.closeImageViewer}
                header={"View"}
                component={
                  <div>
                    <img src={props?.currentImage} alt="" style={{ width: "100%", height: "450px" }} />
                  </div>
                }
              />
            }
          </Box>
          <Box flexGrow={1} marginInlineStart="10px">
            <Typography variant="subtitle2" className={classes.title}>
              {props?.data?.name
                ? props?.data?.name
                : props?.data?.vechicle_number}
              {props?.data?.vechicle_type && (
                <div className={classes.span}>
                  {props?.data?.vechicle_type}
                </div>
              )}{" "}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.view}
              noWrap
              // onClick={() => props.onClick(props.data)}
              onClick={() =>
                props?.data?.actual_in ? props.onClick(props.data) : false
              }
            >
              {t("Update Details")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Grid container spacing={1} className={classes.bottom}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.heading}>
              {t("Checked In")}
            </Typography>
            {props?.data?.actual_in ? (
              <Typography variant="subtitle2" className={classes.title}>
                {"By"} {props?.data?.parking_check_in_by},&#x202a;{props?.data?.actual_in
                  ? (moment(props?.data?.actual_in)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY  hh:mm:a"))
                  : "-"}&#x202c;
              </Typography>
            ) : (
              " - "
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.heading}>
              {t("Checked Out")}
            </Typography>
            {props?.data?.actual_out ? (
              <Typography variant="subtitle2" className={classes.title}>
                {"By"} {props?.data?.parking_check_out_by},&#x202a;{props?.data?.actual_out
                  ? moment(props?.data?.actual_out)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY  hh:mm:a")
                  : "-"}&#x202c;
              </Typography>
            ) : (
              " - "
            )}
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: "8px" }}>
              <SlideButton
                mainText={`Swipe to ${props?.data?.actual_in === null && props?.data?.actual_out === null ? t("Check In") : t("Check Out")}`}
                overlayText={props?.data?.actual_in === null && props?.data?.actual_out === null ? t("Checked In") : t("Checked Out")}
                classList={props?.data?.actual_in === null && props?.data?.actual_out === null ? "my-class1" : "my-class2"}
                caretClassList={props?.data?.actual_in === null && props?.data?.actual_out === null ? "my-caret-class1" : "my-caret-class2"}
                overlayClassList={props?.data?.actual_in === null && props?.data?.actual_out === null ? "my-overlay-class1" : "my-overlay-class2"}
                onSlideDone={() => props?.data?.actual_in ? props.onClick(props.data) : false}
                disabled={(props?.data?.actual_in && props?.data?.actual_out) || disableButton}
              />
            </div>
            {/* <Button
              fullWidth
              variant="contained"
              style={{ marginTop: "8px" }}
              onClick={() =>
                props?.data?.actual_in ? props.onClick(props.data) : false
              }
              color={
                props?.data?.actual_in === null &&props?.data?.actual_out === null
                  ? "success"
                  : "warning"
              }
              disabled={props?.data?.actual_in && props?.data?.actual_out}
            >
              {props?.data?.actual_in === null &&
                props?.data?.actual_out === null
                ? " Check In"
                : "Check Out"}
            </Button> */}
          </Grid>
        </Grid>
        {props?.data?.actual_out && (
          <>

            <div className={classes.rootCard}>
              <Box p={"12px"} marginTop="-12px">
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("ALLOCATED PARKING TIME")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.approved_hours
                        ? props?.data?.approved_hours + `${" Hrs"}`
                        : " - "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("ACTUAL PARKING TIME")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {props?.data?.actual_hours
                        ? props?.data?.actual_hours + `${" Hrs"}`
                        : " 0 "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} margin="10px 0px">
                    <Divider />
                  </Grid>
                  <Grid item xs={6} marginBottom="5px">
                    <Typography
                      variant="subtitle2"
                      className={classes.heading2}
                    >
                      {t("Chargers to be collected")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" marginBottom="5px">
                    <Typography variant="subtitle2" className={classes.title2}>
                      {amount?.symbol}&nbsp;
                      {amount?.payment_charges
                        ? currencyFormater(amount?.payment_charges)
                        : " - "}
                    </Typography>
                  </Grid>
                  {showBill && (
                    <>
                      <Grid item xs={6} marginBottom="5px">
                        <Typography
                          variant="subtitle2"
                          className={classes.heading3}
                        >
                          {t("Bill to the unit")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right" marginBottom="5px">
                        <Typography
                          variant="subtitle2"
                          className={classes.heading3}
                        >
                          {amount?.symbol}&nbsp;
                          {amount?.payment_charges
                            ? currencyFormater(amount?.payment_charges)
                            : " - "}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      gap={2}
                      sx={[
                        below360 &&
                        { flexWrap: 'wrap' }
                      ]}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        // padding="0px 8px"
                        style={{ borderRadius: "8px" }}
                        onClick={BillToAmountApi}
                        disabled={
                          showBill ? showBill : props?.data?.actual_payment
                        }
                      >
                        {t("Bill To The Unit")}
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        // padding="0px 8px"
                        style={{ borderRadius: "8px", padding: "6px 12px" }}
                        onClick={openDrawerAmount}
                        disabled={
                          showBill ? showBill : props?.data?.actual_payment
                        }
                      >
                        {t("Collected amount")}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Hidden smDown>
                <Dialog
                  className={classes.dialog}
                  maxWidth="sm"
                  fullWidth
                  open={drawerAmount}
                  onClose={() => setDrawerAmount(false)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.titleroot}
                      >
                        <Box flexGrow={1}>
                          <Typography
                            variant="subtitle2"
                            className={classes.bottomBarTitle}
                          >
                            {t("Collect Amount")}
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            onClick={() => setDrawerAmount(false)}
                          >
                            <CloseIcon color="secondary" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} p={2} margin={"6px"}>
                      <TextBox
                        label={t("Collected Amount")}
                        value={collectedAmount}
                        onChange={(e) => setCollectedAmount(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      className={classes.saveNUpdateButtonContainerStyle}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        className={classes.iconButtonStyle}
                        onClick={CollectedAmountApi}
                      >
                        <Typography
                          className={classes.saveNUpdateButtonTextStyle}
                        >
                          {t("Collect And Close")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Dialog>
              </Hidden>
              <Hidden smUp>
                <Drawer
                  className={classes.drawer}
                  anchor="bottom"
                  open={drawerAmount}
                  onClose={() => setDrawerAmount(false)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.titleroot}
                      >
                        <Box flexGrow={1}>
                          <Typography
                            variant="subtitle2"
                            className={classes.bottomBarTitle}
                          >
                            {t("Collect Amount")}
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            onClick={() => setDrawerAmount(false)}
                          >
                            <CloseIcon color="secondary" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} p={2} margin={"6px"}>
                      <TextBox
                        label={t("Collected Amount")}
                        value={collectedAmount}
                        onChange={(e) => setCollectedAmount(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      className={classes.saveNUpdateButtonContainerStyle}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        className={classes.iconButtonStyle}
                        onClick={CollectedAmountApi}
                      >
                        <Typography
                          className={classes.saveNUpdateButtonTextStyle}
                        >
                          {t("Collect And Close")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Drawer>
              </Hidden>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default withNamespaces("parkingSlotDetails")(VehicleCard)