import React from "react";
import Notifications from './notification';

class NotificationParent extends React.Component {
    render() {
        return <Notifications />;
    }
}

export default NotificationParent;
