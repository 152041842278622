export const TwoWheeler = (props) => (
    <svg
        width={31}
        height={65}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Path 100103"
                    d="m8.416 210.034 4.4-.038 1.442.63 14.613-.317a1.376 1.376 0 0 0 1.346-1.405v-.145a1.346 1.346 0 0 0-1.34-1.317l-14.817-.051-1.233.841-4.463.146.056 1.656Z"
                    transform="translate(-8.36 -207.39)"
                    fill="#bdbfc9"
                />
            </clipPath>
            <clipPath id="b">
                <path
                    data-name="Path 100118"
                    d="M356.881 107.894a5.011 5.011 0 0 0 2.8-4.63v-.023a5.011 5.011 0 0 0-2.8-4.63 14.306 14.306 0 0 0-7.757-.666c-1.357.212-4.378 2.391-5.021 3.3a2.943 2.943 0 0 0-.618 1.634l-.009.75a2.857 2.857 0 0 0 .606 1.634c.642.908 3.659 3.087 5.016 3.3a14.392 14.392 0 0 0 7.784-.666Z"
                    transform="translate(-343.475 -97.695)"
                    fill="none"
                />
            </clipPath>
            <clipPath id="c">
                <path
                    data-name="Path 100120"
                    d="M356.881 98.606a5.011 5.011 0 0 1 2.8 4.63v.023a5.011 5.011 0 0 1-2.8 4.63 14.306 14.306 0 0 1-7.757.666c-1.357-.212-4.378-2.391-5.021-3.3a2.943 2.943 0 0 1-.618-1.634l-.009-.75a2.857 2.857 0 0 1 .606-1.634c.642-.908 3.659-3.087 5.016-3.3a14.392 14.392 0 0 1 7.784.666Z"
                    transform="translate(-343.475 -97.691)"
                    fill="none"
                />
            </clipPath>
            <clipPath id="d">
                <path
                    data-name="Path 100157"
                    d="M24.8 148.5a27.158 27.158 0 0 0-.033 1.537.278.278 0 0 1-.023.11q.1 0 .2.005c1.321 0 2.392-.626 2.392-1.4s-1.071-1.4-2.392-1.4c-.063 0-.126 0-.189.005a9.419 9.419 0 0 1 .045 1.143Z"
                    transform="translate(-24.74 -147.36)"
                    fill="#fae9c8"
                />
            </clipPath>
            <clipPath id="e">
                <path
                    data-name="Path 100161"
                    d="M573.144 134.566c.041-1 .047-1.994.047-2.991a.758.758 0 0 1 .033-.215 4.288 4.288 0 0 0-.28-.01c-1.868 0-3.383 1.217-3.383 2.72s1.515 2.72 3.383 2.72c.09 0 .179 0 .267-.009-.034-.738-.1-1.475-.067-2.214Z"
                    transform="translate(-569.56 -131.35)"
                    fill="#d6d8e6"
                />
            </clipPath>
        </defs>
        <g data-name="19187163 [Converted]" origin="1 0">
            <g data-name="\xD1\xEB\xEE\xE9 1">
                <g data-name="Group 107098" transform="rotate(-90 32.95 31.35)">
                    <g data-name="Group 107097">
                        <g data-name="Group 107096">
                            <path
                                data-name="Path 100100"
                                d="m34.918 18.95.111-.007a6.192 6.192 0 0 0 4.845-2.917 37.617 37.617 0 0 0 1.536-2.71 5.204 5.204 0 0 1-.4.06 16.5 16.5 0 0 1-1.607 2.111 1.279 1.279 0 0 1-1.217.383 4.712 4.712 0 0 1-3.339 1.626l-13.815.663a.709.709 0 0 0-.5.228.735.735 0 0 0-.194.532.727.727 0 0 0 .76.691l13.819-.663Z"
                                fill="#a9a9ba"
                            />
                            <g data-name="Group 107074">
                                <path
                                    data-name="Path 100101"
                                    d="m.323 19.972 4.4-.038 1.442.63 14.613-.317a1.376 1.376 0 0 0 1.346-1.405v-.145a1.346 1.346 0 0 0-1.34-1.317l-14.817-.051-1.233.841-4.463.146.056 1.656Z"
                                    fill="#bdbfc9"
                                />
                                <g
                                    data-name="Group 107073"
                                    clipPath="url(#a)"
                                    transform="translate(.267 17.328)"
                                >
                                    <path
                                        data-name="Path 100102"
                                        d="m-.427 2.315 5.041-.047 1.558.58 14.754-.44a.334.334 0 0 0 .323-.3.556.556 0 0 0-.565-.608l-14.582.287-1.511-.223-5.287.1.271.648Z"
                                        fill="#e1e2e8"
                                    />
                                </g>
                            </g>
                            <path
                                data-name="Path 100104"
                                d="M6.474 11.31s-4.745.24-5.468.359S.001 13.346.001 13.346v.8s.282 1.557 1.005 1.677 5.468.359 5.468.359Z"
                                fill="#e6e6e6"
                            />
                            <path
                                data-name="Path 100105"
                                d="M10.386 8.856s-6.6.958-6.6 4.552v.639c0 3.594 6.6 4.552 6.6 4.552Z"
                                fill="#e6e6e6"
                            />
                            <path
                                data-name="Path 100106"
                                d="M11.806 8.856s-6.684.958-6.684 4.552v.639c0 3.594 6.684 4.552 6.684 4.552Z"
                                fill="#fcfcfc"
                            />
                            <path
                                data-name="Path 100107"
                                d="m35.627 20.111-4.31-.663a2.182 2.182 0 0 1-1.434-.88l-.8-1.115 6.607 2.416-.062.241Zm-5.866-2.143.327.455a1.929 1.929 0 0 0 1.268.778l3.063.471-4.657-1.7Z"
                                fill="#3a3a40"
                            />
                            <path
                                data-name="Path 100108"
                                d="m25.785 17.399.7.643a.482.482 0 0 0 .343.125l2.85-.124a.605.605 0 0 0 .419-.2l.81-.884-5.124.436Z"
                                fill="#808396"
                            />
                            <path
                                data-name="Path 100109"
                                d="m24.728 16.782.983.9a.67.67 0 0 0 .482.175l3.995-.175a.85.85 0 0 0 .588-.274l1.135-1.238-7.181.612Z"
                                fill="#ced2e3"
                            />
                            <path
                                data-name="Path 100110"
                                d="m25.785 10.337.7-.643a.482.482 0 0 1 .343-.125l2.85.124a.605.605 0 0 1 .419.2l.81.884-5.124-.436Z"
                                fill="#808396"
                            />
                            <path
                                data-name="Path 100111"
                                d="m24.728 10.956.983-.9a.67.67 0 0 1 .482-.175l3.995.175a.85.85 0 0 1 .588.274l1.135 1.238-7.181-.612Z"
                                fill="#ced2e3"
                            />
                            <path
                                data-name="Path 100112"
                                d="M12.587 19.101a51.155 51.155 0 0 0 10.519-1.436 41.9 41.9 0 0 1 8.74-.847l-.376-6.266a37.133 37.133 0 0 1-8.364-.772 51.251 51.251 0 0 0-10.53-1.436c-1.684.042-3.528-.1-5.029 1.289l-.15.363a9.763 9.763 0 0 0 .039 7.556l.111.262c1.742 1.9 3.356 1.247 5.04 1.289Z"
                                fill="#283242"
                            />
                            <path
                                data-name="Path 100113"
                                d="M7.825 13.726a8.556 8.556 0 0 1 .653-3.284l.044-.107a5.4 5.4 0 0 1 3.521-.813c.189 0 .377-.005.563-.01a50.241 50.241 0 0 1 10.184 1.393 33.54 33.54 0 0 0 7.579.817l.236 3.941a36.878 36.878 0 0 0-7.815.876 50.188 50.188 0 0 1-10.173 1.393c-.414-.01-.806.015-1.184.039a3.081 3.081 0 0 1-2.892-.811l-.028-.067a8.545 8.545 0 0 1-.687-3.366Z"
                                fill="#303c4f"
                            />
                            <path
                                data-name="Rectangle 55690"
                                fill="#374458"
                                d="M18.939 8.404v10.568h-1.287V8.404z"
                            />
                            <path
                                data-name="Path 100114"
                                d="M54.199 10.881v5.87a.289.289 0 0 1-.289.289h-.39a.289.289 0 0 1-.289-.289v-5.87a.289.289 0 0 1 .289-.289h.39a.289.289 0 0 1 .289.289Z"
                                fill="#919191"
                            />
                            <g data-name="Group 107078">
                                <path
                                    data-name="Path 100115"
                                    d="M41.672 18.361a5.011 5.011 0 0 0 2.8-4.63v-.023a5.011 5.011 0 0 0-2.8-4.63 14.306 14.306 0 0 0-7.757-.666c-1.357.212-4.378 2.391-5.021 3.3a2.943 2.943 0 0 0-.618 1.634l-.009.75a2.857 2.857 0 0 0 .606 1.634c.642.908 3.659 3.087 5.016 3.3a14.392 14.392 0 0 0 7.784-.666Z"
                                    fill="#fff"
                                    stroke="#71829f"
                                    strokeWidth={0.2}
                                />
                                <path
                                    data-name="Path 100116"
                                    d="M39.626 17.324c-2.89 0-7.058-1.588-7.058-3.547s4.168-3.547 7.058-3.547 3.772 1.588 3.772 3.547-.884 3.547-3.772 3.547Z"
                                    fill="#fff"
                                />
                                <g
                                    data-name="Group 107075"
                                    transform="translate(28.266 8.162)"
                                    clipPath="url(#b)"
                                >
                                    <path
                                        data-name="Path 100117"
                                        d="M6.852.043c-1.8 1.115-2.03 2.087-3.957 2.988S-.047 4.762-.047 5.497s-.781-2.513-.781-2.632S2.009-.479 2.088-.479s4.764.522 4.764.522Z"
                                        fill="#e6e6e6"
                                    />
                                </g>
                                <g
                                    data-name="Group 107076"
                                    clipPath="url(#c)"
                                    transform="translate(28.266 8.162)"
                                >
                                    <path
                                        data-name="Path 100119"
                                        d="M6.852 11.07c-1.8-1.115-2.03-2.087-3.957-2.988S-.047 6.351-.047 5.616s-.781 2.513-.781 2.632 2.837 3.344 2.916 3.344 4.764-.522 4.764-.522Z"
                                        fill="#e6e6e6"
                                    />
                                </g>
                                <g
                                    data-name="Group 107077"
                                    transform="translate(36.663 11.896)"
                                >
                                    <ellipse
                                        data-name="Ellipse 40519"
                                        cx={1.895}
                                        cy={1.882}
                                        rx={1.895}
                                        ry={1.882}
                                        fill="#e3e3e3"
                                    />
                                    <path
                                        data-name="Path 100121"
                                        d="M.677 1.883a1.218 1.218 0 1 1 1.218 1.21 1.215 1.215 0 0 1-1.218-1.21Zm2.359 0a1.141 1.141 0 1 0-1.141 1.133 1.138 1.138 0 0 0 1.142-1.133Z"
                                        fill="#b0b0b0"
                                    />
                                    <ellipse
                                        data-name="Ellipse 40520"
                                        cx={1.008}
                                        cy={1.002}
                                        rx={1.008}
                                        ry={1.002}
                                        transform="translate(.886 .881)"
                                        fill="#fafafa"
                                    />
                                    <path
                                        data-name="Path 100122"
                                        d="M.134 1.63a.155.155 0 0 1 .175-.13.153.153 0 0 1 .131.174.153.153 0 0 1-.175.13.154.154 0 0 1-.133-.152.1.1 0 0 1 .002-.022Zm.219.782a.155.155 0 0 1 .2.075.153.153 0 0 1-.076.2.156.156 0 0 1-.206-.075.16.16 0 0 1-.014-.063.153.153 0 0 1 .09-.14Zm.2-1.688A.154.154 0 0 1 .771.709a.153.153 0 0 1 .016.216.155.155 0 0 1-.218.015.151.151 0 0 1-.053-.116.151.151 0 0 1 .035-.099Zm.336 2.431a.156.156 0 0 1 .214-.046.154.154 0 0 1 .047.212.155.155 0 0 1-.214.046.153.153 0 0 1-.07-.129.149.149 0 0 1 .024-.083Zm.507-2.97a.155.155 0 0 1 .192.1.153.153 0 0 1-.1.191.156.156 0 0 1-.193-.1.154.154 0 0 1-.006-.043.154.154 0 0 1 .105-.147Zm.5 3.155a.154.154 0 0 1 .155.154.154.154 0 0 1-.155.154.153.153 0 0 1-.155-.154.153.153 0 0 1 .154-.153ZM2.205.289a.155.155 0 0 1 .192-.1.153.153 0 0 1 .105.19.154.154 0 0 1-.193.1.152.152 0 0 1-.111-.147.156.156 0 0 1 .006-.043Zm.485 2.82a.156.156 0 0 1 .214.046.152.152 0 0 1-.047.212.156.156 0 0 1-.214-.046.15.15 0 0 1-.025-.083.151.151 0 0 1 .071-.129Zm.332-2.4a.155.155 0 0 1 .218.015.151.151 0 0 1-.016.216.156.156 0 0 1-.218-.015.15.15 0 0 1-.038-.1.154.154 0 0 1 .053-.116Zm.211 1.78a.155.155 0 0 1 .2-.076.154.154 0 0 1 .076.2.155.155 0 0 1-.2.076.154.154 0 0 1-.09-.14.145.145 0 0 1 .015-.064Zm.249-.988a.155.155 0 0 1 .175.13.154.154 0 0 1-.131.174.153.153 0 0 1-.175-.13.09.09 0 0 1 0-.022.154.154 0 0 1 .131-.153Z"
                                        fill="#b0b0b0"
                                    />
                                </g>
                            </g>
                            <path
                                data-name="Path 100123"
                                d="M46.704 15.8h15.57a1.727 1.727 0 0 0 1.727-1.727v-.682a1.727 1.727 0 0 0-1.727-1.727H46.686a20.575 20.575 0 0 1 .018 4.138Z"
                                fill="#283242"
                            />
                            <path
                                data-name="Path 100124"
                                d="M45.7 15.798v-4.1a.684.684 0 0 1 .75-.632l11.64.442a.675.675 0 0 1 .688.632v3.221a.676.676 0 0 1-.688.632l-11.64.442a.684.684 0 0 1-.75-.637Z"
                                fill="#fcfcfc"
                                stroke="#3a3a40"
                                strokeWidth={0.2}
                            />
                            <rect
                                data-name="Rectangle 55691"
                                width={1.35}
                                height={9.156}
                                rx={0.675}
                                transform="rotate(91.66 22.549 31.362)"
                                fill="#d1d1d1"
                            />
                            <path
                                data-name="Path 100125"
                                d="M48.724 14.068v-.644a1.714 1.714 0 0 1 1.786-1.713l4.592.191a1.715 1.715 0 0 1 1.643 1.713v.262a1.714 1.714 0 0 1-1.643 1.713l-4.592.191a1.715 1.715 0 0 1-1.786-1.713Z"
                                fill="#fcfcfc"
                            />
                            <g data-name="Group 107079">
                                <path
                                    data-name="Path 100126"
                                    d="M47.412 22.186a12.288 12.288 0 0 0-.855 4.041h.021a.281.281 0 1 1-.253.16 11.363 11.363 0 0 1 .134-3.641l-.939-.612.49-.612 1.4.668Z"
                                    fill="#bcbecc"
                                />
                                <path
                                    data-name="Path 100127"
                                    d="m45.837 19.44-.827 1.742.953.446.827-1.742Z"
                                    fill="#636473"
                                />
                                <path
                                    data-name="Path 100128"
                                    d="m44.449 22.385-1.3 2.747.952.446 1.305-2.747Z"
                                    fill="#2c2c33"
                                />
                                <path
                                    data-name="Path 100129"
                                    d="m45.048 20.859-.335.7 1.118.524.335-.7Z"
                                    fill="#d6d8e6"
                                />
                                <path
                                    data-name="Path 100130"
                                    d="m44.301 21.639-.16.336a.416.416 0 0 0 .2.555l.969.454a.421.421 0 0 0 .559-.2l.16-.336a.416.416 0 0 0-.2-.555l-.969-.455a.421.421 0 0 0-.559.2Z"
                                    fill="#40404a"
                                />
                                <path
                                    data-name="Path 100131"
                                    d="m46.16 17.402.915.9-.768.835-1.42-1.3Z"
                                    fill="#a3a5b3"
                                />
                                <path
                                    data-name="Path 100132"
                                    d="m46.246 18.387-.753 1.585 1.116.523.753-1.585a.5.5 0 0 0-.243-.672l-.2-.093a.51.51 0 0 0-.677.241Z"
                                    fill="#d6d8e6"
                                />
                                <path
                                    data-name="Path 100133"
                                    d="m43.141 26.067.229.109a.463.463 0 0 0 .617-.214l.105-.216a.458.458 0 0 0-.215-.612l-.229-.109a.463.463 0 0 0-.617.214l-.105.216a.458.458 0 0 0 .215.612Z"
                                    fill="#a5a6bf"
                                />
                            </g>
                            <path
                                data-name="Path 100134"
                                d="M47.412 5.788a12.287 12.287 0 0 1-.855-4.041h.021a.281.281 0 1 0-.253-.16 11.363 11.363 0 0 0 .134 3.641l-.939.612.49.612 1.4-.668Z"
                                fill="#bcbecc"
                            />
                            <path
                                data-name="Path 100135"
                                d="m45.837 8.533-.827-1.742.953-.446.827 1.742Z"
                                fill="#636473"
                            />
                            <path
                                data-name="Path 100136"
                                d="m44.449 5.587-1.3-2.746.952-.446 1.305 2.746Z"
                                fill="#2c2c33"
                            />
                            <path
                                data-name="Path 100137"
                                d="m45.048 7.115-.335-.705 1.118-.524.335.705Z"
                                fill="#d6d8e6"
                            />
                            <path
                                data-name="Path 100138"
                                d="M44.301 6.336 44.141 6a.416.416 0 0 1 .2-.555l.969-.455a.421.421 0 0 1 .559.2l.16.336a.416.416 0 0 1-.2.555l-.969.455a.421.421 0 0 1-.559-.2Z"
                                fill="#40404a"
                            />
                            <g data-name="Group 107080" transform="translate(44.888 8.838)">
                                <path
                                    data-name="Path 100139"
                                    d="M5.578.63 1.385.53a.711.711 0 0 0-.73.689l-.006.212a.706.706 0 0 0 .69.709l4.239.1V.627Z"
                                    fill="#575757"
                                />
                                <rect
                                    data-name="Rectangle 55692"
                                    width={1.35}
                                    height={9.156}
                                    rx={0.675}
                                    transform="rotate(-91.66 4.72 4.346)"
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 100140"
                                    d="m5.578 9.185-4.193.1a.711.711 0 0 1-.73-.689l-.006-.212a.706.706 0 0 1 .69-.709l4.239-.1v1.613Z"
                                    fill="#575757"
                                />
                                <path
                                    data-name="Path 100141"
                                    d="m1.272 1.734.915-.9-.768-.836-1.42 1.3Z"
                                    fill="#a3a5b3"
                                />
                            </g>
                            <path
                                data-name="Path 100142"
                                d="m45.443 18.113.356-.088a.577.577 0 0 0 .429-.569v-7.323a.6.6 0 0 0-.429-.591l-.363-.151c-.057-.017-.109-.068-.168-.068h-.144a.717.717 0 0 0-.638.48l-.931 2.964v2.054l.931 2.879a.641.641 0 0 0 .638.4h.144c.059-.004.117.033.175.013Z"
                                fill="#8a8a8a"
                            />
                            <g data-name="Group 107081" transform="translate(44.523 9.227)">
                                <ellipse
                                    data-name="Ellipse 40521"
                                    cx={0.926}
                                    cy={0.919}
                                    rx={0.926}
                                    ry={0.919}
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 100143"
                                    d="m1.368 1.432-.679.125-.449-.521.231-.647.679-.125.449.521Z"
                                    fill="#e6e6e6"
                                />
                            </g>
                            <g data-name="Group 107082" transform="translate(44.523 16.455)">
                                <ellipse
                                    data-name="Ellipse 40522"
                                    cx={0.926}
                                    cy={0.919}
                                    rx={0.926}
                                    ry={0.919}
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 100144"
                                    d="m1.368 1.432-.679.125-.449-.521.231-.647.679-.125.449.521Z"
                                    fill="#e6e6e6"
                                />
                            </g>
                            <path
                                data-name="Path 100145"
                                d="m46.246 9.586-.753-1.585 1.116-.523.753 1.585a.5.5 0 0 1-.243.672l-.2.093a.51.51 0 0 1-.677-.241Z"
                                fill="#d6d8e6"
                            />
                            <path
                                data-name="Path 100146"
                                d="m43.14 1.907.229-.109a.463.463 0 0 1 .617.214l.105.216a.458.458 0 0 1-.215.612l-.229.109a.463.463 0 0 1-.617-.214l-.105-.216a.458.458 0 0 1 .215-.612Z"
                                fill="#a5a6bf"
                            />
                            <g data-name="Group 107083" fill="#2c2c33">
                                <path
                                    data-name="Path 100147"
                                    d="m46.154 6.731 1.237-.8a1.043 1.043 0 0 0 .458-.759l.266-2.014.251.033-.266 2.011a1.291 1.291 0 0 1-.569.938l-1.237.805Z"
                                />
                                <path
                                    data-name="Path 100148"
                                    d="M47.706 3.666 48.132 0a.711.711 0 0 1 .628.787l-.262 2.255a.714.714 0 0 1-.792.624Z"
                                />
                                <path
                                    data-name="Path 100149"
                                    d="m46.274 7.095-.252-.385.719-.465.252.385Z"
                                />
                            </g>
                            <g data-name="Group 107084" fill="#2c2c33">
                                <path
                                    data-name="Path 100150"
                                    d="m46.154 21.113.139-.21 1.237.8a1.3 1.3 0 0 1 .571.942l.265 2.008-.251.033-.266-2.011a1.043 1.043 0 0 0-.46-.763Z"
                                />
                                <path
                                    data-name="Path 100151"
                                    d="m47.706 24.179.426 3.666a.711.711 0 0 0 .628-.787l-.262-2.255a.714.714 0 0 0-.792-.624Z"
                                />
                                <path
                                    data-name="Path 100152"
                                    d="m46.274 20.748-.252.385.719.465.252-.384Z"
                                />
                            </g>
                            <g data-name="Group 107085" transform="translate(42.546 12.757)">
                                <ellipse
                                    data-name="Ellipse 40523"
                                    cx={1.094}
                                    cy={1.086}
                                    rx={1.094}
                                    ry={1.086}
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 100153"
                                    d="m1.612 1.692-.8.148-.531-.617.272-.764.8-.148.531.617Z"
                                    fill="#e6e6e6"
                                />
                            </g>
                            <g data-name="Group 107088">
                                <path
                                    data-name="Path 100154"
                                    d="M1.425 15.045c-.289 0-.523-.6-.523-1.338s.234-1.338.523-1.338.523.6.523 1.338-.234 1.338-.523 1.338Z"
                                    fill="#f21c00"
                                />
                                <g data-name="Group 107087">
                                    <path
                                        data-name="Path 100155"
                                        d="M1.696 13.453a27.158 27.158 0 0 0-.033 1.537.278.278 0 0 1-.023.11q.1 0 .2.005c1.321 0 2.392-.626 2.392-1.4s-1.071-1.4-2.392-1.4c-.063 0-.126 0-.189.005a9.419 9.419 0 0 1 .045 1.143Z"
                                        fill="#fcfcfc"
                                    />
                                    <g
                                        data-name="Group 107086"
                                        clipPath="url(#d)"
                                        transform="translate(1.636 12.313)"
                                    >
                                        <path
                                            data-name="Path 100156"
                                            d="M.059 2.18a6.683 6.683 0 0 0 2.691-.481c1.046-.534.064-.2.064-.2a14.863 14.863 0 0 1-2.723-.02c-.715-.165-.032.705-.032.705Z"
                                            fill="#fcfcfc"
                                        />
                                    </g>
                                </g>
                                <path
                                    data-name="Rectangle 55693"
                                    fill="#283242"
                                    d="M1.332 15.125V12.29h.512v2.835z"
                                />
                            </g>
                            <path
                                data-name="Path 100158"
                                d="m54.14 17.192-7.405.237a.209.209 0 0 1-.216-.2.209.209 0 0 1 .2-.22l7.405-.237a.209.209 0 0 1 .216.209.209.209 0 0 1-.2.211Z"
                                fill="#6e6e6e"
                            />
                            <g data-name="Group 107095">
                                <g data-name="Group 107091">
                                    <g data-name="Group 107090">
                                        <path
                                            data-name="Path 100159"
                                            d="M50.739 14.191c.041-1 .047-1.994.047-2.991a.758.758 0 0 1 .033-.215 4.288 4.288 0 0 0-.28-.01c-1.868 0-3.383 1.217-3.383 2.72s1.515 2.72 3.383 2.72c.09 0 .179 0 .267-.009-.034-.738-.1-1.475-.067-2.214Z"
                                            fill="#d6d8e6"
                                        />
                                        <g
                                            data-name="Group 107089"
                                            clipPath="url(#e)"
                                            transform="translate(47.155 10.975)"
                                        >
                                            <path
                                                data-name="Path 100160"
                                                d="M3.581 1.2a7.152 7.152 0 0 0-3.807.937c-1.481 1.039-.09.4-.09.4a15.358 15.358 0 0 1 3.852.038c1.012.319.045-1.375.045-1.375Z"
                                                fill="#e4e6f5"
                                            />
                                        </g>
                                    </g>
                                    <path
                                        data-name="Rectangle 55694"
                                        fill="#283242"
                                        d="M51.25 10.943v5.516h-.725v-5.516z"
                                    />
                                </g>
                                <path
                                    data-name="Path 100162"
                                    d="m49.458 16.755.05-.071a1.557 1.557 0 0 0 .229-.823v-4.084a1.631 1.631 0 0 0-.229-.847l-.054-.113a1.679 1.679 0 0 0-1.426-.824h-.315a1.847 1.847 0 0 0-.879.242l-1.023.585v6l1.023.518a1.791 1.791 0 0 0 .879.175h.315a1.624 1.624 0 0 0 1.43-.757Z"
                                    fill="#a3a6b5"
                                />
                                <g data-name="Group 107094">
                                    <g
                                        data-name="Group 107092"
                                        transform="translate(46.073 10.491)"
                                    >
                                        <ellipse
                                            data-name="Ellipse 40524"
                                            cx={1.606}
                                            cy={1.595}
                                            rx={1.606}
                                            ry={1.595}
                                            fill="#dddeeb"
                                        />
                                        <ellipse
                                            data-name="Ellipse 40525"
                                            cx={1.437}
                                            cy={1.427}
                                            rx={1.437}
                                            ry={1.427}
                                            transform="translate(.169 .168)"
                                            fill="#343442"
                                        />
                                        <path
                                            data-name="Path 100163"
                                            d="M1.433 1.643a.168.168 0 0 1-.062.18h.021a.158.158 0 0 0 .1-.082l1.34-.483-1.4.28a.158.158 0 0 0-.128-.019l-.02.007a.171.171 0 0 1 .149.117Z"
                                            fill="#e2e2ff"
                                        />
                                        <path
                                            data-name="Rectangle 55695"
                                            fill="#a7bdb4"
                                            d="M1.178 1.1v1.171H.945V1.1z"
                                        />
                                        <path
                                            data-name="Path 100164"
                                            d="M.827 2.663.866 2.6l.032.018.032.023-.041.065-.032-.023.023-.031-.023.031-.03-.022ZM.992.426l.071-.035.035.069-.069.034L.993.425Zm.184 2.418.023-.074.074.023-.022.074-.076-.023ZM1.367.298l.079-.012.011.076-.077.011-.013-.076Zm.2 2.615v-.077h.083v.077ZM1.792.367l.011-.076.038.006-.011.076Zm0-.038Zm.145 2.462.074-.023.024.074-.076.023-.023-.074ZM2.144.476l.033-.07.034.017-.033.069-.035-.016ZM2.159.44Zm.119 2.2.064-.041.043.061-.067.045-.042-.065ZM2.424.658l.051-.056.058.055-.055.054Zm.134 1.737.026-.028.023-.031.061.048-.027.036-.026.028-.058-.052ZM2.667.94l.066-.038.039.069-.068.037-.037-.067Zm.087 1.142.03-.071.072.027-.03.074-.072-.03Zm.06-.8.075-.018.018.077-.076.016-.016-.075Zm.033.445.006-.077h.078l-.006.079Z"
                                            fill="#e2e2ff"
                                        />
                                    </g>
                                    <g
                                        data-name="Group 107093"
                                        transform="translate(46.073 13.963)"
                                    >
                                        <ellipse
                                            data-name="Ellipse 40526"
                                            cx={1.606}
                                            cy={1.595}
                                            rx={1.606}
                                            ry={1.595}
                                            fill="#dddeeb"
                                        />
                                        <ellipse
                                            data-name="Ellipse 40527"
                                            cx={1.437}
                                            cy={1.427}
                                            rx={1.437}
                                            ry={1.427}
                                            transform="translate(.169 .168)"
                                            fill="#343442"
                                        />
                                        <path
                                            data-name="Path 100165"
                                            d="M1.433 1.643a.168.168 0 0 1-.062.18h.021a.158.158 0 0 0 .1-.082l1.34-.483-1.4.28a.158.158 0 0 0-.128-.019l-.02.007a.171.171 0 0 1 .149.117Z"
                                            fill="#e2e2ff"
                                        />
                                        <path
                                            data-name="Path 100166"
                                            d="m.968 2.729.038-.068a1.238 1.238 0 0 0 .982.1l.024.074a1.324 1.324 0 0 1-1.044-.1Z"
                                            fill="red"
                                        />
                                        <path
                                            data-name="Path 100167"
                                            d="M1.988 2.757a1.229 1.229 0 0 0 .674-.535l.067.039a1.311 1.311 0 0 1-.717.569Z"
                                            fill="#ffa557"
                                        />
                                        <path
                                            data-name="Path 100168"
                                            d="M.944.475a1.318 1.318 0 0 1 .662-.178 1.3 1.3 0 0 1 1.123 1.966l-.067-.039a1.215 1.215 0 0 0 .174-.627A1.232 1.232 0 0 0 .985.543L.946.476Z"
                                            fill="#e2e2ff"
                                        />
                                    </g>
                                </g>
                                <path
                                    data-name="Path 100169"
                                    d="m45.81 10.819-.251.1v.347l-.585.231v4.649l.585.231v.348l.251.087Z"
                                    fill="#595959"
                                />
                            </g>
                            <path
                                data-name="Path 100170"
                                d="m54.14 10.022-7.405-.237a.209.209 0 0 0-.216.2.209.209 0 0 0 .2.22l7.405.237a.209.209 0 0 0 .216-.209.209.209 0 0 0-.2-.211Z"
                                fill="#6e6e6e"
                            />
                        </g>
                    </g>
                    <rect
                        data-name="Rectangle 55696"
                        width={2.475}
                        height={1.399}
                        rx={0.7}
                        transform="translate(35.323 19.35)"
                        fill="#3a3a40"
                    />
                </g>
            </g>
        </g>
    </svg>
)