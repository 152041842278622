import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import CheckinCardIcon from '../utils/checkInCardIcon'
import { NewCheckInCardStyle } from './style'

export const NewCheckinCard = ({
    handleClick = () => false,
    t = () => false
}) => {
    const classes = NewCheckInCardStyle()
    return (
        <Stack alignItems={"center"} rowGap={2}>
            <CheckinCardIcon />
            <Stack alignItems={"center"}>
                <Typography className={classes.title}>{t("Check In")}</Typography>
                <Typography className={classes.subTitle}>{t("Start your 1st check-in today")}</Typography>
            </Stack>
            <Button variant='contained' fullWidth className={classes.newCheckBtn}
                onClick={handleClick}>{t("New Check In")}</Button>
        </Stack>
    )
}