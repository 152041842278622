import React from 'react'
import { CheckInOutStyle } from './style'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { TimeCard } from './timeCard'
import CheckinoutArrow from '../utils/checkinoutArrow'
import moment from 'moment'
import { format } from 'date-fns'
import { currentTimeZone, gmtPattern } from '../../../utils'

export const CheckInOut = ({
    data = {},
    initialCheckInOut = () => false,
    t = () => false
}) => {
    const classes = CheckInOutStyle()
    const language = localStorage.getItem("i18nextLng")
    var now = moment(new Date()); //todays date
    var end = moment(`${data?.checkin_date} ${data?.checkin_time}`); // another date
    var duration = moment.duration(now.diff(end));

    const [elapsedTime, setElapsedTime] = React.useState(duration.asSeconds())

    React.useEffect(() => {
        const timerInterval = setInterval(() => {
            setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
        // eslint-disable-next-line
    }, []);


    const formatTime = (seconds, key) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const second = seconds % 60;
        switch (key) {
            case "hrs":
                return hours ? String(hours?.toFixed(0)).padStart(2, "0") : "00"
            case "min":
                return minutes ? String(minutes?.toFixed(0)).padStart(2, "0") : "00"
            case "sec":
                return second ? String(second?.toFixed(0)).padStart(2, "0") : "00"

            default:
                break;
        }
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
    };

    return (
        <>
            <Stack className={classes.box} alignItems={"center"} rowGap={1}>
                <Typography className={classes.checkTitle}>{t("Self check in & out")}</Typography>
                <Stack direction={"row"} columnGap={1}>
                    <TimeCard count={data?.checkout_date ? formatTime(0, "hrs") : formatTime(elapsedTime, "hrs")} label={t("Hrs")} />
                    <Stack mt={2}>
                        <Box className={classes.dot}></Box>
                        <Box className={classes.dot} mt={1}></Box>
                    </Stack>
                    <TimeCard count={data?.checkout_date ? formatTime(0, "min") : formatTime(elapsedTime, "min")} label={t("Mins")} />
                    <Stack mt={2}>
                        <Box className={classes.dot}></Box>
                        <Box className={classes.dot} mt={1}></Box>
                    </Stack>
                    <TimeCard count={data?.checkout_date ? formatTime(0, "sec") : formatTime(elapsedTime, "sec")} label={t("Secs")} />
                </Stack>

                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ cursor: "pointer", width: "100%" }} onClick={initialCheckInOut}>
                    <Grid container>
                        <Grid item xs={3.5}>
                            <Typography className={classes.checkin}>{Object.keys(data)?.length > 0 ? `${t("Checked in")} -` : `${t("Check in")} -`}</Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                            {
                                Object.keys(data)?.length > 0 &&
                                <Typography className={classes.timeText}>
                                    {`${format(new Date(`${data?.checkin_date} ${data?.checkin_time}`), "p")} ${format(new Date(`${data?.checkin_date} ${data?.checkin_time}`), "dd MMM yy")} (${format(new Date(), gmtPattern, { timeZone: currentTimeZone })})`}
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                    <Box className={language === "ar" && classes.arArrow} sx={{height: "18px"}}>
                        <CheckinoutArrow />
                    </Box>

                </Stack>


            </Stack>
        </>
    )
}