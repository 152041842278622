import { withNamespaces } from "react-i18next";
import { useStyles } from "./styles";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { BackendRoutes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { useLocation, useHistory } from "react-router";
import useWindowDimensions from "../../utils/useWindowDimensions";
import surveyIcon from './assets/surveyIcon.svg';
import { FORM_ERROR_MESSAGE, INITIAL_DATA_STATE } from "./utils/formUtils";
import { Form } from "./components/form";
import { TitleBar } from "../../components";

const SurveyForm = ({
    t = () => false,
    handleTopBarState = () => false,
}) => {
    const search = useLocation().search;
    const history = useHistory();
    const surveyFormId = new URLSearchParams(search).get("id");
    const size = useWindowDimensions();
    const classes = useStyles({ size });

    const alert = useContext(AlertContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [data, setData] = useState(INITIAL_DATA_STATE);
    const [formState, setFormState] = useState([]);

    useEffect(() => {
        handleTopBarState({ text: t("Survey Form") });

        getSurveyFormData({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSurveyFormData = () => {
        const payload = { id: surveyFormId };
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.survey_get}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            const data = response?.data?.data;

            const status = data?.form_status;

            const survey_details = data?.survey_details;
            const details = {
                id: survey_details?.id,
                title: survey_details?.title ?? "",
                formNo: survey_details?.reference_no ?? "",
            };

            const formElement = survey_details?.survey_element?.length > 0
                ? survey_details?.survey_element
                : [];

            const tempFormState = formElement?.map?.(_ => {
                return {
                    id: _?.id,
                    order: _?.order,
                    type: _?.type,
                    isRequired: Boolean(_?.is_mandatory),
                    label: _?.title ?? "",
                    options: _?.data_type_options?.length > 0 ? _?.data_type_options : [],
                    value: _?.survey_value?.length > 0
                        ? (_?.type === "rating"
                            ? parseInt(_?.survey_value?.[0]?.answer)
                            : _?.survey_value?.[0]?.answer)
                        : "",
                    error: "",
                };
            })?.sort?.((a, b) => a?.order - b?.order);

            setData({ status, details });
            setFormState(tempFormState);

            if (status !== "Submitted") {
                setIsDisabled(false);
            }

            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            alert.setSnack({
                ...alert, open: true, msg: t("Something went wrong"),
                severity: AlertProps?.severity?.error,
            });
        });
    };

    const updateForm = ({ index = 0, type = "", value = "", checked = false }) => {
        if (type === "check_box") {
            if (checked) {
                formState[index].value = [...formState[index].value, value];
            } else {
                formState[index].value = formState[index].value?.filter?.(_ => _ !== value);
            }
        } else {
            formState[index].value = value;
        }
        formState[index].error = "";
        setFormState([...formState]);
    }

    const validate = () => {
        let isValid = true;

        for (let i = 0; i < formState?.length; i++) {
            if (formState?.[i]?.isRequired && formState?.[i]?.value?.length === 0) {
                formState[i].error = t(`${FORM_ERROR_MESSAGE?.[formState[i].type]}`);
                isValid = false;
            }
        }

        if (!isValid) {
            alert?.setSnack?.({
                ...alert, open: true,
                severity: AlertProps?.severity?.error,
                msg: t("Please Fill All Mandatory Fields"),
            });
        }

        setFormState([...formState]);
        setIsDisabled(false);

        return isValid;
    };

    const submitForm = () => {
        setIsDisabled(true);
        if (validate()) {
            const payload = {
                insert_param: formState?.map?.(_ => {
                    return {
                        survey_form_id: surveyFormId,
                        survey_element_id: _?.id,
                        answer: _?.value,
                    }
                }),
            }

            NetworkCall(
                `${config?.api_url}${BackendRoutes?.survey_upsert_values}`,
                NetWorkCallMethods?.post, payload, null, true, false
            ).then(() => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Survey Submitted Successfully"),
                    severity: AlertProps?.severity?.success,
                });
                history?.goBack(-1);
            }).catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert, open: true, msg: t("Something went wrong"),
                    severity: AlertProps?.severity?.error,
                });
                setIsDisabled(false);
            });
        } else {
            return false;
        }
    }

    const goBack = () => {
        history?.goBack(-1)
    };

    return (
        isLoading
            ? <></>
            : <Container className={classes.root} maxWidth="sm">
                <TitleBar text={t("Survey Form")} goBack={goBack} arrow={true} />
                <Box className={classes.formBox} sx={{ height: size?.height - (55 + (data?.status !== "Submitted" && 77)) }}>
                    <Stack className={classes.form}
                        direction={"column"} rowGap={"16px"}>
                        <Stack className={classes.formMiniCard} direction={"row"} columnGap={"16px"} alignItems={"center"}>
                            <img className={classes.surveyIcon} src={surveyIcon} alt="" />
                            <Stack direction={"column"} rowGap={"4px"}>
                                <Typography className={classes.formMiniCardTitle}>
                                    {data?.details?.title}
                                </Typography>
                                <Typography className={classes.formMiniCardFormNo}>
                                    {data?.details?.title}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Form t={t} isDisabled={isDisabled} status={data?.status} list={formState} update={updateForm} />
                    </Stack>
                </Box>
                {data?.status !== "Submitted" &&
                    <Box className={classes.submitButtonBox}>
                        <Button className={classes.submitButton}
                            disabled={isDisabled} fullWidth onClick={submitForm}>
                            <Typography className={classes.submitButtonText}>
                                {t("Submit")}
                            </Typography>
                        </Button>
                    </Box>}
            </Container>
    )
}

export default withNamespaces("survey")(SurveyForm)