
import makeStyles from "@mui/styles/makeStyles";
import {  remCalc,SemiBold,Regular,Italic } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    guestName:{
        color:"#091B29",
        fontFamily:SemiBold,
        fontSize:remCalc(14),
    },
    guestMobile:{
        color:"#091B29",
        fontFamily:Regular,
        fontSize:remCalc(12), 
        marginTop:"2px"
    },
    reason:{
        color:"#091B29",
        fontFamily:Italic,
        fontSize:remCalc(12), 
    },
    guestDetailRoot:{
        boxShadow:(props)=>props?.shadow ? "none":"0px 3px 30px #4354721F",
        backgroundColor:(props)=>props?.shadow ? "#F2F4F7": "white",
        borderRadius:"12px",
        cursor:"pointer",
        
    },
    
}))