import * as React from "react"
const PickupRequestIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 117194">
      <g data-name="icons8-delivery (1)">
        <path
          fill="#ffc107"
          d="M21 17h-7V7a1 1 0 0 1 1-1h4a1.044 1.044 0 0 1 .8.4l3 3.85a.961.961 0 0 1 .2.6V15a2.006 2.006 0 0 1-2 2Z"
          data-name="Path 101892"
        />
        <path
          fill="#9575cd"
          d="M3 17h11V5a2.006 2.006 0 0 0-2-2H3.5A2.476 2.476 0 0 0 1 5.5V15a2.006 2.006 0 0 0 2 2Z"
          data-name="Path 101893"
        />
        <path
          fill="#37474f"
          d="M18.5 14.5A2.5 2.5 0 1 0 21 17a2.5 2.5 0 0 0-2.5-2.5ZM7 14.5A2.5 2.5 0 1 0 9.5 17 2.5 2.5 0 0 0 7 14.5Z"
          data-name="Path 101894"
        />
        <path
          fill="#78909c"
          d="M18.5 15.75A1.25 1.25 0 1 0 19.75 17a1.25 1.25 0 0 0-1.25-1.25Zm-11.5 0A1.25 1.25 0 1 0 8.25 17 1.25 1.25 0 0 0 7 15.75Z"
          data-name="Path 101895"
        />
        <path
          fill="#455a64"
          d="M21 12h-1.8a.454.454 0 0 1-.35-.15l-.7-.7a.454.454 0 0 0-.35-.15H16a.472.472 0 0 1-.5-.5v-3A.472.472 0 0 1 16 7h2.75a.455.455 0 0 1 .4.2l2.25 2.7a.711.711 0 0 1 .1.3v1.3a.472.472 0 0 1-.5.5Z"
          data-name="Path 101896"
        />
        <path
          fill="#fff"
          d="M7.5 4.5A4.5 4.5 0 1 0 12 9a4.481 4.481 0 0 0-4.5-4.5Zm0 5A.5.5 0 1 1 8 9a.472.472 0 0 1-.5.5Z"
          data-name="Path 101897"
        />
        <path
          fill="#37474f"
          d="M8.45 9.25c0-.1.05-.15.05-.25a.968.968 0 0 0-.5-.85V5.5H7v2.65a.968.968 0 0 0-.5.85 1 1 0 0 0 1 1 .451.451 0 0 0 .25-.05L8.9 11.1l.7-.7Zm-.95.25A.5.5 0 1 1 8 9a.472.472 0 0 1-.5.5Z"
          data-name="Path 101898"
        />
      </g>
    </g>
  </svg>
)
export default PickupRequestIcon
