import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Box, Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ExtraBold, LanguageOptions, LocalStorageKeys } from "../../utils";
import PhoneIcon from "../../assets/phoneIcon";
import LanguageSwitchIcon from "../../assets/languageSwitch";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { DialogDrawer } from "../dialogDrawer";
import { AuthContext } from "../../contexts";
import { LanguageSwitcherList } from "../languageSwitcherList";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  grow: {},
  appBar: {
    height: "64px",
    backgroundColor: (props) => props?.primaryBackground ? theme.palette.primary.main : theme.palette.background.tertiary1,
    boxShadow: (props) => !props?.primaryBackground && "0px 2px 6px #00000014"
  },
  navbarimg: {
    height: "30px",
  },
  id: {
    color: "white",
    fontFamily: ExtraBold,
    fontSize: "0.875rem",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
  profileImg: {
    height: 30,
    width: 30,
    cursor: "pointer",
    borderRadius: "50%"
  }
}));

const TopNavBars = (props) => {
  const { t = () => false } = props;
  const classes = useStyles(props);
  const logo = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType)) ? JSON.parse(localStorage.getItem(LocalStorageKeys.accessType)).filter((val) => val.name === "Security Guard").find((item) => item?.company_logo !== null) : null;
  const clientLogo = localStorage.getItem(LocalStorageKeys.clientLogo)
  const history = useHistory(props)
  const userProfile = JSON.parse(localStorage.getItem(LocalStorageKeys.userProfile))
  const auth = React.useContext(AuthContext)
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState("")

  const updateLanguage = (data) => {
    setSelectedLanguage(data);
    auth.setAuth({ ...auth })
    props?.i18n.changeLanguage(data?.value);
    window.location.reload();
  }

  const openLanguageSwitcher = () => {
    let getselectedlag = localStorage.getItem("i18nextLng")
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(true)
  }

  const saveLanguage = (language) => {
    props?.i18n.changeLanguage(language?.value);
    setOpenLanguage(false)
    auth.setAuth({ ...auth })
  };
  return (
    <Container maxWidth="sm" style={{ padding: "0px" }}>
      <Grid
        container
        className={classes.appBar}
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid
          container
          xs={6}
          alignContent={"center"}
          alignItems={"center"}
          style={{ padding: "0px 16px" }}
        >
          <img
            src={logo?.company_logo ?? clientLogo}
            alt="logo"
            className={classes.navbarimg}
          />
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
          xs={6}
          style={{ padding: "0px 16px" }}
        >
          <Box />
          {!props?.hideTopNavIcons &&
            <Stack direction={"row"} columnGap={1} alignItems={"center"}>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => history.push(Routes.audioVideoCall)}
              >
                <PhoneIcon />
              </IconButton>
              {props?.note && (
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={props.handleClicknote}
                >
                  <img src="/images/notificationICON.svg" alt="note" />
                </IconButton>
              )}
              <IconButton
                size="small"
                color="secondary"
                onClick={() => openLanguageSwitcher()}
              >
                <LanguageSwitchIcon />
              </IconButton>
              <Avatar src={userProfile?.image_url} className={classes.profileImg}
                onClick={() => history.push(Routes.profileView)}></Avatar>
            </Stack>
          }

          {(props?.home && !props?.hideTopNavIcons) && (
            <Typography className={classes.id}>
              {props?.agreementNo?.map((val) => {
                return (
                  <>
                    {val?.agreement_no === props?.checkno
                      ? val?.agreement_no
                      : ""}
                  </>
                );
              })}
              <IconButton
                size="small"
                onClick={props.handleClick}
                style={{
                  color: "white",
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Typography>
          )}
        </Grid>
      </Grid>
      <DialogDrawer
        height={"400px"}
        open={openLanguage}
        header={t("Choose Language")}
        header_text_transform={"capitalize"}
        padding={"0px 16px 16px 16px"}
        component={
          <LanguageSwitcherList
            languageOptions={LanguageOptions}
            selectedLanguage={selectedLanguage}
            updateLanguage={updateLanguage}
          />
        }
        onClose={() => setOpenLanguage(false)} />
    </Container>
  );
};

export default withNamespaces("loginPage")(TopNavBars)