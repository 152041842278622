import gql from 'graphql-tag';

// export const yourMutation = gql``;
export const CHECK_IN_OUT = `
mutation {
   security_request_visitors(update:$data,id:$visitor_id){
    id
  }
}
`;

export const VECHICLE_CHECK_IN_OUT = `
mutation {
   security_request(update:$data,id:$request_id){
    id
    allocated_parking_slot
  }
}
`;
export const UPDATE_DELIVERY_STATUS = `
mutation($id: ID, $data: security_requestInput) {
  security_request(
    where: { id: { eq: $id } }
    update: $data

  ) {
    id
  }
}`;
//collocted amount
export const COLLECTED_AMOUNT = `
mutation {
   security_request(update:$data,id:$request_id){
    id
  }
}
`;

//service provider
export const GET_SERVICE_PROVIDER = `
mutation INSERT_SERVICE_PROVIDERS_MASTER(
  $insertServiceProvidersMaster: [swift_in_out_logInput!]
) {
  swift_in_out_log(insert: $insertServiceProvidersMaster) {
    id
  }
}
`
//vendor entry
export const GET_VENDOR_ENTRY = `
mutation {
  swift_in_out_log(insert: $data) {
    id
    check_in_time
    check_out_time
  }
}
`
export const GET_VENDOR_ENTRY_CHECKOUT = `
mutation($id:ID,$data:swift_in_out_logInput) {
swift_in_out_log(
    where: { id: { eq: $id } }
    update: $data
  ) {
    id
  }
}
`
// bulkunit
export const GET_BULK_UNIT = `
mutation($insertData:[swift_in_out_log_unitsInput!]) {
  swift_in_out_log_units(insert: $insertData, ) {
  swift_log_id
    unit_id
  }
}
`
//
export let UPSERT_SWIFT_IN_OUT_LOG = (queryType) => gql`
mutation UPSERT_SWIFT_IN_OUT_LOG {
    swift_in_out_log(
      ${queryType === "update" ? `where: { id: { eq: $swiftInOutID } }` : ``}
      ${queryType === "insert" ? `insert: $insertPayload` : ``}
      ${queryType === "update" ? `update: $updatePayload` : ``}
    ) {
      id
    }
  }`;
//edit for viewdetails
export const EDIT_AND_UPDATE_DETAILS = `
  mutation($id: ID, $data: security_request_visitorsInput) {
  security_request_visitors(
    where: { id: { eq: $id } }
    update: $data
  ) {
    id
  }
}
  `
//edit for vehicle details

export const EDIT_AND_UPDATE_VEHICLE_DETAILS = `mutation($id: ID, $data: security_requestInput) {
  security_request(
    where: { id: { eq: $id } }
    update: $data
  ) {
    id
  }
}`

export const PARKING_SLOT_IS_ACTIVE = `
mutation($id: ID, $data: parking_slotInput) {
  parking_slot(
    where: { id: { eq: $id } }
    update: $data
  ) {
    id
    is_active
  }
}
`

export const LOG_OUT_TOKEN = `
mutation($id:String,$token:String,$data:user_device_tokenInput) {
user_device_token(
    where: { user_profile_id: { eq: $id },token:{eq:$token} }
    update: $data

  ) {
    id
  }
}`;

export const INSERT_PROFILE_IMAGE_BY_CONTACT_ID = `
mutation($insertPayload: [assetsInput!]) {
  assets(insert: $insertPayload) {
    id
  }
}
`;
export const UPDATE = `mutation($userId: String, $update: user_profilesInput) {
  user_profiles(
      update: $update
  where: { user_id: { eq: $userId } }

  ) {
      id
      user_id
      alternative_mobile_no
      email_id
      first_name
      last_name
      mobile_no
  }
}
`;

export const UPDATE_PROFILE_IMAGE_BY_CONTACT_ID = `
mutation($assetsID: ID, $updatePayload: assetsInput) {
  assets(
    where: {
      id: { eq: $assetsID }
      assets_master_type: { id: { eq: 2 } }
      is_active: { eq: true }
    }
    update: $updatePayload

  ) {
    id
  }
}
`;

export const UPDATEADDRESS = `
mutation($contactID: ID, $updatedAddress: contactInput) {
  contact(
    where: { id: { eq: $contactID } }
    update: $updatedAddress

  ) {
    id
    street_1
    street_2
    street_3
    district
    city
    state
    zipcode
    country
    latitude
    longitude
  }
}
`;

export const updateDeviceToken = `
mutation($insert:[user_device_tokenOutput!]) {
 user_device_token(
    insert: $insert

  ) {
   id
  }
}`;


export const UNREADNOTIFICATION = `
mutation($id: String, $updatedData: notification_logInput) {
  notification_log(
    where: { notification_id: { eq: $id } }
    update: $updatedData

  ) {
    id
    is_read
  }
}`;