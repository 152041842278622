import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { serviceStyles } from './style';

export const NoService = (props) => {
    const classes = serviceStyles()
    return (
        <>
            {props?.mobile ?
                <Stack justifyContent={"space-between"} alignItems={"center"} height="100%" textAlign={"center"}>
                    <Grid container className={classes.root}>
                        <Grid item xs={12} className={classes.grid}>
                            <img src="/images/troubleshooting.svg" alt='no_service' />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography className={classes.main}>
                                {props?.main}
                            </Typography>
                            <Typography className={classes.sub}>
                                {props?.sub}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        variant='contained'
                        fullWidth={props?.fullWidth ?? false}
                        className={classes.btn}
                        onClick={props?.onClose}

                    >
                        {props?.buttonName}
                    </Button>
                </Stack> :
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={6} className={classes.grid}>
                        <img src="/images/troubleshooting.svg" alt='no_service' />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.main}>
                            {props?.main}
                        </Typography>
                        <Typography className={classes.sub}>
                            {props?.sub}
                        </Typography>
                        <Button
                            variant='contained'
                            fullWidth={props?.fullWidth ?? false}
                            className={classes.btn}
                            onClick={props?.onClose}

                        >
                            {props?.buttonName}
                        </Button>
                    </Grid>
                </Grid>

            }
        </>
    )

}