import { Box, Typography } from "@mui/material";
// import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold, Regular } from "../../../utils";
import TickImageComponent from "../../../assets/tickImage";
const useStyles = makeStyles((theme) => ({
    list: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E4E8EE",
        padding: '12px',
        cursor: "pointer"
    },
    list2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E4E8EE",
        cursor: "pointer",
        padding: '12px',

    },
    Tittle: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: "#071741",
    },
    contant: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "#4E5A6B",
    },
    expires: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#4E5A6B",
        backgroundColor: "#F2F4F7",
        padding: "5px",
        marginInlineStart: "10px",
        borderRadius: theme.palette.borderRadius
    },
    lease: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    Appartment: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#4E5A6B",
    },
    root: {
        marginTop: (props) => props?.agreementList && "12px",
    },
    box: {
        display: "flex", marginTop: "5px"
    },
    agreementImg: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "6px 6px 0px 6px"
    }
}));
export const LanguageList = (props) => {

    const classes = useStyles(props);
    // const history = useHistory();
    return (
        <div className={classes.root}>
            {
                props?.languageOptions?.map((item) => {
                    return (
                        <>
                            <Box
                                className={classes.list2}
                                onClick={() => props?.updateLanguage(item)}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {/* language name */}
                                    <Box>
                                        <div>
                                            <Typography className={classes.Tittle}>
                                                {item.label}
                                            </Typography>
                                        </div>
                                    </Box>
                                </Box>
                                <Box>
                                    {item.value ===
                                        props?.selectedLanguage?.value && (
                                            <TickImageComponent />
                                        )}
                                </Box>
                            </Box>
                        </>
                    )
                })


            }

        </div>
    );
};
