import { IconButton, InputAdornment, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
    search: {
        "&.MuiOutlinedInput-root": {
            height: '20px !important',
            border: '1px solid #E4E8EE',
            fontFamily: SemiBold,
            '&:focus': {
                border: '1px solid #E4E8EE',
            }
        }
    }
}));

export const Search = (props) => {

    const { t = () => false } = props
    const {
        value,
        onChange,
        placeholder = t("Search")
    } = props

    const classes = useStyles()

    return (
        <TextField size="small" variant="outlined" placeholder={placeholder} style={{ backgroundColor: "white", borderRadius: props?.borderRadius ?? "4px" }} fullWidth
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={classes.search}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small">
                            <img src="/images/search_icon.svg" alt="" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}