import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import CheckInOutTimerIcon from '../utils/checkInOutTimerIcon'
import CIOLocationIcon from '../utils/cioLocation'
import CIOFuncLocationIcon from '../utils/cioFuncLocation'
import { CheckInOutStyle } from './style'
import { format } from 'date-fns'
import { getCurrentLocationDetails } from '../utils/checkInOut'
import { NetworkCall } from '../../../networkcall'
import { config } from '../../../config'
import { AlertProps, NetWorkCallMethods, currentTimeZone, gmtPattern } from '../../../utils'
import { AlertContext } from '../../../contexts'

export const CheckInOutPopup = ({
    handleClick = () => false,
    checkout = false,
    t = () => false,
    checkInOutData = {},
    functionalLocation = {},
    setFuncationalLocation = () => false
}) => {
    const classes = CheckInOutStyle()
    const alert = React.useContext(AlertContext)
    const [address, setAddress] = React.useState({
        in_add: "",
        out_add: ""
    })
    const [loading, setLoading] = React.useState(false)


    React.useEffect(() => {
        setLoading(true)
        getCurrentLocation()
        // eslint-disable-next-line
    }, [])

    const refreshLocation = () => {
        getCurrentLocation()
    }

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                getFunctionLocationDistance(position.coords.latitude, position.coords.longitude)
                if (checkout) {
                    const inAddress = await getCurrentLocationDetails(checkInOutData?.in_lat, checkInOutData?.in_long)
                    const outAddress = await getCurrentLocationDetails(position.coords.latitude, position.coords.longitude)
                    if (inAddress && outAddress) {
                        setAddress({
                            ...address,
                            in_add: inAddress,
                            out_add: outAddress
                        })
                        setLoading(false)
                    }
                } else {
                    const address = await getCurrentLocationDetails(position.coords.latitude, position.coords.longitude)
                    if (address) {
                        setAddress({
                            ...address,
                            out_add: address
                        })
                        setLoading(false)
                    }
                }
            },
            function (error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.warning,
                            msg: "Please enable your location"
                        });
                        break;
                    case error.POSITION_UNAVAILABLE:
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.warning,
                            msg: "Location information is unavailable"
                        });
                        break;
                    case error.TIMEOUT:
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.warning,
                            msg: "The request to get your location timed out"
                        });
                        break;
                    default:
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.warning,
                            msg: "An unknown error occurred"
                        });
                }
            }
        );
    }

    const getFunctionLocationDistance = (lat, long) => {
        const data = {
            latitude: lat,
            longitude: long
        };
        NetworkCall(
            `${config.api_url}/self_check_in_out/get_location_distance`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((res) => {
                setFuncationalLocation(res?.data?.data)
            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    }

    return (
        <>
            <Stack justifyContent={"space-between"} height={"100%"}>

                <Grid container  sx={{ overflow: "auto", height: "300px" }}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <CheckInOutTimerIcon />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack rowGap={"6px"} mt={2} alignItems={"center"}>
                            <Typography className={classes.time} style={{ textAlign: "center", width: "100%" }}>{format(new Date(), "p")} {`(${format(new Date(), gmtPattern, { timeZone: currentTimeZone })})`}</Typography>
                            <Typography className={classes.popupCheckinText}>{`${t("Checking in")} - ${format(new Date(), "dd MMM yy, cccc")}`}</Typography>
                        </Stack>
                    </Grid>
                    {
                        checkout &&
                        <Grid item xs={12} mt={"20px"}>
                            <Grid container>
                                <Grid itme xs={2}>
                                    <CIOLocationIcon />
                                </Grid>
                                <Grid itme xs={10}>
                                    {
                                        loading ?
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography className={classes.locationAddress}>{t("Fetching Location")}</Typography>
                                                <Typography className={classes.refreshText} onClick={() => refreshLocation()}>{t("Refresh")}</Typography>
                                            </Stack> :
                                            <Stack rowGap={"4px"}>
                                                <Typography className={classes.locationAddress}>{`${address?.in_add?.street ?? "-"}, ${address?.in_add?.address}`}</Typography>
                                                <Typography className={classes.popupCheckinText}>{checkout ? t("Checked in location") : t("Checking in location")}</Typography>
                                            </Stack>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} mt={"20px"}>
                        <Grid container alignItems={"center"}>
                            <Grid itme xs={2}>
                                <CIOLocationIcon />
                            </Grid>
                            <Grid itme xs={10}>
                                {
                                    loading ?
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography className={classes.locationAddress}>{t("Fetching Location")}</Typography>
                                            <Typography className={classes.refreshText} onClick={() => refreshLocation()}>{t("Refresh")}</Typography>
                                        </Stack> :
                                        <Stack rowGap={"4px"}>
                                            <Typography className={classes.locationAddress}>{`${address?.out_add?.street ?? "-"}, ${address?.out_add?.address}`}</Typography>
                                            <Typography className={classes.popupCheckinText}>{checkout ? t("Checking out location") : t("Checking in location")}</Typography>
                                        </Stack>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        functionalLocation?.function_location_name?.length > 0 &&
                        <Grid item xs={12}>
                            <Grid container className={classes.functionalLocation} mt={"20px"} alignItems={"center"}>
                                <Grid item xs={2}>
                                    <CIOFuncLocationIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography className={classes.functionPrimaryText}>{(functionalLocation?.distance)?.toFixed(1)} {t("Kms")} <span className={classes.functionSecondaryText}>{t("away from")}</span> {`"${functionalLocation?.function_location_name === null ? "" : functionalLocation?.function_location_name}"`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Box sx={{
                    // position: "absolute",
                    // bottom: 0,
                    // left: 0,
                    width: "100%",
                    padding: "16px 16px 0px 16px"
                }}>
                    <Button variant='contained' className={classes.checkInOutBtn} fullWidth
                        disabled={loading}
                        onClick={() => handleClick()}>{checkout ? t("Check Out") : t("Check In")}</Button>
                </Box>
            </Stack>
        </>
    )
}