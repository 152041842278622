export const nudges_card_constants = ({
    t = () => false,
}) => {
    return {
        weather: {
            type: "weather",
            title: t("Weather"),
            background: "transparent linear-gradient(71deg, #00A2FF 0%, #60EFFF 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#0a82bf",
            light_dash_color: "#71d0ff",
        },
    }
};