import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, remCalc } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: "#404E61",
    },
    radioButtonLabel: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: "#404E61",
    },
    radioButton: {
        color: "#404E61",
        [`&.Mui-checked`]: { color: "#5078E1" },
        [`&.Mui-disabled`]: { color: "#A4B2C1" },
    },
    checkBoxLabel: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: "#404E61",
    },
    checkBox: {
        color: "#404E61",
        [`&.Mui-checked`]: { color: "#5078E1" },
        [`&.Mui-disabled`]: { color: "#A4B2C1" },
    },
}));