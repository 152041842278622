import makeStyles from "@mui/styles/makeStyles";
import { Regular, SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root:{
    background: "linear-gradient(161deg, #2C465A, #091B29)",
    height:(props)=>props?.height,
    padding:"0px"
  },
  logo:{
    borderRadius:"4px",
    height:"24px",
    width:"24px"
  },
  subtitle1:{
    fontFamily:Regular,
    color:"white",
    fontSize:remCalc(12)
  },
  subtitle2:{
    fontFamily:Regular,
    fontSize:remCalc(12)
  },
  avatar:{
    height:"80px",
    width:"80px",
    objectFit:"cover",
    marginTop:"20px"
  },
  personName:{
    fontFamily:SemiBold,
    color:"white",
    fontSize:remCalc(20),
    marginTop:"18px"
  },
  address:{
    fontFamily:Regular,
    color:"white",
    fontSize:remCalc(14),
    marginTop:"8px"
  },
  clearIcon:{
    backgroundColor:"#FF4B4B",
    "&:hover":{
        backgroundColor:"#FF4B4B",
    }
  },
  doneIcon:{
    backgroundColor:"#5AC782",
    "&:hover":{
        backgroundColor:"#5AC782",
    }
  },
  btnRoot:{
    padding:"20px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  alreadyText:{
    alreadyText:{
      fontFamily:SemiBold,
      color:"white",
      fontSize:remCalc(34),
    }
  },
  videoBottomRoot:{
    backgroundColor:"white",
    borderTopLeftRadius:"30px",
    borderTopRightRadius:"30px",
  },
}));