export const GreenBoxArrow = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 107521" transform="translate(-328 -360)">
            <rect
                data-name="Rectangle 55682"
                width={24}
                height={24}
                rx={4}
                transform="translate(328 360)"
                fill="#d2fae0"
            />
            <path
                data-name="icons8-expand-arrow (2)"
                d="M337.5 367.865a.357.357 0 0 0 .11.249l4.386 4.386-4.386 4.384a.357.357 0 1 0 .5.5l4.639-4.639a.357.357 0 0 0 0-.5l-4.639-4.639a.357.357 0 0 0-.614.256Z"
                fill="#5ac782"
                stroke="#5ac782"
                strokeWidth={0.5}
            />
        </g>
    </svg>
)