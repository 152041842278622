import { Button, Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { TextBox } from '../../components';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { ExtraBold, Message, SemiBold } from '../../utils';
import useWindowDimensions from '../../utils/useWindowDimensions';
import PasswordInfo from "../../components/passwordInfo";
import { withNamespaces } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    backgroundImage: {
        backgroundColor: theme.palette.primary.main,
        height: "40vh",
        backgroundImage: `url("images/bgNew.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100% 25%",

    },
    content: {
        height: "60vh",
        width: "100%",
        padding: 20,
        overflow: "auto"

    },
    text: {
        fontSize:"0.9375rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "opx 6px 10px #00000014",
    },
    img: {
        width: "auto",
        height: "21.9px",
        // marginTop: "5px"
    },
    poweredby: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        marginTop: "10px"
    },
    pa: {
        color: theme.typography.color.secondary,
        fontFamily: SemiBold
    },
}))

const InitialState = {
    enterPassword: "",
    confirmPassword: "",
    error: {
        enterPassword: "",
        confirmPassword: "",

    }
}

const SetPassword = ({ t = () => false }) => {

    const classes = useStyles();
    const history = useHistory();
    const [account, setAccount] = React.useState({ ...InitialState })
    const size = useWindowDimensions();


    const updateState = (key, value) => {

        let error = account.error;
        error[key] = "";
        setAccount({ ...account, [key]: value, error })
    }
    const isIamValideToCreateAccount = () => {

        let isValid = true;
        let error = account.error;

        const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
        const is_password_strong = is_password_strong_regex.test(account.enterPassword)

        //Checking enterPassword
        if (account.enterPassword.length === 0) {
            isValid = false;
            error.enterPassword = Message.English.requiredMessage('Password')
        } else if (!Boolean(is_password_strong)) {
            isValid = false;
            error.enterPassword = t("Password is not stong");
        }

        //Checking confirmPassword
        if (account.confirmPassword.length === 0) {
            isValid = false;
            error.confirmPassword = Message.English.requiredMessage('confirm Password')
        }
        if (account.confirmPassword.length > 0) {
            if (account.enterPassword !== account.confirmPassword) {
                isValid = false;
                error.confirmPassword = t("Password does not Match")
            }
        }
        if (account.enterPassword !== "" && account.confirmPassword !== "") {
            if (account.enterPassword === account.confirmPassword) {

                isValid = true;
                history.push(Routes.login)
            }
        }

        setAccount({ ...account, error })
        return isValid;
    }

    const onSendAccountBtnClicked = () => {

        if (isIamValideToCreateAccount()) {
            // backdrop.setBackDrop({ ...backdrop, open: true, message: "Creating account..." });

            //Hit Api

            // backdrop.setBackDrop({ ...backdrop, open: false });
            // alert.setSnack({
            //     ...alert,
            //     open: true,
            //     severity: AlertProps.severity.success,
            //     msg: "account Created Successfully"
            // })
        } else {
            // alert.setSnack({
            //     ...alert,
            //     open: true,
            //     severity: AlertProps.severity.warning,
            //     msg: "Please all the mandatory fields"
            // })



            return false;
        }

    }

    return (
        <>
            <Container maxWidth="sm" style={{ padding: 0 }}>
                <div style={{ height: size?.height }}>
                    <Grid container>
                        <Grid item xs={12} className={classes.backgroundImage} />
                    </Grid>
                    <Grid container className={classes.content}  >
                        <Grid item xs={12} >
                            <br />
                            <img src='images/casagrandLogo.png' alt='casagrandLogo'
                                className={classes.img} />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography className={classes.text}>
                                {t("Set Password")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox value={account.enterPassword}
                                type='password'
                                onChange={(e) => updateState('enterPassword', e.target.value)}
                                label={Message.English.createAccount.enterPassword.label}
                                placeholder={Message.English.createAccount.enterPassword.placeholder}
                                isError={account.error.enterPassword.length > 0}
                                errorMessage={account.error.enterPassword}
                                isRequired />
                            <PasswordInfo />
                            {/* <br /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox value={account.confirmPassword}
                                type='password'
                                onChange={(e) => updateState('confirmPassword', e.target.value)}
                                label={Message.English.createAccount.confirmPassword.label}
                                placeholder={Message.English.createAccount.confirmPassword.placeholder}
                                isError={account.error.confirmPassword.length > 0}
                                errorMessage={account.error.confirmPassword}
                                isRequired />
                            <br />
                            {/* <br />
                            <br /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={classes.btn}
                                onClick={onSendAccountBtnClicked}
                            >
                                {t("Set Password")}
                            </Button>
                            {/* <br /> */}
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    )
}
export default withNamespaces("setPassword")(SetPassword)