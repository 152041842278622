import * as React from "react"

export const Bicycle3 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 96488"
        width={55.104}
        height={34.332}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 52972"
                    fill={props?.color ?? "#98a0ac"}
                    d="M0 0h55.104v34.332H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 96487" clipPath="url(#a)">
            <path
                data-name="Path 8992"
                d="M0 22c.128-.611.226-1.231.39-1.833a11.225 11.225 0 0 1 13.587-7.993c.1.025.21.037.414.071-.16-.425-.3-.8-.44-1.168-.241-.617-.512-1.224-.722-1.852a.616.616 0 0 0-.747-.487 8.9 8.9 0 0 1-1.237 0 1.626 1.626 0 0 1-1.607-1.615 1.605 1.605 0 0 1 1.669-1.6c1.776-.009 3.552 0 5.329 0a1.619 1.619 0 1 1 .147 3.229c-.069.007-.138.019-.267.037.237.591.448 1.157.7 1.706a.483.483 0 0 0 .376.188q6.459.014 12.918 0a.694.694 0 0 0 .492-.3q1.425-2.343 2.8-4.717a.691.691 0 0 0-.027-.606 4.012 4.012 0 0 0-2.981-1.8c-.214-.024-.43-.028-.643-.059a1.592 1.592 0 0 1-1.407-1.733A1.591 1.591 0 0 1 30.429 0a7.269 7.269 0 0 1 5.982 3.184 8.187 8.187 0 0 1 1.271 3.168 30.879 30.879 0 0 0 2.077 6.035c.02.046.064.083.041.054a33.386 33.386 0 0 1 3.471-.567 11.237 11.237 0 1 1-10.622 10.544 11 11 0 0 1 3.964-7.9.611.611 0 0 0 .193-.851c-.494-1.171-.94-2.362-1.406-3.544-.049-.126-.1-.251-.186-.46-.149.237-.253.4-.35.559-2.647 4.453-5.3 8.9-7.93 13.364a1.989 1.989 0 0 1-1.968 1.114c-.84-.04-1.684-.008-2.559-.008-.094.417-.173.818-.275 1.212A11.244 11.244 0 0 1 .1 24.514a2.551 2.551 0 0 0-.1-.359V22m19.054-.538a7.956 7.956 0 0 0-8.754-6.351 8.017 8.017 0 0 0 1.016 15.979c3.765-.045 7.619-3.225 7.707-6.4h-.587c-2.386 0-4.772.008-7.158-.005a1.611 1.611 0 1 1-.006-3.221c1.2-.015 2.4 0 3.606 0h4.177m19.384-4.217a7.911 7.911 0 0 0-.982 10.62 8.011 8.011 0 0 0 13.07-9.26c-2.021-3-6.767-4.574-9.228-3.011.5.9.986 1.821 1.518 2.71a166.483 166.483 0 0 0 2.293 3.7 1.664 1.664 0 0 1-.349 2.417 1.627 1.627 0 0 1-2.321-.593 17.942 17.942 0 0 1-.56-.855c-1.146-1.9-2.287-3.807-3.442-5.731m-9.55-3.3h-10.4a6.853 6.853 0 0 0 .729 1.186 11.56 11.56 0 0 1 2.916 5.126c.1.393.18.793.272 1.206.54 0 1.042-.023 1.541.008a.683.683 0 0 0 .722-.4c1.1-1.888 2.223-3.762 3.338-5.641l.88-1.485"
                fill={props?.color ?? "#98a0ac"}
            />
        </g>
    </svg>
)

