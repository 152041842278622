import * as React from "react"
const CheckInOutCalc = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 117596">
      <path
        fill="#5078e1"
        d="M6.25 3A3.262 3.262 0 0 0 3 6.25v11.5A3.262 3.262 0 0 0 6.25 21h11.5A3.262 3.262 0 0 0 21 17.75V6.25A3.262 3.262 0 0 0 17.75 3Zm0 1.5h11.5a1.739 1.739 0 0 1 1.75 1.75V7h-15v-.75A1.739 1.739 0 0 1 6.25 4.5Zm-1.75 4h15v9.25a1.739 1.739 0 0 1-1.75 1.75H6.25a1.739 1.739 0 0 1-1.75-1.75Zm3.25 2A1.25 1.25 0 1 0 9 11.75a1.25 1.25 0 0 0-1.25-1.25Zm4.25 0a1.25 1.25 0 1 0 1.25 1.25A1.25 1.25 0 0 0 12 10.5Zm4.25 0a1.25 1.25 0 1 0 1.25 1.25 1.25 1.25 0 0 0-1.25-1.25ZM7.75 15A1.25 1.25 0 1 0 9 16.25 1.25 1.25 0 0 0 7.75 15ZM12 15a1.25 1.25 0 1 0 1.25 1.25A1.25 1.25 0 0 0 12 15Z"
        data-name="icons8-calendar (12)"
      />
    </g>
  </svg>
)
export default CheckInOutCalc
