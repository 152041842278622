import * as React from "react"
const CheckinoutArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20.759} {...props}>
    <g data-name="Group 117692" transform="translate(2.001 2)">
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.5}
        d="M9.499 6.938a.428.428 0 0 0 .132.3l5.268 5.262-5.268 5.265a.428.428 0 1 0 .605.605l5.563-5.57a.428.428 0 0 0 0-.605l-5.563-5.563a.428.428 0 0 0-.737.306Z"
        data-name="icons8-expand-arrow (2)"
      />
      <rect
        width={20}
        height={20}
        fill="#f2f4f7"
        data-name="Rectangle 57475"
        rx={10}
        transform="translate(-2.001 -2)"
      />
      <g fill="#4e5a6b" data-name="icons8-expand-arrow (2)">
        <path d="M11.483 8c0 .13-.05.25-.142.341l-4.638 4.64a.48.48 0 0 1-.526.109.48.48 0 0 1-.154-.792L10.319 8 6.021 3.703a.477.477 0 0 1-.146-.335v-.011a.48.48 0 0 1 .83-.334L11.34 7.66a.479.479 0 0 1 .142.34Z" />
        <path d="M6 3.357a.359.359 0 0 0 .11.257l4.386 4.387-4.386 4.386a.357.357 0 1 0 .504.505l4.64-4.639a.357.357 0 0 0 0-.505L6.613 3.11A.357.357 0 0 0 6 3.357m-.25 0a.605.605 0 0 1 1.042-.423l4.638 4.638a.603.603 0 0 1 0 .858L6.79 13.069a.603.603 0 0 1-.66.136.604.604 0 0 1-.195-.997L10.143 8 5.934 3.792a.603.603 0 0 1-.184-.42v-.015Z" />
      </g>
    </g>
  </svg>
)
export default CheckinoutArrow
