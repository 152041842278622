import * as React from "react"
const MyOrgIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 116460" transform="translate(-24 -1897)">
      <rect
        width={32}
        height={32}
        fill="#ffecec"
        data-name="Rectangle 58275"
        rx={6}
        transform="translate(24 1897)"
      />
      <g data-name="icons8-organization (1)">
        <path
          fill="#90caf9"
          d="M46.111 1919.667h2.222v-7.778h-7.222v-5h-2.222v5h-7.222v7.778h2.222v-5.556h5v5.556h2.222v-5.556h5Z"
          data-name="Path 101863"
        />
        <path
          fill="#d81b60"
          d="M43.889 1903v5.556h-7.778V1903Z"
          data-name="Path 101864"
        />
        <path
          fill="#2196f3"
          d="M50 1917.444V1923h-5.556v-5.556Zm-14.444 0V1923H30v-5.556Zm7.222 0V1923h-5.556v-5.556Z"
          data-name="Path 101865"
        />
      </g>
    </g>
  </svg>
)
export default MyOrgIcon
