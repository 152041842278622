import * as React from "react"

const GateOne = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53181"
          transform="translate(-22867 -4315)"
          fill="#98a0ac"
          stroke="#98a0ac"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 6442"
      transform="translate(22867 4315)"
      clipPath="url(#a)"
    >
      <path
        d="M-22859.24-4311.998a.688.688 0 0 0-.3.062l-5.049 2.292a.688.688 0 0 0 .514 1.276v13.34h-.229a.688.688 0 1 0 0 1.376h10.091a.688.688 0 1 0 0-1.376h-.229v-4.017l1.376-1.053v-1.73l-1.376 1.048v-7.588a.688.688 0 0 0 .514-1.276l-5.046-2.294a.688.688 0 0 0-.266-.06Zm13.7 4.589a.685.685 0 0 0-.377.14l-.271.207v1.73l1.1-.842a.688.688 0 0 0-.457-1.235Zm-13.72.458a3.442 3.442 0 0 1 3.428 3.211h.012v3.211h-6.879v-3.214h.015a3.442 3.442 0 0 1 3.425-3.211Zm11.7.937-1.376 1.048v1.73l1.376-1.048Zm-2.752 2.1-1.376 1.049v1.73l1.376-1.048Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
)

export default GateOne