import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold, generateImageUrl, img_size } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "12px",
    backgroundColor: (props) => props?.data?.bg,
    cursor: "pointer",
    minHeight: "90px",
  },

  title: {
    fontSize: "0.875rem",
    color: (props) => props?.data?.color,
    fontFamily: Bold,
  },
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  titlecontent: {
    padding: "0px 12px 8px 12px",
  },
}));

export const QuickCard = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          props?.data?.onClick();
        }}
      >
        <div>
          <img src={generateImageUrl(props?.data?.image, img_size.medium_square)} alt="" className={classes.image} />
          <Box
            display="flex"
            alignItems="center"
            className={classes.titlecontent}
          >
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                {props?.data?.name}
              </Typography>
            </Box>
            <Box marginTop="4px">
              <img src={generateImageUrl(props?.data?.arrow, img_size.medium_square)} alt={props?.data?.name} />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
