import makeStyles from "@mui/styles/makeStyles";
import { Bold, Italic, Regular, SemiBold, remCalc } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    statusSection: {
        padding: "12px",
        borderRadius: "12px 12px 0px 0px"

    },
    requestTypeTitle: {
        fontFamily: SemiBold,
        fontSize: remCalc(14),
        color: "#FFFFFF"
    },
    requestDate: {
        fontFamily: Regular,
        fontSize: remCalc(10),
        color: "#FFFFFF"
    },
    requestCardRoot: {
        boxShadow: "0px 3px 30px #4354721F",
        borderRadius: "12px",
        backgroundColor: "white"

    },
    location: {
        fontFamily: Regular,
        color: "#091B29",
        fontSize: remCalc(14)
    },
    unitName: {
        fontFamily: Bold,
        color: "#091B29",
        fontSize: remCalc(16)
    },
    detTitle: {
        color: "#4E5A6B",
        fontFamily: SemiBold,
        fontSize: remCalc(10),
        textAlign: "center"
    },
    detDescription: {
        color: "#091B29",
        fontFamily: SemiBold,
        fontSize: remCalc(14),
        textAlign: "center"
    },
    guestName: {
        color: "#091B29",
        fontFamily: SemiBold,
        fontSize: remCalc(14),
    },
    guestMobile: {
        color: "#091B29",
        fontFamily: Regular,
        fontSize: remCalc(12),
        marginTop: "2px"
    },
    reason: {
        color: "#091B29",
        fontFamily: Italic,
        fontSize: remCalc(12),
    },
    guestDetailRoot: {
        boxShadow: "0px 3px 30px #4354721F",
        backgroundColor: "white",
        borderRadius: "12px",
        cursor: "pointer"
    },
    checkInTitle: {
        fontFamily: SemiBold,
        fontSize: remCalc(12),
        color: "#4E5A6B"
    },
    trackTitle: {
        color: "#091B29",
        fontFamily: SemiBold,
        fontSize: remCalc(14)
    },
    trackDes: {
        color: "#4E5A6B",
        fontFamily: Regular,
        fontSize: remCalc(12)
    },
    trackCardRoot: {
        position: "relative",
        borderLeft: (props) => props?.showBorder ? "1px dashed #5078E1" : "none"
    },
    vehicleCardRoot: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "4px",
        padding: "12px",
        width: "33.33%",
        alignItems: "center",
        cursor: "pointer",
    }

}));