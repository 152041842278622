import * as React from "react"
const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 114124" transform="translate(-329 -53)">
      <rect
        width={30}
        height={30}
        fill="none"
        data-name="Rectangle 44485"
        rx={6}
        transform="translate(329 53)"
      />
      <g data-name="Group 838">
        <path
          fill="none"
          d="M353.98 62.025v.832h-3.792v-.76l1.916-2.064a3.158 3.158 0 0 0 .5-.676 1.345 1.345 0 0 0 .156-.612.736.736 0 0 0-.22-.58.94.94 0 0 0-.644-.2 2.523 2.523 0 0 0-1.532.592l-.344-.756a2.526 2.526 0 0 1 .86-.476 3.22 3.22 0 0 1 1.052-.18 2.026 2.026 0 0 1 1.344.412 1.386 1.386 0 0 1 .5 1.12 2.068 2.068 0 0 1-.208.916 4.059 4.059 0 0 1-.7.956l-1.392 1.476Z"
          data-name="Path 4325"
        />
      </g>
      <path
        fill="#5078e1"
        d="m350.12 75.23-.753.683a2.934 2.934 0 0 1-2.842.597q-3.615-1.107-6.753-6.245t-2.347-8.632a2.718 2.718 0 0 1 1.98-2.029l1.008-.267a2.09 2.09 0 0 1 2.389 1.11l.857 1.775a1.83 1.83 0 0 1-.41 2.188l-1.33 1.177a.688.688 0 0 0-.229.629 7.888 7.888 0 0 0 .94 2.559 7.346 7.346 0 0 0 1.701 2.052.682.682 0 0 0 .626.118l1.91-.566a2.086 2.086 0 0 1 2.234.755l1.194 1.583a1.856 1.856 0 0 1-.175 2.512Z"
        data-name="icons8-call (1)"
      />
    </g>
  </svg>
)
export default PhoneIcon
