import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import { initital_screen_state } from "../../utils/login/utils";
import mob_welcome_background from '../../utils/login/assets/mob_welcome_background.png';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { BackendRoutes, Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { welcomePageUseStyles } from "./style";

const Welcome = ({ t = () => false }) => {
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const firebaseContext = useContext(FirebaseContext);
  const classes_1 = welcomePageUseStyles({ size });
  const history = useHistory();
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);
  React.useEffect(() => {
    getInitialRender();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const img = new Image();

    img.src = screen_state?.mob_welcome_background;

    img.onload = () => {
      setIsBackgroundImageLoading(true);
    };

    img.onerror = () => {
      set_screen_state({ ...screen_state, mob_welcome_background: initital_screen_state?.mob_welcome_background });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen_state?.mob_welcome_background]);

  useEffect(() => {
    if (screen_state?.promotion_page_meta && screen_state?.promotion_page_meta?.is_active) {
      const img = new Image();

      img.src = screen_state?.promotion_page_meta?.mobile_background;

      img.onload = () => {
        setIsBackgroundImageLoading(true);
      };

      img.onerror = () => {
        set_screen_state({
          ...screen_state,
          promotion_page_meta: {
            ...screen_state?.promotion_page_meta,
            mobile_background: mob_welcome_background
          }
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen_state?.promotion_page_meta]);

  useEffect(() => {
    let alert_msg = localStorage?.getItem?.(LocalStorageKeys?.alert_msg)
    if (alert_msg?.length > 0) {
      alert.setSnack({
        ...alert, open: true, msg: t(alert_msg),
        severity: AlertProps?.severity?.info,
      });
      localStorage?.removeItem?.(LocalStorageKeys?.alert_msg);
    }

    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }

    if (authToken !== null && authToken !== "") {
      history.push(Routes.dashboard);
    } else {
    }
    // eslint-disable-next-line
  }, []);

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            // [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            // [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [_?.promotion_page_meta && "promotion_page_meta"]: _?.promotion_page_meta?.find?.(pp => pp?.app_build?.build_code === config?.app_x_build),
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch(() => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }

  const logIn = () => {
    history.push({
      pathname: Routes.login,
      state: {
        origin: Routes?.welcome
      },
    });
  }

  return (
    is_loading
      ? <></>
      : (screen_state?.promotion_page_meta?.is_active
        ? <Container className={classes_1.mob_right_section_card} maxWidth="sm"
          sx={{
            backgroundImage: isBackgroundImageLoading
              ? `url(${screen_state?.promotion_page_meta?.mobile_background
              ?? (mob_welcome_background)})`
              : "none",
            backgroundColor: '#757575',
          }}>
          <Grid container className={classes_1.mob_content_welcome}>
            <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_items}>
              <Stack direction={"column"} alignItems={"start"}>
                <img className={classes_1.mob_logo} src={screen_state?.logo} alt={t("logo")} />
                <Box height={"20px"} />
                <Typography className={classes_1.mob_description}>
                  {screen_state?.promotion_page_meta?.description
                    ?? (t("Be On Top of Your Visitor Management With Ease"))}
                </Typography>
                <Box height={"40px"} />
                <Button className={classes_1.mob_set_up_new_button} onClick={logIn}>
                  <Typography className={classes_1.mob_set_up_new_button_text}>
                    {screen_state?.promotion_page_meta?.cta_text ?? t("Get Start / Log In")}
                  </Typography>
                </Button>
                <Box height={"20px"} />
                <Stack direction={"row"} width={"100%"} display={"flex"}
                  justifyContent={"center"} alignItems={"center"}>
                  <img style={{ height: "11px", objectFit: "contain" }}
                    src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        : <Container className={classes_1.mob_right_section_card} maxWidth="sm"
          sx={{
            backgroundImage: isBackgroundImageLoading
              ? `url(${mob_welcome_background})`
              : "none",
            backgroundColor: '#757575',
          }}>
          <Grid container className={classes_1.mob_content_welcome}>
            <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_items}>
              <Stack direction={"column"} alignItems={"start"}>
                <img className={classes_1.mob_logo} src={screen_state?.logo} alt={t("logo")} />
                <Box height={"20px"} />
                <Typography className={classes_1.mob_description}>
                  {t("Be On Top of Your Visitor Management With Ease")}
                </Typography>
                <Box height={"40px"} />
                <Button className={classes_1.mob_set_up_new_button} onClick={logIn}>
                  <Typography className={classes_1.mob_set_up_new_button_text}>
                    {t("Get Start / Log In")}
                  </Typography>
                </Button>
                <Box height={"20px"} />
                <Stack direction={"row"} width={"100%"} display={"flex"}
                  justifyContent={"center"} alignItems={"center"}>
                  <img style={{ height: "11px", objectFit: "contain" }}
                    src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>)
  );
};
export default withNamespaces("welcome")(Welcome);