import { Box, Grid, Stack, Typography } from "@mui/material"
import { GreenBoxArrow } from "../../assets/greenBoxArrow"
import { vehicleComponent } from "../../utils"
import { useStyles } from "./style"
import { withNamespaces } from "react-i18next"

const VisitorParking = (props) => {

    const {
        data = {},
        handleOpenView = () => false,
        dimension = {},
        t = () => false
    } = props
    const classes = useStyles(props)

    return <div className={classes.parking_units_div}>
        {data?.length > 0 ?
            <Grid container>
                <Grid container spacing={"16px"}>
                    {data?.map((i) =>
                        <Grid item xs={12}>
                            <Typography className={classes.parking_unit_heading}>
                                {i?.parking_group_name}
                            </Typography>
                            <Grid container spacing={"16px"}>
                                {i?.parking_slots?.map((j) =>
                                    <Grid item onClick={() => handleOpenView(j)}
                                        xs={dimension?.width >= 508 ? 3 :
                                            dimension?.width >= 385 ? 4 :
                                                dimension?.width >= 262 ? 6 : 12}>
                                        <div
                                            className={j?.is_available ?
                                                classes.parking_card_vacant :
                                                classes.parking_card_occupied}>
                                            <Stack direction={"column"}
                                                justifyContent={"center"}
                                                alignItems={"center"}>
                                                {j?.is_available ?
                                                    <>
                                                        <Typography
                                                            className={classes.slot_name_vacant}>
                                                            {j?.parking_no}
                                                        </Typography>
                                                        <Box height={"4px"} />
                                                        <Typography className={classes.vehicle_type}>
                                                            {j?.vehicle_type}
                                                        </Typography>
                                                        <Box height={"8px"} />
                                                        <Typography className={classes.available}>
                                                            {t("Available")}
                                                        </Typography>
                                                        <Box height={"15px"} />
                                                        <GreenBoxArrow />
                                                    </> :
                                                    <>
                                                        <Typography
                                                            className={classes.slot_name_occupied}>
                                                            {j?.parking_no}
                                                        </Typography>
                                                        <Box height={"4px"} />
                                                        <Typography className={classes.booking_id}>
                                                            {j?.reference_id}
                                                        </Typography>
                                                        <Box height={"8px"} />
                                                        {vehicleComponent[j?.vehicle_type]}
                                                        <Box height={"8px"} />
                                                        {j?.number_plate && <div className={classes.vehicle_no_div}>
                                                            <Typography className={classes.vehicle_no}>
                                                                {j?.number_plate}
                                                            </Typography>
                                                        </div>}
                                                    </>}
                                            </Stack>
                                        </div>
                                    </Grid>)}
                            </Grid>
                        </Grid>)}
                </Grid>
            </Grid> :
            <div className={classes.no_data_found_div}>
                <Typography className={classes.no_data_found_text}>{t("No Visitor Parking Found")}</Typography>
            </div>}
    </div>
}
export default withNamespaces("info")(VisitorParking)