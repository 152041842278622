import React from "react";
import withBottombar from "../../HOCs/withbottombar";
import SwiftInOutListVendor from "./vendorList";
class VendorParent extends React.Component {
  render() {
    return <SwiftInOutListVendor />;
  }
}

export default withBottombar(VendorParent, { titleBar: true, text: "Vendor Entry" });
