import React from "react";
import ServiceVendorDetails from "./serviceVendorDetails";

class ServiceVendorDetailsParent extends React.Component {
  render() {
    return <ServiceVendorDetails />;
  }
}

export default ServiceVendorDetailsParent;
