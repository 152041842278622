import React from 'react'
import CIOActiveIcon from '../utils/cioActiveIcon'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { ActivityCardStyle } from './style'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CIOInactiveIcon from '../utils/cioInactiveIcon';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../router/routes';

export const ActivityCard = ({
    checkOut = false,
    title = "",
    subTitle = "",
    data = {},
    checkout = () => false,
    index = 0,
    dataLength = 0,
    t = () => false
}) => {
    const classes = ActivityCardStyle()
    const history = useHistory()
    const language = localStorage.getItem("i18nextLng")
    const [checkInTitle, setCheckInTitle] = React.useState({
        hr: "00",
        min: "00"
    })

    React.useEffect(() => {
        const getTitle = setInterval(() => {
            getCheckInTitle()
        }, (moment(new Date()).format("ss") * 1000) - 60)

        return () => {
            clearInterval(getTitle);
        };
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        getCheckInTitle()
        // eslint-disable-next-line
    }, [])

    const getCheckInTitle = () => {

        const dateString = `${data?.checkin_date} ${data?.checkin_time}`

        const providedDate = parseISO(dateString);
        const currentDate = new Date();

        const timeDifferenceMs = currentDate - providedDate;

        const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));

        setCheckInTitle({
            ...checkInTitle,
            hr: hours,
            min: minutes
        })
    }

    const navigateDetailPage = (detail) => {
        history.push({
            pathname: Routes.checkInOutDetail,
            state: data
        })
    }

    return (
        <Box style={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={"12px"}>
                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    {!checkOut ? <CIOActiveIcon /> : <CIOInactiveIcon />}
                    <Stack>
                        <Typography className={classes.title}>{checkOut ? title : `${checkInTitle?.hr} hrs ${checkInTitle?.min} mins`}</Typography>
                        <Typography className={classes.subTitle}>{subTitle}</Typography>
                    </Stack>
                </Stack>
                <Box onClick={() => navigateDetailPage(data)} className={language === "ar" && classes.arArrow}>
                    <KeyboardArrowRightIcon />
                </Box>
            </Stack>
            {!checkOut && <Button variant='outlined' className={classes.checkoutBtn} fullWidth onClick={() => {
                getCheckInTitle()
                checkout(data)
            }}>{t("Check Out")}</Button>}
            {
                dataLength - 1 !== index &&
                <Box mt={2} mb={2}>
                    <Divider />
                </Box>
            }
        </Box >
    )
}