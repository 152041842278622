import { makeStyles } from "@mui/styles";
import { Bold, SemiBold, remCalc } from "../../../utils";

export const EntriesCardStyle = makeStyles((theme) => ({
    card: {
        background: "#fff",
        borderRadius: '8px',
        boxShadow: "0px 3px 30px #00000021",
        width: "100%",
        overflow: "hidden"
    },
    avatarBox: {
        width: 70,
        height: 70,
        borderRadius: 10,
        background: theme.palette.info.light,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    avatar: {
        height: 80,
        width: 50,
        objectFit: "contain !important",
        borderRadius: "10px 10px 0px 0px"
    },
    parkingavatar: {
        height: 80,
        width: 50,
        objectFit: "contain !important",
        borderRadius: "10px 10px 0px 0px"
    },
    avatarIcon: {
        height: 80,
        width: 50,
        objectFit: "contain !important",
        borderRadius: "10px 10px 0px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    cancelled: {
        fontSize: remCalc(12),
        background: theme.palette.background.primary,
        fontFamily: Bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    title1: {
        fontSize: remCalc(16),
        fontFamily: Bold,
        color: theme.typography.color.primary,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "auto"
        // width: "280px"
    },
    title2: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        // width: "130px"
    },
    dateText: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    pending: {
        fontSize: remCalc(12),
        background: theme.palette.info.main,
        fontFamily: Bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    approved: {
        fontSize: remCalc(12),
        background: theme.palette.success.main,
        fontFamily: Bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    rejected: {
        fontSize: remCalc(12),
        background: theme.palette.error.main,
        fontFamily: Bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    tooltipText:{
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
          backgroundColor: "blue",
          border: "2px solid red"
        }
      }
}))

export const listingCardStyle = makeStyles((theme) => ({

    cardRoot:{
        padding:16,
        border:"1px solid #E4E8EE",
        backgroundColor:"white",
        borderRadius:"8px",
        marginTop:"6px",
        width:"100%",
        cursor:"pointer"
    },
    title:{
        fontSize:remCalc(14),
        color:"#091B29",
        fontFamily:SemiBold,
        marginInlineStart:"12px",
        textAlign:"left"
    }
}))