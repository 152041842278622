import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { addMonths, addYears, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subMonths, subYears } from "date-fns";
import moment from 'moment';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { CalendarStyle } from './style';
const CalendarWithTime = ({ handleDateChange = () => false, selectedDate = "" }) => {


    const classes = CalendarStyle()
    const [month, setMonth] = React.useState(selectedDate === "" ? new Date() : selectedDate)
    const [finalDays, setFinalDays] = React.useState([])
    const [time, setTime] = React.useState([])

    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    const next = () => {
        const datebyMonth = addMonths(month, 1)
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setMonth(addMonths(month, 1))
    }

    const prev = () => {
        const datebyMonth = subMonths(month, 1)
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setMonth(subMonths(month, 1))
    }
    // get year
    const nextYear = () => {
        const year = addYears(month, 1)
        const monthStart = startOfMonth(year);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        let finaldays = dateRange(startDate, endDate)
        setFinalDays(finaldays)
        setMonth(addYears(month, 1))

    }
    const prevYear = () => {
        const year = subYears(month, 1)
        const monthStart = startOfMonth(year);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        let finaldays = dateRange(startDate, endDate)
        setFinalDays(finaldays)
        setMonth(subYears(month, 1))
    }
    // Month rendar funtion and state
    function intervals(startString, endString) {
        var start = moment(startString, 'YYYY-MM-DD hh:mm a');
        var end = moment(endString, 'YYYY-MM-DD hh:mm a');

        // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
        // note that 59 will round up to 60, and moment.js handles that correctly
        start.minutes(Math.ceil(start.minutes() / 30) * 30);

        var result = [];

        var current = moment(start);

        while (current <= end) {
            result.push(current.format('HH:mm'));
            current.add(30, 'minutes');
        }

        return result;
    }
    const getDateTime = (seldate, selTime) => {
        handleDateChange(seldate)
    }
    React.useEffect(() => {
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        let finaldays = dateRange(startDate, endDate)
        setFinalDays(finaldays)
        const final = intervals(startOfDay(new Date()), endOfDay(new Date()))
        setTime(final)
        // eslint-disable-next-line
    }, [])
    return (
        <Box className={classes.calendarBox}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <Header next={next} prev={prev} month={month} nextYear={nextYear} prevYear={prevYear} />
                        <Body finaldays={finalDays} month={month} getDateTime={getDateTime} dateTime={selectedDate} time={time} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}


// Calendar header section 
const Header = ({ next = () => false, prev = () => false, month = {}, prevYear = () => false, nextYear = () => false }) => {
    const classes = CalendarStyle()
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ direction: "ltr" }}>

            <IconButton className={classes.iconbutton} onClick={prevYear}>
                <KeyboardDoubleArrowLeft />
            </IconButton>
            <IconButton className={classes.iconbutton} onClick={prev}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography className={classes.headerMonth}>{moment(month).format("MMMM YYYY")}</Typography>
            <IconButton className={classes.iconbutton} onClick={next}>
                <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton className={classes.iconbutton} onClick={nextYear}>
                <KeyboardDoubleArrowRightIcon />
            </IconButton>
        </Stack>
    )
}

// Calendar body section

const Body = ({ finaldays = {}, month = {}, getDateTime = () => false, selected_date = "", dateTime = "" }) => {
    const classes = CalendarStyle()
    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
    return (
        <Box pl={1} pt={1}>
            <Grid container>
                {
                    days.map((day) => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} className={classes.day} >
                                <Box><span>{day}</span></Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container>
                {
                    finaldays?.map((e) => {
                        return (
                            <Grid item xs={1.7} className={moment(e).format("M") === moment(month).format("M") ?
                                classes.date_curr_month : classes.date_not_curr_month} textAlign={"center"}
                                onClick={() => getDateTime(e)}>
                                <Stack className={((moment(e).format("DD MM YY") === moment(dateTime).format("DD MM YY") && (moment(e).format("M YY") === moment(month).format("M YY")))) && classes.today} p={1}>
                                    <Box mt={0.5}><span className={moment(e).format("M") !== moment(month).format("M") && classes.date_hidden} >{moment(e).format("DD")}</span></Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
export default withNamespaces("calendarWithTime")(CalendarWithTime)

