import { makeStyles } from "@mui/styles";
import { SemiBold, remCalc } from "../../utils";

export const CustomSwitchStyle = makeStyles((theme) => ({
    container: {
        padding: "8px",
        borderRadius: "8px",
        background: "#fff",
        boxShadow: "0px 3px 8px #D2D5D938"
    },
    selected:{
        padding: 8,
        textAlign: "center",
        background: theme.palette.primary.main,
        borderRadius: 4,
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: "#fff"
    },
    notSelected:{
        padding: 8,
        textAlign: "center",
        background: "transparent",
        borderRadius: 4,
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    }
}))