import * as React from "react"
const CIOInactiveIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 117573" transform="translate(20150 1079)">
      <rect
        width={40}
        height={40}
        fill="#f5f7fa"
        data-name="Rectangle 55701"
        rx={6}
        transform="translate(-20150 -1079)"
      />
      <g data-name="Group 117572">
        <path
          fill="#98a0ac"
          d="M-20130-1067.5a9 9 0 0 0-8.827 10.752 1.568 1.568 0 0 1 2.518.611 6.735 6.735 0 0 0 11.349 2.124 1.571 1.571 0 0 1 .135-3.137h3.15a1.56 1.56 0 0 1 .548.1 8.948 8.948 0 0 0-8.873-10.45Zm-.225 3.6a.675.675 0 0 1 .675.675v4.275h2.925a.675.675 0 0 1 0 1.35h-3.6a.675.675 0 0 1-.675-.675v-4.95a.675.675 0 0 1 .675-.675Zm-7.545 7.641a.675.675 0 0 0-.641.925 8.988 8.988 0 0 0 16.061 1.573v1.336a.675.675 0 1 0 1.35 0v-3.15a.675.675 0 0 0-.675-.675h-3.15a.675.675 0 1 0 0 1.35h1.58q-.015.022-.028.046a7.641 7.641 0 0 1-13.877-.962.675.675 0 0 0-.62-.443Z"
          data-name="icons8-clock-arrow (1)"
        />
      </g>
    </g>
  </svg>
)
export default CIOInactiveIcon
