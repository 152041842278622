import { Dialog, Divider, Drawer, Grid, Hidden, IconButton, Stack, Typography } from "@mui/material"
import { useStyles } from "../style"
import CloseIcon from "@mui/icons-material/Close";
import { vehicleTypeSVG } from "../../../utils";
import { withNamespaces } from "react-i18next";

const ViewParkingSlot = (props) => {

    const {
        data = {},
        open = false,
        handleOpenView = () => false,
        t = () => false
    } = props

    const classes = useStyles({ vehicle_type: data?.vehicle_type })

    const viewRender = () => {
        return <>
            <Grid container direction="row"
                className={classes.drawer_header_style}>
                <Grid>
                    <Typography className={classes.drawer_header_text_style}>
                        {(data ? (data?.is_available ? t("Empty Slot") : t("Allocated Slot")) : "")}
                    </Typography>
                </Grid>
                <Grid>
                    <IconButton style={{ padding: "0px", }}
                        onClick={() => handleOpenView(null)}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawer_body_style}>
                {data ?
                    (data?.is_available ?
                        <Grid container>
                            <Grid container spacing={"16px"}>
                                <Grid item xs={6}>
                                    <Stack direction={"row"} spacing={"8px"} justifyContent={"space-between"}
                                        className={classes.view_cards_div}>
                                        <Stack direction={"column"} spacing={"8px"}>
                                            <Typography className={classes.label}>
                                                {t("Vehicle")}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {data?.vehicle_type ?? "-"}
                                            </Typography>
                                        </Stack>
                                        <div className={classes.icon_div}>
                                            <img src={vehicleTypeSVG[data?.vehicle_type] ?? "/images/carSVG.svg"} alt="" />
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction={"row"} spacing={"8px"} justifyContent={"space-between"}
                                        className={classes.view_cards_div}>
                                        <Stack direction={"column"} spacing={"8px"}>
                                            <Typography className={classes.label}>
                                                {t("Parking Slot Number")}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {data?.parking_no ?? "-"}
                                            </Typography>
                                        </Stack>
                                        <div className={classes.icon_div}>
                                            <img src="/images/parkingSlotSVG.svg" alt="" />
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid> :
                        <Grid container>
                            <Grid container spacing={"16px"}>
                                <Grid item xs={12}>
                                    <Typography className={classes.value}>
                                        {t("Details")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={"row"} spacing={"8px"} justifyContent={"space-between"}
                                        className={classes.view_cards_div}>
                                        <Stack direction={"column"} spacing={"8px"}>
                                            <Typography className={classes.label}>
                                                {t("Unit ID")}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {data?.unit_name ?? "-"}
                                            </Typography>
                                        </Stack>
                                        <div className={classes.icon_div}>
                                            <img src="/images/unitSVG.svg" alt="" />
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction={"row"} spacing={"8px"} justifyContent={"space-between"}
                                        className={classes.view_cards_div}>
                                        <Stack direction={"column"} spacing={"8px"}>
                                            <Typography className={classes.label}>
                                                {t("Vehicle")}
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {data?.vehicle_type ?? "-"}
                                            </Typography>
                                        </Stack>
                                        <div className={classes.icon_div}>
                                            <img src={vehicleTypeSVG[data?.vehicle_type] ?? "/images/carSVG.svg"} alt="" />
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction={"row"} spacing={"8px"} justifyContent={"space-between"}
                                        className={classes.view_cards_div}>
                                        <Stack direction={"column"} spacing={"8px"}>
                                            <Typography className={classes.label}>
                                                {t("Vehicle No")}.
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {data?.number_plate ?? "-"}
                                            </Typography>
                                        </Stack>
                                        <div className={classes.icon_div}>
                                            <img src="/images/vehicleNoSVG.svg" alt="" />
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.parking_slot_card}>
                                        <Stack direction={"column"} spacing={"8px"}>
                                            <Typography className={classes.label}>
                                                {t("Parking Slot No")}.
                                            </Typography>
                                            <Typography className={classes.parking_slot_value}>
                                                {data?.parking_no ?? "-"}
                                            </Typography>
                                        </Stack>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>) :
                    <></>}
            </Grid>
        </>
    }

    return <>
        <Hidden smDown>
            <Dialog className={classes.dialog}
                fullWidth={true} maxWidth="sm" open={open}
                onClose={() => handleOpenView(null)}>
                <Grid container direction="column"
                    className={classes.dialog_style}>
                    {viewRender()}
                </Grid>
            </Dialog>
        </Hidden>
        <Hidden smUp>
            <Drawer className={classes.drawer}
                anchor="bottom" open={open}
                onClose={() => handleOpenView(null)}>
                <Grid container direction="column"
                    className={classes.bottom_sheet_style}>
                    {viewRender()}
                </Grid>
            </Drawer>
        </Hidden>
    </>
}
export default withNamespaces("info")(ViewParkingSlot)