import * as React from "react"

export const TwoWheel = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 96485"
        width={51.993}
        height={33.591}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 52971"
                    fill={props?.color ?? "#98a0ac"}
                    d="M0 0h51.993v33.591H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 96484" clipPath="url(#a)" fill={props?.color ?? "#98a0ac"}>
            <path
                data-name="Path 8989"
                d="M0 25.138a15.445 15.445 0 0 1 .605-2.08 7.42 7.42 0 0 1 6.578-4.7 7.258 7.258 0 0 1 7.049 3.738c.212.343.393.449.75.294a1.836 1.836 0 0 1 .44-.118 2.437 2.437 0 0 0 2.067-1.561 4.317 4.317 0 0 1 5-2.266 4.581 4.581 0 0 1 .282 8.829 4.541 4.541 0 0 1-5.179-1.916c-.082-.128-.157-.26-.267-.443l-1.852.5a23.93 23.93 0 0 1-.568 2.995 7.58 7.58 0 0 1-7.775 5.154 7.788 7.788 0 0 1-7.02-6.313c-.028-.163-.073-.323-.11-.485v-1.628m11.4-1.774A4.454 4.454 0 0 0 6 21.701a4.57 4.57 0 0 0-2.8 5.393 4.706 4.706 0 0 0 5.117 3.4c2.183-.354 3.96-2.247 3.854-4.145-.132.028-.261.049-.386.083-1.174.317-2.345.644-3.521.953a1.539 1.539 0 0 1-2.029-1.07 1.56 1.56 0 0 1 1.265-1.89c1.286-.353 2.572-.7 3.9-1.064"
            />
            <path
                data-name="Path 8990"
                d="m39.59 19.964-3.121-6.257c-1.587-3.18-3.185-6.355-4.754-9.544a1.777 1.777 0 0 0-1.8-1.115c-1.251.032-2.5.012-3.757.007a1.542 1.542 0 0 1-1.679-1.525A1.539 1.539 0 0 1 26.15 0h3.909a4.518 4.518 0 0 1 4.31 2.682q2.271 4.542 4.528 9.09a.68.68 0 0 0 .725.453c1.489-.025 2.978-.012 4.468-.009a1.537 1.537 0 1 1-.021 3.051h-3.361a3.683 3.683 0 0 0 .139.39c.421.849.868 1.687 1.261 2.548.154.337.324.38.659.308a7.612 7.612 0 1 1-6.091 7.1 7.358 7.358 0 0 1 2.39-5.17c.158-.152.323-.3.522-.478m4.174 1.5c.094.205.165.374.246.538.535 1.076 1.086 2.145 1.605 3.228a1.522 1.522 0 1 1-2.738 1.322c-.626-1.235-1.239-2.477-1.863-3.726a4.5 4.5 0 0 0-.469 5.757 4.584 4.584 0 0 0 5.869 1.435 4.538 4.538 0 0 0 2.3-5.5 4.619 4.619 0 0 0-4.946-3.052"
            />
            <path
                data-name="Path 8991"
                d="M15.741 17.838c-1.679-1.684-3.345-3.322-4.966-5a1.868 1.868 0 0 0-1.539-.637c-1.454.047-2.91.017-4.365.014a1.537 1.537 0 1 1 0-3.051q7.107 0 14.213-.009a2.048 2.048 0 0 0 .865-.2c1.685-.825 3.349-1.693 5.043-2.5a3.865 3.865 0 0 1 1.4-.326c.843-.051 1.692-.028 2.537 0a.624.624 0 0 1 .461.266c1.578 3.127 3.138 6.262 4.715 9.424-.123.075-.232.144-.342.208a6.051 6.051 0 0 0-3.2 5.26 1.506 1.506 0 0 1-1.487 1.594 7.606 7.606 0 0 0-5.146-7.167 7.5 7.5 0 0 0-8.2 2.131"
            />
        </g>
    </svg>
)

