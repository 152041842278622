import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Delivery from "./delivery";
import GatePass from "./gatePass";
import Parking from "./parking";
import SwiftInOut from "./swiftInOut";
import WalkInOut from "./walkIn";
import { useLocation ,useHistory} from "react-router-dom";
import { TitleBar } from "../../components";
import { Routes } from "../../router/routes";
import { Container } from "@mui/material";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ListingEntries = ({ selectedType, text, t = () => false }) => {
  const classes = useStyles();
  const searchURL = useLocation().search;
  const type = new URLSearchParams(searchURL).get("type");
  const history=useHistory()


  const title={
    "1":t("Gate Passes"),
    "2":t("Parking Slot"),
    "3":t("Delivery Collection"),
    "4":t("Shift In & Out"),
    "5":t("Visitor Entry Request")
  }

  const goBack=()=>{
    history.push(Routes.gatePass)
  }
  

  return (
    <Container maxWidth="sm" style={{padding:"0px"}} className={classes.root}>
      <TitleBar text={title[type]} goBack={goBack}/>
      {type === "1" && <GatePass />}
      {type === "2" && <Parking />}
      {type === "3" && <Delivery />}
      {type === "4" && <SwiftInOut />}
      {type === "5" && <WalkInOut />}
    </Container>
  );
};

export default withNamespaces("gatePass")(ListingEntries);