import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderSimmer, TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { GET_VENDOR_ENTRY_CHECKOUT } from "../../graphql/mutations";
import { DOMESTIC_SERVICE_VENDOR_DETAILS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, Bold, enumSelect, enumTypes, ExtraBold, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods, Regular, remCalc, SemiBold } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import SwiftInOutCard from "./swiftInOutCard";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    margin: "12px 10px 8px 14px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
    textTransform: "uppercase"
  },
  // subtitle: {
  //   fontSize: "0.75rem",
  //   color: theme.typography.color.Tertiary,
  //   fontFamily: Bold,
  //   paddingLeft: "12px",
  // },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  userimg: {
    borderRadius: "50%",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  stack: {
    backgroundColor: "#F5F7FA",
    margin: "12px",
  },
  stack1: {
    borderRight: "1px solid #E4E8EE",
  },
  preview: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.Tertiary,
    textAlign: "center",
    marginBottom: "8px",
  },
  input: {
    marginTop: "10px",
  },
  border: {
    borderBottom: "2px solid #E4E8EE",
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  subtitle:{
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
    fontFamily: SemiBold
  },
  subCardtitle:{
    fontSize: remCalc(12),
    fontFamily: Bold,
    color: theme.typography.color.Tertiary,
    textTransform: "uppercase",
    marginLeft: "16px"
  }
}));
const InitialState = {
  name: "",
  mobile: "",
  mail: "",
  idType: "",
  idno: "",
};
const SwiftInOutDetails = (props) => {
  const { t = () => false } = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const [viewDetail] = React.useState({ ...InitialState });
  const [drawer, setDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [detail, setDetail] = React.useState([]);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
  const [enumValue, setEnumValue] = React.useState({
    type: [],

  })
  const [swiftId, setSwiftId] = React.useState("");
  const alert = React.useContext(AlertContext);
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const getPassDetails = () => {
    setLoading(true)
    const data = {
      query: DOMESTIC_SERVICE_VENDOR_DETAILS,
      variables: {
        id: passId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setSwiftId(response?.data?.data?.swift_in_out_log[0]?.id);
        setDetail(response?.data?.data?.swift_in_out_log[0]);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
        setLoading(false)

      });
  };



  // const viewDetails = (data) => {
  //   setDrawer(true);
  //   const InitialState = {
  //     vechicle_number: data?.vechicle_number,
  //     vechicle_type: data?.vechicle_type,
  //     checkOut: data?.actual_out,
  //   };
  //   setVieDetail(InitialState);
  // };

  const checkOut = () => {
    const data5 = {
      query: GET_VENDOR_ENTRY_CHECKOUT,
      variables: {
        id: swiftId,
        data: {
          check_out_by: userProfileId,
          check_out_time: new Date().toISOString(),
          updated_by: userProfileId,
          updated_at: new Date().toISOString(),
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data5,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: t("checked Out"),
        });
        getPassDetails();
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  const renderForm = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("View Vehicle Details")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
        <div
          style={{
            padding: "12px",
            height: size.height - 200,
            overflow: "scroll",
          }}
        >
          <Box
            p={1}
            className={classes.stack}
            display="flex"
            justifyContent="space-around"
          >
            <Box p={1}>
              <Avatar sx={{ width: 90, height: 90 }}>
                <img
                  src={generateImageUrl(viewDetail?.image, img_size.small_square)}
                  alt=""
                  className={classes.userimg}
                />
              </Avatar>
            </Box>
          </Box>
          <Box p={1.5}>
            <div className={classes.input}>
              <TextBox
                label={t("Vechicle Type")}
                disabled
                value={viewDetail?.vechicle_type}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                label={t("Vechicle No")}
                disabled
                value={viewDetail?.vechicle_number}
              />
            </div>
          </Box>
        </div>
        <div style={{ padding: "12px", borderTop: "2px solid #E4E8EE" }}>
          <Button
            variant="contained"
            fullWidth
            className={viewDetail?.checkOut ? classes?.warning : classes.sucess}
            disabled={viewDetail?.checkOut ? true : false}
            size="large"

          // onClick={VechicleCheckOut}
          >
            {t("Check Out")}
          </Button>
        </div>
      </>
    );
  };

  //enum
  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.swift_type])
    let requestSourceMap = result?.swift_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})

    setEnumValue({ ...enumValue, type: requestType })

  }
  React.useEffect(() => {
    getEnum()
    getPassDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      {detail?.visitor_type === "DH" && (
        <TitleBar
          text={t("Domestic Helper")}
          // color="#5078E1"
          goBack={() => history.goBack()}
        />
      )}
      {detail?.visitor_type === "SP" && (
        <TitleBar
          text={t("Service Provider")}
          // color="#5078E1"
          goBack={() => history.goBack()}
        />
      )}
      {detail?.visitor_type === "VE" && (
        <TitleBar
          text={t("Vendor Entry")}
          // color="#5078E1"
          goBack={() => history.goBack()}
        />
      )}

      <div
        className={classes.root}
        style={{ height: size.height - 60, overflow: "scroll" }}
      >
        {loading ?
          <LoaderSimmer count={10} />
          :
          <>
            <div className={classes.cardContent}>
              <Box display="flex" alignItems="center">
                <Box className={classes.img}>
                  <img
                    src="/images/Dh.svg"
                    alt=""
                    style={{ objectFit: "contain", marginTop: "4px" }}
                  />
                </Box>
                <Box flexGrow={1} marginInlineStart="14px">
                  <Typography className={classes.title}>
                    {detail?.visitor_type === "DH" && t("Domestic Helper")}
                    {detail?.visitor_type === "SP" && t("Service Provider")}
                    {detail?.visitor_type === "VE" && t("Vendor Entry")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {t("Requested on")} {moment(detail?.created_at).format("DD MMM ")}
                  </Typography>
                </Box>
                <Box></Box>
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("HELPER")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    {detail?.visitor_type === "DH" && (
                      <>
                        {detail?.domestic_helper_request ?
                          <>
                            {`${detail?.domestic_helper_request?.helper?.name} 
                    (${detail?.domestic_helper_request?.profession[0]?.name})`}
                          </>
                          :

                          detail?.domestic_helper_master?.name
                        }

                      </>
                    )}
                    {detail?.visitor_type === "SP" &&
                      detail?.service_providers_master?.name}
                    {detail?.visitor_type === "VE" && detail?.vendor_master?.name}
                  </Typography>
                  {/* <br /> */}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("Gate Entry")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    {detail?.access_gates_master?.name}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "14px 4px 4px 8px" }}>
              <Typography className={classes.subCardtitle}>
                {detail?.domestic_helper_request
                  ? t("UNIT DETAILS")
                  : t("CHECK IN & CHECK OUT DETAILS")}
              </Typography>
              <Box height="10px" />
              <>
                <SwiftInOutCard data={detail} onClick={checkOut} />
                <Box height="10px" />
              </>
            </div>
          </>
        }
      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("swiftInOutDetails")(SwiftInOutDetails)