import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ListingTypeCard } from "./components/listingTypeCard"
import { useHistory } from "react-router-dom";
import { withBottombar } from "../../HOCs";
import { Box } from "@mui/material";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Requset = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t = () => false } = props
  // Top Tabs Funnctions


  const typeListing = [
    {
      id: 1,
      title: t("Gate Passes"),
      image: "/images/visitorIMG.svg",
      bgColor: "#FFF8E7"
    },
    {
      id: 2,
      title: t("Parking Slot"),
      image: "/images/parkingIMG.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 3,
      title: t("Delivery Collection"),
      image: "/images/delivery_collection_icon.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 4,
      title: t("Shift In & Out"),
      image: "/images/shift_in_out_icon.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 5,
      title: t("Visitor Entry Request"),
      image: "/images/visitor_entry_request_icon.svg",
      bgColor: "#F1F6FF"
    }
  ]

  const onClickCard=(type)=>{
    console.log(type,"kldkld")
    history.push(`${Routes.entries}/?type=${type?.id}`)
  }

  return (
    <div className={classes.root}>

      <Box p={2}>
      {
        typeListing?.map((val) => {
          return <ListingTypeCard details={val} onClick={onClickCard}/>
        })
      }
      </Box>


    </div>
  );
};

export default withNamespaces("gatePass")(withBottombar(Requset, { note: false, hideTopNavIcons: true,bgColor:"#F6F6F6" }));