import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";
import { Bold, Regular, generateImageUrl, img_size } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 12px",
  },
  Cardcontent: {
    [theme.breakpoints.up("sm")]: {
      padding: "8px 12px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "8px 0px",
    },
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  yetto: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
    background: "#5078E11E",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  received: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#FF9340",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  receivedDetail: {
    fontSize: "0.75rem",
    color: "#FF9340",
    fontFamily: Bold,
    background: "#FFF4EB",
    padding: "1px 8px",
    borderRadius: "4px",
    marginTop: "6px",
    display: "inline",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  collected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular,
    [theme.breakpoints.down('sm')]: {
      width: "40px"
    },
  },
  sub2: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular,
    [theme.breakpoints.down('sm')]: {
      width: "70px"
    },
  },
  sub1: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular,
    [theme.breakpoints.down('sm')]: {
      width: "120px"
    },
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
}));

const ServiceCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const language = localStorage.getItem("i18nextLng")

  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(Routes.details + "?passId=" + props?.data?.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex" justifyContent={"space-between"}>
            <Stack direction="row" alignItems="center">
              <Box className={classes.img}>
                <img
                  src={generateImageUrl(props?.data?.img, img_size.small_square)}
                  alt=""
                  style={{ objectFit: "contain", marginTop: "4px" }}
                />
              </Box>
              <Box flexGrow={1} marginInlineStart="10px">
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      noWrap
                    >
                      {t("Yet To Checkout")}
                      {" "}
                      {props?.data?.title}
                    </Typography>
                  </Box>

                  <Box marginInlineStart="10px">
                    {!props?.data?.check_out_time && (
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.yetto}
                      >
                        Yet To Checkout
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box alignItems="center" display="flex" marginTop="4px">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.subtitle1}
                    </Typography>
                  </Box>
                  <Box className={classes.dot} />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub2}
                      noWrap
                    >
                      {props?.data?.subtitle2}
                    </Typography>
                  </Box>
                  <Box className={classes.dot} />
                  <Box>
                    {props?.Title === "Service" ? (
                      <Typography
                        variant="subtitle2"
                        className={classes.sub1}
                        noWrap
                      >
                        {props?.data?.subtitle3}
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        className={classes.sub1}
                        noWrap
                      >
                        &#x202a;{props?.data?.check_out_time
                          ? "Out " +
                          moment(props?.data?.check_out_time)
                            .tz(moment.tz.guess())
                            .format("DD MMM YY hh:mm:a")
                          : "In " +
                          moment(props?.data?.check_in_time)
                            .tz(moment.tz.guess())
                            .format("DD MMM YY hh:mm:a")}&#x202c;
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Stack>
            <Box>
              {language === "ar" ?
                <ArrowBackIosIcon
                  style={{ color: "#98A0AC", fontSize: "14px" }}
                /> :
                <ArrowForwardIosIcon
                  style={{ color: "#98A0AC", fontSize: "14px" }}
                />
              }
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
export default withNamespaces("swiftInOutDetails")(ServiceCard)