import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DialogDrawer, HomepageCard } from "../../components";
import { DashboardSimmer } from "../../components/simmer/dashboardSimmer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, Bold, ExtraBold, LocalStorageKeys, NetWorkCallMethods, Regular, SemiBold, currentTimeZone, gmtPattern } from "../../utils";
import { OtherFeatureCard } from "./component/otherFeatureCard";
import { PickupDeliveryCard } from "./component/pickupDeliveryCard";
import { Announcement } from "./announcement";
// import ExploreCard from "./component/exploreCard";
import PickupRequestIcon from "./utils/pickupRequest";
import DeliveryReceivedIcon from "./utils/deliveryReceived";
import NonDeliveryIcon from "./utils/nonDelivery";
// import FileManagerIcon from "./utils/fileManager";
// import MySurveryIcon from "./utils/mySurvey";
import MyOrgIcon from "./utils/myOrg";
// import ChatIcon from "./utils/chat";
import { NoService } from "../../components/noService";
import { NudgeCard } from "./component/nudgeCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { nudges_card_constants } from "./constant";
import { CheckInOut } from "./component/checkInOut";
import { CheckInOutPopup } from "./component/checkInOutPopup";
import moment from "moment";
import CheckInOutIcon from "../../assets/checkInOutIcon";
import { format } from "date-fns";
// import { WelcomeBanner } from "./component";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    overflow: "hidden",
  },
  msg: {
    fontSize: "0.875rem",
    fontFamily: Regular,
    color: theme.typography.color.primary,
  },

  profileImg: {
    height: "55px",
    width: "55px",
    borderRadius: "50%",
    border: "2px solid #091B29",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
    bottom: "0px",
    backgroundColor: "#091B29",
    borderRadius: "7px",
    color: "white",
    left: "0px",
    right: "0px",
    fontSize: "0.75rem",
    textAlign: "center",
    fontFamily: Bold,
  },
  username: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
  },
  invoice: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: "12px",
    marginTop: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
  },
  unselectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
  },
  menuText: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  menuItem: {
    position: "absolute",
    padding: 0,
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      right: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      left: "58%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  box: {
    border: "1px solid #E4E8EE",
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  text: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.palette.secondary.main,
  },
  pickupDeliveryCard: {
    borderRadius: "12px",
    background: "#fff",
    boxShadow: "0px 3px 30px #4354721F",
    padding: "16px"
  },
  viewAllText: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: "#5078E1",
    cursor: "pointer"
  }
}));
const Dashboard = (props) => {
  const nudge_slide_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    cssEase: "linear",
    arrows: false,
  };
  const { t = () => false } = props;
  const classes = useStyles();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const history = useHistory();
  // const { state } = useLocation()
  // const [decoded, setDecoded] = React.useState(null);
  const [data, setData] = React.useState({});

  const alert = React.useContext(AlertContext);
  const [drawer, setDrawer] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [noServiceOpen, setNoServiceOpen] = React.useState(false)
  const [nudges_card_state, set_nudges_card_state] = React.useState([]);
  const [currentActivity, setCurrentActivity] = React.useState("")
  const [openCheckPopup, setOpenCheckPopup] = React.useState(false)
  const [functionalLocation, setFuncationalLocation] = React.useState("")
  const [controlList, setControlList] = React.useState({
    otherFeaturesList: []
  })
  let companyData = JSON.parse(localStorage.getItem("accessType"));
  // // open dialog
  // const [open, setOpen] = React.useState(state ?? false)
  // // close dialog
  // const closeDrawer = () => {
  //   setOpen(!open)
  // }

  const getDashboardDetails = () => {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      const tempDecoded = jwt_decode(authToken);

      // setDecoded(tempDecoded);
      const data = {
        tenantId: `${config.tenantid}`,
        userId: tempDecoded.id,
      };
      NetworkCall(
        `${config.api_url}/security-dashboard`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setData(response?.data.data);
          localStorage.setItem("user_id", response?.data.data?.userInfo?.id);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
        });
    }
  };
  const getAppControlList = () => {
    const payload = {
      company_id:companyData?.map((val)=>{return val?.company_id})
    }
    NetworkCall(
      `${config.api_url}/company_application_control/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const activeControls = res?.data?.data?.filter((x) => { return (x?.app_build === config.app_x_build || x?.is_common) })?.map((val) => { return val?.title })
      const otherFeaturesList = otherFeatures?.filter((x) => {
        return activeControls.includes(x?.key_name)
      })
      setControlList({ otherFeaturesList: otherFeaturesList })

    }).catch((error) => {
      console.log(error)
    });
  }
  React.useEffect(() => {
    getWeatherData();
    getDashboardDetails();
    getCheckActivity()
    getAppControlList()
    // eslint-disable-next-line
  }, []);

  const TopCarddata = [
    {
      id: 1,
      name: t("Visitors"),
      count: data?.visitors,
      image: "/images/visitorIMG.svg",
      color: "#46B9DA",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "visitors",
      borderColor: "#FFD869",
    },
    {
      id: 2,
      name: t("Workers"),
      count: data?.workers,
      image: "/images/worketIMG.svg",
      color: "#E29336",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "workers",
      borderColor: "#EE6B01",
    },
    {
      id: 3,
      name: t("Parking"),
      count: data?.parking,
      image: "/images/parkingIMG.svg",
      color: "#6248E2",
      link: "/gate_pass",
      redirect: 2,
      redirectLink: "parking",
      borderColor: "#50E6FF",
    },

    {
      id: 4,
      name: t("Vendor"),
      count: data?.vendors,
      image: "/images/vendorIMG.svg",
      color: "#F34276",
      link: "/vendor",

      redirectLink: "vendors",
      borderColor: "#199BE2",
    },
    {
      id: 5,
      name: t("Domestic_Helper"),
      count: data?.domesticHelpers,
      image: "/images/domesticIMG.svg",
      color: "#F34276",
      link: "/gate_pass",
      redirect: 0,
      redirectLink: "domesticHelpers",
      borderColor: "#707B85",
    },
    {
      id: 6,
      name: t("Service_Provider"),
      count: data?.serviceProviders,
      image: "/images/serviceProvider.svg",
      color: "#F34276",
      link: "/swiftinout",
      redirectLink: "serviceProviders",
      borderColor: "#FFD869",
    },
  ];
  // const QuickCarddata = [
  //   {
  //     id: 2,
  //     name: t("Gate_Pass"),
  //     image: "/images/quickaccess2.svg",
  //     color: "#2B478B",
  //     onClick: () => {
  //       history.push("/getpassverification");
  //     },

  //     arrow: "/images/quickaccess2arrow.svg",
  //     bg: "#E9F1FA",
  //   },
  //   {
  //     id: 1,
  //     name: t("Swift_in"),
  //     image: "/images/quickaccess1.svg",
  //     color: "#CF6C20",

  //     onClick: () => setDrawer(true),
  //     arrow: "/images/quickaccess1arrow.svg",
  //     bg: "#FFF8EF",
  //   },
  // ];
  const ActiveEntriesData = [
    {
      id: 1,
      name: t("Visitors"),
      count: data?.overStayVisitors,
      image: "/images/visitorIMG.svg",
      color: "#46B9DA",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "overStayVisitors",
      borderColor: "#FFD869",
    },
    {
      id: 2,
      name: t("Workers"),
      count: data?.overStayWorkers,
      image: "/images/worketIMG.svg",
      color: "#E29336",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "overStayWorkers",
      borderColor: "#EE6B01",
    },
    {
      id: 3,
      name: t("Parking"),

      count: data?.overStayParking,
      image: "/images/parkingIMG.svg",
      color: "#6248E2",
      link: "/gate_pass",
      redirect: 2,
      redirectLink: "overStayParking",
      borderColor: "#50E6FF",
    },

    {
      id: 4,

      name: t("Vendor"),
      count: data?.overStayVendors,
      image: "/images/vendorIMG.svg",
      color: "#F34276",

      link: "/vendor",
      redirectLink: "overStayVendors",
      borderColor: "#199BE2",
    },
    {
      id: 5,
      name: t("Delivery"),
      name1: t("Collections"),
      count: data?.deliveryRequest,
      image: "/images/domesticIMG.svg",
      color: "#F34276",
      link: "/gate_pass",
      redirect: 3,
      redirectLink: "deliveryRequest",
      borderColor: "#707B85",
    },
    {
      id: 6,
      name: t("Service"),
      name1: t("Provider"),
      count: data?.overStayServiceProviders,
      image: "/images/serviceProvider.svg",
      color: "#F34276",

      link: "/swiftinout",
      redirectLink: "overStayServiceProviders",
      borderColor: "#FFD869",
    },
  ];

  const otherFeatures = [

    // {
    //   logo: <FileManagerIcon />,
    //   title: t("File Manager")
    // }
    // ,
    {
      logo: <MyOrgIcon />,
      title: t("My Survey"),
      key_name:"My Survey",
      route: Routes.surveyList
    },
    {
      logo: <MyOrgIcon />,
      title: t("My Org"),
      key_name:"My Org",
      route: Routes.myOrganizations
    },
    {
      logo: <CheckInOutIcon />,
      title: t("Self Check In & Out"),
      key_name:"Self Check In & Out",
      route: Routes.checkInOut
    },
    // {
    //   logo: <ChatIcon />,
    //   title: t("Chat")
    // }
  ]

  const getWeatherData = async () => {
    await new Promise((resolve, reject) => {
      try {
        let weather_nudge_meta_data = JSON?.parse(localStorage?.getItem(LocalStorageKeys?.weather_nudge_meta_data) ?? `{"dismiss":false}`);
        if (!weather_nudge_meta_data?.dismiss && navigator?.geolocation) {
          navigator?.geolocation?.getCurrentPosition?.(
            (_) => {
              getWeatherDetails(_.coords.latitude, _.coords.longitude);
            },
            (err) => {
              set_nudges_card_state([]);
              reject(err);
            });
        } else {
          set_nudges_card_state([]);
        }
        resolve(true);
      } catch (err) {
        set_nudges_card_state([]);
        reject(err);
      }
    });
  }

  const getWeatherDetails = (latitude, longitude, nudges) => {
    let weather_url = `${config.api_url}${BackendRoutes?.weather_get_current_weather}`;
    let payload = { lat: latitude, lon: longitude, };
    NetworkCall(weather_url,
      NetWorkCallMethods?.post, payload, null, true
    ).then((_) => {
      let res = _?.data?.data;

      let weather_data = {
        type: nudges_card_constants({ t, nudge: _ })?.weather?.type,
        title: nudges_card_constants({ t, nudge: _ })?.weather?.title,
        subject: `${res?.name} - ${parseInt(res?.main?.temp)}°C - ${res?.weather?.[0]?.main}`,
        icon: `https://openweathermap.org/img/w/${res?.weather?.[0]?.icon}.png`,
        background: nudges_card_constants({ t, nudge: _ })?.["weather"]?.background,
        dark_dash_color: nudges_card_constants({ t, nudge: _ })?.["weather"]?.dark_dash_color,
        light_dash_color: nudges_card_constants({ t, nudge: _ })?.["weather"]?.light_dash_color,
      };

      set_nudges_card_state([weather_data]);
    }
    ).catch((err) => {
      set_nudges_card_state([]);
    });
  }

  const getCheckActivity = () => {
    const data = {
      "date": moment(new Date()).format("YYYY-MM-DD")
    };
    NetworkCall(
      `${config.api_url}/self_check_in_out/get_current_activity`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((res) => {
        setCurrentActivity(res?.data?.data)
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };


  const handleCheckout = () => {
    let data = {};


    navigator.geolocation.getCurrentPosition(
      async (position) => {
        data = {
          id: currentActivity?.id,
          checkout_date: moment(new Date()).format("YYYY-MM-DD"),
          checkout_time: moment(new Date()).format("kk:mm:ss"),
          out_lat: position.coords.latitude,
          out_long: position.coords.longitude,
          out_distance: functionalLocation?.distance,
          timezone: format(new Date(), gmtPattern, { timeZone: currentTimeZone })
        }

        NetworkCall(
          `${config.api_url}/self_check_in_out/checkout`,
          NetWorkCallMethods.post,
          data,
          null,
          true,
          false
        )
          .then((res) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: res?.data?.data?.message,
            });
            history.push(Routes.checkInOut)
          })
          .catch((err) => {
            console.log(err);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("Something Went Wrong"),
            });
          });
      },
      function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.warning,
              msg: "Please enable your location"
            });
            break;
          case error.POSITION_UNAVAILABLE:
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.warning,
              msg: "Location information is unavailable"
            });
            break;
          case error.TIMEOUT:
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.warning,
              msg: "The request to get your location timed out"
            });
            break;
          default:
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.warning,
              msg: "An unknown error occurred"
            });
        }
      }
    );
  };

  const initialCheckInOut = () => {
    setOpenCheckPopup(true)
  }

  const renderForm = () => {
    return (
      <>
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                {t("Swift_in")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setDrawer(false)}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() =>
                  history.push(
                    `${Routes.getPassVerification}${"?title="}${"Domestic"}`
                  )
                }
              >
                <img src="/images/vendor.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Domestic_Help_Pass")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.vendor)}
              >
                <img src="/images/domestic.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Vendor_Entry")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.swiftInOut)}
              >
                <img src="/images/service.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Service_Provider")}
                </Typography>
              </Box>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };

  return (
    <>
      {load ? (
        <DashboardSimmer />
      ) : (
        <div className={classes.root}>

          <Box
            p={2}
          // height={size?.height - 130}
          // marginRight="-6px"
          // overflow="auto"
          >
            {/* Profile*/}
            {/* <Box display="flex" alignItems="center" marginTop="-8px">
              <Box flexGrow={1}>
                <Typography className={classes.username}>
                  {t("Hi") +
                    "," +
                    (decoded && decoded?.username ? decoded.username : "")}
                </Typography>
                <Typography className={classes.msg}>
                  {t("Welcome_Back")}
                </Typography>
              </Box>
              <Box
                className={classes.relative}
                style={{ cursor: "pointer" }}
                onClick={() => history.push(Routes.profileView)}
              >
                <img
                  src={generateImageUrl(data?.userInfo?.user_image, img_size.small_square)}
                  className={classes.profileImg}
                  alt=""
                />
                <Typography className={classes.absolute}>
                  &nbsp;&nbsp;{t("Security")}&nbsp;&nbsp;
                </Typography>
              </Box>
            </Box> */}

            <Grid container justifyContent="center">
              <Grid item xs={12} alignItems={"center"}>
                <Slider {...nudge_slide_settings}>
                  {nudges_card_state?.map((nudge, i) => {
                    return <NudgeCard t={t} nudge={nudge} list={nudges_card_state} card_index={i} />
                  })}
                </Slider>
              </Grid>
            </Grid>

            {
              (!currentActivity?.is_checked_out && Object.keys(currentActivity)?.length > 0) &&
              <Box mt={2}>
                <CheckInOut data={currentActivity} initialCheckInOut={initialCheckInOut} t={t} />
              </Box>
            }

            {/* Dashboard Card */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mt={"20px"}>
              <Typography className={classes.title}>
                {" "}
                {t("Overall Entries")}
              </Typography>
              {/* <CustomDropDown /> */}
            </Stack>
            <Box height="12px" />
            <Grid container spacing={1.3}>
              {TopCarddata?.map((item) => {
                return (
                  <Grid item xs={4}>
                    <HomepageCard data={item} />
                  </Grid>
                );
              })}
            </Grid>
            {/* <Box height="12px" /> */}
            {/* <div className={classes.invoice}>
              <Typography className={classes.title}>
                {t("Quick_Access")}
              </Typography>
              <Box height="12px" />
              <Grid container spacing={1.3}>
                {QuickCarddata?.map((item) => {
                  return (
                    <Grid item xs={6}>
                      <QuickCard data={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </div> */}
            {/* <Box height="12px" /> */}
            <Box mt={"12px"}>
              <Typography className={classes.title}>
                {" "}
                {t("Pickup & Delivery Management")}
              </Typography>
              <Stack direction={"row"} mt={"12px"}
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent={"space-between"}
                className={classes.pickupDeliveryCard}>
                <PickupDeliveryCard
                  count={data?.pick_up_request}
                  title={t("Pickup Requests")}
                  logo={<PickupRequestIcon />}
                />
                <PickupDeliveryCard
                  count={data?.delivery_received}
                  title={t("Delivery Received")}
                  logo={<DeliveryReceivedIcon />} />
                <PickupDeliveryCard
                  count={data?.non_delivered}
                  title={t("Non Delivered")}
                  logo={<NonDeliveryIcon />} />
              </Stack>
            </Box>
            <Box mt={"20px"}>
              <Box display="flex" justifyContent={"space-between"}>
                <Typography className={classes.title}>{t("Announcements")}</Typography>
                <Box onClick={() => history.push({ pathname: Routes?.notification, state: { tabValue: "2" } })}>
                  <Typography className={classes.viewAllText}>{t("View All")}</Typography>
                </Box>
              </Box>
              <Box mt={1}>
                <Announcement t={t} data={data?.notificationsData} loading={load} />
              </Box>
            </Box>
            <Box height={"16px"} />
            {/* Active Entries Card */}
            <Typography className={classes.title}>
              {t("Overstay_Entries")}
            </Typography>
            <Box height="12px" />
            <Grid container spacing={1.3}>
              {ActiveEntriesData?.map((item) => {
                return (
                  <Grid item xs={4}>
                    <HomepageCard data={item} entries={true} />
                  </Grid>
                );
              })}
            </Grid>

            {controlList?.otherFeaturesList?.length > 0 &&
              <Box mt={"12px"}>
                <Typography className={classes.title}>
                  {" "}
                  {t("Other Features")}
                </Typography>
                <Grid container mt={"12px"} columnSpacing={1} rowGap={1}>
                  {
                    controlList?.otherFeaturesList?.map((data) => {
                      return (
                        <Grid item xs={6} display={"flex"} sx={{ width: "100%" }}>
                          <OtherFeatureCard
                            logo={data?.logo}
                            title={data?.title}
                            handleClick={data?.route ? () => history.push(data?.route) : () => setNoServiceOpen(true)} />
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Box>
            }
            {/* <Box mt={"20px"}>
              <ExploreCard t={t} />
            </Box> */}

          </Box>
          {/* <WelcomeBanner name={decoded.username} open={open} closeDrawer={closeDrawer} /> */}
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={drawer}
              onClose={() => setDrawer(false)}
            >
              {renderForm()}
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={drawer}
              onClose={() => setDrawer(false)}
            >
              {renderForm()}
            </Drawer>
          </Hidden>


          <DialogDrawer
            height={"350px"}
            maxWidth={"sm"}
            open={noServiceOpen}
            header={" "}
            noService={true}
            component={
              <>
                <NoService
                  main={t("This feature is either not enabled or not opted by your organization.")}
                  sub={t("Please contact your administrator for details.")}
                  buttonName={t("Close")}
                  mobile
                  fullWidth
                  onClose={() => setNoServiceOpen(false)}
                />
              </>
            }
            onClose={() => setNoServiceOpen(false)}
          />

          <DialogDrawer
            height={"380px"}
            maxWidth={"sm"}
            open={openCheckPopup}
            header={t("Check Out")}
            component={
              <>
                <CheckInOutPopup
                  handleClick={handleCheckout}
                  checkout={true}
                  t={t}
                  checkInOutData={currentActivity}
                  functionalLocation={functionalLocation}
                  setFuncationalLocation={setFuncationalLocation} />
              </>
            }
            onClose={() => setOpenCheckPopup(false)}
          />
        </div>
      )}
    </>
  );
};
export default withNamespaces("dashboard")(Dashboard);
