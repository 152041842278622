import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SelectBox, SlideButton, TextBox } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  input: {
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
}));
const InitialState = {
  remarks: "",
  gate: "",
  error: {
    remarks: "",
    gate: "",
  },
};
const DetailForm = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const alert = React.useContext(AlertContext);
  const [data, setData] = React.useState({ ...InitialState });
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const isIamValideTostep = () => {
    let isValid = true;
    let error = data.error;
    //Checking Remarks
    if (data.remarks.length === 0) {
      isValid = false;
      error.remarks = t("Remarks is Required");
    }
    //Checking gate
    if (data.gate.length === 0) {
      isValid = false;
      error.gate = t("Gate is Required");
    }

    setData({ ...data, error });
    return isValid;
  };
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  const submit = () => {
    if (isIamValideTostep()) {
      const payload = {
        request_id: props?.type,
        collected_access_gate: data?.gate?.value,
        delivery_status: props?.detail?.delivery_status === "Yet To Receive" ? "Received At Gate" : "Collected",
        updated_by: userProfileId,
        updated_at: new Date().toISOString(),
        delivery_collected_date: props?.detail?.delivery_status === "Yet To Receive" ? undefined : new Date().toISOString(),
        received_date: props?.detail?.delivery_status === "Yet To Receive" ? new Date().toISOString() : undefined,
        delivery_collected_by: props?.detail?.delivery_status === "Yet To Receive" ? userProfileId : undefined,
        is_notify: props?.detail?.delivery_status === "Yet To Receive" ? true : false
      }

      if (props?.detail?.delivery_status === "Yet To Receive") {
        payload["remarks"] = data?.remarks;
      } else {
        payload["collected_remarks"] = data?.remarks;
      }

      NetworkCall(
        `${config.api_url}/security/update_security_pass_status`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          props?.reload();
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={classes.cardContent}>
        <div className={classes.input}>
          <SelectBox
            label={t("Gate Number")}
            placeholder={t("Select Gate")}
            options={props?.gate}
            onChange={(value) => updateState("gate", value)}
            value={data?.gate}
            isError={data?.error?.gate?.length > 0}
            errorMessage={data?.error?.gate}
          />
        </div>
        <div className={classes.input}>
          <TextBox
            multiline
            label={t("Remark")}
            placeholder={t("Enter Remark")}
            value={data?.remarks}
            onChange={(value) => updateState("remarks", value.target.value)}
            isError={data?.error?.remarks?.length > 0}
            errorMessage={data?.error?.remarks}
          />
        </div>
        <div className={classes.input}>
          {/* <Button
            variant="contained"
            fullWidth
            className={classes.submitbtn}
            onClick={submit}
            disabled={props?.detail?.delivery_status === "Collected" ? true : false}
          >
            {props?.detail?.delivery_status === "Received At Gate" &&
              t("Update As Collected")}
            {props?.detail?.delivery_status === "Yet To Receive" &&
              t("Update As Received")}
            {props?.detail?.delivery_status === "Collected" &&
              t("Update As Collected")}
          </Button> */}

          <SlideButton
            mainText={
              (props?.detail?.delivery_status === "Received At Gate") ?
                t("Swipe to Update As Collected") :
                (props?.detail?.delivery_status === "Yet To Receive") ?
                  t("Swipe to Update As Received") :
                  (props?.detail?.delivery_status === "Collected") &&
                  t("Swipe to Update As Collected")
            }
            overlayText={
              (props?.detail?.delivery_status === "Received At Gate") ?
                t("Update As Collected") :
                (props?.detail?.delivery_status === "Yet To Receive") ?
                  t("Update As Received") :
                  (props?.detail?.delivery_status === "Collected") &&
                  t("Update As Collected")
            }
            classList={props?.detail?.delivery_status === "Received At Gate" ? "my-class2" : "my-class1"}
            caretClassList={props?.detail?.delivery_status === "Received At Gate" ? "my-caret-class2" : "my-caret-class1"}
            overlayClassList={props?.detail?.delivery_status === "Received At Gate" ? "my-overlay-class2" : "my-overlay-class1"}
            onSlideDone={submit}
            disabled={props?.detail?.delivery_status === "Collected" ? true : false}
          // disabled={disableButton || (props?.data?.check_in_time !== null && props?.data?.check_out_time !== null && props?.parentData?.entry_type === "single")}
          />
        </div>
      </div>      
    </>
  );
};
export default withNamespaces("deliveryDetails")(DetailForm)