import React from "react"
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import ChattingIcon from "../../../assets/chattingIcon"
import VideoIcon from "../../../assets/videoIcon"
import { withNamespaces } from "react-i18next"
const ManagerCard = (
    {
        t = () => false,
        subTitle,
        title,
        onClick = () => false,
        onClose = () => false,
        hidden = "",
        open = false,
        avatar = "",
        onSelect = () => false,
        dialogData = {},
        hideViewMore = false
    }
) => {
    const classes = useStyles()
    return (
        <Box>
            <Stack direction={"row"} columnSpacing={1} justifyContent={"space-between"} alignItems={"center"} className={classes.rootBox} onClick={onSelect}>

                <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                    <Avatar className={classes.img} src={avatar} />
                    <Stack>
                        <Typography className={classes.title}>{title}</Typography>
                        {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
                        {!hideViewMore && <Typography className={classes.viewMore} onClick={onClick}>{t("View more")}</Typography>}
                    </Stack>
                </Stack>
                <Stack direction={"row"} columnGap={1} justifyContent={"space-between"} alignItems={"center"}>
                    <ChattingIcon />
                    <VideoIcon />
                </Stack>



            </Stack>
            {hidden && <Divider className={classes.divider} />}
        </Box>

    )
}

export default withNamespaces("myOrganizations")(ManagerCard);