import * as React from "react"
const CheckInOutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <radialGradient
        id="b"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#09914a" stopOpacity={0} />
        <stop offset={0.326} stopColor="#098b47" stopOpacity={0.094} />
        <stop offset={0.402} stopColor="#054c27" />
        <stop offset={0.56} stopColor="#077039" />
        <stop offset={1} stopColor="#054925" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#09914a" stopOpacity={0} />
        <stop offset={0.397} stopColor="#098b47" stopOpacity={0.094} />
        <stop offset={0.402} stopColor="#054c27" />
        <stop offset={0.56} stopColor="#077039" />
        <stop offset={1} stopColor="#054925" stopOpacity={0} />
      </radialGradient>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M81.999 182.811h20v20h-20z"
          data-name="Rectangle 58571"
        />
      </clipPath>
    </defs>
    <g data-name="Group 116460" transform="translate(-20 -1893)">
      <rect
        width={32}
        height={32}
        fill="#dfebcd"
        data-name="Rectangle 58276"
        rx={6}
        transform="translate(20 1893)"
      />
      <g
        clipPath="url(#a)"
        data-name="Group 117697"
        transform="translate(-56 1716.189)"
      >
        <g transform="translate(82 184.576)">
          <path
            fill="url(#b)"
            d="M6.41 18.053a8.234 8.234 0 1 1 11.644 0 8.234 8.234 0 0 1-11.644 0Z"
            data-name="Path 101907"
            transform="translate(-2.274 -3.997)"
          />
          <path
            fill="url(#c)"
            d="M2.411 14.056a8.234 8.234 0 1 1 11.644 0 8.234 8.234 0 0 1-11.644 0Z"
            data-name="Path 101908"
            transform="rotate(180 9.096 8.233)"
          />
          <circle
            cx={6.999}
            cy={6.999}
            r={6.999}
            fill="#f2f2f2"
            data-name="Ellipse 131022"
            transform="translate(2.96 1.236)"
          />
          <path
            fill="#d83b01"
            d="M9.959 13.484a.309.309 0 0 1-.309-.309v-4.94a.309.309 0 0 1 .618 0v4.941a.309.309 0 0 1-.309.308Z"
            data-name="Path 101909"
          />
          <path
            fill="#45494d"
            d="M9.959 8.543a.309.309 0 0 1-.218-.527l3.493-3.493a.309.309 0 1 1 .437.437l-3.494 3.493a.306.306 0 0 1-.218.09Z"
            data-name="Path 101910"
          />
          <path
            fill="#45494d"
            d="M9.959 8.749a.512.512 0 0 1-.222-.051L6.531 7.16a.515.515 0 0 1 .445-.928l3.205 1.536a.515.515 0 0 1-.223.979Z"
            data-name="Path 101911"
          />
          <circle
            cx={0.823}
            cy={0.823}
            r={0.823}
            fill="#1e2021"
            data-name="Ellipse 131023"
            transform="translate(9.136 7.411)"
          />
          <path
            fill="#09914a"
            d="m15.126 9.492 2.134-2.768a.338.338 0 0 1 .536 0l2.134 2.768a.338.338 0 0 1-.268.544h-4.269a.338.338 0 0 1-.267-.544Z"
            data-name="Path 101912"
          />
          <path
            fill="#09914a"
            d="m4.874 7.571-2.129 2.768a.338.338 0 0 1-.536 0L.071 7.571a.338.338 0 0 1 .268-.544h4.268a.338.338 0 0 1 .267.544Z"
            data-name="Path 101913"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CheckInOutIcon
