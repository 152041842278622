import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
// import ImageViewer from "react-simple-image-viewer";
import { Bold, Regular, SemiBold, generateImageUrl, img_size, remCalc } from "../../utils";
import { DialogDrawer, SlideButton } from "../../components";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },

  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },

  sub: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: Regular,
    textTransform: "capitalize",
  },

  checksub: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  checkheading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  bottom: {
    padding: "12px",
    marginTop: "-20px",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  checktitle: {
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
    fontFamily: Bold,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "95%",
    overflow: "hidden"
  }
}));

const VisiterCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const timeStringStart = props?.parentData?.work_hour_start; // example time string in HH:MM:SS format
  const timeStringEnd = props?.parentData?.work_hour_end; // example time string in HH:MM:SS format

  const timePartsStart = timeStringStart?.split(":"); // split the string into an array of parts
  const timePartsEnd = timeStringEnd?.split(":"); // split the string into an array of parts

  const workStartHours = parseInt(timePartsStart?.[0], 10); // get the hours as an integer
  const workStartMinutes = parseInt(timePartsStart?.[1], 10); // get the minutes as an integer
  // const workStartSeconds = parseInt(timePartsStart[2], 10);

  const workEndHours = parseInt(timePartsEnd?.[0], 10); // get the hours as an integer
  const workEndMinutes = parseInt(timePartsEnd?.[1], 10); // get the minutes as an integer
  // const workEndSeconds = parseInt(timePartsEnd[2], 10);

  let now = new Date();

  // Create a new Date object for the given time
  let givenStartTime = new Date();
  givenStartTime.setHours(workStartHours); // Set the hours to 10 (for example)
  givenStartTime.setMinutes(workStartMinutes); // Set the minute
  // Create a new Date object for the given time
  let givenEndTime = new Date();
  givenEndTime.setHours(workEndHours); // Set the hours to 10 (for example)
  givenEndTime.setMinutes(workEndMinutes); // Set the minute
  const workerDisableButton = ((now.getTime() < givenStartTime.getTime()) || now.getTime() > givenEndTime.getTime()) ? true : false

  const disableButton = ((new Date() <= new Date(props?.parentData?.request_from)) || (new Date() >= new Date(props?.parentData?.request_to))) ? true : false
  const disabled = props?.type === "WGR" ? (disableButton ? disableButton : ((props?.data?.check_in_time !== null && props?.data?.check_out_time !== null) || props?.data?.check_in_time === null) && workerDisableButton) : (disableButton || (props?.data?.check_in_time !== null && props?.data?.check_out_time !== null && props?.parentData?.entry_type === "single"))


  return (
    <>
      <div className={classes.card}>
        <Box alignItems="center" display="flex" p={2}>
          <Box>
            {(props?.data?.visitor_image || props?.data?.domestic_helpers?.visitor_image) ?
              <Avatar src={(props?.data?.visitor_image || props?.data?.domestic_helpers?.visitor_image) ? generateImageUrl((props?.data?.visitor_image || props?.data?.domestic_helpers?.visitor_image), img_size.small_square) : ""} onClick={() => props?.openImageViewer([props?.data?.visitor_image])}>
              </Avatar>
              :
              <Avatar>
              </Avatar>
            }

            {/* {props?.isViewerOpen && (
              <ImageViewer
                src={props?.currentImage}
                currentIndex={0}
                onClose={props?.closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )} */}
            {
              <DialogDrawer
                open={props?.isViewerOpen}
                onClose={props?.closeImageViewer}
                header={"View"}
                component={
                  <div>
                    <img src={props?.currentImage} alt="" style={{ width: "100%", height: "450px" }} />
                  </div>
                }
              />
            }
            {/* <img
              src={props?.data?.visitor_image}
              alt=""
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                height: "50px",
                width: "50px",
                marginTop: "4px",
              }}
            /> */}
          </Box>
          <Box flexGrow={1} marginInlineStart="10px">
            <Typography variant="subtitle2" className={classes.title}>
              {(props?.data?.name || props?.data?.domestic_helpers?.name)}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.view}
              noWrap
              onClick={() => props.onClick(props.data)}
            >
              {t("View Details")}
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Grid container spacing={1} className={classes.bottom}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.heading}>
              {t("Checked In")}
            </Typography>
            <Box style={{ width: "100%" }}>
              <Typography variant="subtitle2" className={classes.checktitle} >
                {props?.data?.check_in_time ?
                  <>
                    By {props?.data?.check_in_by},
                    &#x202a;{moment(props?.data?.check_in_time)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY  hh:mm:a")}&#x202c;
                  </>
                  : " - "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.heading}>
              {t("Checked Out")}
            </Typography>
            <Typography variant="subtitle2" className={classes.checktitle} >
              {props?.data?.check_out_time ?
                <>
                  {t("By")} {props?.data?.check_out_by},
                  &#x202a;{moment(props?.data?.check_out_time)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY  hh:mm:a")}&#x202c;
                </>
                : " - "}
            </Typography>
          </Grid>
          {props?.data?.check_in_time !== null && !(props?.data?.check_in_time !== null && props?.data?.check_out_time !== null) &&
            <Grid item xs={12}>
              <SlideButton
                mainText={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? t("Swipe to Check In") : t("Swipe to Check Out")}
                overlayText={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? t("Checked In") : t("Checked Out")}
                classList={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "my-class1" : "my-class2"}
                caretClassList={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "my-caret-class1" : "my-caret-class2"}
                overlayClassList={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "my-overlay-class1" : "my-overlay-class2"}
                onSlideDone={() => props.onClick(props?.data)}
                disabled={disabled}
              // disabled={disableButton || (props?.data?.check_in_time !== null && props?.data?.check_out_time !== null && props?.parentData?.entry_type === "single")}
              />
            </Grid>
          }
        </Grid>
      </div>
    </>
  );
};
export default withNamespaces("gatePassDetails")(VisiterCard)