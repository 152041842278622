import { withNamespaces } from "react-i18next";
import { useStyles } from "./style";

const PasswordInfo = ({ t = () => false }) => {

    const classes = useStyles();

    return (
        <div>
            <p className={classes.password_strength} style={{ margin: "0px 0px 6px 2px" }}>
                {t("For strong password")}
            </p>
            <ul className={classes.unordered_list}>
                <li className={classes.password_strength}>
                    {t("Password should contain minimum 8 characters.")}
                </li>
                <li className={classes.password_strength}>
                    {t("Password should contain atleast 1 capital letter")}.
                </li>
                <li className={classes.password_strength}>
                    {t("Password should contain atleast 1 small letter.")}
                </li>
                <li className={classes.password_strength}>
                    {t("Password should contain atleast 1 special character")}.
                </li>
                <li className={classes.password_strength}>
                    {t("Password should contain atleast 1 digit")}.
                </li>
            </ul>
        </div>
    );
};
export default withNamespaces("setupAccount")(PasswordInfo);
