import * as React from "react"
const CIOActiveIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 117573" transform="translate(20150 1079)">
      <rect
        width={40}
        height={40}
        fill="#f1f7ff"
        data-name="Rectangle 55701"
        rx={6}
        transform="translate(-20150 -1079)"
      />
      <g data-name="Group 117572">
        <path
          fill="#78b1fe"
          d="M-20131.25-1069a.75.75 0 1 0 0 1.5h.5v1.038a9.008 9.008 0 1 0 7.616 3.156l.912-.912a.75.75 0 1 0-1.061-1.061l-.912.912a8.954 8.954 0 0 0-5.056-2.1v-1.033h.5a.75.75 0 1 0 0-1.5Zm4.5 7.5a.75.75 0 0 1 .53 1.28l-2.336 2.336A1.5 1.5 0 1 1-20130-1059a1.474 1.474 0 0 1 .384.056l2.336-2.336a.747.747 0 0 1 .53-.22Z"
          data-name="icons8-time (7)"
        />
      </g>
    </g>
  </svg>
)
export default CIOActiveIcon
