import {
    Badge,
    Box,
    IconButton,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { DialogDrawer, LoaderSimmer } from "../../components";
import Filter from "../../components/filter";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Bold, generateImageUrl, img_size, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import InfiniteScroll from "react-infinite-scroll-component";
import { EntriesCard } from "./components/entriesCard";
import { NoData } from "../../assets";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.main,
        padding: "0px",
        overflow: "hidden",
    },
    loader: {
        textAlign: "center",
        marginTop: "50%",
    },
    search: {
        [`& fieldset`]: {
            borderRadius: "10px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "6px 0px 10px 10px",
            fontSize: "0.875rem",
        },
    },
    filter: {
        borderRadius: "6px",
        border: "1px solid #E4E8EE",
        color: "black",
        backgroundColor: "white",
    },
    title: {
        fontSize: "1rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
}));

const WalkInOut = (props) => {
    const { t = () => false } = props;
    const classes = useStyles();
    const size = useWindowDimensions();
    const [state, setState] = React.useState({
        units: [],
        offset: 0,
        list: [],
        status: [{ label: "Approved", value: "Approved" }, { label: "Pending", value: "Pending" }, { label: "Rejected", value: "Rejected" }],
        count: 0
    });
    const [filter, setFilter] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [selectedFilter, setSelectedFilter] = React.useState({
        unit: [],
        status: ["Approved", "Pending", "Rejected"]
    })

    const getPassList = (offset = 0, units, status, setUnit, more = false) => {


        const payload = {
            request_type: ["VGR"],
            offset,
            limit: 10,
            unit_ids: units,
            status
        }
        NetworkCall(
            `${config.api_url}/walkInout/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setState({
                    ...state,
                    units: setUnit?.length > 0 ? setUnit : state?.units,
                    list: more ? [...state?.list, ...res?.data?.data] : res?.data?.data,
                    count: res?.data?.count,
                    offset
                })
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getUnits = () => {

        NetworkCall(
            `${config.api_url}/walkInout/unit_filter`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((res) => {
                getPassList(0, res?.data?.data?.map(i => i?.value), [
                    "Rejected",
                    "Pending",
                    "Approved"
                  ], res?.data?.data)
                setSelectedFilter({ ...selectedFilter, unit: res?.data?.data?.map(i => i?.value) })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //filter json

    const filterData = [
        {
            id: "1",
            title: t("Status"),
            key: "status",
            filterType: "CHECKBOX",
            values: state?.status ?? [],
        },
        {
            id: "2",
            title: t("Unit"),
            key: "unit",
            filterType: "CHECKBOX",
            values: state?.units ?? [],
        }
    ];

    //apply filter
    const applyFilter = (data) => {
        getPassList(0, data?.unit, data?.status)
        setSelectedFilter({
            unit: data?.unit,
            status: data?.status
        })
        setFilter(false)

    };

    const fetchMoreData = () => {
        let offset = state?.offset + 10
        getPassList(offset, selectedFilter?.unit, selectedFilter?.status, [], true)
    };

    React.useEffect(() => {
        getUnits()
        // eslint-disable-next-line
    }, []);

    const returnStatus = (status, check_in, check_out) => {
        if (status === "Rejected") {
            return "Rejected"
        } else if (!check_in && !check_out) {
            return "Yet To CheckIn"
        } else if (check_in && !check_out) {
            return "Yet To Checkout"
        } else if (check_in && check_out) {
            return "Checkout"
        }

    }

    return (
        <>
            {loading ? (
                <LoaderSimmer count={10} />

            ) : (
                <div className={classes.root}>
                    <Box display="flex" alignItems="center" p={1.5}>
                        <Box flexGrow={1}>
                            <Typography className={classes.title}>
                                {t("Visitor Entry Request")} ({state?.count})
                            </Typography>
                        </Box>
                        <Box sx={{ marginInlineStart: "10px" }}>
                            <IconButton
                                className={classes.filter}
                                onClick={() => setFilter(true)}
                            >
                                <Badge variant="dot" color="primary">
                                    <img src="/images/icons8-funnel.svg" alt="filter" />
                                </Badge>
                            </IconButton>
                        </Box>
                    </Box>
                    {
                        state?.list?.length > 0 ?

                            <InfiniteScroll
                                dataLength={state?.list?.length}
                                next={fetchMoreData}
                                hasMore={true}
                                height={size?.height - 110}
                            >
                                <Box p={1.5}>
                                    {state?.list?.map((val) => (
                                        <Box marginBottom={"10px"}>
                                            <EntriesCard
                                                id={val?.id}
                                                imgUrl={generateImageUrl(val?.vendor_image, img_size.small_square)}
                                                title={val?.person_name}
                                                vehicleType={val?.vechicle_type}
                                                count={val?.vechicle_number}
                                                propertyName={val?.property_name}
                                                unitNo={val?.unit_no}
                                                startDateString={`${t("In")} -`}
                                                startDate={val?.check_in_time}
                                                endDateString={`${t("Out")} ${val?.check_in_time ? "-" : ""}`}
                                                endDate={val?.check_out_time}
                                                type="parking"
                                                status={returnStatus(val?.status, val?.check_in_time, val?.check_out_time)}
                                                selectedTab="walkIn"
                                                description={val?.reason}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </InfiniteScroll>
                            :
                            <center>
                                <NoData />
                            </center>
                    }


                    <DialogDrawer
                        is_filter
                        padding={0}
                        anchor="right"
                        isnotTitle
                        open={filter}
                        component={
                            <Filter
                                filterData={filterData}
                                selectedList={filterData[0]}
                                filters={{
                                    status: selectedFilter?.status,
                                    unit: selectedFilter?.unit,
                                }}
                                setSelectedFilter={setSelectedFilter}
                                onGoBack={() => setFilter(false)}
                                onApply={applyFilter}
                                onClose={() => { setFilter(false) }}
                            />
                        }
                        onClose={() => { setFilter(false) }}
                    />


                </div>
            )}
        </>
    );
};
export default withNamespaces("gatePass")(WalkInOut);
