import { Avatar, Box, Typography } from "@mui/material"
import { useStyles } from "./styles";

export const GuestDetailCard = ({ t = () => false, details = {},shadow=false}) => {

    const classes = useStyles({shadow});


    return (
        <Box className={classes.guestDetailRoot} >

            <Box display="flex" p={2} alignItems="center">
                <Box>
                    <Avatar src={details?.vendor_image} sx={{height:50,width:50}}/>
                </Box>
                <Box sx={{ marginInlineStart: "14px" }}>
                    <Typography className={classes.guestName}>{details?.person_name}</Typography>
                    {details?.mobile_no &&
                    <Typography className={classes.guestMobile}>{details?.mobile_no_country_code} {details?.mobile_no}</Typography>
                    }
                    <Typography className={classes.reason}>
                    {details?.reason ? `Reason: ${details?.reason}`:details?.detail } 
                    </Typography>
                </Box>
            </Box>

        </Box>
    )
}