import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";
import { Bold, Regular, SemiBold, generateImageUrl, img_size } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",

    boxShadow: "0px 3px 30px #5C86CB2E",
    height: "122px"
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  titleHead:{
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
    marginTop:'8px'
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#78B1FE",
    padding: "1px 8px",
    // borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "1px 8px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "red",
    padding: "1px 8px",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "122px",
    width: "100%",
    objectFit: "cover",

  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,

  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  flag: {
    backgroundColor: "#5078E1",
    padding: "2px 8px",
    // position: "absolute",
    top: "4px",
    borderRadius: "4px",
    boxShadow: '0px 3px 6px #00000029',
    marginBottom:"8px"
  },
  flagChild: {
    height: "10px",
    width: "11px",
    backgroundColor: "#5078E1",
    bottom: "-8px",
    position: "absolute",
  },
  flagName: {
    color: "white",
    fontSize: "0.875rem",
    fontFamily: Bold,

  },
}));

const SwiftCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const language = localStorage.getItem("i18nextLng")

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container className={classes.card}
        onClick={() =>
          history.push(Routes.swiftInOutDetails + "?passId=" + props?.data?.id)
        }
      >

        <Grid item xs={4} md={3}>
          <Box style={{ position: "relative" }}>
            {props?.data?.img ?
              <img src={generateImageUrl(props?.data?.img, img_size.small_square)} alt="" className={classes.avatar} /> :
              <Avatar className={classes.avatar} variant="square" />
            }
            <Box style={{ position: "absolute" }} className={classes.bottomText1}>
              {props?.data?.status === "Pending" && (
                <Typography
                  variant="subtitle2"
                  noWrap
                  className={classes.progress}
                >
                  {t("In Progress")}
                </Typography>
              )}
              {props?.data?.status === "Approved" && (
                <Typography
                  variant="subtitle2"
                  className={classes.approved}
                >
                  {t("Approved")}
                </Typography>
              )}
              {props?.data?.status === "Rejected" && (
                <Typography
                  variant="subtitle2"
                  className={classes.rejected}
                >
                  {t("Rejected")}
                </Typography>
              )}
              {props?.data?.status === "Cancelled" && (
                <Typography variant="subtitle2" className={classes.delete}>
                  {t("Cancelled")}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} md={9} alignItems="center">
          <Box display={'flex'} justifyContent={'space-between'} sx={{ padding: "4px 12px 0px 12px", marginInlineEnd: "-24px" }}>
            <Typography noWrap className={classes.titleHead}>
              {props?.data?.subtitle2}
            </Typography>
            <Box sx={{ left: language === "ar" && "-10px", right: language !== "ar" && "-10px" }} className={classes.flag}>
              <Box position={"relative"}>
                <Typography className={classes.flagName} noWrap>
                  {props?.data?.title}
                </Typography>
                <Box
                  sx={{ left: language === "ar" && "-8px", right: language !== "ar" && "-8px", borderBottomRightRadius: language !== "ar" && "195px", borderBottomLeftRadius: language === "ar" && "195px" }}
                  className={classes.flagChild} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: "0px 12px 12px 12px" }}>
            <Stack direction="row" marginTop="6px" divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />} >
              <Typography

                variant="subtitle2"
                className={classes.sub}
                noWrap
              >
                &#x202a;{moment(props?.data?.check_in_time)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}&#x202c;
              </Typography>
              {
                props?.data?.check_out_time &&
                <Typography
                  variant="subtitle2"
                  className={classes.sub}
                  noWrap
                >
                  &#x202a;{moment(props?.data?.check_out_time)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY")}&#x202c;
                </Typography>
              }

            </Stack>
            <Stack direction="row" marginTop="6px" divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />}>
              <Typography

                variant="subtitle2"
                className={classes.sub}
                noWrap
              >
                {props?.data?.property}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.sub}
                noWrap
              >
                {props?.data?.unit}
              </Typography>
            </Stack>

            <Stack direction="row" marginTop="6px" spacing={1}>
              <Typography

                variant="subtitle2"
                className={classes.sub}
                noWrap
              >
                {props?.data?.person_name}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>

    </Box>
  );
};
export default withNamespaces("gatePass")(SwiftCard);