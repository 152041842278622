import React from 'react'
import { SvgIcon } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props?.color ? props.color : theme.palette.text.primary
    })
}))

export function DashboardSelected({
    color = null,
    cls = '',
}) {
    const classes = useStyles({ color });
    return (
        <SvgIcon viewBox="0 0 20 20" style={{ height: 24 }} fontSize="small">
            <g className={`${classes.cls1} ${cls}`} id="noun-dashboard-4463421" transform="translate(-87.51 -17.5)">
                <path id="Path_3339" data-name="Path 3339" d="M95.977,19.315A1.815,1.815,0,0,0,94.163,17.5H89.325a1.815,1.815,0,0,0-1.815,1.815v9.258a1.815,1.815,0,0,0,1.815,1.815h4.838a1.815,1.815,0,0,0,1.815-1.815Z" fill="#5078e1" />
                <path id="Path_3340" data-name="Path 3340" d="M95.977,369.315a1.815,1.815,0,0,0-1.815-1.815H89.325a1.815,1.815,0,0,0-1.815,1.815v2.419a1.815,1.815,0,0,0,1.815,1.815h4.838a1.815,1.815,0,0,0,1.815-1.815Z" transform="translate(0 -336)" fill="#5078e1" />
                <path id="Path_3341" data-name="Path 3341" d="M375.977,19.306a1.808,1.808,0,0,0-1.815-1.8h-4.838a1.808,1.808,0,0,0-1.815,1.8v16.4a1.808,1.808,0,0,0,1.815,1.8h4.838a1.808,1.808,0,0,0,1.815-1.8Z" transform="translate(-270.467 -0.007)" fill="#5078e1" />
            </g>
        </SvgIcon>
    )
}

DashboardSelected.propTypes = {
    color: PropTypes.string
}
