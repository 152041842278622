import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, remCalc } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    unitBox: {
        backgroundColor: "#ffffff",
        padding: "16px",
        boxShadow:"0px -1px 6px #00000014"
    },
    purpleBox: {
        backgroundColor: "#F6F1FE",
        borderRadius: "6px",
        padding: "12px"
    },
    text: {
        fontFamily: SemiBold,
        color: theme.typography.color.primary,
        fontSize: "0.875rem"
    },
    subText: {
        fontFamily: Regular,
        color: theme.typography.color.secondary,
        fontSize: "0.75rem"
    },
    change: {
        color: "#5078E1",
        fontSize: "0.75rem",
        fontFamily: SemiBold
    },
    chooseUnitRoot:{
        backgroundColor:"#F6F6F6"
    },
    location:{
        fontFamily:Regular,
        color:"#091B29",
        fontSize:remCalc(14)
    },
    unitName:{
        fontFamily:Bold,
        color:"#091B29",
        fontSize:remCalc(16)
    },
    cardRoot:{
        backgroundColor:"white",
        borderRadius:12,
        padding:"0px 16px 16px 16px",
        marginTop:"16px"
    },
    detTitle:{
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:remCalc(14)
    },
    detDescription:{
        color:"#091B29",
        fontFamily:SemiBold,
        fontSize:remCalc(14)
    },
    unitCardBtn:{
        padding:13,
        fontFamily:SemiBold,
        fontSize:remCalc(14),
        boxShadow:"none",
        borderRadius:"8px",
        marginTop:"18px"
    }
    
}))

