import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold, remCalc } from "../../../utils";

export const CustomDropDownStyle = makeStyles((theme) => ({
  ddCard: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.secondary}`,
    background: "#fff",
    padding: "0px 4px 0px 8px"
  },
  ddText: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  },
  menuItem: {
    height: 20,
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.primary
  }
}))

export const OtherFeatureCardStyle = makeStyles((theme) => ({
  card: {
    padding: "12px",
    borderRadius: 8,
    boxShadow: "0px 3px 30px #4354721F",
    background: "#fff"
  },
  text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  }
}))

export const PickupDeliveryCardStyle = makeStyles((theme) => ({
  title: {
    fontSize: remCalc(20),
    fontFamily: Bold,
    color: theme.typography.color.primary
  },
  subTitle: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  }
}))

export const nudgeUseStyles = makeStyles((theme) => ({
  nudge_card: {
    cursor: "pointer",
    minWidth: ({ size }) => size?.width >= 599 ? "568px" : `${size?.width - 32}px`,
    maxWidth: "568px",
    minHeight: "80px",
    borderRadius: theme?.palette?.borderRadius2,
    padding: "16px",
  },
  nudge_title_text: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.palette.primary.contrastText,
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_subject_text: {
    fontSize: remCalc(16),
    fontFamily: Bold,
    color: theme.palette.primary.contrastText,
    textShadow: "0px 0px 6px #00000029",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  nudge_CTA_text: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: theme.palette.primary.contrastText,
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_pagnation_style: {
    borderRadius: "1px",
    width: "20px",
    maxWidth: "20px",
    height: "2px",
  },
  nudge_icon: {
    objectFit: "contain",
    height: "40px",
    width: "40px",
  },
}));



export const CheckInOutStyle = makeStyles((theme) => ({
  box: {
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: "8px",
    padding: "16px",
    background: "#fff"
  },
  timeCard: {
    background: "linear-gradient(135deg, #727A81, #091B29)",
    borderRadius: 6,
    padding: "10px 13px",
    // transform: "rotateX(0deg)",
    // transition: "transform 0.6s",
    transition: "transform 0.6s",
    // transformStyle: "preserve-3d",
    // "&:hover": {
    //   transform: "rotateX(180deg)"
    // }
  },
  flipTimeCard: {
    background: "linear-gradient(135deg, #727A81, #091B29)",
    borderRadius: 6,
    padding: "10px 13px",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
    transform: "rotateX(180deg) !important"
  },
  checkin: {
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
    fontFamily: Regular
  },
  timeText: {
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
    fontFamily: SemiBold
  },
  checkTitle: {
    fontSize: 12,
    textTransform: "uppercase",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold
  },
  countText: {
    color: "#fff",
    fontSize: remCalc(20),
    fontFamily: SemiBold,
  },
  countStamp: {
    color: theme.typography.color.Tertiary,
    fontSize: remCalc(12),
    fontFamily: SemiBold,
  },
  time: {
    fontSize: remCalc(16),
    color: theme.typography.color.primary,
    fontFamily: Bold
  },
  popupCheckinText: {
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
    fontFamily: Regular
  },
  locationAddress: {
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
    width: "320px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  functionPrimaryText: {
    color: theme.typography.color.primary,
    fontSize: remCalc(12),
    fontFamily: SemiBold,
  },
  functionSecondaryText: {
    color: theme.typography.color.secondary,
    fontSize: remCalc(12),
    fontFamily: SemiBold,
  },
  checkInOutBtn: {
    height: 48,
    borderRadius: 8,
    fontFamily: SemiBold,
    fontSize: remCalc(14)
  },
  functionalLocation: {
    background: theme.palette.background.secondary,
    borderRadius: 8,
    padding: 8
  },
  dot: {
    height: "4px",
    width: "4px",
    background: "#CED4DA",
    borderRadius: "50%"
  },
  refreshText: {
    fontSize: remCalc(14),
    color: theme.palette.warning.main,
    fontFamily: SemiBold,
  },
  arArrow: {
    rotate: "180deg",
  }
}))

export const ActivityCardStyle = makeStyles((theme) => ({
  checkoutBtn: {
    height: "48px",
    borderRadius: "8px",
    fontFamily: SemiBold,
    fontSize: remCalc(14)
  },
  title: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  },
  subTitle: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.secondary
  },
  arArrow: {
    rotate: "180deg",
  }
}))

export const NewCheckInCardStyle = makeStyles((theme) => ({
  title: {
    fontSize: remCalc(16),
    color: theme.typography.color.primary,
    fontFamily: Bold
  },
  subTitle: {
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
    fontFamily: Regular
  },
  newCheckBtn: {
    height: 48,
    borderRadius: 8,
    fontFamily: SemiBold,
    fontSize: remCalc(14)
  }
}))

export const CalendarStyle = makeStyles((theme) => ({
  iconbutton: {
    // border: `1px solid ${theme.palette.border.secondary}`
  },
  day: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    textTransform: "uppercase",
    border: `1px solid ${theme.palette.border.secondary}`,
    padding: 4
  },
  date_curr_month: {
    border: `1px solid ${theme.palette.border.secondary}`,
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: Bold,
    cursor: "pointer",
  },
  date_not_curr_month: {
    border: `1px solid ${theme.palette.border.secondary}`,
    color: theme.palette.border.secondary,
    fontSize: "0.875rem",
    fontFamily: Bold,
    cursor: "not-allowed",
  },
  today: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "0.875rem",
    fontFamily: Bold,
  },
  timeBox: {
    height: "calc(100vh - 506px)",
    overflow: "auto",
    marginTop: "18px",
    border: "1px solid #E4E8EE",
    padding: "3px",
    scrollBehavior: "smooth"
  },
  selectedTime: {
    backgroundColor: "#5078E1",
    color: "white",
    borderRadius: theme.palette.borderRadius,
    padding: "3px",
  },
  date_hidden: {
    display: "none"
  },
  headerMonth: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,

    fontFamily: SemiBold,
  }
}))