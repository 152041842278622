import {
    Box,
    // Avatar,
    Stack, Typography
} from "@mui/material"
import GateOne from "../../../assets/gate_1"
// import PhoneOne from "../../../assets/phone_1"
// import { generateImageUrl, img_size } from "../../../utils"
import { useStyles } from "../style"
import { withNamespaces } from "react-i18next"

const SecurityGuard = (props) => {
    const {
        item = {},
        index = 0,
        array = [],
        t = () => false
    } = props
    //const
    const classes = useStyles(props)

    return <div className={classes.security_card_root}>
        <div className={classes.security_card}>
            {/* <div className={classes.gate_info_div}>
                <Stack className={classes.gate_info}
                    direction={"row"} spacing={"4px"}>
                    <GateOne />
                    <Stack direction={"row"}>
                        <Typography className={classes.gate_no_label}>
                            {t("Gate No")}:
                        </Typography>
                        <Typography className={classes.gate_no_value}>
                            {item?.gate_no}
                        </Typography>
                    </Stack>
                </Stack>
            </div> */}
            <div className={classes.guard_info_div}>
                <Stack direction={"column"} spacing={"12px"}>
                    {/* <Stack direction={"row"} 
                        className={classes.guard_info_div_1}> */}
                        <Stack direction={"row"}
                            className={classes.guard_info_div_2}>
                            <Box sx={{
                                marginInlineEnd: "12px", height: "40px",
                                width: "40px", borderRadius: "10%",
                                border: "1px dashed #b9b9b9", display: "flex",
                                justifyContent: "center", alignItems: "center"
                            }}>
                                <GateOne />
                            </Box>
                            {/* <Avatar sx={{ marginInlineEnd: "12px" }} variant="circular" src={generateImageUrl(item?.profile_pic,img_size.small_square) ?? "/images/avatar.jpg"} alt="" /> */}
                            <Stack direction={"column"} spacing={"6px"}>
                                <Typography className={classes.guard_name}>
                                    {/* {item?.name} */}
                                    {t("Gate No")}
                                </Typography>
                                <Typography className={classes.guard_no}>
                                    {/* {item?.mobile_code ?? ""} {item?.mobile_no ?? ""} */}
                                    {item?.gate_no}
                                </Typography>
                            </Stack>
                        </Stack>
                        {/* <a href={`${t("tel")}:${item?.mobile_code}+${item?.mobile_no}`}>
                            <div className={classes.call_button}>
                                <PhoneOne />
                            </div>
                        </a>
                    </Stack> */}
                    <Stack direction={"row"} style={{ alignItems: "center", justifyContent: "center" }}>
                        {array?.map((i, ind) =>
                            ind === index ?
                                <div style={{ height: "8px", width: "8px", backgroundColor: "#5078E1", borderRadius: "50%" }} /> :
                                <div style={{ height: "8px", width: "8px", backgroundColor: "#E4E8EE", borderRadius: "50%", marginInlineStart: "2px" }} />)}
                    </Stack>
                </Stack>
            </div>
        </div>
    </div>
}
export default withNamespaces("info")(SecurityGuard)