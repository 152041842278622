import * as React from "react"
const ChattingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 117062">
      <g data-name="Group 4035">
        <g fill="#fff" stroke="#ced3dd" data-name="Rectangle 44602">
          <rect width={28} height={28} stroke="none" rx={4} />
          <rect width={27} height={27} x={0.5} y={0.5} fill="none" rx={3.5} />
        </g>
        <path
          fill="#bd2c2c"
          d="M9.734 8.311a2.062 2.062 0 0 0-2.055 2.055v5.373a2.062 2.062 0 0 0 2.055 2.055h.474v1.738a.808.808 0 0 0 1.264.632l3.161-2.371h3.635a2.062 2.062 0 0 0 2.055-2.055v-5.372a2.062 2.062 0 0 0-2.055-2.055Z"
          data-name="icons8-chat (1)"
        />
      </g>
    </g>
  </svg>
)
export default ChattingIcon
