import { Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { SlideButton } from "../../components";
import { withNamespaces } from "react-i18next";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
  },

  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },

  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  heading1: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "4px",
  },

  sub: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: Regular,
    textTransform: "capitalize",
  },

  checksub: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  checkheading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  bottom: {
    padding: "12px",
    marginTop: "-20px",
  },
  span: {
    backgroundColor: "#5078E11E",
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: Bold,
    marginLeft: "6px",
    padding: "4px",
    borderRadius: "4px"

  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
    marginTop: "9px"
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
    marginTop: "9px"
  },
  subtitle: {
    fontFamily: SemiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.primary
  }
}));

const SwiftInOutCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  return (
    <>
      <div className={classes.card}>
        {props?.data?.domestic_helper_request &&
          <>
            <Box alignItems="center" p={2} marginTop="-10px">
              <Typography variant="subtitle2" className={classes.heading}>
                {t("APPROVED UNITS AS DOMESTIC HELPER")}
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.domestic_helper_request?.unit?.unit_no}
                {/* {props?.data?.domestic_helper_units.map((item) => item?.unit?.name).join(',')} */}
              </Typography>
            </Box>
            <Divider />
          </>
        }
        <Grid container spacing={1} className={classes.bottom}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.heading}>
              {t("Checked In")}
            </Typography>
            {props?.data?.check_in_time ?
              <Typography variant="subtitle2" className={classes.subtitle}>
                {t("By")} {props?.data?.check_in_byByID?.first_name},{props?.data?.check_in_time ? moment(props?.data?.check_in_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.heading1}>
              {t("Checked Out")}
            </Typography>
            {props?.data?.check_out_time ?
              <Typography variant="subtitle2" className={classes.subtitle}>
                {t("By")} {props?.data?.check_out_byByID?.first_name},{props?.data?.check_out_time ? moment(props?.data?.check_out_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={12}>
            <SlideButton
              mainText={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? t("Swipe to Check In") : t("Swipe to Check Out")}
              overlayText={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? t("Checked In") : t("Checked Out")}
              classList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-class1" : "my-class2"}
              caretClassList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-caret-class1" : "my-caret-class2"}
              overlayClassList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-overlay-class1" : "my-overlay-class2"}
              onSlideDone={() => props?.data?.check_in_time ? props.onClick(props.data) : false}
              disabled={props?.data?.check_in_time && props?.data?.check_out_time}
            />
            {/* <Button
              fullWidth
              variant="contained"
              onClick={() => props?.data?.check_in_time ? props.onClick(props.data) : false}
              className={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? classes.sucess : classes.warning}
              disabled={props?.data?.check_in_time && props?.data?.check_out_time}
              size="large"

            >
              {props?.data?.check_in_time === null && props?.data?.check_out_time === null ? " Check In" : "Check Out"}
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default withNamespaces("swiftInOutDetails")(SwiftInOutCard)