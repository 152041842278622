import { Box, Button, Container, Dialog, Drawer, Grid, Hidden, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BackendRoutes, Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertProps, Bold, ExtraBold, NetWorkCallMethods, Regular, SemiBold, remCalc } from "../../utils";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { withNamespaces } from "react-i18next";
import { TermsAndCondition } from "./component/termsAndCondition";
import { Close } from "@mui/icons-material";
import { initital_screen_state } from "../../utils/login/utils";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,

    backgroundImage: `url(/images/welcome.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "65% 65%",
    backgroundPosition: "center"


  },
  welcomeText: {
    fontSize: "1.625rem",
    fontFamily: ExtraBold,
    color: "white",
  },
  btn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.tertiary1,
    padding: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "opx 6px 10px #00000014",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  img: {
    width: "auto",
    height: "36px",
  },
  btnRoot: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    right: 0,
    left: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "0px 45px",
    },
    // padding: " 0px 16px"
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.75rem",
    color: "white",
  },
  pa: {
    color: "white",
    fontFamily: SemiBold,
    fontSize: "0.75rem",
  },
  termsAndConditionText: {
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: Regular,
    cursor: "pointer"
  },
  termsAndConditionUnderLineText: {
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: Bold,
    cursor: "pointer"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  getStartBtn: {
    height: 48,
    fontFamily: Bold
  },
  btnBox: {
    padding: "16px",
    borderTop: `1px solid ${theme.palette.border.secondary}`
  }
}));
export const welcomePageUseStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "white",
  },
  grid: {
    height: ({ size }) => size?.height,
    alignItems: "center",
  },
  left_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  right_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "end",
  },
  web_right_section_card: {
    display: "contents",
    height: ({ size }) => size?.height,
    padding: "0px",
  },
  web_content: {
    height: "100vh",
    overflow: "overlay",
    display: "flex",
    position: "relative",
    padding: "40px 0px",
    backgroundColor: "white",
  },
  web_logo: {
    height: "32px",
    objectFit: "contain",
  },
  web_description: {
    fontSize: remCalc(20),
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  web_set_up_new_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius3,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: theme.palette.background.button_background_1,
    border: "1px solid #5078E1",
  },
  web_set_up_new_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.contrastText,
  },
  web_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius3,
    boxShadow: "0px 6px 10px #00000014",
    border: "1px solid #5078E1",
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  web_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.primary_2,
  },
  web_terms_of_use_description: {
    textAlign: "center",
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.secondary,
  },
  web_terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    textDecoration: "underline",
    display: "inline",
    cursor: "pointer",
  },
  web_version_no: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.Tertiary,
  },
  web_powered_by_logo: {
    height: "11px",
    objectFit: "contain",
  },
  mob_right_section_card: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    padding: "0px",
  },
  mob_content: {
    paddingTop: ({ size }) => size?.height - 354,
    overflow: "overlay",
  },
  mob_items: {
    padding: "16px 16px 18px 16px",
  },
  mob_logo: {
    height: "32px",
    objectFit: "contain",
  },
  mob_description: {
    fontSize: remCalc(20),
    fontFamily: SemiBold,
    color: "#FEF4F4",
    textShadow: "0px 6px 10px #00000033",
  },
  mob_set_up_new_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  mob_set_up_new_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "white",
  },
  mob_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#ffffff75",
    "&:hover": {
      backgroundColor: "#ffffff75",
    },
  },
  mob_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "white",
  },
  mob_terms_of_use_description: {
    width: "100%",
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: "white",
  },
  mob_terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: Bold,
    color: "white",
    textDecoration: "underline",
    display: "inline",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      minWidth: ({ size }) => size?.width > 599 && "500px",
      maxWidth: ({ size }) => size?.width > 599 && "500px",
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  drawer_header_grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
    padding: "16px",
  },
  drawer_header_text: {
    marginLeft: "12px",
    fontSize: remCalc(16),
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  drawer_content_box: {
    height: ({ size }) => size?.width > 599 ? "calc(100vh - 145px)" : "450px",
    overflow: "overlay",
    padding: "16px",
  },
  drawer_button_box: {
    padding: "16px",
    borderTop: `1px solid ${theme.palette.border.secondary}`
  },
  agree_and_start_button: {
    height: "48px",
    fontFamily: Bold,
  },
  welcome_backdrop: {
    width: "100%",
    objectFit: "contain",
  },
}));
const WelcomePage = (props) => {
  const { t = () => false } = props;

  const size = useWindowDimensions();
  const classes = useStyles();
  const classes_1 = welcomePageUseStyles({ size });
  const history = useHistory();
  const alert = React.useContext(AlertContext);

  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get("token");
  const [decoded, setDecoded] = React.useState(null);
  const [tcStatus, setTCStatus] = React.useState(false)
  const [termCondition, setTermCondition] = React.useState("")
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);

  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
      getTermsAndCondition(jwt_decode(welcomePageAuthToken))
      initialLoad();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const img = new Image();

    img.src = screen_state?.mob_welcome_background;

    img.onload = () => {
      setIsBackgroundImageLoading(true);
    };

    img.onerror = () => {
      set_screen_state({ ...screen_state, mob_welcome_background: initital_screen_state?.mob_welcome_background });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen_state?.mob_welcome_background]);
  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }
  const getStarted = () => {
    const payload = {
      userId: decoded.user_id,
    };
    NetworkCall(
      `${config.auth_api_url}/auth/verifyUser/?token=${welcomePageAuthToken}`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.is_allowed) {
            history.push(
              Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          } else {
            history.push(Routes.login);
          }
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing went wrong"),
        });
      });
  };

  const getTermsAndCondition = (decoded_data = decoded) => {

    const payload = {
      client: decoded_data?.client,
    };
    NetworkCall(
      `${config.auth_api_url}/client/terms_and_conditions`,
      NetWorkCallMethods.post,
      payload,
      { "Referer": "https://resident.pms2.propgoto.com/" },
      false,
      false
    )
      .then((response) => {
        setTermCondition(response?.data?.data)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const handleClose = () => {
    setTCStatus(false)
  }

  const logIn = () => {
    history.push({
      pathname: Routes.login,
      state: {
        origin: Routes?.welcomePage
      },
    });
  }

  return (
    is_loading
      ? <></>
      : <Container className={classes_1.mob_right_section_card} maxWidth="sm"
        sx={{
          backgroundImage: isBackgroundImageLoading
            ? `url(${screen_state?.mob_welcome_background})`
            : "none",
          backgroundColor: '#757575',
        }}>
        <Grid container className={classes_1.mob_content}>
          <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_items}>
            <Stack direction={"column"} alignItems={"start"}>
              <img className={classes_1.mob_logo} src={screen_state?.logo} alt={t("logo")} />
              <Box height={"20px"} />
              <Typography className={classes_1.mob_description}>
                {t("Easy, secure & most reliable solution for property & facility management")}
              </Typography>
              <Box height={"40px"} />
              <Button className={classes_1.mob_set_up_new_button} onClick={getStarted}>
                <Typography className={classes_1.mob_set_up_new_button_text}>
                  {t("Set Up New Account")}
                </Typography>
              </Button>
              <Box height={"16px"} />
              <Button className={classes_1.mob_log_in_button} onClick={logIn}>
                <Typography className={classes_1.mob_log_in_button_text}>
                  {t("Log In")}
                </Typography>
              </Button>
              <Box height={"20px"} />
              <Typography className={classes_1.mob_terms_of_use_description} textAlign={"center"}>
                {t(`By tapping "Set Up New Account", you agree to our `)}
                <Typography className={classes_1.mob_terms_of_use_word}
                  onClick={() => setTCStatus(true)}>
                  {t("Terms & Conditions")}
                </Typography>
              </Typography>
            </Stack>
          </Grid>
        </Grid>


        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullWidth
            open={tcStatus}
            onClose={() => handleClose()}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} p={2}>
                <Close sx={{ color: "#4E5A6B" }} onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "450px", overflow: "auto" }} p={2}>
                  <TermsAndCondition termsCondition={termCondition} t={t} />
                </Box>
                <Box className={classes.btnBox}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.getStartBtn}
                    onClick={getStarted}
                  >
                    {t("GetStarted")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            anchor="bottom"
            className={classes.drawer}
            open={tcStatus}
            onClose={() => handleClose()}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} p={2}>
                <Close sx={{ color: "#4E5A6B" }} onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "450px", overflow: "auto" }} p={2}>
                  <TermsAndCondition termsCondition={termCondition} t={t} />
                </Box>
                <Box className={classes.btnBox}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.getStartBtn}
                    onClick={getStarted}
                  >
                    {t("GetStarted")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
      </Container>
  );
};
export default withNamespaces("welcomePage")(WelcomePage);