import * as React from "react"
const AudioCallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.024}
    height={15.487}
    {...props}
  >
    <path
      fill="#5078e1"
      d="m13.27 13.368-.585.533a2.282 2.282 0 0 1-2.209.461Q7.664 13.5 5.223 9.505t-1.82-6.711a2.114 2.114 0 0 1 1.536-1.581l.784-.209a1.625 1.625 0 0 1 1.858.864l.665 1.38a1.424 1.424 0 0 1-.315 1.703l-1.038.914a.535.535 0 0 0-.18.494 6.135 6.135 0 0 0 .736 1.988 5.714 5.714 0 0 0 1.327 1.598.53.53 0 0 0 .486.092l1.486-.44a1.622 1.622 0 0 1 1.739.588l.929 1.23a1.444 1.444 0 0 1-.146 1.953Z"
      data-name="icons8-call (1)"
    />
  </svg>
)
export default AudioCallIcon
