import { Avatar, Box, Typography,Stack,Divider } from "@mui/material"
import { returnTypeData } from "../utils";
import { useStyles } from "./styles";

export const RequestCardDetails = ({ status = "Pending", t = () => false, details = {} }) => {

    const classes = useStyles()
    return (
        <Box className={classes.requestCardRoot}>
            <Box
                className={classes.statusSection}
                sx={{
                    backgroundColor: returnTypeData(status, t)?.bgColor
                }}
            >
                <center>
                    {returnTypeData(status, t)?.icon}
                    <Typography className={classes.requestTypeTitle}>{returnTypeData(status, t)?.title}</Typography>
                    <Typography className={classes.requestDate}>{details?.requestDate}</Typography>
                </center>
            </Box>

            <Box display="flex" alignItems="center" p={2} borderBottom={"2px solid #E4E8EE"}>
                <Box>
                    <Avatar sx={{ borderRadius: "6px", height: "50px", width: "50px" }} src="/images/bgNew.png" />
                </Box>
                <Box sx={{marginInlineStart:"12px"}}>
                    <Typography className={classes.unitName}>{details?.unit_name}</Typography>
                    <Typography className={classes.location}>{details?.location}</Typography>
                </Box>
            </Box>

            <Stack direction="row"
            sx={{padding:"18px"}}
            divider={<Divider sx={{ marginInline: "8px",border:"1px solid #E4E8EE",backgroundColor:"#E4E8EE" }} orientation="vertical" flexItem />}
            justifyContent="space-between"
            >
                <Box>
                    <Typography className={classes.detTitle}>{t("VISIT DATE")}</Typography>
                    <Typography className={classes.detDescription}>{details?.visit_date}</Typography>
                </Box>
                <Box>
                    <Typography className={classes.detTitle}>{t("REFERENCE NUMBER")}</Typography>
                    <Typography className={classes.detDescription}>{details?.reference_id}</Typography>
                </Box>
                <Box>
                    <Typography className={classes.detTitle}>{t("GATE ENTRY")}</Typography>
                    <Typography className={classes.detDescription}>{details?.gate_no}</Typography>
                </Box>

            </Stack>

        </Box>
    )
}


