import { Box, Button, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TitleBar } from "../../components";
import { config } from "../../config";
import { GET_GATE_PROPERTY, GET_PROPERTY_BY_ROLE } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, Bold, enumSelect, enumTypes, LocalStorageKeys, NetWorkCallMethods, remCalc } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import DetailForm from "./deliveryForm";
import DetailCard from "./detailCard";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: Bold,
    paddingLeft: "12px",
  },
  backToHome: {
    height: 48,
    borderRadius: "8px",
    fontSize: remCalc(14),
    fontFamily: Bold,
  }
}));

const DeliveryDetails = ({ t = () => false }) => {

  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const searchURL = useLocation().search;
  const [detail, setDetail] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [gate, setGate] = React.useState([]);
  const userId = localStorage.getItem(LocalStorageKeys.userId)
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
  const [enumValue, setEnumValue] = React.useState({
    status: [],
  })
  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetail(response?.data?.data?.[0]);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getGate = (val) => {
    const data = {
      query: GET_GATE_PROPERTY,
      variables: {
        id: val,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setGate(response?.data.data?.access_gates_master);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_PROPERTY_BY_ROLE,
      variables: {
        user_profile_id: userId,
        role_id: roleId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        getGate(
          response?.data.data?.access_control?.map(
            (val) => val?.property?.value
          )
        );
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.delivery_status_type])
    let requestSourceMapStatus = result?.delivery_status_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus).map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    setEnumValue({ ...enumValue, status: requestTypeStatus })

  }
  React.useEffect(() => {
    getEnum()
    getPassDetails();
    getPropertyId();
    // eslint-disable-next-line
  }, []);
  return (
    <Container maxWidth="sm" sx={{ padding: "0px", overflow: "hidden" }}>
      <TitleBar
        text={t("Delivery Order Collection")}
        // color="#5078E1"
        goBack={() => history.goBack()}
      />
      <div
        className={classes.root}
        style={{
          height: detail?.delivery_status === enumValue?.status?.type3 ? size.height - 136 : size.height - 45,
          overflow: "scroll",
          marginRight: "-6px",
        }}
      >
        <DetailCard
          type={passId}
          detail={detail}
          statusKey={enumValue?.status}
        />
        {detail?.delivery_status === enumValue?.status?.type4 ||
          detail?.delivery_status === enumValue?.status?.type3 ? (
          ""
        ) : (
          <div style={{ margin: "8px 10px 8px 14px" }}>
            <Typography className={classes.subtitle}>
              {t("DELIVERY DETAILS")}
            </Typography>
            <Box height="10px" />
            <DetailForm
              type={passId}
              detail={detail}
              gate={gate}
              statusKey={enumValue?.status}
              reload={getPassDetails}
            />
          </div>
        )}
      </div>
      {
        detail?.delivery_status === enumValue?.status?.type3 &&
        <Box p={2}>
          <Button fullWidth variant="outlined" className={classes.backToHome}
            onClick={() => history.goBack()}>
            {t("Back To Home")}
          </Button>
        </Box>
      }
    </Container>
  );
};
export default withNamespaces("deliveryDetails")(DeliveryDetails)