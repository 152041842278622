import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { Bold, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabroot: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px 0px",
    width: "100%",
    overflow: "auto"
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "20px",
    },
    minWidth: "auto",

    marginRight: "8px",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "20px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },
  title: {
    padding: "8px",
    borderRadius: "20px",
    // background: "#5078E1",
    color: theme.typography.color.primary,
    textAlign: "center",
    display: "flex",
    fontFamily: SemiBold
  },
  titleselect: {
    padding: "8px 14px",
    marginLeft: "8px",
    borderRadius: "20px",
    backgroundColor: " #071741",
    color: "white",
    textAlign: "center",
    display: "flex",
    fontFamily: Bold
  },
}));
const TabsComponent = ({
  selectedTab = 0,
  tab1Label = "Home",
  tab2Label = "Invoice",
  tab3Label = "Payment History",
  onTab1Clicked = () => false,
  onTab2Clicked = () => false,
  onTab3Clicked = () => false,
  onTab4Clicked = () => false,
  onTab5Clicked=()=>false,
  t = () => false
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Tab Section */}
        <Grid item xs={12}>
          <ScrollContainer className="scroll-container">
            <Tabs
              value={selectedTab}
              indicatorColor="none"
              textColor="secondary"
              className={classes.tabroot}
              variant="scrollable"
              visibleScrollbar={false}
              scrollButtons={false}
            >
              <Tab
                className={
                  selectedTab === 0 ? classes.tabItemSelect : classes.tabItem
                }
                label={
                  <span
                    className={
                      selectedTab === 0 ? classes.titleselect : classes.title
                    }
                  >
                    {selectedTab === 0 && (
                      <img
                        src="/images/icons8-female-user-update.svg"
                        alt="img3"
                      />
                    )}
                    &nbsp;&nbsp;{t("Swift In & Out")}
                  </span>
                }
                onClick={onTab1Clicked}
              />
              <Tab
                className={
                  selectedTab === 1 ? classes.tabItemSelect : classes.tabItem
                }
                label={
                  <span
                    className={
                      selectedTab === 1 ? classes.titleselect : classes.title
                    }
                  >
                    {selectedTab === 1 && (
                      <img src="/images/ticket.svg" alt="img1" />
                    )}
                    &nbsp;&nbsp;{t("Visitors")}
                  </span>
                }
                onClick={onTab2Clicked}
              />
              <Tab
                className={
                  selectedTab === 2 ? classes.tabItemSelect : classes.tabItem
                }
                label={
                  <span
                    className={
                      selectedTab === 2 ? classes.titleselect : classes.title
                    }
                  >
                    {selectedTab === 2 && (
                      <img
                        src="/images/icons8-delivered-box (1).svg"
                        alt="img2"
                      />
                    )}
                    &nbsp;&nbsp;{t("Parkings")}
                  </span>
                }
                onClick={onTab3Clicked}
              />
              <Tab
                className={
                  selectedTab === 3 ? classes.tabItemSelect : classes.tabItem
                }
                label={
                  <span
                    className={
                      selectedTab === 3 ? classes.titleselect : classes.title
                    }
                  >
                    {selectedTab === 3 && (
                      <img
                        src="/images/icons8-delivered-box (1).svg"
                        alt="img3"
                      />
                    )}
                    &nbsp;&nbsp;{t("Delivery Collection")}
                  </span>
                }
                onClick={onTab4Clicked}
              />

              <Tab
                className={
                  selectedTab === 4 ? classes.tabItemSelect : classes.tabItem
                }
                label={
                  <span
                    className={
                      selectedTab === 4 ? classes.titleselect : classes.title
                    }
                  >
                    {selectedTab === 4 && (
                       <img
                       src="/images/icons8-delivered-box (1).svg"
                       alt="img2"
                     />
                    )}
                    &nbsp;&nbsp;{t("Visitor Entry Request")}
                  </span>
                }
                onClick={onTab5Clicked}
              />
            </Tabs>
          </ScrollContainer>
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("tabsComponent")(TabsComponent)
