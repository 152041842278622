import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import TotalItemIcon from './utils/totalTimeIcon'
import { makeStyles } from '@mui/styles'
import { AlertProps, Bold, NetWorkCallMethods, Regular, SemiBold, currentTimeZone, gmtPattern, remCalc } from '../../utils'
import CheckInOutCalc from './utils/checkinOutCalc'
import { DialogDrawer, LoaderSimmer, TitleBar } from '../../components'
import { useHistory } from "react-router-dom";
import { ActivityCard } from './component/activityCard'
import { NewCheckinCard } from './component/newCheckinCard'
import { CheckInOutPopup } from './component/checkInOutPopup'
import moment from 'moment'
import { AlertContext } from '../../contexts'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { format } from 'date-fns'
import { getHourmin } from './utils/checkInOut'
import { withNamespaces } from 'react-i18next'
import CalendarWithTime from "./component/customCalender"
import useWindowDimensions from '../../utils/useWindowDimensions'
import InfiniteScroll from 'react-infinite-scroll-component'

const CheckInOutStyle = makeStyles((theme) => ({
    title: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    subTitle: {
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: theme.typography.color.secondary
    },
    totalBox: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "8px",
        padding: "16px",
        background: "#fff",
        height: "auto"
    },
    root: {
        backgroundColor: theme.palette.background.main,
        padding: "0px",
        overflow: "hidden",
        height: "calc(100vh - 0px)"
    },
    activityText: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        textTransform: "uppercase",
        marginTop: "20px",
        marginBottom: "8px"
    },
    newCheckBtn: {
        height: 48,
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        borderRadius: 8
    },
    noDataBox: {
        height: "calc(100vh - 350px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    noDataText: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary
    }
}))

const CheckInOut = ({
    t = () => false
}) => {
    const classes = CheckInOutStyle()
    const history = useHistory()
    const alert = React.useContext(AlertContext)
    const size = useWindowDimensions();
    const [popup, setPopup] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [datePopup, setDatePopup] = React.useState(false)
    const [checkData, setCheckData] = React.useState({
        activityList: [],
        selectedListData: {},
        checkout: false,
        totalhours: "-",
        selectedDate: new Date(),
        listCount: 0
    })
    const [functionalLocation, setFuncationalLocation] = React.useState("")
    const [offset, setOffset] = React.useState(0)


    React.useEffect(() => {
        getActivityList()
        // eslint-disable-next-line
    }, [])


    const checkInOutClick = async () => {
        await new Promise(async (resolve, reject) => {
            try {
                let data = {};
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        data = {
                            [checkData?.checkout && "id"]: checkData?.selectedListData?.id,
                            [checkData?.checkout ? "checkout_date" : "checkin_date"]: moment(new Date()).format("YYYY-MM-DD"),
                            [checkData?.checkout ? "checkout_time" : "checkin_time"]: moment(new Date()).format("kk:mm:ss"),
                            [checkData?.checkout ? "out_lat" : "in_lat"]: position.coords.latitude,
                            [checkData?.checkout ? "out_long" : "in_long"]: position.coords.longitude,
                            [checkData?.checkout ? "out_distance" : "in_distance"]: functionalLocation?.distance,
                            timezone: format(new Date(), gmtPattern, { timeZone: currentTimeZone })
                        }
                        NetworkCall(
                            checkData?.checkout ? `${config.api_url}/self_check_in_out/checkout` : `${config.api_url}/self_check_in_out/checkin`,
                            NetWorkCallMethods.post, data, null, true, false
                        ).then((res) => {

                            alert.setSnack({
                                ...alert,
                                open: true,
                                severity: AlertProps.severity.success,
                                msg: res?.data?.data?.message,
                            });
                            setPopup(false)
                            getActivityList()
                            setCheckData({
                                ...checkData,
                                selectedListData: {},
                                checkout: false
                            })
                        })
                            .catch((err) => {
                                console.log(err);
                                alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.error,
                                    msg: t("Something Went Wrong"),
                                });
                            });
                    },
                    function (error) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: "Please enable your location"
                                });
                                break;
                            case error.POSITION_UNAVAILABLE:
                                alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: "Location information is unavailable"
                                });
                                break;
                            case error.TIMEOUT:
                                alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: "The request to get your location timed out"
                                });
                                break;
                            default:
                                alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: "An unknown error occurred"
                                });
                        }
                    }
                );
            } catch (error) {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            }
        });

    }

    const getActivityList = (date = new Date(), offset = 0, limit = 10, bool = false) => {
        setLoading(bool ? false : true)
        const data = {
            "date": moment(date).format("YYYY-MM-DD"),
            offset: offset,
            limit: limit
        };
        NetworkCall(
            `${config.api_url}/self_check_in_out/get_activity_list`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((res) => {
                getTotalHour(date, res?.data?.activities, res?.data?.count, bool)

            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    };

    const getTotalHour = (date, activityData = [], countList = 0, bool = false) => {
        const data = {
            "date": moment(date).format("YYYY-MM-DD")
        };
        NetworkCall(
            `${config.api_url}/self_check_in_out/get_total_hours`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((res) => {
                if (bool) {
                    setCheckData({
                        ...checkData,
                        activityList: checkData.activityList.concat(activityData),
                        totalhours: res?.data?.data?.total_hours,
                        selectedDate: date,
                        listCount: countList
                    })
                } else {
                    setCheckData({
                        ...checkData,
                        activityList: activityData,
                        totalhours: res?.data?.data?.total_hours,
                        selectedDate: date,
                        listCount: countList
                    })
                }

                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    };

    const checkout = (data) => {
        setCheckData({
            ...checkData,
            checkout: true,
            selectedListData: data
        })
        setPopup(true)
    }

    const newCheckIn = () => {
        setCheckData({
            ...checkData,
            checkout: false,
        })
        setPopup(true)
    }

    const getSelectedDateList = (date) => {
        getActivityList(date)
        setDatePopup(false)
    }

    const fetchMoreData = () => {
        const off = offset + 10
        setOffset(off);
        getActivityList(checkData?.selectedDate, off, 10, true)
    }

    return (
        <div>

            {/* <Container maxWidth="sm" sx={{ padding: "0px" }}>
                <div className={classes.root}>
                    <TitleBar text={t("Check in Check out")} goBack={() => history.goBack(-1)} />
                    {
                        loading ?
                            <Stack height={"100%"}>
                                <LoaderSimmer count={1} size={500} />
                                <Box mt={3}>
                                    <LoaderSimmer count={4} size={500} />
                                </Box>
                            </Stack> :
                            <Stack justifyContent={"space-between"} height={"calc(100vh - 100px)"}>
                                <Box p={2}>
                                    <Stack direction={"row"} className={classes.totalBox} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} columnGap={1}>
                                            <TotalItemIcon />
                                            <Stack>
                                                <Typography className={classes.title}>{checkData?.totalhours}</Typography>
                                                <Typography className={classes.subTitle}>{
                                                    moment(new Date()).format("YYYY-MM-DD") === moment(checkData?.selectedDate).format("YYYY-MM-DD") ?
                                                        t("Today's Total checked in hours") :
                                                        `${moment(checkData?.selectedDate).format("DD MMM YY")} ${t("Total checked in hours")}`}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Box onClick={() => setDatePopup(true)}>
                                            <CheckInOutCalc />
                                        </Box>
                                    </Stack>
                                    <Typography className={classes.activityText}>{t("Activity")}</Typography>
                                    <Box className={classes.totalBox}
                                        sx={{
                                            // minHeight: checkData?.activityList?.length === 0 ? "auto" : "calc(100vh - 350px)",
                                            // maxHeight: checkData?.activityList?.length === 0 ? "auto" : "calc(100vh - 350px)",
                                            overflow: "auto"
                                        }}>
                                        {checkData?.activityList?.length === 0 ?
                                            moment(new Date()).format("YYYY-MM-DD") !== moment(checkData?.selectedDate).format("YYYY-MM-DD") ?
                                                <Box className={classes.noDataBox}>
                                                    <Typography className={classes.noDataText}>{t("No Data Found")}</Typography>
                                                </Box> :
                                                <NewCheckinCard handleClick={() => setPopup(true)} t={t} /> :
                                            <Box>
                                                <InfiniteScroll
                                                    dataLength={checkData?.listCount}
                                                    next={fetchMoreData}
                                                    hasMore={true}
                                                    height={size?.height - 400}
                                                >
                                                    {
                                                        checkData?.activityList?.map((data, index) => {
                                                            return (
                                                                <ActivityCard
                                                                    checkOut={data?.is_checked_out}
                                                                    title={data?.is_checked_out ? `${getHourmin(`${data?.checkin_date} ${data?.checkin_time}`, `${data?.checkout_date} ${data?.checkout_time}`)}` : ""}
                                                                    subTitle={data?.is_checked_out ?
                                                                        `${format(new Date(`${data?.checkin_date} ${data?.checkin_time}`), "p")} - ${format(new Date(`${data?.checkout_date} ${data?.checkout_time}`), 'p')}` :
                                                                        `${t("checked in on")} ${format(new Date(`${data?.checkin_date} ${data?.checkin_time}`), "p")}`
                                                                    }
                                                                    data={data}
                                                                    checkout={checkout}
                                                                    index={index}
                                                                    dataLength={checkData?.activityList?.length} />
                                                            )
                                                        })
                                                    }
                                                </InfiniteScroll>
                                            </Box>}
                                    </Box>

                                </Box>
                                {
                                    (
                                        checkData?.activityList?.length > 0 &&
                                        checkData?.activityList?.filter((i) => i?.is_checked_out === false)?.length === 0 &&
                                        moment(new Date()).format("YYYY-MM-DD") === moment(checkData?.selectedDate).format("YYYY-MM-DD")
                                    ) &&
                                    <Box p={"0px 16px"}>
                                        <Button variant='contained' fullWidth className={classes.newCheckBtn}
                                            onClick={() => newCheckIn()}>{t("New Check In")}</Button>
                                    </Box>}
                            </Stack>
                    }
                </div>
                <DialogDrawer
                    height={"380px"}
                    maxWidth={"sm"}
                    open={popup}
                    header={checkData?.checkout ? t("Check Out") : t("Check In")}
                    component={
                        <>
                            <CheckInOutPopup
                                checkout={checkData?.checkout}
                                handleClick={checkInOutClick}
                                t={t}
                                checkInOutData={checkData?.selectedListData}
                                functionalLocation={functionalLocation}
                                setFuncationalLocation={setFuncationalLocation}
                            />
                        </>
                    }
                    onClose={() => setPopup(false)}
                />

                <DialogDrawer
                    height={"350px"}
                    maxWidth={"sm"}
                    open={datePopup}
                    header={"Date"}
                    component={
                        <>
                            <CalendarWithTime
                                handleDateChange={date => getSelectedDateList(date)}
                                selectedDate={checkData?.selectedDate}
                                hideCondition={false}
                            />
                        </>
                    }
                    onClose={() => setDatePopup(false)}
                />
            </Container> */}


            <Container maxWidth="sm" sx={{ padding: "0px" }}>
                <div className={classes.root}>
                    <TitleBar text={t("Check In Check Out")} goBack={() => history.goBack(-1)} />
                    {
                        loading ?
                            <Stack height={"100%"}>
                                <LoaderSimmer count={1} size={500} />
                                <Box mt={3}>
                                    <LoaderSimmer count={4} size={500} />
                                </Box>
                            </Stack> :
                            <Stack justifyContent={"space-between"} height={"calc(100vh - 100px)"}>
                                <Box p={2}>
                                    <Stack direction={"row"} className={classes.totalBox} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} columnGap={1}>
                                            <TotalItemIcon />
                                            <Stack>
                                                <Typography className={classes.title}>{checkData?.totalhours}</Typography>
                                                <Typography className={classes.subTitle}>{
                                                    moment(new Date()).format("YYYY-MM-DD") === moment(checkData?.selectedDate).format("YYYY-MM-DD") ?
                                                        t("Today's Total checked in hours") :
                                                        `${moment(checkData?.selectedDate).format("DD MMM YY")} ${t("Total checked in hours")}`}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Box onClick={() => setDatePopup(true)}>
                                            <CheckInOutCalc />
                                        </Box>
                                    </Stack>
                                    <Typography className={classes.activityText}>{t("ACTIVITY")}</Typography>
                                    <Stack justifyContent={'space-between'} height={"100%"}>
                                        <Box className={classes.totalBox}
                                            sx={{
                                                // minHeight: checkData?.activityList?.length === 0 ? "auto" : "calc(100vh - 350px)",
                                                // maxHeight: checkData?.activityList?.length === 0 ? "auto" : "calc(100vh - 350px)",
                                                overflow: "auto"
                                            }}>
                                            {checkData?.activityList?.length === 0 ?
                                                moment(new Date()).format("YYYY-MM-DD") !== moment(checkData?.selectedDate).format("YYYY-MM-DD") ?
                                                    <Box className={classes.noDataBox}>
                                                        <Typography className={classes.noDataText}>{t("No Data Found")}</Typography>
                                                    </Box> :
                                                    <NewCheckinCard handleClick={() => setPopup(true)} t={t} /> :
                                                <Box>
                                                    <InfiniteScroll
                                                        dataLength={checkData?.listCount}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        height={size?.height - 400}
                                                    >
                                                        {
                                                            checkData?.activityList?.map((data, index) => {
                                                                return (
                                                                    <ActivityCard
                                                                        t={t}
                                                                        checkOut={data?.is_checked_out}
                                                                        title={data?.is_checked_out ? `${getHourmin(`${data?.checkin_date} ${data?.checkin_time}`, `${data?.checkout_date} ${data?.checkout_time}`)}` : ""}
                                                                        subTitle={data?.is_checked_out ?
                                                                            `${format(new Date(`${data?.checkin_date} ${data?.checkin_time}`), "p")} - ${format(new Date(`${data?.checkout_date} ${data?.checkout_time}`), 'p')}` :
                                                                            `${t("checked in on")} ${format(new Date(`${data?.checkin_date} ${data?.checkin_time}`), "p")}`
                                                                        }
                                                                        data={data}
                                                                        checkout={checkout}
                                                                        index={index}
                                                                        dataLength={checkData?.activityList?.length} />
                                                                )
                                                            })
                                                        }
                                                    </InfiniteScroll>
                                                </Box>}
                                        </Box>
                                        {
                                            (
                                                checkData?.activityList?.length > 0 &&
                                                checkData?.activityList?.filter((i) => i?.is_checked_out === false)?.length === 0 &&
                                                moment(new Date()).format("YYYY-MM-DD") === moment(checkData?.selectedDate).format("YYYY-MM-DD")
                                            ) &&
                                            <Box p={"0px 16px"}>
                                                <Button variant='contained' fullWidth className={classes.newCheckBtn}
                                                    onClick={() => newCheckIn()}>{t("New Check In")}</Button>
                                            </Box>
                                        }
                                    </Stack>
                                </Box>

                            </Stack>
                    }
                </div>
                <DialogDrawer
                    height={"380px"}
                    maxWidth={"sm"}
                    open={popup}
                    header={checkData?.checkout ? t("Check Out") : t("Check In")}
                    component={
                        <>
                            <CheckInOutPopup
                                checkout={checkData?.checkout}
                                handleClick={checkInOutClick}
                                t={t}
                                checkInOutData={checkData?.selectedListData}
                                functionalLocation={functionalLocation}
                                setFuncationalLocation={setFuncationalLocation}
                            />
                        </>
                    }
                    onClose={() => setPopup(false)}
                />

                <DialogDrawer
                    height={"350px"}
                    maxWidth={"sm"}
                    open={datePopup}
                    header={"Date"}
                    component={
                        <>
                            <CalendarWithTime
                                handleDateChange={date => getSelectedDateList(date)}
                                selectedDate={checkData?.selectedDate}
                                hideCondition={false}
                            />
                        </>
                    }
                    onClose={() => setDatePopup(false)}
                />
            </Container>

        </div>
    )
}

export default withNamespaces("dashboard")(CheckInOut)