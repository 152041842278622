import { makeStyles } from "@mui/styles";
import { Bold, Regular, remCalc } from "../../../utils";

export const CallCardStyle = makeStyles((theme) => ({
    card: {
        background: "#fff",
        borderRadius: "4px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        padding: "12px"
    },
    title:{
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    content:{
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: theme.typography.color.secondary
    },
    callText: {
        fontSize: remCalc(14),
        fontFamily: Regular,
        color: theme.typography.color.primary
    }
}))