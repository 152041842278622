import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "12px",
    borderBottom: (props) => props?.transparent ? "none" : `1px solid ${theme.palette.border.secondary}`,
    boxShadow: (props) => props?.transparent ? "none" : "0px 2px 6px #00000014",
    backgroundColor: (props) =>
      props?.color ? props?.color : theme.palette.background.paper,
  },
  title: {
    fontSize: "1rem",
    textTransform: "capitalize",
    fontFamily: Bold,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    '@media screen and (min-width: 320px)': {
      width: "270px"
    },
    '@media screen and (min-width: 375px)': {
      width: "325px"
    },
    '@media screen and (min-width: 425px)': {
      width: "380px"
    },
    '@media screen and (min-width: 768px)': {
      width: "490px"
    },
  },
  arrow: {
    fontSize: "1.25rem",
  },
  clear: {
    fontSize: "0.875rem",
    color: "#5078E1",
    fontFamily: SemiBold,
    cursor: "pointer",
  },
}));

export const TitleBar = (props) => {
  const classes = useStyles(props);
  const language = localStorage.getItem("i18nextLng")
  return (
    <Container maxWidth="sm" style={{ padding: "0px" }}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box>
                {!props?.hideBackButton &&
                  <IconButton size="small" onClick={props.goBack}>
                    {language === "ar" ?
                      <ArrowForwardIosIcon
                        fontSize="small"
                        className={classes.arrow}
                        style={{ color: props?.color ? "white" : "#091B29" }} /> :
                      <ArrowBackIosIcon
                        fontSize="small"
                        className={classes.arrow}
                        style={{ color: props?.color ? "white" : "#091B29" }}
                      />
                    }
                  </IconButton>}
              </Box>
              <Box flexGrow={1}>
                <Typography
                  className={classes.title}
                  style={{ color: props?.color ? "white" : "black" }}
                >
                  {props?.text}
                </Typography>
              </Box>
              <Box height={"30px"} width={"30px"} />
              <Box>
                {/* {props.clearFilter && (
                <Typography className={classes.clear} onClick={props.onClear}>
                  Clear Filter
                </Typography>
              )} */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
