import * as React from "react"
const VideoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 117061">
      <g fill="#fff" stroke="#ced3dd" data-name="Rectangle 55696">
        <rect width={28} height={28} stroke="none" rx={4} />
        <rect width={27} height={27} x={0.5} y={0.5} fill="none" rx={3.5} />
      </g>
      <path
        fill="#bd2c2c"
        d="M16.666 16.833A2.167 2.167 0 0 1 14.5 19h-5a2.167 2.167 0 0 1-2.167-2.167v-5.666A2.167 2.167 0 0 1 9.5 9h5a2.167 2.167 0 0 1 2.167 2.167Zm3.747-6.433a.5.5 0 0 0-.5.006l-2.58 1.544v4.1l2.576 1.55a.5.5 0 0 0 .757-.429v-6.338a.5.5 0 0 0-.253-.433Z"
      />
    </g>
  </svg>
)
export default VideoIcon