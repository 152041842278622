import React from "react";
import GateEntry from './gateEntry';

class GateEntryParent extends React.Component {
  render() {
    return <GateEntry />;
  }
}

export default (GateEntryParent);
