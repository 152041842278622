import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datepicker.css';
import { Bold, SemiBold } from "../../utils";
const useStyles = makeStyles((theme) => ({
      Label: {
            fontSize: "0.75rem",
            paddingBottom: "8px",
            fontFamily: Bold
      },
      textbox: {
            height: (props) => (props.multiline ? "unset" : 50),
            width: '100%',
            borderRadius: "6px",
            border: '1px solid #E4E8EE',
            cursor: 'pointer',
            color: "#091B29",
            fontSize: "0.875rem",
            fontFamily: SemiBold,
            [`& fieldset`]: {
                  borderRadius: "4px",
                  height: (props) => (props.multiline ? "unset" : 50),
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: (props) => (props.multiline ? "0px" : "11px 14px"),
            },
            ":-webkit-autofill": {
                  WebkitBoxShadow: "none !important",
                  backgroundColor: "red !important",
            },
            '&:focusVisible': {
                  border: '1.5px solid #E2E2E2',
            },
            // padding: '24px'
      },
      textbox2: {
            height: (props) => (props.multiline ? "unset" : 45),
            width: '100%',
            borderRadius: "6px",
            border: 'none',
            cursor: 'pointer',
            color: "#091B29",
            fontSize: "0.875rem",
            fontFamily: SemiBold,
            [`& fieldset`]: {
                  borderRadius: "4px",
                  height: (props) => (props.multiline ? "unset" : 50),
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "none",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: (props) => (props.multiline ? "0px" : "11px 14px"),
            },
            ":-webkit-autofill": {
                  WebkitBoxShadow: "none !important",
                  backgroundColor: "red !important",
            },
            '&:focusVisible': {
                  border: 'none',
            },
            padding: '0px 30px'
      },
      calander: {
            position: 'absolute',
            top: "25%",
            right: '25px',
      },
      calander2: {
            position: 'absolute',
            top: "25%",
            left: '0px',
      }
}));

export const DatePickerNew = (props) => {
      const language = localStorage.getItem("i18nextLng");
      const classes = useStyles(props);
      const getLabel = (props) => {
            return (
                  <div style={{ display: "flex" }}>
                        {
                              <Typography
                                    variant="body1"
                                    className={classes.Label}
                                    align="left"
                                    noWrap
                                    sx={{ color: props?.labelColor ? props?.labelColor : "#4E5A6B" }}
                              >
                                    {props?.label}
                              </Typography>
                        }
                        {props?.label && props?.isRequired && (
                              <Typography color="error" variant="caption">
                                    &nbsp;*
                              </Typography>
                        )}
                  </div>
            );
      };
      return (
            <>
                  {getLabel(props)}
                  <div style={{ position: 'relative' }}>
                        {
                              props?.dateFormat ?
                                    <Datepicker
                                          dateFormat={props?.dateFormat ?? "dd-MM-yy"}
                                          selected={new Date(props?.value)}
                                          onChange={date => props?.onChange(date)}
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          className={props?.borderNone ? classes.textbox2 : classes.textbox}
                                          placeholderText={props?.placeholder}
                                          minDate={props?.minDate ?? false}
                                          maxDate={props?.maxDate ?? false}
                                          onKeyDown={(e) => {
                                                e.preventDefault();
                                          }}
                                          disabled={props?.isReadonly}
                                          onFocus={(e) => e.target.readOnly = true}
                                          popperPlacement={props?.placement ?? 'auto-start'}
                                          popperProps={{ strategy: 'fixed' }}
                                    />
                                    :
                                    <Datepicker
                                          dateFormat={props?.date_format}
                                          selected={props?.value}
                                          onChange={date => props?.onChange(date)}
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          className={props?.borderNone ? classes.textbox2 : classes.textbox}
                                          placeholderText={props?.placeholder}
                                          minDate={props?.minDate ?? false}
                                          maxDate={props?.maxDate ?? false}
                                          onKeyDown={(e) => {
                                                e.preventDefault();
                                          }}
                                          disabled={props?.isReadonly}
                                          onFocus={(e) => e.target.readOnly = true}
                                          popperPlacement={props?.placement ?? 'auto-start'}
                                          popperProps={{ strategy: 'fixed' }}
                                    />
                        }
                        {!props?.hideIcon&&language === "ar" ?
                              (<div class={"datepicker"}>
                                    <img src={'/images/schedule1.svg'} alt="" />
                              </div>) :
                              !props?.hideIcon&&
                              (<div className={props?.borderNone ? classes.calander2 : classes.calander} >
                                    <img src={'/images/schedule1.svg'} alt="" />
                              </div>)
                        }

                  </div>
                  {props.isError && (
                        <Typography variant={"caption"} color={"error"} noWrap>
                              {props.errorMessage}
                        </Typography>
                  )}
            </>
      )
}