import * as React from "react"
const CheckinCardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={74.795}
    height={74.795}
    {...props}
  >
    <path
      fill="#ced3dd"
      d="M37.4 0A37.378 37.378 0 0 0 .719 44.676a6.516 6.516 0 0 1 10.463 2.538 27.984 27.984 0 0 0 47.156 8.827 6.529 6.529 0 0 1 .562-13.034h13.09a6.482 6.482 0 0 1 2.275.431A37.182 37.182 0 0 0 37.4 0Zm-.935 14.959a2.8 2.8 0 0 1 2.8 2.8v17.769h12.157a2.805 2.805 0 0 1 0 5.61H36.463a2.8 2.8 0 0 1-2.8-2.8V17.764a2.8 2.8 0 0 1 2.8-2.805ZM5.109 46.71a2.805 2.805 0 0 0-2.662 3.842 37.348 37.348 0 0 0 66.738 6.538v5.551a2.805 2.805 0 1 0 5.61 0V49.552a2.805 2.805 0 0 0-2.8-2.8H58.9a2.805 2.805 0 1 0 0 5.61h6.566q-.062.093-.117.19a31.749 31.749 0 0 1-57.663-4 2.805 2.805 0 0 0-2.577-1.842Z"
      data-name="icons8-clock-arrow (1)"
    />
  </svg>
)
export default CheckinCardIcon
