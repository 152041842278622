import jwt_decode from "jwt-decode";

/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  userId: "user_id",
  roles: "roles",
  clientLogo: "clientLogo",
  userProfileId: "user_profile_id",
  selected: "selected",
  accessType: "accessType",
  client: "client",
  deviceToken: "device_token",
  lastCancelClickTime: "lastCancelClickTime",
  userProfile: "userProfile",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export let roles = {
  securityGuard: "Security Guard"
}
export let visitorTypes = {
  domesticHelper: "DH"
}
export const getClient = (token) => jwt_decode(token)?.selected_client
//enum types

export const enumTypes = {
  delivery_status_type: "delivery_status_type",
  security_status_enum_type: "security_status_enum_type",
  security_request_type: "security_request_type",
  vechicle_type: "vechicle_type",
  swift_type: "swift_type",
  parking_payment_type: "parking_payment_type",
  vehicle_type: "vehicle_type",
  parking_area_type: "parking_area_type"
}

const language = localStorage.getItem("i18nextLng")

//fonts function
export const Regular = language === "ar" ? "IBMPlexSans-Arabic-Regular" : "IBMPlexSans-All-Regular";
export const Bold = language === "ar" ? "IBMPlexSans-Arabic-Bold" : "IBMPlexSans-All-Bold";
export const SemiBold = language === "ar" ? "IBMPlexSans-Arabic-SemiBold" : "IBMPlexSans-All-SemiBold";
export const ExtraBold = language === "ar" ? "IBMPlexSans-Arabic-ExtraBold": "IBMPlexSans-All-ExtraBold";
export const Italic = language === "ar" ? "IBMPlexSans-Arabic-Italic": "IBMPlexSans-All-Italic";

export const availabilityOptions = [
  { label: "Vacant", value: "Vacant" },
  { label: "Occupied", value: "Occupied" },
]

export const allowed_file_size = 5

export const remCalc = (val) => {
  return `${(val < 12 ? (12 / 16) : (val / 16))}rem`
}

export const LanguageOptions = [
  { value: "en", label: "English" },
  { value: "es", label: "Español" },
  { value: "ar", label: "العربية" },
  { value: "fr", label: "Français" },
];

export const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const gmtPattern = '\'GMT\' XXX' // only use for date fns

export function wrapLabelsInT(enumValues, t) {
  return enumValues.map(item => {
      return {
          label: `${t(item.label)}`,
          value: item.value
      };
  });
}
