import * as React from "react"
const NonDeliveryIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 117194">
      <g data-name="icons8-products-pile (1)">
        <path
          fill="#ff9800"
          d="M11.387 22.421H2.192a.6.6 0 0 1-.613-.579v-7.526H12v7.526a.6.6 0 0 1-.613.579Z"
          data-name="Path 101905"
        />
        <path
          fill="#ffaa2e"
          d="M12.579 14.316H1v-1.737A.6.6 0 0 1 1.61 12h10.36a.6.6 0 0 1 .609.579Z"
          data-name="Path 101906"
        />
        <path
          fill="#ff9800"
          d="M21.808 22.421h-9.195a.6.6 0 0 1-.613-.579v-7.526h10.421v7.526a.6.6 0 0 1-.613.579Z"
          data-name="Path 101907"
        />
        <path
          fill="#ffaa2e"
          d="M23 14.316H11.421v-1.737a.6.6 0 0 1 .61-.579h10.36a.6.6 0 0 1 .609.579Z"
          data-name="Path 101908"
        />
        <path
          fill="#ff9800"
          d="M16.597 12H7.402a.6.6 0 0 1-.613-.579V3.895H17.21v7.526a.6.6 0 0 1-.613.579Z"
          data-name="Path 101909"
        />
        <path
          fill="#ffaa2e"
          d="M17.79 3.895H6.211V2.158a.6.6 0 0 1 .61-.579h10.36a.6.6 0 0 1 .609.579Z"
          data-name="Path 101910"
        />
        <path
          fill="#754600"
          d="M3.316 18.947h.579v1.158h-.579z"
          data-name="Rectangle 58524"
        />
        <path
          fill="#754600"
          d="M4.474 18.948H2.737l.872-1.159Z"
          data-name="Path 101911"
        />
        <path
          fill="#754600"
          d="M5.632 18.919h.579v1.186h-.579z"
          data-name="Rectangle 58525"
        />
        <path
          fill="#754600"
          d="M6.79 18.948H5.053l.867-1.158Z"
          data-name="Path 101912"
        />
        <path
          fill="#754600"
          d="M2.737 20.684H6.79v.579H2.737z"
          data-name="Rectangle 58526"
        />
        <path
          fill="#754600"
          d="M13.737 18.947h.579v1.158h-.579z"
          data-name="Rectangle 58527"
        />
        <path
          fill="#754600"
          d="M14.895 18.948h-1.737l.872-1.159Z"
          data-name="Path 101913"
        />
        <path
          fill="#754600"
          d="M16.053 18.919h.579v1.186h-.579z"
          data-name="Rectangle 58528"
        />
        <path
          fill="#754600"
          d="M17.211 18.948h-1.737l.867-1.158Z"
          data-name="Path 101914"
        />
        <path
          fill="#754600"
          d="M13.158 20.684h4.053v.579h-4.053z"
          data-name="Rectangle 58529"
        />
        <path
          fill="#754600"
          d="M8.526 8.526h.579v1.158h-.579z"
          data-name="Rectangle 58530"
        />
        <path
          fill="#754600"
          d="M9.684 8.527H7.947l.872-1.159Z"
          data-name="Path 101915"
        />
        <path
          fill="#754600"
          d="M10.842 8.498h.579v1.186h-.579z"
          data-name="Rectangle 58531"
        />
        <path
          fill="#754600"
          d="M12 8.526h-1.737l.867-1.158Z"
          data-name="Path 101916"
        />
        <path
          fill="#754600"
          d="M7.947 10.263H12v.579H7.947z"
          data-name="Rectangle 58532"
        />
        <path
          fill="#c77600"
          d="M16.651 12H7.359a.57.57 0 0 1-.57-.57v-.009H17.21v.02a.559.559 0 0 1-.559.559Z"
          data-name="Path 101917"
        />
        <path
          fill="#c77600"
          d="M12 12v9.842a.579.579 0 0 1-.579.579V12Z"
          data-name="Path 101918"
        />
        <path
          fill="#c77600"
          d="M12 12.579a.579.579 0 0 1 .579-.579H12Z"
          data-name="Path 101919"
        />
        <path
          fill="#c77600"
          d="M11.421 12.579a.579.579 0 0 0-.579-.579h.579Z"
          data-name="Path 101920"
        />
      </g>
    </g>
  </svg>
)
export default NonDeliveryIcon
