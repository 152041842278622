import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import React from 'react';
import {  Camera } from '../../assets/camera';
import { config } from '../../config';
import { AlertContext,BackdropContext } from '../../contexts';
import { AlertProps, allowed_file_size, Bold, SemiBold, singleFileUpload } from '../../utils';
import { IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "fontFamily" && prop !== "fontSize" && prop !== "color"
})(({ fontFamily, fontSize, color }) => ({
    fontFamily,
    fontSize,
    color
}))

const ImageWrapper = styled('div')({
    width: 120,
    height: 120,
    borderRadius: 71,
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position:"relative"
});

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const CustomAvatar = styled(Avatar)({
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
})

const UploadButton = styled(IconButton)(({ theme }) => ({
    border: "3px solid white",
        position: "absolute",
    marginTop: theme.spacing(2),
    color: "#071741",
    background: "#E4E8EE",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    cursor: "pointer",
    // '&:hover': {
    //     background: "#FFFFFF"
    // },
    bottom:"0px",
    right:"0px"
}))

export const ProfileUpload = (props) => {

    const {
        logo_title = "",
        accept = "image/*",
        fileUploadUrl = true,
        handleChange = null,
        data = {},
        isReadonly = false,
        isError = false,
        errorMessage = "",
        isrequired = false,
        icon
    } = props;

    const theme = useTheme();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);

    const [loading, setLoading] = React.useState(false);

    const handleUpload = async (data) => {


        // const {height} = await findImageSize(data?.[0]);


        // if(height > 30){
        //     alert.setSnack({
        //         open: true,
        //         horizontal: AlertProps.horizontal.center,
        //         vertical: AlertProps.vertical.top,
        //         msg: `Image Height Shoud Be 30px`,
        //         severity: AlertProps.severity.error,
        //     });
        // }
        //only allow if file selected
        if (data?.length) {
            if (fileUploadUrl) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: true,
                    message: "Uploading...",
                    isOnclose:false
                  });
                executeImageData(data)
            }
            else {
                handleChange && handleChange(data)
            }

        }
    }

    const executeImageData = async (data) => {

        setLoading(true);

        let uploaded_file = await singleFileUpload(data?.[0], { tenantId: `${config.tenantId}` }, alert, allowed_file_size)
        if (uploaded_file?.[0]?.url) {
            handleChange && handleChange({ src: uploaded_file?.[0]?.url });
            setLoading(false);
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "Upload Completed",
                isOnclose:false
              });
        } else {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
                isOnclose:false

              });
            handleChange(null);
            setLoading(false);
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: `Unable to upload`,
                severity: AlertProps.severity.error,
            });
        }
    }

    const removeImage = () => {
        handleChange && handleChange(null)
    }

    return (
        <div>
          
                {logo_title &&
                    <CustomTypography
                        fontFamily={Bold}
                        fontSize={12}
                        textAlign="center"
                        
                        color={theme.typography.color.secondary}
                        sx={{
                            mb: 2
                        }}
                    >
                        {logo_title}
                        {isrequired && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                *
                            </Typography>
                        )}
                    </CustomTypography>
                }
                {
                    loading ? (
                        <Skeleton width={141} height={141} variant={"circular"} />
                    ) : (
                        <>
                            <ImageWrapper justifyContent={props?.float ?? "center"}>
                                {
                                    data?.src ? (

                                        <CustomAvatar src={data?.src} />
                                    ) : (
                                        icon?
                                        icon:
                                        <>
                                            <PersonIcon sx={{ fontSize: "60px", color: "#98A0AC" }} />
                                        </>
                                    )
                                }
                                {
                                    !isReadonly &&
                                    <>

                                        <UploadButton
                                            variant={"outlined"}
                                            disabled={loading}
                                        >
                                            <Camera />
                                            <CustomInput
                                                type="file"
                                                onChange={(e) => handleUpload(e.target.files)}
                                                accept={accept}
                                            />
                                        </UploadButton>
                                    </>
                                }

                            </ImageWrapper>
                        </>
                    )
                }
         
                {
                    !isReadonly &&
                    <>
                        {
                            (data?.src && !loading) &&
                            <CustomTypography
                                fontFamily={Bold}
                                fontSize={12}
                                color={theme.palette.primary.main}
                                onClick={removeImage}
                                sx={{
                                    mt: 1,
                                    cursor: "pointer",
                                    marginLeft:"12px"
                                }}
                            >
                                {"Remove Image"}
                            </CustomTypography>
                        }
                    </>
                }
                {
                    !isReadonly &&
                    <>
                        {isError && (
                            <Typography variant={"caption"} color={"error"}>
                                {errorMessage}
                            </Typography>
                        )}
                    </>
                }
        </div>
    )
}