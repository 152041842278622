import makeStyles from "@mui/styles/makeStyles";
import { Bold, Italic, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.typography.color.tertiary1,
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
    },
    img: {
        height: "40px",
        width: "40px"
    },
    title: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold,

    },
    subTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        margin: "4px 0px"

    },
    viewMore: {
        fontSize: "0.75rem",
        color: "#B20606",
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    rootBox: {
        backgroundColor: theme.typography.color.tertiary1,
        // padding: "16px",
    },
    divider: {
        marginTop: "12px"
    },
    avatarImg: {
        width: "80px",
        height: "80px",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    avatarImgBox: {
        marginTop: "20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    },
    name: {
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: Bold,
        textAlign: "center",
        marginTop: "12px"
    },
    drawertitle: {
        fontSize: "0.75rem",
        color: "#fff",
        fontFamily: Italic,
        backgroundColor: theme.palette.background.glaucous,
        padding: "4px 8px",
        borderRadius: theme.palette.borderRadius
    },
    department: {
        fontSize: "0.75rem",
        color: "#fff",
        fontFamily: Italic,
        backgroundColor: theme.palette.background.brown,
        padding: "4px 8px",
        borderRadius: theme.palette.borderRadius

    },
    subtitles: {
        gap: "8px",
        marginTop: "12px"
    },
    reportingname: {
        color: theme.typography.color.secondary,
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        textAlign: "center",
        marginTop: "12px"
    },
    drawer: {
        "&. MuiPaper-root": {
            borderRadius: "12px",
        },
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            padding: "16px"
        },
        "& .MuiContainer-root": {
            padding: "0px",
        },
    },
    dialog: {
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "100% !important",
            borderRadius: "4px",
            height: (props) => props?.noService && "400px",
            padding: "16px"

        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative'
        },
        '& .MuiDialog-container': {
            padding: (props) => props?.padding ?? '',

        }
    },
    chatsBox: {
        marginTop: "20px"
    },
    arrow:{
        rotate: "180deg"
    }
}));