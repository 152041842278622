
import { useSocket } from "./socket";
import WaitingScreen from "./waitingScreen";
import { useState, useEffect, useContext } from "react";
import VideoContainer from "./videoCall";
import { Container } from "@mui/material";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import Peer from 'peerjs';
import { Routes } from "../../router/routes";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { LoadingSection } from "../../components";
import { withNamespaces } from "react-i18next";

const VideoCall = ({ t = () => false }) => {

    const socket = useSocket();
    const history = useHistory();
    const searchURL = useLocation().search;
    const request_id = new URLSearchParams(searchURL).get("id");
    const status = new URLSearchParams(searchURL).get("status");
    const alert = useContext(AlertContext);
    const [isvideo, setIsVideo] = useState(false);
    const [live_user, setLiveUser] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [peer, setPeer] = useState(null);
    const [partnerPeerId, setPartnerPeerId] = useState('');
    const [controls, setControls] = useState({
        video: true,
        audio: true,
        facingMode: 'user'
    })
    const [partnerControl, setPartnerControl] = useState({
        audio: false,
        video: false
    })

    const [details, setDetails] = useState({
        data: null,
        loading: true
    });


    useEffect(() => {

        if (socket && details?.data) {

            socket.emit('users', {
                token: localStorage.getItem(LocalStorageKeys?.authToken),
            });
            socket.on("user_connected", (data) => {

                console.log(data?.connected_users, 'user_connected');

                const isSubset = [details?.data?.trigger_to, localStorage.getItem(LocalStorageKeys.userId)].every(value => data?.current_active_user?.includes(value));

                setLiveUser(isSubset ? true : false);

            })
            socket.on("disconnect_trigger", (user) => {

                disconnect()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: `${user?.first_name} Left`,
                });

            })

            if(status==="accept"){
                onAccept()
            }

            if(status==="rejected"){
                disconnect()
            }
            return () => {
                disconnect()
            };

        }

        // eslint-disable-next-line
    }, [socket,details]);


    useEffect(() => {

        

        const peerInstance = new Peer(localStorage.getItem(LocalStorageKeys.userId), {
            key: "peerjs",
            debug: 2,
            secure: true
        });
        

        setPeer(peerInstance);

        peerInstance.on('open', (id) => {
            console.log('My peer ID is: ' + id);
            // setMyPeerId(id);
        });

        peerInstance.on('call', (call) => {
            // Answer the call and send your stream
            const userMediaConstraints = {
                video: controls?.video ? { facingMode: controls?.facingMode } : false,
                audio: controls?.audio,
            };

            navigator.mediaDevices
                .getUserMedia(userMediaConstraints)
                .then((stream) => {

                    call.answer(stream);
                    call.on('stream', (partnerStream) => {


                        const isVideoEnabled = partnerStream.getVideoTracks().length > 0;
                        const isAudioEnabled = partnerStream.getAudioTracks().length > 0;

                        // Handle the partner's stream
                        const partnerVideo = document.getElementById('partner-video');
                        if (partnerStream) {
                            partnerVideo.srcObject = partnerStream;
                        }

                        setPartnerControl({
                            audio: isAudioEnabled,
                            video: isVideoEnabled
                        })

                    });
                })
                .catch((error) => {
                    console.error('Error accessing user media:', error);
                });
        });

        return () => {
            // Clean up Peer.js when the component is unmounted
            if (peer && socket) {
                disconnect()
            }
        };

        // eslint-disable-next-line
    }, [])


    const disconnect = () => {

        
        peer.destroy();

        navigator.mediaDevices.getUserMedia({audio:false,video:false})

        if (socket) {
        socket.emit('disconnect_trigger', details?.data?.created_by, details?.data?.trigger_to);
        }
        history.push(`${Routes.walkInDetails}?passId=${details?.data?.id}`)
        
        

    }

    const goBack = () => {
        disconnect()
    }

    useEffect(() => {

        let timer;

        if (seconds > 0 && !isvideo) {
            timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }

        // Cleanup the timer when the component unmounts or when seconds reach 0
        return () => clearInterval(timer);

        // eslint-disable-next-line
    }, [seconds]);

    useEffect(() => {

        if (seconds === 0 && isvideo) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: "Resisent Lefted",
            });

            disconnect()
        }

        // eslint-disable-next-line
    }, [seconds])


    const accessControl = (controls) => {

        setControls(controls)

        navigator.mediaDevices
            .getUserMedia(controls)
            .then((stream) => {
                peer.call(partnerPeerId, stream);
            })
            .catch((error) => {
                console.error('Error accessing user media:', error);
            });

    }

    useEffect(() => {

        if (request_id) {

            NetworkCall(
                `${config.api_url}/walkInout/details`,
                NetWorkCallMethods.post,
                { id: request_id },
                null,
                true,
                false
            ).then((res) => {

                setDetails({
                    data: res?.data,
                    loading: false
                })

                setPartnerPeerId(res?.data?.trigger_to)
            }).catch((err) => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    const startCall = () => {


        // Call a partner using their peer ID
        const userMediaConstraints = {
            video:controls?.video,
            audio: controls?.audio,
        };


        console.log(partnerPeerId, "userMediaConstraints")

        navigator.mediaDevices
            .getUserMedia(userMediaConstraints)
            .then((stream) => {
                console.log(stream,"stream")
                const call = peer.call(partnerPeerId, stream);
                console.log("sreamed")
                // // Handle the partner's stream
                call.on('stream', (partnerStream) => {
                    // For example, display it in a video element
                    console.log("skslks")
                    const partnerVideo = document.getElementById('partner-video');
                    console.log(partnerStream, "partnerStream")
                    partnerVideo.srcObject = partnerStream;
                });
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Unabled to stream`,
                });
                console.error('Error accessing user media:', error);
            });
    };

    const onAccept = () => {
        setIsVideo(true)
        startCall()
        socket.emit('accpet-resident', details?.data?.trigger_to, localStorage.getItem(LocalStorageKeys.userId));
    }


    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>

            {
                details?.loading ?
                    <LoadingSection message={"Loading"} top={"20vh"} />
                    :

                    <>
                        {isvideo ?

                            <VideoContainer
                                goBack={goBack}
                                disconnect={disconnect}
                                controls={controls} accessControl={accessControl}
                                partnerControl={partnerControl}
                                t={t}
                                details={details?.data}
                            />

                            :

                            <WaitingScreen
                            onAccept={onAccept}
                                live_user={live_user}
                                goBack={goBack}
                                details={details?.data}
                                t={t}
                                cancelCall={disconnect}
                                controls={controls} accessControl={accessControl}
                            />

                        }
                    </>
            }


        </Container>

    )
}
export default withNamespaces("walkInRequest")(VideoCall)