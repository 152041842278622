import { Avatar, Box, Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { Bold, Regular, SemiBold, generateImageUrl, img_size } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    overflow: "hidden",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 4px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  yetto: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
    background: "#5078E11E",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  received: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#FF9340",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  receivedDetail: {
    fontSize: "0.75rem",
    color: "#FF9340",
    fontFamily: Bold,
    background: "#FFF4EB",
    padding: "1px 8px",
    borderRadius: "4px",
    marginTop: "6px",
    display: "inline",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  collected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular,
  },

  box: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  img: {
    // objectFit: "cover",
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "110px"
  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,

  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,

  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },

}));

export const ParkingCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <Grid
        container
        className={classes.card}
        onClick={() =>
          history.push(Routes.parkingSlotDetails + "?passId=" + props?.data?.id)
        }
      >
        <Grid item xs={12}>
          <div className={classes.Cardcontent}>
            <Box alignItems="center" display="flex" sx={{ flexWrap: "nowrap" }} position="relative">
              {props?.data?.vehicle_image ?
                <Avatar src={generateImageUrl(props?.data?.vehicle_image, img_size.small_square)} alt={props?.data?.vehicle_image} variant="rounded" className={classes.avatar} /> :

                <Avatar src={
                  props?.parking
                    ? "/images/parkingslot.svg"
                    : "/images/workergate.svg"
                }
                  className={classes.img} variant="rounded" />
              }
              <div className={classes.bottomText}>
                <Typography fontSize={"10px"}>
                  {props?.data?.request_no}
                </Typography>
              </div>

              <Box flexGrow={1} marginLeft="10px">
                <Box alignItems="center" display="flex" justifyContent={"space-between"}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      noWrap
                    >
                      <Hidden smUp>Parking Slot</Hidden>
                      <Hidden smDown>Parking Slot Requirement</Hidden>
                      {/* {props?.data?.request_no} */}
                    </Typography>

                  </Box>
                  <Box marginRight="10px">
                    {!props?.data?.actual_out && (
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.yetto}
                      >
                        Yet To Checkout
                      </Typography>
                    )}
                  </Box>

                </Box>
                <Box
                  style={{ flexFlow: "wrap !important" }}
                  className={classes.box}
                  alignItems="center"
                  display="flex"
                  marginTop="4px"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        className={classes.sub}
                        noWrap
                      >
                        {props?.data?.property_name}
                      </Typography>
                    </Box>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box className={classes.dot} />
                    <Box>
                      <Typography
                        variant="subtitle2"
                        className={classes.sub}
                        noWrap
                      >
                        {props?.data?.units?.[0]?.unit_no ??
                          props?.data?.unit?.[0]?.unit_no}
                      </Typography>
                    </Box>
                  </div>
                </Box>
                <Box
                  style={{ flexFlow: "wrap !important" }}
                  className={classes.box}
                  alignItems="center"
                  display="flex"
                  marginTop="4px"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        className={classes.semiBold}
                        noWrap
                      >
                        {props?.data?.vechicle_number ? "Vehicle Number :" + props?.data?.vechicle_number : ""}
                      </Typography>
                    </Box>
                  </div>


                  {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                  {props?.data?.request_no && <Box className={classes.dot} />}

                  <Box>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {props?.data?.request_no}
                    </Typography>
                  </Box>
                </div> */}
                </Box>
                <Box
                  style={{ flexFlow: "wrap !important" }}
                  className={classes.box}
                  alignItems="center"
                  display="flex"
                  marginTop="4px"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DateIcon /> &nbsp;
                    <Box>
                      <Typography
                        variant="subtitle2"
                        className={classes.bold}
                        noWrap
                      >
                        {moment(props?.data?.request_from)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY")}
                        &nbsp;-&nbsp;
                        {moment(props?.data?.request_to)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY")}
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </Box>

            </Box>
          </div>
          {/* <div className={classes.Cardcontent}>
            <Box alignItems="center" display="flex">
              <Box className={classes.img}>
                <img
                  src="/images/Dh.svg"
                  alt=""
                  style={{ objectFit: "contain", marginTop: "4px" }}
                />
              </Box>
              <Box flexGrow={1} marginLeft="10px">
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      noWrap
                    >
                      {props?.data?.title}
                    </Typography>
                  </Box>
                  <Box marginLeft="10px">
                    {!props?.data?.actual_out && (
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.yetto}
                      >
                        Yet To Checkout
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box alignItems="center" display="flex" marginTop="0px">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.subtitle1}
                    </Typography>
                  </Box>
                  <Box className={classes.dot} />

                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.actual_out
                        ? "Out " +
                          moment(props?.data?.actual_out)
                            .tz(moment.tz.guess())
                            .format("DD MMM YY hh:mm:a")
                        : "In " +
                          moment(props?.data?.actual_in)
                            .tz(moment.tz.guess())
                            .format("DD MMM YY hh:mm:a")}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="subtitle2"
                  className={classes.sub}
                  style={{ marginTop: "3px" }}
                  noWrap
                >
                  {props?.data?.subtitle2}
                </Typography>
              </Box>
              <Box>
                <ArrowForwardIosIcon
                  style={{ color: "#98A0AC", fontSize: "0.875rem" }}
                />
              </Box>
            </Box>
          </div> */}
        </Grid>
      </Grid>
    </>
  );
};
