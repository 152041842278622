import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, Message, RetainLocalStorage, ValidateEmail } from "../../utils";
import { Bold, ExtraBold, LocalStorageKeys, NetWorkCallMethods, Regular, SemiBold, remCalc } from "../../utils/constants";
import ResetPassword from "./resetPassword";
import { withNamespaces } from "react-i18next";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { silentOTP } from "./silentOTP";
import { initital_screen_state } from "../../utils/login/utils";
import { TitleBarNew } from "../../components/titlebarNew";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { getCurrentBuildNumber } from "../../utils/version";
import { ExploreIcon } from "./assets/exploreIcon";



const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100% 25%",
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "0.9375rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: Bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  forgetPassword: {
    textAlign: "right",
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    marginTop: "10px",
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
  },
  version: {
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: "#091B29",
  }
}));

const InitialState = {
  email: "",
  password: "",
  error: {
    email: "",
    password: "",
  },
};
export const loginUseStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "white",
  },
  grid: {
    height: ({ size }) => size?.height,
    alignItems: "center",
  },
  left_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  right_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
  },
  web_right_section_card: {
    display: "contents",
    height: ({ size }) => size?.height,
    padding: "0px",
  },
  web_back_button: {
    cursor: "pointer",
    height: "32px",
    width: "32px",
  },
  web_content: {
    height: "100vh",
    overflow: "overlay",
    display: "flex",
    position: "relative",
    padding: "40px",
    backgroundColor: "white",
  },
  web_logo: {
    height: "32px",
    objectFit: "contain",
  },
  web_title: {
    fontSize: remCalc(16),
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  web_description: {
    fontSize: remCalc(16),
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  web_forget_password: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: "#5078E1",
    textAlign: "end",
    cursor: "pointer",
  },
  web_login_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#b20606",
    border: "1px solid #b20606",
  },
  web_login_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.white,
  },
  web_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
    boxShadow: "0px 6px 10px #00000014",
    border: "1px solid #b20606",
    "&:hover": {
      backgroundColor: "#b20606",
    },
  },
  web_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.primary_2,
  },
  mob_right_section_card: {
    padding: "0px",
  },
  mob_content: {
    // height: "calc(100vh - 54px)",
    // overflow: "overlay",
  },
  mob_body_items: {
    padding: "16px",
  },
  mob_background: {
    height: "235px",
    borderRadius: theme.palette.borderRadius,
  },
  mob_background_image: {
    height: "235px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  },
  mob_bottom_items: {
    padding: "16px",
  },
  mob_logo: {
    height: "32px",
    objectFit: "contain",
  },
  mob_description: {
    fontSize: remCalc(20),
    fontFamily: SemiBold,
    color: "#FEF4F4",
    textShadow: "0px 6px 10px #00000033",
  },
  mob_set_up_new_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  mob_set_up_new_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "white",
  },
  mob_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#ffffff75",
    "&:hover": {
      backgroundColor: "#ffffff75",
    },
  },
  mob_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "white",
  },
  mob_terms_of_use_description: {
    width: "100%",
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: "white",
  },
  mob_terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: Bold,
    color: "white",
    textDecoration: "underline",
    display: "inline",
    cursor: "pointer",
  },
  version_no: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.tertiary,
  },
  terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.tertiary,
    cursor: "pointer",
  },
  powered_by_logo: {
    height: "11px",
    objectFit: "contain",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      minWidth: ({ size }) => size?.width > 599 && "500px",
      maxWidth: ({ size }) => size?.width > 599 && "500px",
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  drawer_header_grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
    padding: "16px",
  },
  drawer_header_text: {
    marginLeft: "12px",
    fontSize: remCalc(16),
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  drawer_content_box: {
    height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
    overflow: "overlay",
    padding: "16px",
  },
  drawer_button_box: {
    padding: "16px",
    borderTop: `1px solid ${theme.palette.border.secondary}`
  },
  region_box: {
    cursor: "pointer",
    height: "40px",
    padding: "7px 4px 7px 8px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #E4E8EE",
    borderRadius: "20px",
    columnGap: "2px",
  },
  region_flag: {
    borderRadius: "50%",
    objectFit: "contain",
    height: "18px",
    width: "18px",
  },
  region_menu_list: {
    "& .MuiPopover-paper": {
      maxHeight: "250px",
      minWidth: "200px",
      marginTop: "10px",
      padding: "8px 16px",
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
    },
  },
  selected_region_menu_item: {
    border: "1px solid #5078E1",
    borderRadius: theme.palette.borderRadius,
    padding: "12px 5px",
    backgroundColor: "#F1F7FF",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  unselected_region_menu_item: {
    borderRadius: theme.palette.borderRadius,
    padding: "12px 5px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  selected_region_name: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.selected_1
  },
  unselected_region_name: {
    fontSize: remCalc(14),
    fontFamily: Regular,
    color: theme.typography.color.primary
  },
  exploreCard: {
        cursor: "pointer",
        backgroundColor: "#F8EBFF",
        borderRadius: theme.palette.borderRadius3,
        border: "1px solid #E8D5F2",
        padding: "10px 12px",
  },
  exploreInformation: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: "#4A0A77",
  },
  exploreCTA: {
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: "#4A0A77",
  },
}));

const Login = (props) => {
  const { t = () => false } = props;
  const [app_version, set_app_version] = React.useState(null);
  const state = useLocation().state;
  const size = useWindowDimensions();
  const classes_1 = loginUseStyles({ size });
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [login, setLogin] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const search = useLocation().search;
  const [region_list, set_region_list] = useState({});
  const [disable, setDisable] = React.useState(false)
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const deviceToken = new URLSearchParams(search).get(
    "deviceToken"
  );

  const [showDrawer, setShowDrawer] = React.useState(false);
  const firebaseContext = React.useContext(FirebaseContext);
  const [regionAnchorEl, setRegionAnchorEl] = useState(null);
  const is_region_open = Boolean(regionAnchorEl);
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);

  useEffect(() => {
    getCurrentBuild();
    initialLoad();
    getRegionList();
    // eslint-disable-next-line
  }, []);

  const getCurrentBuild = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
        set_app_version(temp_app_version);
      } catch (error) {
        reject(error);
      }
    });
  }

  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
            [_?.explore_meta && "explore_meta"]: _?.explore_meta?.[0],
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }

  React.useEffect(() => {
    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      let Token = jwt_decode(welcomePageAuthToken)
      setLogin({
        ...login,
        email: Token.email_id
      })

    } else if (authToken !== null && authToken !== "") {
      history.push(Routes.dashboard);
    } else {
      // history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [history]);
  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;

    if (login.email.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage(" Enter your email/mobile/UCID");
    }
    if (login.password.length === 0) {
      isValid = false;
      error.password = Message.English.requiredMessage("Password");
    }
    if (login.email !== "" && login.password !== "") {
      isValid = true;
    }
    setLogin({ ...login, error });
    return isValid;
  };

  // eslint-disable-next-line
  const onSendLoginBtnClicked = (e) => {
    let data = {
      // tenantId: `${config.tenantid}`,
      password: login.password,
    };
    if (isIamValideToLogin()) {
      // Check phone number
      //eslint-disable-next-line
      var isphone = /^\d+$/.test(login.email)
      // Check email id
      //eslint-disable-next-line
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(login.email);
      if (isphone) {
        data["mobile_no"] = login.email;
      } else if (email) {
        data["email_id"] = login.email?.toLowerCase();
      } else {
        data["ucid"] = login.email;
      }
      NetworkCall(
        `${config.auth_api_url}/auth/login`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 200) {
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              history.push(
                Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
              );
            } else {
              history.push(
                Routes.otpPage +
                "?data=" +
                (login.email)
              );
            }
            localStorage.setItem(LocalStorageKeys.authToken, response.data.token)

          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Credientials",
          });
          RetainLocalStorage();
        });
    } else {
      return false;
    }
  };
  const onDrawerClose = () => {
    setShowDrawer(false);
  };
  // eslint-disable-next-line
  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onSendLoginBtnClicked()
    }
  }
  const handleGoBack = () => {
    history.goBack(-1);
  }

  const getRegionList = () => {
    NetworkCall(
      `${config.auth_api_url}${BackendRoutes?.login_domain_domain_list}`,
      NetWorkCallMethods.post, {}, false, false
    ).then((response) => {
      set_region_list(response?.data?.data?.domainList ?? []);
    }).catch((err) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    });
  };

  const handleRegion = (e) => {
    setRegionAnchorEl(e.currentTarget);
  }

  const handleRegionClick = (_) => {
    setRegionAnchorEl(null);

    let temp_screen_state = {
      ...screen_state,
      [_?.id && "id"]: _?.id,
      [_?.region && "region"]: _?.region,
      [_?.login_logo && "logo"]: _?.login_logo,
      [_?.web_background && "web_background"]: _?.web_background,
      [_?.mobile_background && "mob_background"]: _?.mobile_background,
      [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
      [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
      [_?.flag && "flag"]: _?.flag,
      [_?.domain && "domain"]: _?.domain?.[0]?.domain,
      [_?.explore_meta && "explore_meta"]: _?.explore_meta?.[0],
    }

    set_screen_state(temp_screen_state);

    let link = `${temp_screen_state?.domain}${window.location.pathname}${window.location.search}`;
    window.open(link, "_self");

    alert.setSnack({
      ...alert, open: true,
      severity: AlertProps.severity.info,
      msg: `${t("Region Changed to")} ${temp_screen_state?.region} ${t("successfully.")}`,
    });
  }

  const handleImageOnLoad = () => {
    setIsBackgroundImageLoading(false);
  }

  const handleImageOnError = () => {
    set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
  }

  const handleExploreCard = () => {
    let finalUrl = screen_state?.explore_meta?.link ?? "";

    if (finalUrl && !finalUrl.startsWith("http://") && !finalUrl.startsWith("https://")) {
      finalUrl = "https://" + (finalUrl.startsWith("www.") ? finalUrl?.replace("www.", "") : finalUrl);
    }

    finalUrl && window.open(finalUrl, "_blank", "noreferrer");
  }

  return (<div>
    {is_loading
      ? <></>
      : <Container className={classes_1.mob_right_section_card} maxWidth="sm">
        <TitleBarNew is_back_button={[Routes?.welcomePage, Routes?.welcome]?.includes?.(state?.origin)}
          title={t("Log In")} goBack={handleGoBack} />
        <Grid container className={classes_1.mob_content}>
          <Grid item xs={12} className={classes_1.mob_body_items}
            sx={{ height: size?.height - 161, overflow: "overlay" }}>
            <Box className={classes_1.mob_background}>
              {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes_1.mob_background_image} />}
              <img className={classes_1.mob_background_image}
                style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                src={screen_state?.mob_background} alt=""
                onLoad={handleImageOnLoad}
                onError={handleImageOnError} />
            </Box>
            {Boolean(screen_state?.explore_meta?.is_active) &&
              <>
                <Box height={"16px"} />
                <Stack className={classes_1.exploreCard}
                  direction={"row"} columnGap={"10px"}
                  alignItems={"center"} justifyContent={"space-between"}
                  onClick={handleExploreCard}>
                  <Stack direction={"row"} columnGap={"10px"}
                    alignItems={"center"}>
                    <ExploreIcon />
                    <Typography className={classes_1.exploreInformation}>
                      {screen_state?.explore_meta?.information}
                    </Typography>
                  </Stack>
                  <Typography className={classes_1.exploreCTA}>
                    {screen_state?.explore_meta?.cta_text}
                  </Typography>
                </Stack>
              </>}
            <Box height={"16px"} />
            <Stack direction={"row"} columnGap={"16px"}
              justifyContent={"space-between"} alignItems={"start"}>
              <Typography className={classes_1.web_title}>
                {t("Login as Security")}
              </Typography>
              {!Boolean(screen_state?.is_custom_domain) &&
                <Box className={classes_1.region_box} onClick={handleRegion}>
                  <img className={classes_1.region_flag} src={screen_state?.flag} alt="" />
                  {is_region_open
                    ? <KeyboardArrowUpRoundedIcon fontSize="small" color="#4E5A6B" />
                    : <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                  }
                </Box>
              }
            </Stack>
            <Box height={"16px"} />

            <TextBox
              value={login.email} isRequired
              onChange={(e) => updateState("email", e.target.value)}
              label={Message.English.login.email.label}
              placeholder={Message.English.login.email.placeholder}
              isError={login.error.email.length > 0}
              errorMessage={login.error.email} />
            <Box height={"24px"} />
            <Password
              value={login.password} isRequired
              onChange={(e) => updateState("password", e.target.value)}
              label={t("Password")}
              placeholder={t("Enter Password")}
              isError={login.error.password.length > 0}
              errorMessage={login.error.password} />
            <Box height={"8px"} />
            <Typography className={classes_1.web_forget_password}
              onClick={() => setShowDrawer(true)}>
              {t("Forgot password?")}
            </Typography>
          </Grid>
          <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_bottom_items}>
            <Stack direction={"column"} alignItems={"start"}>
              <Button className={classes_1.mob_set_up_new_button} disabled={disable}
                onClick={(e) => {
                  setDisable(true)
                  silentOTP(e, login, alert, history, isIamValideToLogin, setDisable, welcomePageAuthToken, deviceToken)
                }}>
                <Typography className={classes_1.mob_set_up_new_button_text}>
                  {t("Login")}
                </Typography>
              </Button>
              <Box height={"16px"} />
              <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                justifyContent={"space-between"} alignItems={"center"}>
                <img className={classes_1.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                {app_version &&
                  <Typography className={classes_1.version_no}>
                    {`V ${app_version}`}
                  </Typography>
                }
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {/* Region List */}
        <Menu
          id="region-menu_list" open={is_region_open}
          anchorEl={regionAnchorEl} elevation={0}
          className={classes_1.region_menu_list}
          onClose={() => setRegionAnchorEl(null)}
          MenuListProps={{ "aria-labelledby": "basic-button", }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
          transformOrigin={{ vertical: "top", horizontal: "right", }}>
          {region_list?.map?.((_) => {
            return (
              <MenuItem
                onClick={() => handleRegionClick(_)}
                className={_?.id === screen_state?.id
                  ? classes_1.selected_region_menu_item
                  : classes_1.unselected_region_menu_item}>
                <img className={classes_1.region_flag} src={_?.flag} alt="" />
                <Box width={"10px"} />
                <Typography className={_?.id === screen_state?.id
                  ? classes_1.selected_region_name
                  : classes_1.unselected_region_name}>
                  {_?.region}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
        {/* Drawer */}
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            open={showDrawer}
            anchor="bottom"
            onClose={() => setShowDrawer(false)}
          >
            <Container maxWidth="sm" style={{ overflow: "hidden" }}>
              <div className={classes.contentBottom}>
                <Box
                  display="flex"
                  p={2}
                  alignItems="center"
                  className={classes.titleroot}
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.bottomTitle}>
                      {t("Reset_Password")}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setShowDrawer(false)}
                    >
                      <CloseIcon color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <ResetPassword onDrawerClose={onDrawerClose} />
              </div>
            </Container>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Dialog fullWidth={true} maxWidth="sm" open={showDrawer}>
            <Container maxWidth="sm" style={{ overflow: "hidden" }}>
              <div className={classes.contentBottom}>
                <Box
                  display="flex"
                  p={2}
                  alignItems="center"
                  className={classes.titleroot}
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.bottomTitle}>
                      {t("Reset_Password")}

                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setShowDrawer(false)}
                    >
                      <CloseIcon color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <ResetPassword onDrawerClose={onDrawerClose} />
              </div>
            </Container>
          </Dialog>
        </Hidden>
      </Container>}
  </div>);
};
export default withNamespaces("security_guard_keys")(Login)
