import * as React from "react"
const DeliveryReceivedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 117194">
      <g data-name="icons8-package (1)">
        <path
          fill="#4caf50"
          d="M12 3.837 9.511 8.7 12 15.242l7.526-3.647V3.837Z"
          data-name="Path 101899"
        />
        <path
          fill="#81c784"
          d="M4.474 3.837v7.758L12 15.242V3.837Z"
          data-name="Path 101900"
        />
        <path
          fill="#a5d6a7"
          d="M4.474 3.837 12 7.137l7.526-3.3L12 1Z"
          data-name="Path 101901"
        />
        <path
          fill="#ffb74d"
          d="M21.668 7.368a1.239 1.239 0 0 0-.984 1.216v5.153l-1.505 2.605.753-1.389a1.159 1.159 0 0 0-.289-1.505 1.141 1.141 0 0 0-1.737.347l-2.547 4.632a3.85 3.85 0 0 0-.521 1.968V23h4.632v-1.737l3.068-5.616a3.426 3.426 0 0 0 .405-1.679V8.526a1.1 1.1 0 0 0-1.275-1.158Zm-19.337 0a1.239 1.239 0 0 1 .984 1.216v5.153l1.505 2.605-.753-1.389a1.159 1.159 0 0 1 .289-1.505 1.141 1.141 0 0 1 1.737.347l2.547 4.632a3.85 3.85 0 0 1 .521 1.968V23H4.532v-1.737l-3.069-5.616A3.112 3.112 0 0 1 1 14.026v-5.5a1.172 1.172 0 0 1 1.332-1.158Z"
          data-name="Path 101902"
        />
        <path
          fill="#ff9800"
          d="M20.279 15.763a1.528 1.528 0 0 0 .405-1.1v-.926l-3.126 5.211Zm-16.558 0a1.828 1.828 0 0 1-.405-1.1v-.926l3.126 5.211Z"
          data-name="Path 101903"
        />
        <g data-name="Group 117198">
          <path
            fill="#ffcc80"
            d="M19.642 14.605c-.174.405-.521.232-.926.058s-.637-.405-.463-.753a.779.779 0 0 1 1.042-.347.721.721 0 0 1 .347 1.042Zm-15.284 0c.174.405.521.232.926.058s.637-.405.463-.753a.779.779 0 0 0-1.042-.347.763.763 0 0 0-.347 1.042Z"
            data-name="Path 101904"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default DeliveryReceivedIcon
