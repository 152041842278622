import React from "react";
import { withBottombar } from "../../HOCs";
import Info from "./info";

class InfoParent extends React.Component {
    render() {
        return <Info />;
    }
}

export default withBottombar(InfoParent, {
    titleBar: true, text: "Info",
    hideBackButton: true
});