export const Car = (props) => (
    <svg
        width={31}
        height={65}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            data-name="Path 98957"
            d="M1.82 17.479a29.971 29.971 0 0 1-.3-4.19v-2.982C1.519 4.891 5.911.499 11.327.5h8.377c5.414.003 9.801 4.393 9.8 9.807v2.982a29.971 29.971 0 0 1-.3 4.19l-.585 4.113a29.733 29.733 0 0 0-.292 4.6l.239 17.079c.015 1.004.173 2 .469 2.959.311 1.004.469 2.049.469 3.1v7.58a7.582 7.582 0 0 1-7.583 7.583H9.111a7.582 7.582 0 0 1-7.583-7.583v-7.58c0-1.051.159-2.096.469-3.1.298-.959.456-1.955.469-2.959l.239-17.079a29.733 29.733 0 0 0-.292-4.6l-.585-4.113H1.82Z"
            fill="#e0e0e0"
            origin="-0.054317 -0.007813"
        />
        <path
            data-name="Path 98958"
            d="M28.437 18.328c.188-1.309.282-2.629.281-3.951v-2.813a9.255 9.255 0 0 0-9.253-9.253h-7.9a9.255 9.255 0 0 0-9.253 9.253v2.813a27.641 27.641 0 0 0 .281 3.951l.554 3.882c.204 1.437.296 2.888.277 4.34l-.223 16.117a9.841 9.841 0 0 1-.442 2.793 9.822 9.822 0 0 0-.446 2.928v7.153a7.157 7.157 0 0 0 7.156 7.156h12.1a7.157 7.157 0 0 0 7.156-7.156v-7.153c0-.993-.15-1.98-.446-2.928a9.841 9.841 0 0 1-.442-2.793l-.223-16.117a28.248 28.248 0 0 1 .277-4.34l.554-3.882h-.008Z"
            fill="#fcfcfc"
            origin="-0.087533 -0.03827"
        />
        <path
            data-name="Path 98959"
            d="M2.278 19.549a8.953 8.953 0 0 0-1.866 1.635c-.265.531 0 1.016.177.973.177-.043 2.255-.927 2.255-.927l-.566-1.677v-.004Z"
            fill="#e2dfe6"
            origin="-0.117883 -7.488143"
        />
        <path
            data-name="Path 98960"
            d="M28.754 19.549a8.953 8.953 0 0 1 1.866 1.635c.265.531 0 1.016-.177.973-.177-.043-2.255-.927-2.255-.927l.566-1.677v-.004Z"
            fill="#e2dfe6"
            origin="-11.079748 -7.488143"
        />
        <g data-name="Group 105279" origin="-0.126058 -0.018807">
            <g data-name="Group 105278">
                <path
                    data-name="Path 98961"
                    d="M8.804 2.528a1.454 1.454 0 0 0 .092-.758A9.355 9.355 0 0 0 4.56 5.333 8.937 8.937 0 0 1 6.726 3.99c.8-.346 1.743-.658 2.078-1.462Z"
                    fill="#fff"
                />
                <path
                    data-name="Path 98962"
                    d="M22.227 2.528a1.454 1.454 0 0 1-.092-.758 9.355 9.355 0 0 1 4.336 3.563 8.937 8.937 0 0 0-2.166-1.343c-.8-.346-1.743-.658-2.078-1.462Z"
                    fill="#fff"
                />
                <path
                    data-name="Path 98963"
                    d="M5.91 35.698c-.042-3.351-.123-6.421-.123-7.549 0-3.382-2.52-6.564-2.52-6.564v13.112c.866.374 1.75.685 2.643 1.001Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98964"
                    d="M5.841 44.031c.1-2.128.108-5.056.077-7.849a37.812 37.812 0 0 1-2.65-.827v9.946a35.67 35.67 0 0 0 2.573-1.266Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98965"
                    d="M3.267 46.024v3.47s2.19-.065 2.52-4.575c.008-.123.02-.25.027-.381a29.015 29.015 0 0 1-2.543 1.485Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98966"
                    d="M18.92 1.166h-6.808a9.95 9.95 0 0 0-2.385.289 5.233 5.233 0 0 0 2.943.908h5.694a5.212 5.212 0 0 0 2.943-.908 10.147 10.147 0 0 0-2.385-.289Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98967"
                    d="M25.121 35.698c.042-3.351.123-6.421.123-7.549 0-3.382 2.52-6.564 2.52-6.564v13.112c-.865.374-1.754.685-2.643 1.001Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98968"
                    d="M25.19 44.031c-.1-2.128-.108-5.056-.077-7.849a37.812 37.812 0 0 0 2.651-.827v9.946a35.67 35.67 0 0 1-2.574-1.266Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98969"
                    d="M27.764 46.024v3.47s-2.189-.065-2.52-4.575c-.008-.123-.019-.25-.027-.381a29.015 29.015 0 0 0 2.543 1.485Z"
                    fill="#45474a"
                />
                <path
                    data-name="Path 98970"
                    d="M26.406 19.384a12.171 12.171 0 0 1-2.3 5.487 1.109 1.109 0 0 1-.989.439c-1.274-.108-4.382-.342-7.6-.342s-6.329.235-7.6.342a1.109 1.109 0 0 1-.989-.439 12.171 12.171 0 0 1-2.3-5.487 1.125 1.125 0 0 1 .566-1.143c1.135-.619 4.01-1.7 10.323-1.7s9.192 1.077 10.323 1.7a1.122 1.122 0 0 1 .566 1.143Zm-2.42 27.525a20.937 20.937 0 0 1-16.937 0 25.7 25.7 0 0 0-1.058 6.079 1.573 1.573 0 0 0 .87 1.516 19.594 19.594 0 0 0 17.314 0 1.573 1.573 0 0 0 .87-1.516 25.9 25.9 0 0 0-1.059-6.078Z"
                    fill="#45474a"
                />
            </g>
            <path
                data-name="Path 98971"
                d="M6.372 62.688a5.638 5.638 0 0 1-2.255-1.316 2.252 2.252 0 0 0-.53-.427.666.666 0 0 0-.463-.05 5.656 5.656 0 0 0 3.821 2.27v-.015c-.008-.265-.323-.389-.573-.466Z"
                fill="#ff320e"
            />
            <path
                data-name="Path 98972"
                d="M24.66 62.688a5.638 5.638 0 0 0 2.254-1.316 2.252 2.252 0 0 1 .531-.427.666.666 0 0 1 .462-.05 5.656 5.656 0 0 1-3.82 2.27v-.015c.007-.265.322-.389.572-.466Z"
                fill="#ff320e"
            />
        </g>
    </svg>
)