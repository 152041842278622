import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, remCalc, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
      root: {
            height: "100vh",
            backgroundColor: "white",
      },
      grid: {
            height: ({ size }) => size?.height,
            alignItems: "center",
      },
      left_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                  display: "none"
            },
      },
      right_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            display: "flex",
            justifyContent: "end",
      },
      web_right_section_card: {
            display: "contents",
            height: ({ size }) => size?.height,
            padding: "0px",
      },
      web_back_button: {
            cursor: "pointer",
            height: "32px",
            width: "32px",
      },
      web_content: {
            height: "100vh",
            overflow: "overlay",
            display: "flex",
            position: "relative",
            padding: "40px",
            backgroundColor: "white",
      },
      web_logo: {
            height: "32px",
            objectFit: "contain",
      },
      web_title: {
            fontSize: remCalc(16),
            fontFamily: Bold,
            color: theme.typography.color.primary,
      },
      web_description: {
            fontSize: remCalc(16),
            fontFamily: SemiBold,
            color: theme.typography.color.secondary,
      },
      web_forget_password: {
            fontSize: remCalc(12),
            fontFamily: SemiBold,
            color: "#b20606",
            textAlign: "end",
            cursor: "pointer",
      },
      web_login_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#b20606",
            border: "1px solid #5078E1",
      },
      web_login_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: theme.typography.color.white,
      },
      web_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: theme.palette.background.tertiary1,
            },
      },
      web_log_in_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: theme.typography.color.primary_2,
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  minWidth: ({ size }) => size?.width > 599 && "500px",
                  maxWidth: ({ size }) => size?.width > 599 && "500px",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      drawer_header_grid: {
            display: "flex",
            alignItems: "center",
            justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
            padding: "16px",
      },
      drawer_header_text: {
            marginLeft: "12px",
            fontSize: remCalc(16),
            fontFamily: ExtraBold,
            color: theme.typography.color.primary,
      },
      drawer_content_box: {
            height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
            overflow: "overlay",
            padding: "16px",
      },
      drawer_button_box: {
            padding: "16px",
            borderTop: `1px solid ${theme.palette.border.secondary}`
      },
      selectCompanyBorder: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: theme.palette.borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: `2px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
            width: '100%'
      },
      selectCompany: {
            padding: '14px',
            border: `2px solid ${theme.palette.border.secondary}`,
            margin: '8px 0px',
            borderRadius: theme.palette.borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            width: '100%'
      },
      companySelect: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
      },
      comapnyImage: {
            width: "50px",
            height: "50px",
            borderRadius: theme.palette.borderRadius,
            border: `1px solid ${theme.palette.border.secondary}`,
      },
      companyName: {
            color: theme.typography.color.primary,
            fontFamily: Bold,
            fontSize: "0.875rem",
            marginLeft: '12px'
      },
      tickIcon: {
            color: "#5078e1",
            fontSize: "2.5rem"
      },
      mob_right_section_card: {
            padding: "0px",
      },
      mob_content: {
            // height: "calc(100vh - 54px)",
            // overflow: "overlay",
      },
      mob_body_items: {
            padding: "16px",
      },
      mob_background: {
            height: "235px",
            borderRadius: theme.palette.borderRadius,
      },
      mob_background_image: {
            height: "235px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
      },
      mob_bottom_items: {
            padding: "16px",
      },
      mob_logo: {
            height: "32px",
            objectFit: "contain",
      },
      mob_description: {
            fontSize: remCalc(20),
            fontFamily: SemiBold,
            color: "#FEF4F4",
            textShadow: "0px 6px 10px #00000033",
      },
      mob_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#5078E1",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: "#5078E1",
            },
      },
      mob_set_up_new_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: "white",
      },
      mob_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#ffffff75",
            "&:hover": {
                  backgroundColor: "#ffffff75",
            },
      },
      mob_log_in_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: "white",
      },
      mob_terms_of_use_description: {
            width: "100%",
            fontSize: remCalc(12),
            fontFamily: Regular,
            color: "white",
      },
      mob_terms_of_use_word: {
            fontSize: remCalc(12),
            fontFamily: Bold,
            color: "white",
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      version_no: {
            fontSize: remCalc(12),
            fontFamily: Regular,
            color: theme.typography.color.tertiary,
      },
      terms_of_use_word: {
            fontSize: remCalc(12),
            fontFamily: Regular,
            color: theme.typography.color.tertiary,
            cursor: "pointer",
      },
      powered_by_logo: {
            height: "11px",
            objectFit: "contain",
      },
}));