import { makeStyles } from "@mui/styles";
import { Regular, SemiBold, remCalc } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"white",
    overflow: "hidden",
    borderRadius:"8px",
    padding:"16px"
  },
  first_name:{
    fontSize:remCalc(14),
    color:theme.typography.color.primary,
    fontFamily:SemiBold
  },
  posted_at:{
    fontSize:remCalc(12),
    color:theme.typography.color.secondary,
    fontFamily:Regular
  },
  title:{
    fontSize:remCalc(14),
    color:"#071741",
    fontFamily:SemiBold,
    textWrap:"noWrap",
    textOverflow:"ellipsis",
    overflow:"hidden"
  },
  description:{
    fontSize:remCalc(12),
    color:theme.typography.color.secondary,
    fontFamily:Regular,
    margin:"0px !important"
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    fontFamily:SemiBold,
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  attachFile:{
    fontSize:remCalc(10),
    color:theme.typography.color.secondary,
    fontFamily:SemiBold
  },
  attachBox:{
    backgroundColor:"#F2F4F7",
    borderRadius:"10px",
    padding:"4px 12px",
    width:"fit-content"
  },
  dot:{
    borderRadius:"50%",
    backgroundColor:"#E4E8EE",
    width:"6px",
    height:"6px"
  },
  likeIcon:{
    fontSize:remCalc(14),
    color:theme.typography.color.secondary,
    fontFamily:SemiBold,
    marginLeft:"5px"
  },
  bottomFix:{
    position:"absolute",
    bottom:"10px"
  },
  bottomImageFix:{
    position:"absolute",
    bottom:0,
    left:0,
    right:0,
    padding:"16px"
  }
}))