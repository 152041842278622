import React from "react";
import GetPassVerification from "./getpassVerification";

class GetPassVerificationParent extends React.Component {
  render() {
    return <GetPassVerification />;
  }
}

export default (GetPassVerificationParent);
