import { Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { TitleBar, TopNavBars } from "../components";
import { Routes } from "../router/routes";
import useWindowDimensions from "../utils/useWindowDimensions";
import BottomNavBar from "../components/bottomNavBar";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
  },

  screenPadding: {
    paddingBottom: "10px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    backgroundColor:(props)=>props?.bgColor?? "#F2F5FA",
    // margin: "64px auto auto auto",
    // overflow:"scroll"
  },
  screen: {
    position: "relative",
    // height:"100vh"
  },
  bottomNavbar: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    zIndex: 1,
  },
  topBar: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 999
  }

}));

const withBottombar = (Component, params) => (props) => {
  const classes = useStyles(params);
  const history = useHistory();
  const size = useWindowDimensions();
  
  const onTab1Clicked = () => {
    history.push(Routes.dashboard);
  };
  const onTab2Clicked = () => {
    history.push(Routes.gatePass);
  };
  const onTab3Clicked = () => {
    history.push(Routes.info);
  };
  const onTab4Clicked = () => {
    history.push(Routes.service);
  };
  // const onTab5Clicked = () => {
  //   history.push(Routes.profileView);
  // };
  const goBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
        <Box
          className={classes.screen}
        >
          {!params?.hideTopBar ? <Box className={classes.topBar}>
            {
              params?.titleBar ?
                <TitleBar hideBackButton={params?.hideBackButton} goBack={goBack} arrow={params?.goBack} text={params?.text} color={params?.color} />
                :
                <TopNavBars note={params?.note} handleClicknote={() => history.push(Routes.notification)} home={params?.home}
                  hideTopNavIcons={params?.hideTopNavIcons} primaryBackground={params?.primaryBackground} />
            }

          </Box> :
            <Box sx={{ height: 0 }}></Box>
          }

          <Box
            className={classes.screenPadding}
            // style={{ height: size?.height - 62 }}
            // style={{ height: size?.height - (params?.titleBar ? 125 : 130), overflow: "auto" }}
            style={{
              height: size?.height - (params?.titleBar ? 55 : 126),
              overflow: "auto",
              margin: params?.hideTopBar ? "auto" :  (params?.titleBar ? "55px auto auto auto" : "64px auto auto auto"),
            }}

          >
            <Component {...props}>{props.children}</Component>
          </Box>
          {!params?.hideBottom && <Box className={classes.bottomNavbar}>
            <BottomNavBar
              onTab1Clicked={onTab1Clicked}
              onTab2Clicked={onTab2Clicked}
              onTab3Clicked={onTab3Clicked}
              onTab4Clicked={onTab4Clicked}
            // onTab5Clicked={onTab5Clicked}
            />
          </Box>}
        </Box>
      </Container>
      
      {/* Content */}
    </div>
  );
};

export default withBottombar;
