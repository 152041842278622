
import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Bold, remCalc } from "../../utils";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: 18,
        borderRadius: 12
    },
    title: {
        color: "#031629",
        fontFamily: Bold,
        fontSize: remCalc(14)
    },
    inoutLogo: {
        height: 50
    },
    inoutRoot: {
        borderRadius: 12,
        border: "1px solid #CED3DD",
        padding: 12,
        marginTop: 14
    }
}));

export const SwiftInoutCard = ({ t = () => false }) => {

    const classes = useStyles();
    const history = useHistory();

    const scan_list = [
        {
            id: 1,
            name: t("Scan Visitor"),
            url: "/images/visitor_scan.svg",
            color: "#FFF8E7",
            border: "#FFEBB7",
            route: Routes.getPassVerification
        },
        {
            id: 2,
            name: t("Scan Worker"),
            url: "/images/worker_scan.svg",
            color: "#E6EFFF",
            border: "#C9D9F6",
            route: `${Routes.getPassVerification}${"?title="}${"Domestic"}`
        }
    ]

    const entry_list = [
        {
            id: 1,
            name: t("Vendor Entry"),
            url: "/images/vendor_entry_scan.svg",
            color: "#FFF8E7",
            border: "#FFEBB7",
            route: Routes.vendor
        },
        {
            id: 2,
            name: t("Service Provider Entry"),
            url: "/images/service_provider_scan.svg",
            color: "#E6EFFF",
            border: "#C9D9F6",
            route: Routes.swiftInOut
        },
        {
            id: 3,
            name: t("Walkin Entry Request"),
            url: "/images/walk_in_scan.svg",
            color: "#E6EFFF",
            border: "#C9D9F6",
            route: Routes.createWalkIn
        }
    ]

    return (
        <Box className={classes.root}>



            {/* worker and visitor scan */}
            <Grid container spacing={2}>
                {
                    scan_list?.map((val) => {
                        return (
                            <Grid item xs={6}>
                                <Box className={classes.cardRoot}
                                    sx={{ backgroundColor: val?.color, border: `1px solid ${val?.border}` }}
                                    onClick={() => history.push(val?.route)}
                                >
                                    <Typography className={classes.title}>{val?.name}</Typography>
                                    <Stack direction={"row"} justifyContent={"right"} style={{ marginTop: "-27px" }}>
                                        <img src={val?.url} alt="" />
                                    </Stack>
                                    <Box style={{ marginTop: "-27px" }}>
                                        <img src="/images/arrow.svg" alt="" />
                                    </Box>

                                </Box>

                            </Grid>
                        )
                    })
                }

            </Grid>
            {/* swift in swift out */}
            <Box >
                {
                    entry_list?.map((val) => {
                        return (

                            <Box className={classes.inoutRoot} display="flex" alignItems="center" onClick={() => history.push(val?.route)}>
                                <Box>
                                    <img src={val?.url} alt="" className={classes.inoutLogo} />
                                </Box>
                                <Box flexGrow={1} sx={{ marginInlineStart: "12px" }}>
                                    <Typography className={classes.title}>{val?.name}</Typography>
                                </Box>
                                <Box>
                                    <NavigateNextIcon sx={{ color: "#091B29" }} />

                                </Box>
                            </Box>
                        )
                    })
                }

            </Box>

        </Box>

    )
}