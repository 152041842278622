import {Box,Container} from "@mui/material";
import React from "react";
import {ChooseUnit,CreateForm} from "./components"
import { withNamespaces } from "react-i18next";
import { TitleBar } from "../../components";
import {useStyles} from "./styles";
import {useHistory} from "react-router-dom"
import { Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods } from "../../utils";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";

const intitialState={
    name:"",
    logo:null,
    reason:"",
    unit:null,
    gate:"",
    mobile:"",
    error:{
    name:"",
    reason:"",
    gate:""
    }
}

 const CreateWalkInRequest=({t=()=>false})=>{

    const [step,setStep]=React.useState(1);
    const classes=useStyles()
    const history=useHistory()
    const [data,setData]=React.useState({...intitialState});
    const alert = React.useContext(AlertContext);


    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
      };


      const onSubmit=()=>{
        const payload = {
            gate_id:data?.gate?.value,
            person_image:data?.logo?.src,
            person_name:data?.name,
            reason:data?.reason,
            unit_id:data?.unit?.id,
            agreement_unit_user:data?.unit?.agreement_unit_user,
            mobile_no:data?.mobile?.mobile,
            mobile_no_country_code:data?.mobile?.mobile_code,
            company_id:data?.unit?.company_id,
            trigger_to:data?.unit?.user?.id

        }

        NetworkCall(`${config.api_url}/walkInout/create`, NetWorkCallMethods.post, payload, null, true, false)
            .then((response) => {

                console.log(response)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Request Successfully Created"),
                  });
                  history.push(`${Routes.walkInDetails}?passId=${response?.data?.request_id}`)
            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Request Creation Failed"),
                  });
            });
      }

    const component={
        1:<ChooseUnit data={data} updateState={updateState} t={t} setStep={setStep} setData={setData}/>,
        2:<CreateForm data={data} updateState={updateState} t={t} setData={setData}onSubmit={onSubmit} />
    }

    const goBack=()=>{
        if(step===1){
            history.push(Routes.dashboard)
        }else{
            setStep(1)
        }
    }

   

    return(
        <Container maxWidth="sm" style={{padding:"0px"}}>
        <Box>
            <TitleBar goBack={goBack} text={t(step===1? "Walkin Entry Request":"Visitor Details")}/>
            <Box className={classes.root}>
            {component[step]}
            </Box>
        </Box>
        </Container>
    )
}
export default withNamespaces("walkInRequest")(CreateWalkInRequest)