import makeStyles from "@mui/styles/makeStyles";
import { SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    headTitle:{
        color:"#98A0AC",
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        margin:"12px 0px"
    },
    detailRoot:{
        backgroundColor:"#F6F6F6",
        
        overflow:"overlay"
    },
    trackCard:{
        backgroundColor:"white",
        boxShadow:"0px 3px 30px #4354721F",
        borderRadius:"12px",
        padding:"20px"
    },
    revokeBtn:{
        padding:"12px",
        fontSize:remCalc(14),
        fontFamily:SemiBold,
        borderRadius:"8px",
        boxShadow:"none"
    },
    btnRoot:{
        backgroundColor:"white",
        boxShadow:"0px -1px 6px #00000021"
    }

}));