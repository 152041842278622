import React from "react";
import { withBottombar } from "../../HOCs";
import ProfileView from "./profileView";
class ProfileViewParent extends React.Component {
  render() {
    return <ProfileView />;
  }
}

export default withBottombar(ProfileViewParent, { note: false, home: false });
