import * as React from "react"

export const FourWheel = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 96483"
        width={71.316}
        height={33.954}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 52970"
                    fill="#5078e1"
                    stroke="rgba(0,0,0,0)"
                    d="M0 0h71.316v33.954H0z"
                />
            </clipPath>
        </defs>
        <g
            data-name="Group 96482"
            clipPath="url(#a)"
            fill={props?.color ?? "#98a0ac"}
            stroke="rgba(0,0,0,0)"
        >
            <path
                data-name="Path 8986"
                d="M66.083 28.28a9.074 9.074 0 1 0-17.848-.038H25.382a9.089 9.089 0 1 0-17.894-.027 6.28 6.28 0 0 1-7.453-5.91c-.052-2.19-.04-4.383 0-6.574a5.063 5.063 0 0 1 4.986-4.96c1.437-.027 2.875-.022 4.312 0a1.166 1.166 0 0 0 1.022-.459c2.157-2.558 4.37-5.069 6.5-7.649A6.953 6.953 0 0 1 22.639 0c5.184.055 10.37.032 15.555.009A6.849 6.849 0 0 1 42.8 1.661c3.1 2.6 6.271 5.137 9.334 7.789a7.372 7.372 0 0 0 3.815 1.742c3.716.61 7.416 1.313 11.119 2a5.044 5.044 0 0 1 4.232 5.079c.012 1.7.029 3.394-.008 5.09a5.091 5.091 0 0 1-4.592 4.917c-.2.019-.406 0-.62 0M38.6 10.766v.025h7.85c.472 0 .917-.035 1.09-.569.166-.515-.125-.842-.5-1.137-2.39-1.878-4.766-3.775-7.176-5.626a2.322 2.322 0 0 0-1.29-.453c-2.616-.042-5.233-.028-7.849-.017a1.7 1.7 0 0 0-1.848 1.851c-.013 1.32-.008 2.64 0 3.959a1.736 1.736 0 0 0 1.95 1.964c2.593.01 5.186 0 7.779 0m-19.1 0c1.224 0 2.448.018 3.672-.006A1.656 1.656 0 0 0 24.9 9.076c.029-1.459.027-2.919 0-4.377a1.667 1.667 0 0 0-1.7-1.706 7.478 7.478 0 0 0-1.76.021 2.794 2.794 0 0 0-1.46.689C18.359 5.5 16.8 7.359 15.254 9.226a1.145 1.145 0 0 0-.222.991 1.292 1.292 0 0 0 .945.523c1.174.062 2.353.024 3.53.022m12.747 6.218c.423 0 .848.029 1.268-.006a1.691 1.691 0 0 0 1.569-1.688 1.674 1.674 0 0 0-1.578-1.675 24.128 24.128 0 0 0-2.465 0 1.675 1.675 0 0 0-1.6 1.651 1.694 1.694 0 0 0 1.612 1.717c.4.028.8 0 1.2 0"
            />
            <path
                data-name="Path 8987"
                d="M16.448 19.251a7.351 7.351 0 1 1-7.375 7.294 7.37 7.37 0 0 1 7.375-7.294m3.37 7.369a3.391 3.391 0 1 0-3.37 3.374 3.4 3.4 0 0 0 3.37-3.374"
            />
            <path
                data-name="Path 8988"
                d="M57.116 33.953a7.351 7.351 0 1 1 7.4-7.27 7.36 7.36 0 0 1-7.4 7.27m3.436-7.33a3.391 3.391 0 1 0-3.373 3.371 3.4 3.4 0 0 0 3.373-3.371"
            />
        </g>
    </svg>
)

