import * as React from "react"
const SearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      fill="#98a0ac"
      d="M6.12 0A6.12 6.12 0 1 0 9.8 10.995l2.759 2.758a.844.844 0 1 0 1.194-1.194L10.995 9.8A6.106 6.106 0 0 0 6.12 0Zm0 1.688a4.433 4.433 0 0 1 3.228 7.469.844.844 0 0 0-.189.189A4.432 4.432 0 1 1 6.12 1.688Z"
      data-name="icons8-search (2)"
    />
  </svg>
)
export default SearchIcon
