import { NetWorkCallMethods } from "../utils";
import { NetworkCall } from "../networkcall";
import { config } from "../config";
import { AlertProps, LocalStorageKeys, SemiBold } from "./constants";
import { BiCycle, Car, TwoWheeler } from "../assets";
import Compressor from "compressorjs";

const companyID = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))?.[0]?.company_id


export const mapResult = (map) => {
      let updateData = {
            latitude: map?.lat,
            longitude: map?.lng
      };
      if (map) {
            let keys = ['doorNo', 'addressLineOne', 'addressLineTwo', 'landmark', 'area', 'district', 'city', 'state', 'country', 'pincode', 'zipcode'];
            let findAddress = map?.result?.address_components;
            keys?.map(_ => {
                  switch (_) {
                        case 'doorNo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["premise"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineOne':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["establishment", "street_number"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineTwo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["neighborhood", "route",]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'landmark':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('landmark'))?.long_name ?? ''
                              break;
                        case 'area':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes("political", "locality"))?.long_name ?? ''
                              break;
                        case 'district':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'city':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'state':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_1'))?.long_name ?? ''
                              break;
                        case 'country':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('country'))?.long_name ?? ''
                              break;
                        case 'pincode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        case 'zipcode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        default:
                              return null
                  }
                  return _
            })
      }

      return updateData
}

// File Upload
export const fileUpload = async (file, extra_param, alert) => {
      return new Promise(async (resolve, reject) => {
            try {
                  const formData = new FormData();
                  file && formData.append("files", file);
                  extra_param?.companyId && formData.append("companyId", `${extra_param?.companyId}`);
                  extra_param?.type && formData.append("type", `${extra_param?.type}`);
                  extra_param?.tenantId && formData.append("tenantId", `${extra_param?.tenantId}`);

                  let uploaded_file_res = await NetworkCall(
                        `${config.api_url}/file-upload`,
                        NetWorkCallMethods.post,
                        formData, null, true, false
                  );

                  resolve(uploaded_file_res?.data?.fileUrls)
            } catch (error) {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something Went Wrong",
                  });
            }
      });
};

/**
 * Single File Upload
 * @param {File} file - Single file object
 * @param {*} extra_param - Object contains any key and value
 * @param {context} alert - Alert Context to show snackbar
 * @param {int} allowed_image_size - Allowed image size
 * @returns {object} - Uploaded file name and S3 url
 */

// Single File Upload
export const singleFileUpload = async (file, extra_param, alert, allowed_image_size) => {
      return new Promise(async (resolve, reject) => {
            try {
                  let uploaded_file = []
                  let file_type = file?.type.split("/")?.[0]
                  if (file_type === "image") {
                        new Compressor(file, {
                              quality: 0.8,
                              async success(result) {
                                    let bytes = result?.size
                                    let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                                    if (finalSize <= allowed_image_size) {
                                          uploaded_file = await fileUpload(result, extra_param, alert)
                                          resolve(uploaded_file)
                                    } else {
                                          alert.setSnack({
                                                ...alert, open: true,
                                                severity: AlertProps.severity.error,
                                                msg: "Please upload less then 5 MB image",
                                          });
                                          resolve(null)
                                    }
                              },
                              error(err) {
                                    alert.setSnack({
                                          ...alert, open: true,
                                          severity: AlertProps.severity.error,
                                          msg: "Something Went Wrong",
                                    });
                              },
                        })
                  } else {
                        let bytes = file?.size
                        let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                        if (finalSize <= allowed_image_size) {
                              uploaded_file = await fileUpload(file, extra_param, alert)
                              resolve(uploaded_file)
                        } else {
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.error,
                                    msg: "Please upload less then 5 MB file",
                              });
                              resolve(null)
                        }
                  }
            } catch (error) {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something Went Wrong",
                  });
            }
      });
};
/**
 * Multi File Upload
 * @param {FileList} files - Array of file object
 * @param {*} extra_param - Object contains any key and value
 * @param {context} alert - Alert Context to show snackbar
 * @param {int} allowed_image_size - Allowed image size
 * @param {Boolean} priority - Boolean to add asset type to each object - default - true
 * @returns  {object} - Uploaded file name and S3 url
 */

// Multi File Upload
export const multiFileUpload = async (data, extra_param, alert, allowed_image_size) => {
      return new Promise(async (resolve, reject) => {
            try {
                  let uploaded_files = []
                  for (let i = 0; i < data.length; i++) {
                        let uploaded_file = await singleFileUpload(data[i], extra_param, alert, allowed_image_size)
                        if (uploaded_file?.[0]?.url) {
                              uploaded_files = [...uploaded_files, uploaded_file?.[0]]
                        }
                  }
                  resolve(uploaded_files)
            } catch (error) {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something Went Wrong",
                  });
            }
      });
};

// Get size of image
export function formatSizeUnits(bytes) {
      if (bytes >= 1073741824) {
            bytes = (bytes / 1073741824).toFixed(2) + " GB";
      } else if (bytes >= 1048576) {
            bytes = (bytes / 1048576).toFixed(2) + " MB";
      } else if (bytes >= 1024) {
            bytes = (bytes / 1024).toFixed(2) + " KB";
      } else if (bytes > 1) {
            bytes = bytes + " bytes";
      } else if (bytes === 1) {
            bytes = bytes + " byte";
      } else {
            bytes = "0 bytes";
      }
      return bytes;
}

// sigle images
export const singleImageUpload = async (data, type) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);
      if (companyID) {
            formData.append("companyId", `${companyID}`);

      }
      formData.append("type", `${type}`);
      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )

      return image.data.fileUrls[0].url

}

// multiple images
export const multiImageUpload = async (data, { companyId }) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);
      formData.append("companyId", `${companyId}`);

      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )
      return image.data.fileUrls[0].url

};


// const images type
export const assestType = {
      Facility_Images: 1,
      General_Images: 2,
      Videos: 3,
      Documents: 4,
      Three_sixty_Images: 5,
      Thumbnails: 6,
      Floor_Plan: 7

}

/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes) => {
      let result;

      const payload = { enumName: enumTypes, };

      await NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload, null, true, false
      ).then((res) => {
            result = res?.data?.data;
      }).catch((err) => {
            console.log(err)
      })

      return result;
};

//for key
export const keyMapSource = ({ enumType }) => {
      return enumType?.flatMap((item, index) => {
            return {
                  [`type${String.fromCharCode(64 + (index + 1))}`]: item?.value
            }
      }).reduce((old, item) => (
            { ...old, ...item }
      ), {})

}
//enum modifier
export const customEnumModifier = ({ filterKey, replaceKey, actualKey, data }) => {


      return data?.filter(i => i.value !== filterKey)?.map(i => {
            return {
                  label: i.label === actualKey ? replaceKey : i.label,
                  value: i.value
            }
      })



}

export const enumCatagory = {
      notifications_content_type: 'notifications_content_type',
      security_status_enum_type: 'security_status_enum_type',
      security_request_type: 'security_request_type',
      delivery_status_type: 'delivery_status_type',
      general_maintenance_type: 'general_maintenance_type',
      swift_type: 'swift_type',
      payment_mode: 'payment_mode'
}

export const vehicleComponent = {
      "FOUR-WHEELER": (<Car />),
      "TWO-WHEELER": (<TwoWheeler />),
      "BI-CYCLE": (<BiCycle />)
}

export const vehicleTypeSVG = {
      "FOUR-WHEELER": "/images/carSVG.svg",
      "TWO-WHEELER": "/images/bikeSVG.svg",
      "BI-CYCLE": "/images/bicycleSVG.svg"
}

export const vehicleTypeBGSVG = {
      "FOUR-WHEELER": "/images/parkingSlotCarBGSVG.svg",
      "TWO-WHEELER": "/images/parkingSlotBikeBGSVG.svg",
      "BI-CYCLE": "/images/parkingSlotBicycleBGSVG.svg"
}


/**
 * Generate Image URL
 * @param {String} image_url - S3 Image URL
 * @param {int} width - Required width
 * @param {int} height - Required height
 * @returns String - Converted image size URL
 */
export const generateImageUrl = (image_url, { width, height }) => {
      let image_url_split = image_url?.split('/')
      if (Boolean(image_url_split?.[image_url_split?.length - 2]?.includes("X"))) {
            image_url_split[image_url_split?.length - 2] = `${width}X${height}`
            return image_url_split?.join('/')
      } else {
            return image_url
      }
}

export const img_size = {
      small_square: { width: 100, height: 100, },
      small_rectangle: { width: 192, height: 108, },
      medium_square: { width: 500, height: 500, },
      medium_rectange: { width: 896, height: 504, },
      large_square: { width: 1000, height: 1000, },
      large_rectangle: { width: 1792, height: 1008, },
}
export const currencyFormater = (amount) => {
      return Intl.NumberFormat('en-US').format(amount)
}

export const RetainLocalStorage = () => {
      let i18nextLng = localStorage.getItem(LocalStorageKeys?.i18nextLng);
      let version = localStorage.getItem(LocalStorageKeys?.version);
      localStorage.clear();
      localStorage.setItem(LocalStorageKeys?.i18nextLng, i18nextLng ?? "en-US");
      localStorage.setItem(LocalStorageKeys?.version, version);
}
export const alphabetColors = {
      A: '#C24B23',
      B: '#647D18',
      C: '#2C7E4E',
      D: '#3275A5',
      E: '#5E3B77',
      F: '#773B54',
      G: '#A1652A',
      H: '#366D00',
      I: '#387C65',
      J: '#55678C',
      K: '#9636A9',
      L: '#A93666',
      M: '#867339',
      N: '#497639',
      O: '#228078',
      P: '#464D9F',
      Q: '#994092',
      R: '#890A3F',
      S: '#616116',
      T: '#267D26',
      U: '#3E6C75',
      V: '#453B77',
      W: '#773B63',
      X: '#CB2C6E',
      Y: '#6F2E2E',
      Z: '#962CCB',
    };
    
    export const stringAvatar = (name) => {
      let capName = name ? name.trim('').toUpperCase() : null
      return {
        sx: {
          bgcolor: alphabetColors[capName?.[0]],
          fontFamily:SemiBold
        },
        children: capName && (capName.indexOf(' ') >= 0 ? `${capName.split(' ')[0][0]}${capName.split(' ')[1][0]}` : `${capName.split(' ')[0][0]}`),
      };
    }